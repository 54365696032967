import { useFormContext, useWatch } from 'react-hook-form';
import { Toggle as TagToggle } from '@adeccoux/tag-ds';
import tagStyles from '@common/assets/styles/main.scss';
import cn from 'classnames';

import styles from './styles.module.scss';

const Toggle = (props) => {
  const { name, label } = props;
  const { register, getFieldState } = useFormContext();
  const error = getFieldState(name)?.error;
  const checked = useWatch({ name });
  const registeredProps = register(name);

  return (
    <div
      className={cn('tag-ds', styles.toggle, {
        [tagStyles.error]: error,
        [styles.error]: error,
      })}
    >
      <div className="toggle">
        {label && <p className="toggle-label">{label}</p>}
        <div className="toggle-container">
          <label
            className={cn({
              'toggle-pill_checked': checked,
              'toggle-pill': !checked,
              [styles.disabled]: props.disabled,
            })}
          >
            <input
              type="checkbox"
              className="toggle-input"
              style={{ visibility: 'hidden' }}
              id={name}
              {...props}
              {...registeredProps}
              onChange={(e) => {
                registeredProps.onChange(e);
                props.onChange?.(e.target.checked);
              }}
            />
            <span className="toggle-handle" />
          </label>
        </div>
      </div>
      {error && (
        <div className={styles.error}>{error?.message?.toString()}</div>
      )}
    </div>
  );
};

export default Toggle;

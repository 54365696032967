import { useEffect } from 'react';
import cn from 'classnames';
import { $getRoot, BLUR_COMMAND, COMMAND_PRIORITY_EDITOR } from 'lexical';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { ListNode, ListItemNode } from '@lexical/list';
import { LinkNode } from '@lexical/link';
import { HeadingNode } from '@lexical/rich-text';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $convertFromMarkdownString,
  $convertToMarkdownString,
  TRANSFORMERS,
} from '@lexical/markdown';
import { useAppInsights } from '@common/utils/AppInsightsProvider';
import { useController } from 'react-hook-form';

import theme from './theme';
import ToolbarPlugin from './ToolbarPlugin';
import './theme.scss';
import styles from './styles.module.scss';

const ReInitializePlugin = ({ control, name }) => {
  const { field } = useController({ name, control });
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (field.value) {
      editor?.update(() =>
        $convertFromMarkdownString(field.value, TRANSFORMERS)
      );
    }
  }, [field.value]);

  return null;
};

const OnBlurPlugin = ({ control, name }) => {
  const { field } = useController({ name, control });
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.registerCommand(
      BLUR_COMMAND,
      () => {
        const editorState = editor.getEditorState();
        editorState?.read(() => {
          const value = $convertToMarkdownString(TRANSFORMERS, $getRoot());
          field.onChange(value);
        });
        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [editor, field]);

  return null;
};

const OnDisablePlugin = ({ disabled }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.setEditable(!disabled);
  }, [disabled]);

  return null;
};

const RichTextEditor = (props) => {
  const { name, label, control, disabled, placeholder } = props;
  const {
    field,
    fieldState: { error },
  } = useController({ name, control });
  const { trackException } = useAppInsights();

  return (
    <div
      className={cn('tag-ds', 'input-wrapper', styles.richTextEditor, {
        [styles.disabled]: disabled,
      })}
    >
      <label className="caption">{label}</label>
      <div className={cn(styles.outline, { [styles.error]: error })}>
        <LexicalComposer
          initialConfig={{
            editorState: () =>
              $convertFromMarkdownString(field.value, TRANSFORMERS),
            theme,
            onError: trackException,
            namespace: 'Editor',
            nodes: [HeadingNode, ListNode, ListItemNode, LinkNode],
          }}
        >
          <div className={styles.controls}>
            <ToolbarPlugin disabled={disabled} />
          </div>
          <RichTextPlugin
            contentEditable={<ContentEditable className={styles.editor} />}
            placeholder={placeholder || ''}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <ListPlugin />
          <LinkPlugin />
          <HistoryPlugin />
          <OnBlurPlugin control={control} name={name} />
          <ReInitializePlugin control={control} name={name} />
          <OnDisablePlugin disabled={disabled} />
        </LexicalComposer>
      </div>
      {error && <div className={styles.error}>{error?.message}</div>}
    </div>
  );
};

export default RichTextEditor;

import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Grid, { Row } from '@common/Grid';
import tagStyles from '@common/assets/styles/main.scss';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { resetState } from '@redux/reducers/conversationDesignerReducer';

import styles from './styles.module.scss';

const OpenExistingDialog = ({ onClose }) => {
  const { t } = useTranslation('conversationDesigner', {
    keyPrefix: 'openExistingDialog',
  });

  const dispatch = useDispatch();

  const onDiscard = () => {
    dispatch(resetState());
    onClose();
  };

  return (
    <Grid>
      <div className={styles.openExistingDialog}>
        <div className={styles.modalWrapper}>
          <div
            className={cn(
              tagStyles.tagDs,
              tagStyles.modal,
              tagStyles.docs,
              styles.modal
            )}
          >
            <Row marginBottom={16}>
              <h3>{t('title')}</h3>
              <button
                id="close"
                type="button"
                className={tagStyles.modalCloseButton}
                onClick={onClose}
              >
                <CloseOutlinedIcon />
              </button>
            </Row>
            <>
              <Row marginBottom={32}>{t('description')}</Row>
              <Row>
                <div className={styles.buttonBar}>
                  <button onClick={onClose}>{t('open')}</button>
                  <button
                    onClick={onDiscard}
                    className={tagStyles.buttonSecondary}
                  >
                    {t('discard')}
                  </button>
                </div>
              </Row>
            </>
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default OpenExistingDialog;

import { useMemo } from 'react';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Drawer,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
} from '@adeccoux/tag-ds';
import { persistor, RootState } from '@redux/store';
import Grid, { Row, Col } from '@common/Grid';
import Input from '@common/reactHookForm/Input';
import RadioGroup from '@common/reactHookForm/Radio';
import Toggle from '@common/reactHookForm/Toggle';
import Table from '@common/Table';
import tagStyles from '@common/assets/styles/main.scss';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { useValidateTemplateJsonMutation } from '@redux/api/cxBotApiSlice';
import { usePostTemplateMutation } from '@redux/api/conversationApiSlice';
import { Template, Content } from '@appTypes/Conversation.types';
import SecurityGroupSelector from '@common/SecurityGroupSelector';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useGetGroupsForCurrentUserQuery,
  useListGroupsWithFilterQuery,
} from '@redux/api/securityApiSlice';
import Loader from '@common/Loader';
import { resetState } from '@redux/reducers/conversationDesignerReducer';

import styles from './styles.module.scss';
import validationSchema from './validations';
import { useBuildTemplateJSON } from '../utils';

const CreateConversationDrawer = ({ onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('conversationDesigner');

  const { designerSettings } = useSelector(
    (state: RootState) => state.conversationDesignerStore
  );

  const { data: groups, isLoading: isGetGroupsForCurrentUserLoading } =
    useGetGroupsForCurrentUserQuery();
  const { data: allGroups, isLoading: isListGroupsWithFilteLoading } =
    useListGroupsWithFilterQuery({});

  const [
    validateTemplateJson,
    { isLoading: isValidateTemplateJsonLoading, data: validationResult },
  ] = useValidateTemplateJsonMutation();
  const [postTemplate, { isLoading: isPostTemplateLoading }] =
    usePostTemplateMutation();

  const initialValues = useMemo(
    () => ({
      group: groups?.[0].path,
      name: designerSettings.conversationName,
      description: '',
      templateJson: '',
      conversationType: ['open', 'funnel']?.includes(
        designerSettings.conversationType
      )
        ? 'inbound'
        : 'outbound',
      isSchedulingEnabled: ['scheduling', 'funnel']?.includes(
        designerSettings.conversationType
      ),
    }),
    [groups]
  );

  const buildTemplateJSON = useBuildTemplateJSON();
  const onSubmit = async (values) => {
    const templateJSON = buildTemplateJSON();
    const validationResult = await validateTemplateJson(
      JSON.stringify(templateJSON)
    ).unwrap();

    if (validationResult?.isValid) {
      const response = await postTemplate({
        groupId: allGroups?.find((group) => group.path === values.group)?.id,
        template: {
          description: values.description,
          name: values.name,
          category: values.category,
          isVisible: values.isActive,
          conversationType: values.conversationType,
          type: 'Template',
          content: templateJSON as Content,
          createdBy: undefined,
          status: 'Published',
          messagingTemplates:
            validationResult.discoveredMessageTemplates as any,
        } as Template,
      }).unwrap();

      persistor.purge();
      dispatch(resetState());

      navigate(
        `/conversationTemplates/${
          response.id?.split('_')?.[0]
        }/conversationEditor`
      );
      onClose();
    }
  };

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema(t)) as any,
  });

  return (
    <div className={styles.drawerWrapper}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid>
            <Drawer className={cn(styles.drawer, 'tag-ds')} open>
              <DrawerHeader className={styles.header}>
                <h3>{t('createConversationDrawer.publishTemplate')}</h3>
                <button
                  id="close"
                  type="button"
                  className={tagStyles.upperMenuIcon}
                  onClick={onClose}
                >
                  <CloseOutlinedIcon />
                </button>
              </DrawerHeader>

              {isGetGroupsForCurrentUserLoading ||
              isListGroupsWithFilteLoading ||
              isValidateTemplateJsonLoading ||
              isPostTemplateLoading ? (
                <Loader />
              ) : (
                <>
                  <DrawerBody>
                    {validationResult?.errors?.length > 0 ? (
                      <>
                        <Row marginBottom={12}>
                          <div className={tagStyles.subtitle}>
                            {t('createConversationDrawer.hasErrors')}
                          </div>
                        </Row>
                        <Row marginBottom={12}>
                          <Col colSpan={16}>
                            <Table
                              data={validationResult?.errors}
                              columns={[
                                {
                                  id: 'severity',
                                  name: t('createConversationDrawer.severity'),
                                  formatter: (row) => (
                                    <>
                                      {row.severity === 'Error' && (
                                        <span className={styles.error}>
                                          <ErrorOutlineOutlinedIcon />
                                        </span>
                                      )}
                                      {row.severity === 'Warning' && (
                                        <span className={styles.warning}>
                                          <WarningAmberOutlinedIcon />
                                        </span>
                                      )}
                                    </>
                                  ),
                                },
                                {
                                  id: 'lineNumber',
                                  name: t('createConversationDrawer.line'),
                                },
                                {
                                  id: 'linePosition',
                                  name: t('createConversationDrawer.position'),
                                },
                                {
                                  id: 'message',
                                  name: 'Message',
                                  formatter: (row) => (
                                    <div className={styles.errorMessage}>
                                      {row.message}
                                    </div>
                                  ),
                                },
                              ]}
                            />
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <>
                        <Row marginBottom={12}>
                          <Col colSpan={16}>
                            <h4>
                              {t('createConversationDrawer.setVisibility')}
                            </h4>
                          </Col>
                        </Row>
                        <Row marginBottom={28}>
                          <Col colSpan={16}>
                            <SecurityGroupSelector
                              onChange={({ selectedGroupPath }) => {
                                methods.setValue('group', selectedGroupPath);
                              }}
                              overflowLayout={true}
                              fullWidth={true}
                            />
                          </Col>
                        </Row>
                        <Row marginBottom={12}>
                          <Col colSpan={16}>
                            <h4>
                              {t('createConversationDrawer.templateDetails')}
                            </h4>
                          </Col>
                        </Row>
                        <Row marginBottom={12}>
                          <Col colSpan={16}>
                            <Input
                              id="template-name-input"
                              name="name"
                              label={`${t('common:name')} *`}
                            />
                          </Col>
                        </Row>
                        <Row marginBottom={24}>
                          <Col colSpan={16}>
                            <Input
                              id="template-description-input"
                              name="description"
                              label={`${t('common:description')}`}
                            />
                          </Col>
                        </Row>
                        <Row marginBottom={12}>
                          <Col colSpan={8}>
                            <div className={tagStyles.subtitle}>
                              {t('newDesigner.conversationType')}
                            </div>
                            <RadioGroup
                              name="conversationType"
                              horizontal
                              choices={[
                                {
                                  label: t('newDesigner.outbound'),
                                  value: 'outbound',
                                },
                                {
                                  label: t('newDesigner.inbound'),
                                  value: 'inbound',
                                },
                              ]}
                            />
                          </Col>
                        </Row>
                        <Row marginBottom={16}>
                          <Col colSpan={16}>
                            <div className={tagStyles.subtitle}>
                              {t('newDesigner.scheduling')}
                            </div>
                            <div className={styles.scheduleToggle}>
                              <Toggle
                                id="isSchedulingEnabled"
                                name="isSchedulingEnabled"
                                label=""
                              />
                              <label htmlFor="isSchedulingEnabled">
                                {t('newDesigner.schedulingInfo')}
                              </label>
                            </div>
                          </Col>
                        </Row>
                        <Row marginBottom={12}>
                          <Col colSpan={16}></Col>
                        </Row>
                      </>
                    )}
                  </DrawerBody>
                  <DrawerFooter>
                    <Row marginBottom={12}>
                      <Col colSpan={16}>
                        <div className={cn(styles.buttonBar)}>
                          <button
                            id="close-button"
                            className={tagStyles.buttonSecondary}
                            onClick={onClose}
                          >
                            {t('common:cancel')}
                          </button>
                          <button
                            id="upload-confirm-button"
                            type="submit"
                            disabled={validationResult?.errors?.length > 0}
                          >
                            {t('createConversationDrawer.publish')}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </DrawerFooter>
                </>
              )}
            </Drawer>
          </Grid>
        </form>
      </FormProvider>
    </div>
  );
};

export default CreateConversationDrawer;

import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import isAfter from 'date-fns/isAfter';
import {
  ContentSwitcher,
  ContentSwitcherItem,
  FileUploader,
} from '@adeccoux/tag-ds';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FileOpenOutlinedIcon from '@mui/icons-material/FileOpenOutlined';
import Grid, { Col, Row } from '@common/Grid';
import tagStyles from '@common/assets/styles/main.scss';
import Card from '@common/Card';
import RadioGroup from '@common/reactHookForm/Radio';
import Input from '@common/reactHookForm/Input';
import Select from '@common/reactHookForm/Select';
import {
  useGetGroupsForCurrentUserQuery,
  useGetResourcesForGroupQuery,
} from '@redux/api/securityApiSlice';
import {
  useGetUserConversationDesignerItemsQuery,
  useLazyGetStoredConversationDesignerItemsByIdQuery,
} from '@redux/api/conversationApiSlice';
import {
  persistFormValues,
  setDesignerSettings,
  setTreeData,
} from '@redux/reducers/conversationDesignerReducer';
import Toggle from '@common/reactHookForm/Toggle';
import { readFile } from '@common/utils';

import styles from './styles.module.scss';
import { getNodesAndValuesFromTemplate } from './utils';

interface INewDesignerFormValues {
  languages: string[];
  conversationType: string;
  conversationName: string;
  defaultLanguage: string;
  isSchedulingEnabled: boolean;
}

const ContentSwitcherItems = (t: TFunction<'reporting', undefined>) => [
  {
    id: 'new',
    title: t('new'),
    icon: <AddOutlinedIcon />,
  },
  {
    id: 'recent',
    title: t('recent'),
    icon: <HistoryOutlinedIcon />,
  },
  {
    id: 'upload',
    title: t('upload'),
    icon: <FileUploadOutlinedIcon />,
  },
];

const NewDesigner = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('conversationDesigner', {
    keyPrefix: 'newDesigner',
  });
  const [activeItem, setActiveItem] = useState('new');
  const [isInvalidinvalidFile, setInvalidFile] = useState(false);

  const { data: groups } = useGetGroupsForCurrentUserQuery();
  const { data: resources, isFetching: isGetLanguagesLoading } =
    useGetResourcesForGroupQuery(
      {
        groupId: groups?.[0]?.id,
        resourceType: 'country',
      },
      { skip: !groups?.[0]?.id }
    );

  const languageOptions = useMemo(
    () =>
      resources?.languages?.map((language) => ({
        label: (
          <div className={styles.languageSelectorLabel}>
            <div
              className={styles.imageContainer}
              style={{
                background: `no-repeat center url(/assets/flags/${language.countryCode}.svg)`,
              }}
            />
            {language.languageName}
          </div>
        ),
        value: language.cultureCode,
      })),
    [resources]
  );

  const { data: recentTemplates } = useGetUserConversationDesignerItemsQuery();

  const methods = useForm<INewDesignerFormValues>({
    mode: 'all',
    defaultValues: {
      conversationName: '',
      conversationType: 'outbound',
      defaultLanguage: 'en-us',
      isSchedulingEnabled: false,
    },
    resolver: yupResolver(
      Yup.object().shape({
        conversationName: Yup.string().required(t('required')),
        conversationType: Yup.string().required(t('required')),
        defaultLanguage: Yup.string().required(t('required')),
      })
    ) as any,
  });

  const onSubmit = (values: INewDesignerFormValues) => {
    const getConversationType = () => {
      if (values.isSchedulingEnabled) {
        if (values.conversationType === 'outbound') {
          return 'scheduling';
        } else {
          return 'funnel';
        }
      } else {
        if (values.conversationType === 'outbound') {
          return 'refresh';
        } else {
          return 'open';
        }
      }
    };

    dispatch(
      setDesignerSettings({
        conversationName: values.conversationName,
        defaultLanguage: values.defaultLanguage,
        conversationType: getConversationType(),
        languages: [],
      })
    );
  };

  const [getStoredConversationDesignerItemsById] =
    useLazyGetStoredConversationDesignerItemsByIdQuery();

  const onOpenRecent = async (templateId: string) => {
    const template = await getStoredConversationDesignerItemsById(
      templateId
    ).unwrap();
    dispatch(
      setDesignerSettings({
        conversationName: template.name,
        defaultLanguage: template.content?.defaultLanguage,
        languages: template.content?.supportedLanguages?.filter(
          (language) => language !== template.content?.defaultLanguage
        ),
      })
    );
    const { treeData, formValues } = getNodesAndValuesFromTemplate(
      template.content
    );
    dispatch(setTreeData(treeData));
    dispatch(persistFormValues(formValues));
  };

  const onFileUpload = async (file: File[]) => {
    const fileType = file?.[0]?.name?.split('.')?.pop();
    const fileData = await readFile(file[0]);
    const template = JSON.parse(fileData as string);
    let generatorData = template;

    if (fileType === 'json') {
      generatorData = template?.metadata?.generatorData;
    }

    if (!generatorData || !generatorData?.items) {
      setInvalidFile(true);
      return;
    } else {
      setInvalidFile(false);
    }

    dispatch(
      setDesignerSettings({
        conversationName: generatorData.name,
        defaultLanguage: generatorData?.defaultLanguage,
        languages: generatorData?.supportedLanguages?.filter(
          (language) => language !== template?.defaultLanguage
        ),
      })
    );
    const { treeData, formValues } =
      getNodesAndValuesFromTemplate(generatorData);
    dispatch(setTreeData(treeData));
    dispatch(persistFormValues(formValues));
  };

  return (
    <div className={styles.newDesigner}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid>
            <Row className={styles.contentSwitcher} marginBottom={12}>
              <Col colSpan={8}>
                <ContentSwitcher
                  className="tag-ds"
                  defaultActiveItem={activeItem}
                  onChangeItem={setActiveItem}
                >
                  {ContentSwitcherItems(t)?.map((item) => (
                    <ContentSwitcherItem
                      id={item.id}
                      key={item.id}
                      title={item.title}
                      icon={item.icon}
                      active={activeItem}
                    />
                  ))}
                </ContentSwitcher>
              </Col>
            </Row>
            {activeItem === 'new' && (
              <>
                <Row marginBottom={12}>
                  <Col colSpan={8}>
                    <h3>{t('newConversation')}</h3>
                  </Col>
                </Row>
                <Row marginBottom={24}>
                  <Col colSpan={8}>
                    <Input
                      name="conversationName"
                      label={t('conversationName')}
                    />
                  </Col>
                </Row>
                <Row marginBottom={32}>
                  <Col colSpan={8}>
                    <Select
                      name="defaultLanguage"
                      label={t('defaultLanguage')}
                      menuPosition="fixed"
                      options={languageOptions}
                      isLoading={isGetLanguagesLoading}
                    />
                  </Col>
                </Row>
                <Row marginBottom={12}>
                  <Col colSpan={8}>
                    <div className={tagStyles.subtitle}>
                      {t('conversationType')}
                    </div>
                    <RadioGroup
                      name="conversationType"
                      horizontal
                      choices={[
                        { label: t('outbound'), value: 'outbound' },
                        { label: t('inbound'), value: 'inbound' },
                      ]}
                    />
                  </Col>
                </Row>
                <Row marginBottom={16}>
                  <Col colSpan={8}>
                    <div className={tagStyles.subtitle}>{t('scheduling')}</div>
                    <div className={styles.scheduleToggle}>
                      <Toggle
                        id="isSchedulingEnabled"
                        name="isSchedulingEnabled"
                        label=""
                      />
                      <label htmlFor="isSchedulingEnabled">
                        {t('schedulingInfo')}
                      </label>
                    </div>
                  </Col>
                </Row>
                <Row marginBottom={12}>
                  <Col colSpan={8}>
                    <div className={styles.buttonBar}>
                      <button type="submit">{t('continue')}</button>
                    </div>
                  </Col>
                </Row>
              </>
            )}
            {activeItem === 'recent' && (
              <>
                <Row marginBottom={12}>
                  <Col colSpan={8}>
                    <h3>{t('recentTemplates')}</h3>
                  </Col>
                </Row>
                <Row marginBottom={12}>
                  <Col colSpan={8}>
                    {Object.assign([], recentTemplates)
                      ?.sort((a, b) =>
                        isAfter(
                          new Date(a?.data?.updated),
                          new Date(b?.data?.updated)
                        )
                          ? -1
                          : 1
                      )
                      ?.map((template) => (
                        <Card
                          className={styles.currentTemplateCard}
                          key={template?.data.templateId}
                          onClick={() =>
                            onOpenRecent(template?.data.templateId)
                          }
                        >
                          <div>
                            <FileOpenOutlinedIcon />
                          </div>
                          <div>{template?.data?.name}</div>
                        </Card>
                      ))}
                  </Col>
                </Row>
              </>
            )}
            {activeItem === 'upload' && (
              <>
                <Row marginBottom={12}>
                  <Col colSpan={8}>
                    <h3>{t('chooseFile')}</h3>
                  </Col>
                </Row>
                <Row marginBottom={12}>
                  <Col colSpan={8}>
                    <div className="tag-ds">
                      <FileUploader
                        formats=".itf, .json"
                        helperText="Only .itf, .json"
                        label=""
                        max={1}
                        onChange={onFileUpload}
                        secondary
                        value={null}
                      />
                    </div>
                    {isInvalidinvalidFile && (
                      <div className={styles.errorMsg}>{t('invalidFile')}</div>
                    )}
                  </Col>
                </Row>
              </>
            )}
          </Grid>
        </form>
      </FormProvider>
    </div>
  );
};

export default NewDesigner;

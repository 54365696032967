import { useField } from 'formik';
import TagDatePicker, { DatePickerMode } from './datepicker';
import cn from 'classnames';
import parseISO from 'date-fns/parseISO';
import getUnixTime from 'date-fns/getUnixTime';
import * as Locales from 'date-fns/locale';
import tagStyles from '@common/assets/styles/main.scss';
import CloseIcon from '@mui/icons-material/Close';
import i18n from '@i18n/i18n';

import './overrides.scss';
import styles from './styles.module.scss';

const DatePicker = (props) => {
  const [field, meta, helpers] = useField(props);
  const { isDismissable, onDismiss, disabled } = props;

  const mapLanguageToLocales = (locales: typeof Locales, language: string) => {
    let newLocale = '';
    if (language === 'en') {
      newLocale = 'enUS';
    } else if (locales.hasOwnProperty(language.replace('-', ''))) {
      newLocale = language.replace('-', '')
    } else {
      newLocale = language.slice(0, 2);
    } 
    return locales[newLocale];
  }
  
  const localesObject = {...Locales};
  const locale = mapLanguageToLocales(localesObject, i18n?.language);
  
  const hasError = meta.touched && meta.error;

  const getValue = () => {
    if (props.mode === DatePickerMode.RANGE) {
      const value = [];
      if (field?.value?.[0]) {
        value.push(getUnixTime(parseISO(field?.value[0])) * 1000);
      }
      if (field?.value?.[1]) {
        value.push(getUnixTime(parseISO(field?.value[1])) * 1000);
      }
      return value;
    } else if (field?.value) {
      return getUnixTime(parseISO(field?.value)) * 1000;
    } else {
      return '';
    }
  };

  const onChange = (value) => {
    helpers.setTouched(true);
    if (props.mode === DatePickerMode.RANGE) {
      const values = [];
      if (value?.[0]) {
        values.push(new Date(value[0]).toISOString());
      }
      if (value?.[1]) {
        values.push(new Date(value[1]).toISOString());
      }
      helpers.setValue(values);
      props.onChange?.(values);
    } else {
      helpers.setValue(new Date(value).toISOString());
      props.onChange?.(new Date(value).toISOString());
    }
  };

  return (
    <div className={cn('tag-ds', styles.datepicker)}>
      {(!isDismissable || disabled) && (
        <label className={tagStyles.caption}>{props?.label}</label>
      )}
      {isDismissable && !disabled && (
        <span className={styles.dismissContainer}>
          <label className={tagStyles.caption}>{props?.label}</label>
          {field.value && (
            <button
              className={cn(tagStyles.upperMenuIcon)}
              id={`clear-group-${field.name}`}
              onClick={onDismiss}
              type="button"
            >
              <CloseIcon />
            </button>
          )}
        </span>
      )}
      <div className={cn({ [styles.errorBorder]: hasError })}>
        <TagDatePicker
          error={hasError}
          {...field}
          {...props}
          dateFormat={locale?.formatLong?.date({ width: 'short' })}
          defaultValue={getValue()}
          value={getValue()}
          onChange={onChange}
        />
      </div>
      {hasError && <div className={styles.error}>{meta.error}</div>}
    </div>
  );
};

export default DatePicker;

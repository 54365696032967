export const configs = [
  'CountrySupportsIntegration',
  'CountrySupportsPushNotification',
  'CountrySupportsTargetChannel',
  'CountrySupportsTextToApply',
  'CountrySupportsJobId',
  'CountrySupportsHybridCSVOption',
  'AdditionalHybridCsvValidations',
  'CountryDoesNotSupportFirstNameSMSValidation',
  'CountryDoesNotSupportStopSMSUrlValidation',
  'ReleaseSchedule',
  'CampaignSplitTolerance',
  'CountrySupportsWhatsApp',
  'ReportingSummaryDashboardEnabledItems',
];

export const featureFlags = [
  '.appconfig.featureflag/isFileUploadEnabled',
  '.appconfig.featureflag/isFunnelReportFilterByChannelEnabled',
  '.appconfig.featureflag/isCampaignCreationRedesignEnabled',
  '.appconfig.featureflag/isNewConversationDesignerEnabled',
  '.appconfig.featureflag/isNewConversationPreviewEnabled',
  '.appconfig.featureflag/isOutreachTemplatesEnabled',
];

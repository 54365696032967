import { useEffect } from 'react';
import {
  $insertNodes,
  $isRootOrShadowRoot,
  $createParagraphNode,
  createCommand,
  COMMAND_PRIORITY_EDITOR,
} from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $wrapNodeInElement } from '@lexical/utils';

import { $createVideoNode, VideoNode } from './VideoNode';

export const INSERT_VIDEO_COMMAND = createCommand('INSERT_VIDEO_COMMAND');

const VideoPlugin = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([VideoNode])) {
      throw new Error('VideoPlugin: VideoNode not registered on editor');
    }

    editor.registerCommand(
      INSERT_VIDEO_COMMAND,
      (payload) => {
        const videoNode = $createVideoNode(payload);
        $insertNodes([videoNode]);
        if ($isRootOrShadowRoot(videoNode.getParentOrThrow())) {
          $wrapNodeInElement(videoNode, $createParagraphNode).selectEnd();
        }

        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [editor]);

  return null;
};

export default VideoPlugin;

import * as Yup from 'yup';
import {
  ConversationDesignerNode,
  NodeType,
} from '@appTypes/Conversation.types';
import { TFunction } from 'i18next';

import { ROOT_NODE_ID } from './constants';

const validateBlockNameNotUnique = (
  flattenedItems: ConversationDesignerNode[],
  t: TFunction,
  node: ConversationDesignerNode,
  value: any,
  createError: (params?: Yup.CreateErrorOptions) => Yup.ValidationError
) => {
  if (
    flattenedItems?.filter((item) => parent[item.id]?.name === value.name)
      ?.length > 1
  ) {
    return createError({
      path: `${node.id}.name`,
      message: t('blockNameNotUnique'),
    });
  }
  return true;
};

const validateBlockNotReachable = (
  flattenedItems: ConversationDesignerNode[],
  t: TFunction,
  node: ConversationDesignerNode,
  createError: (params?: Yup.CreateErrorOptions) => Yup.ValidationError
) => {
  const siblings =
    flattenedItems.find((item) => item.id === node.parentId)?.children ||
    flattenedItems?.filter((item) => item.parentId === ROOT_NODE_ID);

  const successFailNodeIndex = siblings?.findIndex(
    (item) => item.type === NodeType.Success || item.type === NodeType.Failure
  );

  const nodeIndex = siblings?.findIndex((item) => item.id === node.id);

  if (successFailNodeIndex >= 0 && nodeIndex > successFailNodeIndex) {
    return createError({
      path: `${node.id}.name`,
      message: t('blockNotReachable'),
    });
  }
  return true;
};

const validateIntroBlock = (
  flattenedItems: ConversationDesignerNode[],
  t: TFunction,

  createError: (params?: Yup.CreateErrorOptions) => Yup.ValidationError
) => {
  if (flattenedItems[0]?.type !== NodeType.IntroMessage) {
    return createError({
      path: `${flattenedItems[0].id}.name`,
      message: t('fileNoIntroductoryMessage'),
    });
  }
  return true;
};

const validateExitBlock = (
  flattenedItems: ConversationDesignerNode[],
  t: TFunction,
  createError: (params?: Yup.CreateErrorOptions) => Yup.ValidationError
) => {
  if (
    flattenedItems[flattenedItems?.length - 1]?.type !== NodeType.Success &&
    flattenedItems[flattenedItems?.length - 1]?.type !== NodeType.Failure
  ) {
    return createError({
      path: `${flattenedItems[flattenedItems?.length - 1].id}.name`,
      message: t('fileNoExit'),
    });
  }
  return true;
};

export const createValidationSchema = (
  flattenedItems: ConversationDesignerNode[],
  defaultLanguage: string,
  languages: string[],
  t: TFunction
) => {
  const schema = {};
  flattenedItems.forEach((node) => {
    switch (node.type) {
      case NodeType.Condition: {
        schema[node.id] = Yup.object()
          .shape({
            name: Yup.string().required(t('blockNameEmpty')).default(''),
            message: Yup.string().required(t('blockTextEmpty')).default(''),
          })
          .test(
            'validateReachability',
            t('blockNotReachable'),
            (_value, { createError }) =>
              validateBlockNotReachable(flattenedItems, t, node, createError)
          )
          .test(
            'validateIntroBlock',
            t('fileNoIntroductoryMessage'),
            (_value, { createError }) =>
              validateIntroBlock(flattenedItems, t, createError)
          )
          .test(
            'validateExitBlock',
            t('fileNoExit'),
            (_value, { createError }) =>
              validateExitBlock(flattenedItems, t, createError)
          );
        break;
      }

      case NodeType.YesAnswer:
      case NodeType.NoAnswer:
      case NodeType.TermsAndConditions:
      case NodeType.ConfirmationText:
      case NodeType.BlockerText:
      case NodeType.MultipleChoiceAnswerChoice:
      case NodeType.Comment: {
        schema[node.id] = Yup.object().shape({
          message: Yup.object().shape(
            [defaultLanguage, ...(languages || [])].reduce((acc, lang) => {
              acc[lang] = Yup.string()
                .required(t('blockTextEmptyForSomeLanguages'))
                .default('');
              return acc;
            }, {} as { [key: string]: Yup.StringSchema })
          ),
        });
        break;
      }

      case NodeType.IfCondition:
      case NodeType.ElseCondition:
        schema[node.id] = Yup.object().shape({
          message: Yup.string().required(t('blockTextEmpty')).default(''),
        });
        break;

      case NodeType.DataBinding:
        schema[node.id] = Yup.object()
          .shape({
            name: Yup.string().required(t('blockNameEmpty')).default(''),
            message: Yup.object().shape({
              from: Yup.string()
                .required(t('blockExpressionEmpty'))
                .default(''),
              to: Yup.string().required(t('blockExpressionEmpty')).default(''),
            }),
          })
          .test(
            'validateDuplicateBlockName',
            t('blockNameNotUnique'),
            (value, { createError }) =>
              validateBlockNameNotUnique(
                flattenedItems,
                t,
                node,
                value,
                createError
              )
          )
          .test(
            'validateReachability',
            t('blockNotReachable'),
            (_value, { createError }) =>
              validateBlockNotReachable(flattenedItems, t, node, createError)
          )
          .test(
            'validateIntroBlock',
            t('fileNoIntroductoryMessage'),
            (_value, { createError }) =>
              validateIntroBlock(flattenedItems, t, createError)
          )
          .test(
            'validateExitBlock',
            t('fileNoExit'),
            (_value, { createError }) =>
              validateExitBlock(flattenedItems, t, createError)
          );
        break;

      case NodeType.PersonalDataQuestion:
        schema[node.id] = Yup.object()
          .shape({
            name: Yup.string().required(
              t('blockNameEmpty')
            ),
            instanceData: Yup.object().shape({
              firstNameEnabled: Yup.boolean(),
              lastNameEnabled: Yup.boolean(),
              mobileNumberEnabled: Yup.boolean(),
              emailAddressEnabled: Yup.boolean(),
            }),
            message: Yup.object(),
          })
          .test(
            'validateMessages',
            t('blockTextEmptyForSomeLanguages'),
            (value, { createError }) => {
              if (value.instanceData.firstNameEnabled) {
                if (
                  Object.values(value.message.firstName)?.some(
                    (value) => !value || (value as string)?.trim() === ''
                  )
                ) {
                  return new Yup.ValidationError(
                    [defaultLanguage, ...languages]?.map((key) =>
                      createError({
                        path: `${node.id}.message.firstName.${key}`,
                        message: t('blockTextEmptyForSomeLanguages'),
                      })
                    )
                  );
                }
              }
              if (value.instanceData.lastNameEnabled) {
                if (
                  Object.values(value.message.lastName)?.some(
                    (value) => !value || (value as string)?.trim() === ''
                  )
                ) {
                  return new Yup.ValidationError(
                    [defaultLanguage, ...languages]?.map((key) =>
                      createError({
                        path: `${node.id}.message.lastName.${key}`,
                        message: t('blockTextEmptyForSomeLanguages'),
                      })
                    )
                  );
                }
              }
              if (value.instanceData.mobileNumberEnabled) {
                if (
                  Object.values(value.message.mobileNumber)?.some(
                    (value) => !value || (value as string)?.trim() === ''
                  )
                ) {
                  return new Yup.ValidationError(
                    [defaultLanguage, ...languages]?.map((key) =>
                      createError({
                        path: `${node.id}.message.mobileNumber.${key}`,
                        message: t('blockTextEmptyForSomeLanguages'),
                      })
                    )
                  );
                }
              }
              if (value.instanceData.emailAddressEnabled) {
                if (
                  Object.values(value.message.emailAddress)?.some(
                    (value) => !value || (value as string)?.trim() === ''
                  )
                ) {
                  return new Yup.ValidationError(
                    [defaultLanguage, ...languages]?.map((key) =>
                      createError({
                        path: `${node.id}.message.emailAddress.${key}`,
                        message: t('blockTextEmptyForSomeLanguages'),
                      })
                    )
                  );
                }
              }

              return true;
            }
          )
          .test(
            'validateDuplicateBlockName',
            t('blockNameNotUnique'),
            (value, { createError }) =>
              validateBlockNameNotUnique(
                flattenedItems,
                t,
                node,
                value,
                createError
              )
          )
          .test(
            'validateReachability',
            t('blockNotReachable'),
            (_value, { createError }) =>
              validateBlockNotReachable(flattenedItems, t, node, createError)
          )
          .test(
            'validateIntroBlock',
            t('fileNoIntroductoryMessage'),
            (_value, { createError }) =>
              validateIntroBlock(flattenedItems, t, createError)
          )
          .test(
            'validateExitBlock',
            t('fileNoExit'),
            (_value, { createError }) =>
              validateExitBlock(flattenedItems, t, createError)
          );
        break;

      default:
        schema[node.id] = Yup.object()
          .shape({
            name: Yup.string().required(t('blockNameEmpty')).default(''),
            message: Yup.object().shape(
              [defaultLanguage, ...(languages || [])].reduce((acc, lang) => {
                acc[lang] = Yup.string()
                  .required(t('blockTextEmptyForSomeLanguages'))
                  .default('');
                return acc;
              }, {} as { [key: string]: Yup.StringSchema })
            ),
          })
          .test(
            'validateDuplicateBlockName',
            t('blockNameNotUnique'),
            (value, { createError }) =>
              validateBlockNameNotUnique(
                flattenedItems,
                t,
                node,
                value,
                createError
              )
          )
          .test(
            'validateReachability',
            t('blockNotReachable'),
            (_value, { createError }) =>
              validateBlockNotReachable(flattenedItems, t, node, createError)
          )
          .test(
            'validateIntroBlock',
            t('fileNoIntroductoryMessage'),
            (_value, { createError }) =>
              validateIntroBlock(flattenedItems, t, createError)
          )
          .test(
            'validateExitBlock',
            t('fileNoExit'),
            (_value, { createError }) =>
              validateExitBlock(flattenedItems, t, createError)
          );
        break;
    }
  });

  return Yup.object().shape(schema).default({});
};

import { useState, useEffect, useCallback } from 'react';
import cn from 'classnames';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $getSelection,
  $isRangeSelection,
  $isRootOrShadowRoot,
  FORMAT_TEXT_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  SELECTION_CHANGE_COMMAND,
  TextFormatType,
} from 'lexical';
import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  ListNode,
} from '@lexical/list';
import { $findMatchingParent, $getNearestNodeOfType } from '@lexical/utils';
import { $isHeadingNode } from '@lexical/rich-text';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { $isAtNodeEnd } from '@lexical/selection';
import Card from '@common/Card';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import StrikethroughSIcon from '@mui/icons-material/StrikethroughS';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Input from '@common/formik/Input';
import Popup from '@common/Popup';
import tagStyles from '@common/assets/styles/main.scss';

import styles from './styles.module.scss';
import { urlRegex, allowedEmojis } from './constants';
import { INSERT_VIDEO_COMMAND } from './VideoPlugin';

const ToolbarPlugin = ({ disabled }) => {
  const [editor] = useLexicalComposerContext();
  const [selection, setSelection] = useState(null);
  const [activeEditor, setActiveEditor] = useState(editor);
  const [blockType, setBlockType] = useState('paragraph');

  const updateBlockType = useCallback(() => {
    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, (e) => {
              const parent = e.getParent();
              return parent !== null && $isRootOrShadowRoot(parent);
            });

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }
      const elementKey = element.getKey();
      const elementDOM = activeEditor.getElementByKey(elementKey);
      if (elementDOM) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList
            ? parentList.getListType()
            : element.getListType();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          setBlockType(type);
        }
      }
    }
  }, [activeEditor]);

  useEffect(
    () =>
      editor?.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, newEditor) => {
          setSelection($getSelection());
          updateBlockType();
          setActiveEditor(newEditor);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      ),
    [editor, updateBlockType]
  );

  return (
    <div className={styles.toolbar}>
      <InlineControls
        activeEditor={activeEditor}
        selection={selection}
        disabled={disabled}
      />
      <BlockStyleControls
        activeEditor={activeEditor}
        blockType={blockType}
        disabled={disabled}
      />
      <HyperlinkPanel selection={selection} disabled={disabled} />
      <EmojisPanel disabled={disabled} />
      {/* <VideoPanel /> */} {/* Disabling video plugin */}
    </div>
  );
};

export default ToolbarPlugin;

const INLINE_STYLES = [
  { label: <FormatBoldIcon />, command: FORMAT_TEXT_COMMAND, payload: 'bold' },
  {
    label: <FormatItalicIcon />,
    command: FORMAT_TEXT_COMMAND,
    payload: 'italic',
  },
  {
    label: <FormatUnderlinedIcon />,
    command: FORMAT_TEXT_COMMAND,
    payload: 'underline',
  },
  {
    label: <StrikethroughSIcon />,
    command: FORMAT_TEXT_COMMAND,
    payload: 'strikethrough',
  },
];

const InlineControls = ({ activeEditor, selection, disabled }) => (
  <div className={styles.controls}>
    {INLINE_STYLES.map((style) => (
      <button
        disabled={disabled}
        tabIndex={-1}
        className={cn(tagStyles.upperMenuIcon, {
          [styles.selected]:
            $isRangeSelection(selection) &&
            selection?.hasFormat(style.payload as TextFormatType),
        })}
        type="button"
        key={style.payload}
        onClick={() =>
          activeEditor.dispatchCommand(style.command, style.payload)
        }
      >
        {style.label}
      </button>
    ))}
  </div>
);

const BLOCK_TYPES = [
  {
    label: <FormatListBulletedIcon />,
    command: INSERT_UNORDERED_LIST_COMMAND,
    payload: undefined,
    blockType: 'bullet',
  },
  {
    label: <FormatListNumberedIcon />,
    command: INSERT_ORDERED_LIST_COMMAND,
    payload: undefined,
    blockType: 'number',
  },
];

const BlockStyleControls = ({ activeEditor, blockType, disabled }) => {
  const [editor] = useLexicalComposerContext();

  return (
    <div className={styles.controls}>
      {BLOCK_TYPES.map((style) => (
        <button
          tabIndex={-1}
          className={cn(tagStyles.upperMenuIcon, {
            [styles.selected]:
              activeEditor === editor && blockType === style.blockType,
          })}
          type="button"
          disabled={disabled}
          key={style.blockType}
          onClick={() => {
            if (activeEditor === editor) {
              if (blockType !== style.blockType) {
                editor.dispatchCommand(style.command, style.payload);
              } else {
                editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
              }
            }
          }}
        >
          {style.label}
        </button>
      ))}
    </div>
  );
};

const HyperlinkPanel = ({ selection, disabled }) => {
  const [editor] = useLexicalComposerContext();
  const [link, setLink] = useState('');
  const { t } = useTranslation('campaign');

  const getSelectedNode = (selection) => {
    if (selection) {
      const anchor = selection.anchor;
      const focus = selection.focus;
      const anchorNode = selection.anchor.getNode();
      const focusNode = selection.focus.getNode();
      if (anchorNode === focusNode) {
        return anchorNode;
      }
      const isBackward = selection.isBackward();
      if (isBackward) {
        return $isAtNodeEnd(focus) ? anchorNode : focusNode;
      } else {
        return $isAtNodeEnd(anchor) ? anchorNode : focusNode;
      }
    }
  };

  const onSubmit = ({ link }) => {
    if (link !== '') {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, link);
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  };

  useEffect(() => {
    editor.update(() => {
      const node = getSelectedNode(selection);
      const parent = node?.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setLink(node.getURL?.() || parent.getURL?.());
      } else {
        setLink('');
      }
    });
  }, [selection]);

  return (
    <div className={styles.controls}>
      <Popup
        trigger={
          <button
            className={tagStyles.upperMenuIcon}
            disabled={disabled}
            type="button"
            tabIndex={-1}
          >
            <InsertLinkIcon />
          </button>
        }
      >
        {(dismissPopup) => (
          <Card className={styles.hyperlinkPanel}>
            <Formik
              initialValues={{ link }}
              onSubmit={onSubmit}
              validationSchema={Yup.object().shape({
                link: Yup.string()
                  .required(t('validationErrors.required'))
                  .matches(urlRegex, t('validationErrors.urlNotValid')),
              })}
            >
              {({ submitForm }) => (
                <Form>
                  <Input label={t('common:insertHyperlink')} name="link" />
                  <div className={styles.buttonBar}>
                    <button
                      tabIndex={-1}
                      disabled={disabled}
                      className={tagStyles.upperMenuIcon}
                      type="button"
                      onClick={() => dismissPopup()}
                    >
                      <CloseIcon />
                    </button>
                    <button
                      tabIndex={-1}
                      disabled={disabled}
                      className={tagStyles.upperMenuIcon}
                      type="button"
                      onClick={() => {
                        submitForm();
                        dismissPopup();
                      }}
                    >
                      <CheckIcon />
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </Card>
        )}
      </Popup>
    </div>
  );
};

const EmojisPanel = ({ disabled }) => {
  const [editor] = useLexicalComposerContext();
  const { t } = useTranslation('common');

  return (
    <div className={styles.controls}>
      <Popup
        trigger={
          <button
            className={tagStyles.upperMenuIcon}
            type="button"
            tabIndex={-1}
            disabled={disabled}
          >
            <EmojiEmotionsIcon />
          </button>
        }
      >
        {(dismissPopup) => (
          <Card className={styles.emojisPanel}>
            <label className={tagStyles.caption}>{t('insertEmojis')}</label>
            <div className={styles.emojis}>
              {allowedEmojis.map((emoji) => (
                <button
                  tabIndex={-1}
                  key={emoji}
                  className={cn(tagStyles.upperMenuIcon, styles.emojiButton)}
                  onClick={() => {
                    editor.update(() => {
                      const selection = $getSelection();
                      if (selection) {
                        selection.insertText(emoji);
                      }
                    });
                    dismissPopup();
                  }}
                  type="button"
                >
                  {emoji}
                </button>
              ))}
            </div>
          </Card>
        )}
      </Popup>
    </div>
  );
};

const VideoPanel = () => {
  const [editor] = useLexicalComposerContext();
  const { t } = useTranslation('campaign');

  const onSubmit = ({ src }) => {
    editor.dispatchCommand(INSERT_VIDEO_COMMAND, { src });
  };

  return (
    <div className={styles.controls}>
      <Popup
        trigger={
          <button className={tagStyles.upperMenuIcon} type="button">
            <OndemandVideoIcon />
          </button>
        }
      >
        {(dismissPopup) => (
          <Card className={styles.hyperlinkPanel}>
            <Formik
              initialValues={{ src: '' }}
              onSubmit={onSubmit}
              validationSchema={Yup.object().shape({
                src: Yup.string()
                  .required(t('validationErrors.required'))
                  .matches(urlRegex, t('validationErrors.urlNotValid')),
              })}
            >
              {({ submitForm }) => (
                <Form>
                  <Input label={t('common:insertVideo')} name="src" />
                  <div className={styles.buttonBar}>
                    <button
                      className={tagStyles.upperMenuIcon}
                      type="button"
                      onClick={() => dismissPopup()}
                    >
                      <CloseIcon />
                    </button>
                    <button
                      className={tagStyles.upperMenuIcon}
                      type="button"
                      onClick={() => {
                        submitForm();
                        dismissPopup();
                      }}
                    >
                      <CheckIcon />
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </Card>
        )}
      </Popup>
    </div>
  );
};

import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CxBot from '@adeccoca/cxbot-core';
import '@adeccoca/cxbot-core/dist/main.css';
import { Formik, Form } from 'formik';
import { DirectLine } from 'botframework-directlinejs';
import cn from 'classnames';
import Grid, { Col, Row } from '@common/Grid';
import tagStyles from '@common/assets/styles/main.scss';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Loader from '@common/Loader';
import Select from '@common/formik/Select';
import { useGetAppConfigQuery } from '@redux/api/portalApiSlice';
import { useLazyGetBotTokenQuery } from '@redux/api/cxBotApiSlice';
import { BotTestingChannel, Template } from '@appTypes/Conversation.types';
import { CampaignDetails } from '@appTypes/Campaign.types';

import styles from './styles.module.scss';
import { getInitialActivity } from './utils';

interface IChatBotTesterProps {
  currentCampaign: CampaignDetails;
  currentTemplate: Template;
  contextId?: string;
  showLanguageAndChannelSelectors?: boolean;
  activityName?: string;
}
interface IChatBotTesterDialogProps {
  onClose: () => void;
  currentCampaign?: CampaignDetails;
  currentTemplate: Template;
  contextId?: string;
  showLanguageAndChannelSelectors?: boolean;
  activityName?: string;
}

const ChatBotTesterDialog: React.FC<IChatBotTesterDialogProps> = ({
  onClose,
  currentCampaign,
  currentTemplate,
  contextId,
  showLanguageAndChannelSelectors,
  activityName,
}) => {
  const { t } = useTranslation('campaign');
  const { data: appConfig } = useGetAppConfigQuery();

  useEffect(() => {
    (window as any).CACxBotOptions = {};
    (window as any).CACxBotOptions.url = appConfig?.externalServices?.cxbot;
  }, [appConfig]);

  return (
    <div className={styles.chatBotTester}>
      <div className={styles.modalWrapper}>
        <div
          className={cn(
            tagStyles.tagDs,
            tagStyles.modal,
            tagStyles.docs,
            styles.modal
          )}
        >
          <button
            id="close"
            type="button"
            className={tagStyles.modalCloseButton}
            onClick={onClose}
          >
            <CloseOutlinedIcon />
          </button>
          <Row marginBottom={12}>
            <h4 className={tagStyles.modalHeader}>{t('testConversation')}</h4>
          </Row>
          <ChatBotTester
            currentCampaign={currentCampaign}
            currentTemplate={currentTemplate}
            contextId={contextId}
            showLanguageAndChannelSelectors={showLanguageAndChannelSelectors}
            activityName={activityName}
          />
        </div>
      </div>
    </div>
  );
};

export const ChatBotTester: React.FC<IChatBotTesterProps> = ({
  currentCampaign,
  currentTemplate,
  contextId,
  showLanguageAndChannelSelectors,
  activityName,
}) => {
  const [getBotToken, { data: botToken }] = useLazyGetBotTokenQuery();
  const { conversationId, token } = botToken || {};
  const [directLine, setDirectLine] = useState<DirectLine>(null);

  const { brand } = currentCampaign || {};

  const { data: appConfig, isLoading: isGetAppConfigLoading } =
    useGetAppConfigQuery();

  const languageOptions =
    useMemo(
      () =>
        currentTemplate?.content?.languageResources?.supportedLanguages.map(
          (language) => {
            const languageItem =
              appConfig?.referenceConfig?.supportedCultures?.find(
                (item) => item.cultureCode === language
              );
            return {
              label: (
                <div className={styles.languageSelectorLabel}>
                  <div
                    className={styles.imageContainer}
                    style={{
                      background: `no-repeat center url(/assets/flags/${languageItem.countryCode}.svg)`,
                    }}
                  />
                  {languageItem.languageName}
                </div>
              ),
              value: languageItem.cultureCode,
            };
          }
        ),
      [
        appConfig?.referenceConfig?.supportedCultures,
        currentTemplate?.content?.languageResources?.supportedLanguages,
      ]
    ) || [];

  const channelOptions = [
    { value: BotTestingChannel.Directline, label: 'WebChat' },
    { value: BotTestingChannel.Sms, label: 'Sms' },
    { value: BotTestingChannel.WhatsApp, label: 'WhatsApp' },
    { value: BotTestingChannel.FullSMS, label: 'Full SMS' },
  ];

  const [locale, setLocale] = useState(languageOptions[0]?.value);
  const [channel, setChannel] = useState(channelOptions[0]?.value);

  useEffect(() => {
    setLocale(languageOptions[0]?.value);
  }, [languageOptions[0]]);

  useEffect(() => {
    setChannel(channelOptions[0]?.value);
  }, [channelOptions[0]]);

  const initializeBot = async () => {
    const directLine = new DirectLine({
      secret: token,
      conversationId: conversationId,
      webSocket: true,
    });
    setDirectLine(directLine);
  };

  useEffect(() => {
    getBotToken();
  }, []);

  useEffect(() => {
    if (token) {
      initializeBot();
    }

    return () => {
      directLine?.end();
      setDirectLine(null);
    };
  }, [token]);

  return (
    <div className={styles.chatBotTester}>
      {!directLine || isGetAppConfigLoading ? (
        <Loader />
      ) : (
        <Grid>
          {showLanguageAndChannelSelectors && (
            <Formik
              initialValues={{
                language: languageOptions[0]?.value,
                channel: channelOptions[0]?.value,
              }}
              onSubmit={() => {}}
            >
              <Form>
                <Row marginBottom={12}>
                  <Col colSpan={8}>
                    <div className={styles.dropdownLeft}>
                      <Select
                        name="language"
                        label="language"
                        isSearchable={false}
                        options={languageOptions}
                        onChange={(option) => {
                          setLocale(option.value);
                          getBotToken();
                        }}
                      />
                    </div>
                  </Col>
                  <Col colSpan={8}>
                    <div className={styles.dropdownRight}>
                      <Select
                        name="channel"
                        label="channel"
                        isSearchable={false}
                        options={channelOptions}
                        onChange={(option) => {
                          setChannel(option.value);
                          getBotToken();
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Form>
            </Formik>
          )}
          <Row marginBottom={12}>
            <div className={cn(tagStyles.modalBody)}>
              <div className={styles.bot}>
                <CxBot
                  model={{
                    ...botToken,
                    brand: brand === 'AdeccoFull' ? 'Adecco' : brand,
                    entityId: '00000000-0000-0000-0000-000000000000',
                    language: locale,
                  }}
                  directline={directLine}
                  initialActivity={getInitialActivity(
                    currentTemplate,
                    locale,
                    channel,
                    contextId,
                    activityName
                  )}
                />
              </div>
            </div>
          </Row>
        </Grid>
      )}
    </div>
  );
};

export default ChatBotTesterDialog;

import { CandidateManagerType } from '@appTypes/Candidate.types';
import { TemplateQuestion, TemplateType } from '@appTypes/Conversation.types';
import { ITextFieldItem } from '@redux/reducers/createCampaign/conversationStructureReducer';

export interface CampaignCountry {
  cultureCode: string;
  languageName: string;
  countryName: string;
  countryCode: string;
  phonePrefix: string;
  phoneValidation?: string;
}

export interface CampaignBrand {
  name: string;
  displayName: string;
  cdnBaseAddress: string;
  logoName: string;
  primaryColor: string;
}

export interface CampaignCategory {
  name: string;
  displayName: string;
  options: {
    showJobs: boolean;
    showInterviews: boolean;
  };
}

export enum CampaignCategoryName {
  Refresh = 'Refresh',
  Funnel = 'Funnel',
  Scheduling = 'Scheduling',
  Open = 'Open',
}

export enum WaveStatus {
  None = 'None',
  Preparation = 'Preparation',
  Draft = 'Draft',
  ReadyToLaunch = 'ReadyToLaunch',
  Active = 'Active',
  Running = 'Running',
  Paused = 'Paused',
  Closed = 'Closed',
}

export enum IntegrationTypes {
  RcGlobal = 'RcGlobal',
  RcBullhorn = 'RcBullhornBot',
}

export interface IntegrationType {
  name: string;
  displayName: string;
}

export interface CustomersByCountry {
  country: string;
  customers: string[];
}

export interface ConversationType {
  name: string;
  displayName: string;
  special?: boolean;
}

export interface Currency {
  code: string;
  sign: string;
  price: number;
}

export interface Wave {
  id: number;
  name: string;
  startDate: string;
  endDate: string;
  status: WaveStatus;
  template?: any;
}

export interface CampaignData {
  id: string;
  name: string;
  category: string;
  customerName: string;
  brand: string;
  created: string;
  waves: Wave[];
  isTest: boolean;
  isIntegration: boolean;
  type: string;
  warningStatus: {
    warningLevel: string;
    warningPurpose: string;
  } | null;
  contactCount?: number;
}

export interface Role {
  name: string;
  scope: string;
}

export interface CampaignListItem {
  data: CampaignData;
  resourceId: string;
  roles: Role[];
  groupPath: string;
  ownerId: string;
  owner: string;
}

export interface CampaignFilters {
  NameFilter?: string;
  CustomerFilter?: string;
  CategoryFilter?: string;
  GroupPath?: string;
}

export interface GetAllCampaignsParams {
  filters?: any;
  orderBy?: string;
  isDescending?: boolean;
  pagination?: {
    offset: number;
    limit: number;
  };
}

export interface GetAllCampaignsResponse {
  resultCount: number;
  result: CampaignListItem[];
}

export interface CampaignDetails {
  id?: string;
  groupId: string;
  name: string;
  interactionContextId: string;
  description: string;
  category: string;
  customerName: string;
  jobOffering?: JobOffering;
  smsPrices?: SmsPrices;
  waves: Wave[];
  type: CampaignType;
  newType: NewCampaignType;
  purpose: string;
  candidateContactChannel?: CandidateContactChannel;
  conversationChannel: ConversationChannel;
  language?: CampaignLanguage;
  candidateContactLanguage: string;
  created?: Date;
  updated?: Date;
  createdBy?: string;
  firstContactMessages?: MessageSetting[];
  reminderSettings?: MessageSetting[];
  reengagements?: Reengagement[];
  timeSlotMessages?: {
    timeSlotCancel: MessageSetting[];
    timeSlotUpdate: MessageSetting[];
  };
  brand: string;
  isPushNotifEnabled: boolean;
  isFallbackActive?: boolean;
  formless?: boolean;
  pdfDownloadEnabled?: boolean;
  isTest: boolean;
  isIntegration?: boolean;
  integrationType?: string;
  candidatesManagedByCbe?: string;
  candidatesManagerType?: CandidateManagerType;
  tenantId?: string;
  candidateCount?: number;
  candidateMobileAppCount?: number;
  candidateProperties?: Property[];
  shortnerCode?: string;
  warningStatus?: WarningStatus;
  phoneNumber?: string;
  job?: Job;
  otherCustomerName?: string;
}

export interface JobOffering {
  jobTitle: string;
  recruiterName: string;
  recruiterPhoneNumber: string;
  recruiterEmail: string;
  locations: JobLocation[];
}

export interface JobLocation {
  originalText: string;
  locality: any;
}

export interface SmsPrices {
  in: number;
  out: number;
  unit: string;
  messageSegmentsPerSecond: number;
}

export interface MessageSetting {
  messageId: string;
  enabled: boolean;
  channel: MessagingTemplateChannel;
  timeBeforeSending?: ReminderTimeBeforeSending;
}

export interface Reengagement {
  id: string;
  sentOn: string;
  status: ReengagementStatus;
  channel: MessagingTemplateChannel;
  context: MessagingTemplateContext;
}

export interface Property {
  name: string;
  variableName: string;
  isExtendedProperty: boolean;
  averageSize: number;
  minSize: number;
  maxSize: number;
}

export interface WarningStatus {
  warningLevel: WarningLevel;
  warningPurpose?: WarningPurpose;
}

export interface Job {
  id: string;
  jobTitle: string;
}

export interface CampaignCreationResponse {
  message: string;
  id: string;
}

export interface SuggestionDate {
  specificTime: Date;
  dayOfTheWeek: number;
  startHour: number;
  endHour: number;
}

export interface Suggestions {
  suggestionDate?: SuggestionDate;
  preferedChannel?: MessagingTemplateChannel;
  id?: string;
  additionalText?: string;
  isValid?: boolean;
}

export interface MessagingTemplate {
  messageId?: string;
  id?: string;
  name: string;
  enabled?: boolean;
  templateType?: TemplateType;
  created?: Date;
  updated?: Date;
  createdBy?: string;
  category?: string;
  context?: MessagingTemplateContext;
  channel: MessagingTemplateChannel;
  labels: string[];
  variables?: TemplateVariable[];
  content?: TemplateContent[];
  segment?: number;
}

export interface MessagingTemplateCollection {
  [MessagingTemplateGroup.InitialMessages]: {
    [MessagingTemplateContext.FirstContact]: {
      MessagingTemplateChannelGroup;
    };
    [MessagingTemplateContext.FirstReengagement]: {
      MessagingTemplateChannelGroup;
    };
    [MessagingTemplateContext.SecondReengagement]: {
      MessagingTemplateChannelGroup;
    };
  };
  [MessagingTemplateGroup.FromChatbot]: {
    [MessagingTemplateContext.Confirmation]: {
      MessagingTemplateChannelGroup;
    };
    [MessagingTemplateContext.Cancellation]: {
      MessagingTemplateChannelGroup;
    };
  };
  [MessagingTemplateGroup.AfterChatbot]: {
    [MessagingTemplateContext.Reminder]: {
      MessagingTemplateChannelGroup;
    };
    [MessagingTemplateContext.TimeSlotUpdate]: {
      MessagingTemplateChannelGroup;
    };
    [MessagingTemplateContext.TimeSlotCancel]: {
      MessagingTemplateChannelGroup;
    };
  };
}

export interface MessagingTemplateChannelGroup {
  [MessagingTemplateChannel.None]: MessagingTemplate[];
  [MessagingTemplateChannel.Sms]: MessagingTemplate[];
  [MessagingTemplateChannel.Email]: MessagingTemplate[];
  [MessagingTemplateChannel.MobileNotification]: MessagingTemplate[];
  [MessagingTemplateChannel.WhatsApp]: MessagingTemplate[];
  [MessagingTemplateChannel.FullSMS]: MessagingTemplate[];
}

export interface TemplateVariable {
  name: string;
  displayName: string;
  description: string;
  value: string;
}

export interface TemplateContent {
  language: string;
  content: string;
  subject?: string;
}
export interface Interview {
  id?: string;
  outlookEventId?: string;
  waveId: number;
  meetingId?: string;
  numberOfSeat: number;
  campaignId?: string;
  interviewType: string;
  location: string;
  locationLatitude: number;
  locationLongitude: number;
  numberSeatOpened?: number;
  datetimeStart: string;
  datetimeEnd: string;
  recruiters: TimeSlotRecruiter[];
}

export interface TimeSlotRecruiter {
  id?: string;
  displayName: string;
  email: string;
  userPrincipalName: string;
  organizer: boolean;
  status?: ResponseStatus;
  statusDate?: Date;
}

export interface SplittedCampaign {
  id: string;
  name: string;
  status: WaveStatus;
}

export interface SplitCampaignResponse {
  data: SplittedCampaign[];
  errorMessage?: string;
  failureDetails: string;
  success: boolean;
  finishedWithFailure: boolean;
}

export interface CampaignSettingsFormValues {
  brand: string;
  campaignPurpose: string;
  campaignType: NewCampaignType;
  candidateManagerType: CandidateManagerType;
  conversationChannel?: ConversationChannel;
  country: string;
  customerName: string;
  fromDate: string;
  fromTime: string;
  groupId: string;
  isPushNotifEnabled: false;
  isTest: boolean;
  jobId?: string;
  language: string;
  name: string;
  otherCustomerName?: string;
  phoneNumber: string;
  toDate: string;
  toTime: string;
  isSchedulingEnabled: boolean;
}

export interface QuestionSelectionFormValues {
  questions: TemplateQuestion[];
}

export interface ConversationStructureFormValues {
  [item: string]: (TemplateQuestion | ITextFieldItem)[];
}

export interface ContactListFormValues {
  candidatesCSV: File;
}

export enum FilterType {
  NONE,
  TEXT,
  DATE,
  DROPDOWN,
  LANGUAGE,
  RANGE,
  MULTICHOICE,
}

export enum NewCampaignType {
  Inbound = 'Inbound',
  Outbound = 'Outbound',
}

export enum CampaignType {
  None = 'None',
  Open = 'Open',
  Targeted = 'Targeted',
  Hybrid = 'Hybrid',
}

export enum CandidateContactChannel {
  None = 'None',
  Sms = 'Sms',
  EMail = 'EMail',
  WhatsApp = 'WhatsApp',
}

export enum ConversationChannel {
  None = 'None',
  WebChat = 'WebChat',
  Sms = 'Sms',
  FbMessenger = 'FbMessenger',
  WhatsApp = 'WhatsApp',
}

export enum CampaignLanguage {
  None = 'None',
  BrowserDefault = 'BrowserDefault',
  Forced = 'Forced',
  UserPreference = 'UserPreference',
}

export enum MessagingTemplateChannel {
  None = 'None',
  Sms = 'Sms',
  Email = 'Email',
  MobileNotification = 'MobileNotification',
  WhatsApp = 'WhatsApp',
  FullSMS = 'FullSms',
}

export enum ReminderTimeBeforeSending {
  OneDayBefore = 'OneDayBefore',
  SixHoursBefore = 'SixHoursBefore',
  EightOfSameDay = 'EightOfSameDay',
}

export enum ReengagementStatus {
  NotSent = 'NotSent',
  Sending = 'Sending',
  Sent = 'Sent',
}

export enum WarningLevel {
  None = 'None',
  Warning = 'Warning',
  Error = 'Error',
}

export enum WarningPurpose {
  CandidatePreparationIssue = 'CandidatePreparationIssue',
  OutreachIssue = 'OutreachIssue',
  ZeroClicksOnLinks = 'ZeroClicksOnLinks',
}

export enum MessagingTemplateGroup {
  InitialMessages = 'initialMessages',
  FromChatbot = 'fromChatbot',
  AfterChatbot = 'afterChatbot',
}

export enum MessagingTemplateContext {
  None = 'None',
  FirstContact = 'FirstContact',
  FirstReengagement = 'FirstReengagement',
  SecondReengagement = 'SecondReengagement',
  Confirmation = 'Confirmation',
  Cancellation = 'Cancellation',
  TimeSlotUpdate = 'TimeSlotUpdate',
  TimeSlotCancel = 'TimeSlotCancel',
  Reminder = 'Reminder',
  Custom = 'Custom',
  OnlineMeeting = 'OnlineMeeting',
}

export enum ResponseStatus {
  None = 'None',
  Organizer = 'Organizer',
  TentativelyAccepted = 'TentativelyAccepted',
  Accepted = 'Accepted',
  Declined = 'Declined',
  NotResponded = 'NotResponded',
}

import { NodeType } from '@appTypes/Conversation.types';

export const ROOT_NODE_ID = 'items';
export const INDENTATION_WIDTH = 20;

export const Nodes = {
  [NodeType.Comment]: {
    label: 'nodeLabels.comment',
    color: '#1C304B',
    hasBlockName: false,
    isModifiable: true,
    hasSettings: false,
    allowSibings: true,
    allowChild: false,
    singleUseOnly: false,
  },
  [NodeType.Condition]: {
    label: 'nodeLabels.condition',
    color: '#7E63E8',
    hasBlockName: true,
    isModifiable: true,
    hasSettings: false,
    allowSibings: true,
    allowChild: false,
    singleUseOnly: false,
  },
  [NodeType.IfCondition]: {
    label: 'nodeLabels.conditionIf',
    color: '#fff',
    hasBlockName: false,
    isModifiable: false,
    hasSettings: false,
    allowSibings: false,
    allowChild: true,
    singleUseOnly: false,
  },
  [NodeType.ElseCondition]: {
    label: 'nodeLabels.conditionElse',
    color: '#fff',
    hasBlockName: false,
    isModifiable: false,
    hasSettings: false,
    allowSibings: false,
    allowChild: true,
    singleUseOnly: false,
  },
  [NodeType.DataBinding]: {
    label: 'nodeLabels.dataBinding',
    color: '#7E63E8',
    hasBlockName: true,
    isModifiable: true,
    hasSettings: false,
    allowSibings: true,
    allowChild: false,
    singleUseOnly: false,
  },
  [NodeType.Failure]: {
    label: 'nodeLabels.chatEndFailure',
    color: '#E04D42',
    hasBlockName: true,
    isModifiable: true,
    hasSettings: false,
    allowSibings: true,
    allowChild: false,
    singleUseOnly: false,
  },
  [NodeType.Success]: {
    label: 'nodeLabels.chatEndSuccess',
    color: '#73D85A',
    hasBlockName: true,
    isModifiable: true,
    hasSettings: false,
    allowSibings: true,
    allowChild: false,
    singleUseOnly: false,
  },
  [NodeType.IntroMessage]: {
    label: 'nodeLabels.introMessage',
    color: '#8894A5',
    hasBlockName: true,
    isModifiable: true,
    hasSettings: false,
    allowSibings: true,
    allowChild: false,
    singleUseOnly: true,
  },
  [NodeType.MultipleChoiceQuestion]: {
    label: 'nodeLabels.multipleChoiceQuestion',
    color: '#009ACC',
    hasBlockName: true,
    isModifiable: true,
    hasSettings: true,
    allowSibings: true,
    allowChild: false,
    singleUseOnly: false,
  },
  [NodeType.MultipleChoiceAnswerChoice]: {
    label: 'nodeLabels.choice',
    color: '#fff',
    hasBlockName: false,
    isModifiable: true,
    hasSettings: false,
    allowSibings: false,
    allowChild: true,
    singleUseOnly: false,
  },
  [NodeType.PersonalDataQuestion]: {
    label: 'nodeLabels.personalDataQuestion',
    color: '#009ACC',
    hasBlockName: false,
    isModifiable: true,
    hasSettings: false,
    allowSibings: true,
    allowChild: false,
    singleUseOnly: true,
  },
  [NodeType.TermsAndConditions]: {
    label: 'nodeLabels.termsAndConditions',
    color: '#FFC133',
    hasBlockName: false,
    isModifiable: true,
    hasSettings: false,
    allowSibings: true,
    allowChild: false,
    singleUseOnly: true,
  },
  [NodeType.ConfirmationText]: {
    label: 'nodeLabels.confirmationText',
    color: '#fff',
    hasBlockName: false,
    isModifiable: false,
    hasSettings: false,
    allowSibings: true,
    allowChild: false,
    singleUseOnly: false,
  },
  [NodeType.BlockerText]: {
    label: 'nodeLabels.blockerText',
    color: '#fff',
    hasBlockName: false,
    isModifiable: false,
    hasSettings: false,
    allowSibings: true,
    allowChild: false,
    singleUseOnly: false,
  },
  [NodeType.TextQuestion]: {
    label: 'nodeLabels.textQuestion',
    color: '#009ACC',
    hasBlockName: true,
    isModifiable: true,
    hasSettings: true,
    allowSibings: true,
    allowChild: false,
    singleUseOnly: false,
  },
  [NodeType.YesNoQuestion]: {
    label: 'nodeLabels.yesNoQuestion',
    color: '#009ACC',
    hasBlockName: true,
    isModifiable: true,
    hasSettings: true,
    allowSibings: true,
    allowChild: false,
    singleUseOnly: false,
  },
  [NodeType.YesAnswer]: {
    label: 'nodeLabels.yesAnswer',
    color: '#fff',
    hasBlockName: false,
    isModifiable: false,
    hasSettings: false,
    allowSibings: false,
    allowChild: true,
    singleUseOnly: false,
  },
  [NodeType.NoAnswer]: {
    label: 'nodeLabels.noAnswer',
    color: '#fff',
    hasBlockName: false,
    isModifiable: false,
    hasSettings: false,
    allowSibings: false,
    allowChild: true,
    singleUseOnly: false,
  },
};

export const ConversationStructure = [
  NodeType.IntroMessage,
  NodeType.Success,
  NodeType.Failure,
];

export const QuestionTypes = [
  NodeType.YesNoQuestion,
  NodeType.TextQuestion,
  NodeType.MultipleChoiceQuestion,
  NodeType.PersonalDataQuestion,
];

export const LegalAndCompliance = [NodeType.TermsAndConditions];

export const AdditionalContext = [NodeType.Comment];

export const ConditionalAndDynamicLogic = [
  NodeType.Condition,
  NodeType.DataBinding,
];

export const schedulingTextResources = [
  {
    key: 'WELCOMEAGAIN_MSG',
    values: [
      {
        language: 'fr-fr',
        texts: [
          "Bonjour {candidate.firstname}! Vous avez déjà répondu à ce questionnaire. Nous vous rappelons que vous avez un rendez-vous le {dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dddd dd MMMM yyyy HH:mm')}.\nQue souhaitez-vous faire ?",
        ],
      },
      {
        language: 'en-us',
        texts: [
          "Hello {candidate.firstname}! You have already booked an appointment. We remind you that you have an appointment on {dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dddd dd MMMM yyyy HH:mm')}.\nWhat would you like to do?",
        ],
      },
      {
        language: 'en-gb',
        texts: [
          "Hello {candidate.firstname}! You have already booked an appointment. We remind you that you have an appointment on {dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dddd dd MMMM yyyy HH:mm')}.\nWhat would you like to do?",
        ],
      },
      {
        language: 'nl-nl',
        texts: [
          "Hallo {candidate.firstname}! Je hebt alle vragen al beantwoord. We willen je er aan herinneren dat je een afspraak hebt op {dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dddd dd MMMM yyyy HH:mm')}. Wil je iets veranderen?",
        ],
      },
      {
        language: 'es-es',
        texts: [
          "Hola {candidate.firstname}! Ya has respondido a este formulario. Te recordamos que tu reserva está confirmada para el {dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dddd dd MMMM yyyy HH:mm')}.\n¿Qué te gustaría hacer ahora?",
        ],
      },
      {
        language: 'en-au',
        texts: [
          "Hello {candidate.firstname}! You have already booked an appointment. We remind you that you have an appointment on {dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dddd dd MMMM yyyy HH:mm')}.\nWhat would you like to do?",
        ],
      },
      {
        language: 'en-ca',
        texts: [
          "Hello {candidate.firstname}! You have already booked an appointment. We remind you that you have an appointment on {dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dddd dd MMMM yyyy HH:mm')}.\nWhat would you like to do?",
        ],
      },
      {
        language: 'en-de',
        texts: [
          "Hello {candidate.firstname}! You have already booked an appointment. We remind you that you have an appointment on {dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dddd dd MMMM yyyy HH:mm')}.\nWhat would you like to do?",
        ],
      },
      {
        language: 'en-hk',
        texts: [
          "Hello {candidate.firstname}! You have already booked an appointment. We remind you that you have an appointment on {dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dddd dd MMMM yyyy HH:mm')}.\nWhat would you like to do?",
        ],
      },
      {
        language: 'en-ie',
        texts: [
          "Hello {candidate.firstname}! You have already booked an appointment. We remind you that you have an appointment on {dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dddd dd MMMM yyyy HH:mm')}.\nWhat would you like to do?",
        ],
      },
      {
        language: 'en-in',
        texts: [
          "Hello {candidate.firstname}! You have already booked an appointment. We remind you that you have an appointment on {dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dddd dd MMMM yyyy HH:mm')}.\nWhat would you like to do?",
        ],
      },
      {
        language: 'en-my',
        texts: [
          "Hello {candidate.firstname}! You have already booked an appointment. We remind you that you have an appointment on {dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dddd dd MMMM yyyy HH:mm')}.\nWhat would you like to do?",
        ],
      },
      {
        language: 'en-nl',
        texts: [
          "Hello {candidate.firstname}! You have already booked an appointment. We remind you that you have an appointment on {dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dddd dd MMMM yyyy HH:mm')}.\nWhat would you like to do?",
        ],
      },
      {
        language: 'en-sg',
        texts: [
          "Hello {candidate.firstname}! You have already booked an appointment. We remind you that you have an appointment on {dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dddd dd MMMM yyyy HH:mm')}.\nWhat would you like to do?",
        ],
      },
      {
        language: 'lb-lu',
        texts: [
          "Hello {candidate.firstname}! You have already booked an appointment. We remind you that you have an appointment on {dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dddd dd MMMM yyyy HH:mm')}.\nWhat would you like to do?",
        ],
      },
      {
        language: 'de-at',
        texts: [
          "Hallo {candidate.firstname}! Sie haben bereits einen Termin gebucht. Wir erinnern Sie daran, dass Sie am {dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dddd dd MMMM yyyy HH:mm')} einen Termin haben.\n Was würden Sie gerne tun?",
        ],
      },
      {
        language: 'de-ch',
        texts: [
          "Hallo {candidate.firstname}! Sie haben bereits einen Termin gebucht. Wir erinnern Sie daran, dass Sie am {dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dddd dd MMMM yyyy HH:mm')} einen Termin haben.\n Was würden Sie gerne tun?",
        ],
      },
      {
        language: 'de-de',
        texts: [
          "Hallo {candidate.firstname}! Sie haben bereits einen Termin gebucht. Wir erinnern Sie daran, dass Sie am {dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dddd dd MMMM yyyy HH:mm')} einen Termin haben.\n Was würden Sie gerne tun?",
        ],
      },
      {
        language: 'fr-be',
        texts: [
          "Bonjour {candidate.firstname}! Vous avez déjà répondu à ce questionnaire. Nous vous rappelons que vous avez un rendez-vous le {dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dddd dd MMMM yyyy HH:mm')}.\nQue souhaitez-vous faire ?",
        ],
      },
      {
        language: 'fr-ca',
        texts: [
          "Bonjour {candidate.firstname}! Vous avez déjà répondu à ce questionnaire. Nous vous rappelons que vous avez un rendez-vous le {dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dddd dd MMMM yyyy HH:mm')}.\nQue souhaitez-vous faire ?",
        ],
      },
      {
        language: 'fr-ch',
        texts: [
          "Bonjour {candidate.firstname}! Vous avez déjà répondu à ce questionnaire. Nous vous rappelons que vous avez un rendez-vous le {dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dddd dd MMMM yyyy HH:mm')}.\nQue souhaitez-vous faire ?",
        ],
      },
      {
        language: 'fr-tn',
        texts: [
          "Bonjour {candidate.firstname}! Vous avez déjà répondu à ce questionnaire. Nous vous rappelons que vous avez un rendez-vous le {dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dddd dd MMMM yyyy HH:mm')}.\nQue souhaitez-vous faire ?",
        ],
      },
      {
        language: 'ar-ae',
        texts: [
          "مرحبا {candidate.firstName}! لقد قمت بالفعل بحجز موعد. نذكركم بأن لديك موعدا في {dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart)، 'dddd MMM yyy HH:mm')}.\nما الذي تريد القيام به؟",
        ],
      },
      {
        language: 'bg-bg',
        texts: [
          "Здравейте {candidate.firstname}! Вече сте резервирали среща. Напомняме ви, че имате среща на {dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dddd dd MMMM yyyy HH:mm')}.\nКакво бихте искали да направите?",
        ],
      },
      {
        language: 'cs-cz',
        texts: [
          "Ahoj {candidate.firstname}! Již jste si rezervovali schůzku. Připomínáme vám, že máte schůzku na {dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dddd dd MMMM yyyy HH:mm')}.\nCo byste chtěli dělat?",
        ],
      },
      {
        language: 'el-gr',
        texts: [
          "Γεια σας {candidate.firstName}! Έχετε ήδη κλείσει ραντεβού. Σας υπενθυμίζουμε ότι έχετε ραντεβού στις {dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dddd dd MMMM yyyy HH:mm')}.\nΤι θα θέλατε να κάνετε;",
        ],
      },
      {
        language: 'fi-fi',
        texts: [
          "Hei {candidate.firstname}! Olet jo varannut tapaamisen. Muistutamme, että sinulla on tapaaminen {dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dddd dd MMMM yyyy HH:mm')}.\nMitä haluaisit tehdä?",
        ],
      },
      {
        language: 'hr-hr',
        texts: [
          "Pozdrav, {candidate.firstname}! Već ste rezervirali termin. Podsjećamo vas da imate termin na datum {dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart), 'dddd dd MMMM yyyy HH:mm')}.  N Što biste željeli učiniti?",
        ],
      },
      {
        language: 'hu-hu',
        texts: [
          "Hello {candidate.firstName}! Ön már lefoglalt egy időpontot. Emlékeztetjük Önt, hogy időpontja {dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dddd dd MMMM yyyy HH:mm')}.\nMit szeretne tenni?",
        ],
      },
      {
        language: 'it-ch',
        texts: [
          "Ciao {candidate.firstname}!  Lei ha già prenotato un appuntamento. Le ricordiamo che ha un appuntamento il {dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dddd dd MMMM yyyy HH:mm')}.\nCosa vorrebbe fare?",
        ],
      },
      {
        language: 'it-it',
        texts: [
          "Ciao {candidate.firstname}!  Lei ha già prenotato un appuntamento. Le ricordiamo che ha un appuntamento il {dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dddd dd MMMM yyyy HH:mm')}.\nCosa vorrebbe fare?",
        ],
      },
      {
        language: 'ja-jp',
        texts: [
          "こんにちは{candidate.firstname}！ {dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dddd dd MMMM yyyy HH:mm')}。n何をしますか？",
        ],
      },
      {
        language: 'ko-kr',
        texts: [
          "안녕하세요, {candidate.firstname} 님! 당신은 이미 약속을 잡았군요. {dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dd MMMM yyyy HH:mm')}에 약속이 있음을 알립니다.\n어떻게 하시겠습니까?",
        ],
      },
      {
        language: 'ms-my',
        texts: [
          "Helo {candidate.firstname}! Anda telah menempah temujanji. Kami mengingatkan anda bahawa anda mempunyai janji temu pada {dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dddd dd MMMM yyyyy HH:mm')}.\nApakah yang anda ingin lakukan?",
        ],
      },
      {
        language: 'nl-be',
        texts: [
          "Hallo {candidate.firstname}! U heeft al een afspraak geboekt. We herinneren je eraan dat je een afspraak hebt op {dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dddd dd MMMM jjjj HH:mm')}.\nWat zou je willen doen?",
        ],
      },
      {
        language: 'pl-pl',
        texts: [
          "Witaj {candidate.firstname}! Zarezerwowałeś już wizytę. Przypominamy, że ma Pan(i) umówione spotkanie w dniu {dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dddd dd MMMM yyyy HH:mm')}.\nCo chciał(a)by Pan(i) robić?",
        ],
      },
      {
        language: 'ro-ro',
        texts: [
          "Bună ziua {candidate.firstname}! Ați rezervat deja o programare. Vă reamintim că aveți o programare pe {dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dddd dd MMMM yyyy HH:mm')}.\nCe ați dori să faceți?",
        ],
      },
      {
        language: 'sk-sk',
        texts: [
          "Ahoj {candidate.firstname}! Už ste si rezervovali stretnutie. Pripomíname vám, že máte stretnutie na {dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dddd dd MMMM yyyy HH:mm')}.\nČo by ste chceli robiť?",
        ],
      },
      {
        language: 'sl-si',
        texts: [
          "Pozdravljeni {candidate.firstname}! Že ste rezervirali sestanek. Opozarjamo vas, da imate sestanek na {dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dddd dd MMMM yyyy HH:mm')}.\nKaj bi radi počeli?",
        ],
      },
      {
        language: 'sr-latn-rs',
        texts: [
          "Здраво {candidate.firstname}! Већ сте заказали састанак. Подсећамо вас да имате заказану обавезу у оквиру {dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dddd dd MMMM yyyy HH:mm')}.\nШта желите да урадите?",
        ],
      },
      {
        language: 'sv-se',
        texts: [
          "Hej {candidate.firstname}! Du har redan bokat ett möte. Vi påminner dig om att du har en tid den {dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dddd dd dd MMMM yyyy HH:mm')}.\nVad vill du göra?",
        ],
      },
      {
        language: 'th-th',
        texts: [
          "สวัสดี {candidate.firstname}!  คุณได้จองการนัดหมายไว้แล้ว เราเตือนคุณว่าคุณมีการนัดหมายบน {dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dd MMMM yyy HH:mm')}\nคุณต้องการทําอะไร",
        ],
      },
      {
        language: 'tr-tr',
        texts: [
          "Merhaba{candidate.firstname}! Zaten bir randevu ayarladınız. {dateFormat(toLocalTime(myBookings.bookings[0].datetimeStart),'dd MMMM yyyy HH:mm')} tarihinde bir randevunuz olduğunu size anımsatıyoruz.\nNe yapmak istersiniz?",
        ],
      },
      {
        language: 'vi-vn',
        texts: [
          "Xin chào {candidate.firstname}! Bạn đã đăng ký một cuộc hẹn. Chúng tôi nhắc bạn rằng bạn có cuộc hẹn vào {dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dd MMMM yyyy HH:mm')}.\nBạn muốn làm gì?",
        ],
      },
    ],
  },
  {
    key: 'YES_MSG',
    values: [
      {
        language: 'fr-fr',
        texts: ['Oui'],
      },
      {
        language: 'en-us',
        texts: ['Yes'],
      },
      {
        language: 'en-gb',
        texts: ['Yes'],
      },
      {
        language: 'nl-nl',
        texts: ['Ja'],
      },
      {
        language: 'es-es',
        texts: ['Si'],
      },
      {
        language: 'en-au',
        texts: ['Yes'],
      },
      {
        language: 'en-ca',
        texts: ['Yes'],
      },
      {
        language: 'en-de',
        texts: ['Yes'],
      },
      {
        language: 'en-hk',
        texts: ['Yes'],
      },
      {
        language: 'en-ie',
        texts: ['Yes'],
      },
      {
        language: 'en-in',
        texts: ['Yes'],
      },
      {
        language: 'en-my',
        texts: ['Yes'],
      },
      {
        language: 'en-nl',
        texts: ['Yes'],
      },
      {
        language: 'en-sg',
        texts: ['Yes'],
      },
      {
        language: 'lb-lu',
        texts: ['Yes'],
      },
      {
        language: 'de-at',
        texts: ['Ja'],
      },
      {
        language: 'de-ch',
        texts: ['Ja'],
      },
      {
        language: 'de-de',
        texts: ['Ja'],
      },
      {
        language: 'fr-be',
        texts: ['Oui'],
      },
      {
        language: 'fr-ca',
        texts: ['Oui'],
      },
      {
        language: 'fr-ch',
        texts: ['Oui'],
      },
      {
        language: 'fr-tn',
        texts: ['Oui'],
      },
      {
        language: 'ar-ae',
        texts: ['نعم'],
      },
      {
        language: 'bg-bg',
        texts: ['Да'],
      },
      {
        language: 'cs-cz',
        texts: ['Ano'],
      },
      {
        language: 'el-gr',
        texts: ['Ναι'],
      },
      {
        language: 'fi-fi',
        texts: ['Kyllä'],
      },
      {
        language: 'hr-hr',
        texts: ['Da'],
      },
      {
        language: 'hu-hu',
        texts: ['Igen'],
      },
      {
        language: 'it-ch',
        texts: ['Sì'],
      },
      {
        language: 'it-it',
        texts: ['Sì'],
      },
      {
        language: 'ja-jp',
        texts: ['はい。'],
      },
      {
        language: 'ko-kr',
        texts: ['예'],
      },
      {
        language: 'ms-my',
        texts: ['Ya'],
      },
      {
        language: 'nl-be',
        texts: ['Ja'],
      },
      {
        language: 'pl-pl',
        texts: ['Tak'],
      },
      {
        language: 'ro-ro',
        texts: ['Da'],
      },
      {
        language: 'sk-sk',
        texts: ['Áno'],
      },
      {
        language: 'sl-si',
        texts: ['Da'],
      },
      {
        language: 'sr-latn-rs',
        texts: ['Да'],
      },
      {
        language: 'sv-se',
        texts: ['Ja'],
      },
      {
        language: 'th-th',
        texts: ['ใช่'],
      },
      {
        language: 'tr-tr',
        texts: ['Evet'],
      },
      {
        language: 'vi-vn',
        texts: ['Có'],
      },
    ],
  },
  {
    key: 'NO_MSG',
    values: [
      {
        language: 'fr-fr',
        texts: ['Non'],
      },
      {
        language: 'en-us',
        texts: ['No'],
      },
      {
        language: 'en-gb',
        texts: ['No'],
      },
      {
        language: 'nl-nl',
        texts: ['Nee'],
      },
      {
        language: 'es-es',
        texts: ['No'],
      },
      {
        language: 'en-au',
        texts: ['No'],
      },
      {
        language: 'en-ca',
        texts: ['No'],
      },
      {
        language: 'en-de',
        texts: ['No'],
      },
      {
        language: 'en-hk',
        texts: ['No'],
      },
      {
        language: 'en-ie',
        texts: ['No'],
      },
      {
        language: 'en-in',
        texts: ['No'],
      },
      {
        language: 'en-my',
        texts: ['No'],
      },
      {
        language: 'en-nl',
        texts: ['No'],
      },
      {
        language: 'en-sg',
        texts: ['No'],
      },
      {
        language: 'lb-lu',
        texts: ['No'],
      },
      {
        language: 'de-at',
        texts: ['Nein'],
      },
      {
        language: 'de-ch',
        texts: ['Nein'],
      },
      {
        language: 'de-de',
        texts: ['Nein'],
      },
      {
        language: 'fr-be',
        texts: ['Non'],
      },
      {
        language: 'fr-ca',
        texts: ['Non'],
      },
      {
        language: 'fr-ch',
        texts: ['Non'],
      },
      {
        language: 'fr-tn',
        texts: ['Non'],
      },
      {
        language: 'ar-ae',
        texts: ['لا'],
      },
      {
        language: 'bg-bg',
        texts: ['Не'],
      },
      {
        language: 'cs-cz',
        texts: ['Ne'],
      },
      {
        language: 'el-gr',
        texts: ['Όχι'],
      },
      {
        language: 'fi-fi',
        texts: ['Ei'],
      },
      {
        language: 'hr-hr',
        texts: ['Ne'],
      },
      {
        language: 'hu-hu',
        texts: ['Nem'],
      },
      {
        language: 'it-ch',
        texts: ['No'],
      },
      {
        language: 'it-it',
        texts: ['No'],
      },
      {
        language: 'ja-jp',
        texts: ['いいえ'],
      },
      {
        language: 'ko-kr',
        texts: ['아니요'],
      },
      {
        language: 'ms-my',
        texts: ['Tidak'],
      },
      {
        language: 'nl-be',
        texts: ['Nee'],
      },
      {
        language: 'pl-pl',
        texts: ['Nie'],
      },
      {
        language: 'ro-ro',
        texts: ['Nu'],
      },
      {
        language: 'sk-sk',
        texts: ['Nie'],
      },
      {
        language: 'sl-si',
        texts: ['Ne'],
      },
      {
        language: 'sr-latn-rs',
        texts: ['Не'],
      },
      {
        language: 'sv-se',
        texts: ['Ingen'],
      },
      {
        language: 'th-th',
        texts: ['ใไม่'],
      },
      {
        language: 'tr-tr',
        texts: ['Hayır'],
      },
      {
        language: 'vi-vn',
        texts: ['Không'],
      },
    ],
  },
  {
    key: 'EXITSMSANDEMAIL_MSG',
    values: [
      {
        language: 'fr-fr',
        texts: [
          'On vous confirme ça par sms et email.\n Merci et bonne journée',
        ],
      },
      {
        language: 'en-us',
        texts: [
          'We will send you a confirmation via email and SMS.\n Thank you and have a nice day',
        ],
      },
      {
        language: 'en-gb',
        texts: [
          'We will send you a confirmation via email and SMS.\n Thank you and have a nice day',
        ],
      },
      {
        language: 'nl-nl',
        texts: [
          'We bevestigen de informatie per e-mail en sms. Dank je wel en nog een fijne dag',
        ],
      },
      {
        language: 'es-es',
        texts: ['¡Gracias y que tengas buen día!'],
      },
      {
        language: 'en-au',
        texts: [
          'We will send you a confirmation via email and SMS.\n Thank you and have a nice day',
        ],
      },
      {
        language: 'en-ca',
        texts: [
          'We will send you a confirmation via email and SMS.\n Thank you and have a nice day',
        ],
      },
      {
        language: 'en-de',
        texts: [
          'We will send you a confirmation via email and SMS.\n Thank you and have a nice day',
        ],
      },
      {
        language: 'en-hk',
        texts: [
          'We will send you a confirmation via email and SMS.\n Thank you and have a nice day',
        ],
      },
      {
        language: 'en-ie',
        texts: [
          'We will send you a confirmation via email and SMS.\n Thank you and have a nice day',
        ],
      },
      {
        language: 'en-in',
        texts: [
          'We will send you a confirmation via email and SMS.\n Thank you and have a nice day',
        ],
      },
      {
        language: 'en-my',
        texts: [
          'We will send you a confirmation via email and SMS.\n Thank you and have a nice day',
        ],
      },
      {
        language: 'en-nl',
        texts: [
          'We will send you a confirmation via email and SMS.\n Thank you and have a nice day',
        ],
      },
      {
        language: 'en-sg',
        texts: [
          'We will send you a confirmation via email and SMS.\n Thank you and have a nice day',
        ],
      },
      {
        language: 'lb-lu',
        texts: [
          'We will send you a confirmation via email and SMS.\n Thank you and have a nice day',
        ],
      },
      {
        language: 'de-at',
        texts: [
          'Wir werden Ihnen eine Bestätigung per E-Mail und SMS schicken.\n Vielen Dank und einen schönen Tag',
        ],
      },
      {
        language: 'de-ch',
        texts: [
          'Wir werden Ihnen eine Bestätigung per E-Mail und SMS schicken.\n Vielen Dank und einen schönen Tag',
        ],
      },
      {
        language: 'de-de',
        texts: [
          'Wir werden Ihnen eine Bestätigung per E-Mail und SMS schicken.\n Vielen Dank und einen schönen Tag',
        ],
      },
      {
        language: 'fr-be',
        texts: [
          'On vous confirme ça par sms et email.\n Merci et bonne journée',
        ],
      },
      {
        language: 'fr-ca',
        texts: [
          'On vous confirme ça par sms et email.\n Merci et bonne journée',
        ],
      },
      {
        language: 'fr-ch',
        texts: [
          'On vous confirme ça par sms et email.\n Merci et bonne journée',
        ],
      },
      {
        language: 'fr-tn',
        texts: [
          'On vous confirme ça par sms et email.\n Merci et bonne journée',
        ],
      },
      {
        language: 'ar-ae',
        texts: [
          'سنرسل لك تأكيدا عبر البريد الإلكتروني والرسائل القصيرة / ن شكرا لكم وعقدوا يوما جميلا',
        ],
      },
      {
        language: 'bg-bg',
        texts: [
          'Ще ви изпратим потвърждение по имейл и чрез SMS.\n Благодаря ви и ви желая приятен ден',
        ],
      },
      {
        language: 'cs-cz',
        texts: [
          'Potvrzení vám zašleme e-mailem a SMS.\n Děkujeme a přejeme hezký den.',
        ],
      },
      {
        language: 'el-gr',
        texts: [
          'Θα σας στείλουμε επιβεβαίωση μέσω email και SMS.\n Σας ευχαριστούμε και καλή σας μέρα.',
        ],
      },
      {
        language: 'fi-fi',
        texts: [
          'Lähetämme sinulle vahvistuksen sähköpostitse ja tekstiviestillä.\n Kiitos ja hyvää päivänjatkoa.',
        ],
      },
      {
        language: 'hr-hr',
        texts: [
          'Poslat ćemo vam potvrdu putem e -pošte i SMS -a.  N Hvala i ugodan dan',
        ],
      },
      {
        language: 'hu-hu',
        texts: [
          'Visszaigazolást küldünk Önnek e-mailben és SMS-ben.\n Köszönjük és szép napot kívánunk!',
        ],
      },
      {
        language: 'it-ch',
        texts: [
          'Le invieremo una conferma via e-mail e SMS. Grazie e buona giornata',
        ],
      },
      {
        language: 'it-it',
        texts: [
          'Le invieremo una conferma via e-mail e SMS. Grazie e buona giornata',
        ],
      },
      {
        language: 'ja-jp',
        texts: [
          'メールとSMSで確認をお送りします。 nありがとうございます。良い一日を。',
        ],
      },
      {
        language: 'ko-kr',
        texts: [
          '이메일과 SMS로 확인서를 보내드리겠습니다.\n감사합니다. 즐거운 하루 보내세요',
        ],
      },
      {
        language: 'ms-my',
        texts: [
          'Kami akan menghantar pengesahan melalui e-mel dan SMS.\n Terima kasih dan semoga anda mempunyai hari yang baik',
        ],
      },
      {
        language: 'nl-be',
        texts: [
          'We bevestigen de informatie per e-mail en sms. Dank je wel en nog een fijne dag',
        ],
      },
      {
        language: 'pl-pl',
        texts: [
          'Wyślemy Ci potwierdzenie przez e-mail i SMS.NDziękuję i życzę miłego dnia.',
        ],
      },
      {
        language: 'ro-ro',
        texts: [
          'Vă vom trimite o confirmare prin e-mail și SMS.\n Vă mulțumim și vă dorim o zi bună.',
        ],
      },
      {
        language: 'sk-sk',
        texts: [
          'Potvrdenie vám zašleme e-mailom a SMS.\n Ďakujeme a prajeme pekný deň',
        ],
      },
      {
        language: 'sl-si',
        texts: [
          'Potrditev vam bomo poslali po e-pošti in SMS.\n Hvala in lep dan',
        ],
      },
      {
        language: 'sr-latn-rs',
        texts: [
          'Послаћемо вам потврду путем е-поште и СМС-а.\n Хвала и желим вам леп дан',
        ],
      },
      {
        language: 'sv-se',
        texts: [
          'Vi kommer att skicka dig en bekräftelse via e-post och SMS.\n Tack och ha en trevlig dag.',
        ],
      },
      {
        language: 'th-th',
        texts: [
          'เราจะส่งการยืนยันทางอีเมลและ SMS ให้คุณ\nขอขอบคุณและขอให้มีความสุข',
        ],
      },
      {
        language: 'tr-tr',
        texts: [
          'Size e-posta ve SMS ile onay göndereceğiz.\n Teşekkür eder, iyi günler dileriz',
        ],
      },
      {
        language: 'vi-vn',
        texts: [
          'Chúng tôi sẽ gửi cho bạn một xác nhận qua email và tin nhắn SMS.\n Cảm ơn bạn và có một ngày tốt đẹp',
        ],
      },
    ],
  },
  {
    key: 'EXITSMS_MSG',
    values: [
      {
        language: 'fr-fr',
        texts: ['Nous venons de vous envoyer un sms.\n Merci et bonne journée'],
      },
      {
        language: 'en-us',
        texts: [
          'We just sent you a text message.\n Thank you and have a nice day',
        ],
      },
      {
        language: 'en-gb',
        texts: [
          'We just sent you a text message.\n Thank you and have a nice day',
        ],
      },
      {
        language: 'nl-nl',
        texts: [
          'We hebben je zojuist een sms gestuurd. Dank je wel en nog een fijne dag',
        ],
      },
      {
        language: 'es-es',
        texts: ['¡Gracias y que tengas buen día!'],
      },
      {
        language: 'en-au',
        texts: [
          'We just sent you a text message.\n Thank you and have a nice day',
        ],
      },
      {
        language: 'en-ca',
        texts: [
          'We just sent you a text message.\n Thank you and have a nice day',
        ],
      },
      {
        language: 'en-de',
        texts: [
          'We just sent you a text message.\n Thank you and have a nice day',
        ],
      },
      {
        language: 'en-hk',
        texts: [
          'We just sent you a text message.\n Thank you and have a nice day',
        ],
      },
      {
        language: 'en-ie',
        texts: [
          'We just sent you a text message.\n Thank you and have a nice day',
        ],
      },
      {
        language: 'en-in',
        texts: [
          'We just sent you a text message.\n Thank you and have a nice day',
        ],
      },
      {
        language: 'en-my',
        texts: [
          'We just sent you a text message.\n Thank you and have a nice day',
        ],
      },
      {
        language: 'en-nl',
        texts: [
          'We just sent you a text message.\n Thank you and have a nice day',
        ],
      },
      {
        language: 'en-sg',
        texts: [
          'We just sent you a text message.\n Thank you and have a nice day',
        ],
      },
      {
        language: 'lb-lu',
        texts: [
          'We just sent you a text message.\n Thank you and have a nice day',
        ],
      },
      {
        language: 'de-at',
        texts: [
          'Wir haben Ihnen gerade eine Textnachricht geschickt.\n Vielen Dank und einen schönen Tag',
        ],
      },
      {
        language: 'de-ch',
        texts: [
          'Wir haben Ihnen gerade eine Textnachricht geschickt.\n Vielen Dank und einen schönen Tag',
        ],
      },
      {
        language: 'de-de',
        texts: [
          'Wir haben Ihnen gerade eine Textnachricht geschickt.\n Vielen Dank und einen schönen Tag',
        ],
      },
      {
        language: 'fr-be',
        texts: ['Nous venons de vous envoyer un sms.\n Merci et bonne journée'],
      },
      {
        language: 'fr-ca',
        texts: ['Nous venons de vous envoyer un sms.\n Merci et bonne journée'],
      },
      {
        language: 'fr-ch',
        texts: ['Nous venons de vous envoyer un sms.\n Merci et bonne journée'],
      },
      {
        language: 'fr-tn',
        texts: ['Nous venons de vous envoyer un sms.\n Merci et bonne journée'],
      },
      {
        language: 'ar-ae',
        texts: ['لقد أرسلنا لك رسالة نصية. شكرا و أتمنى لك يوما جميلا'],
      },
      {
        language: 'bg-bg',
        texts: [
          'Току-що ви изпратихме текстово съобщение.\n Благодаря ви и ви желая приятен ден',
        ],
      },
      {
        language: 'cs-cz',
        texts: [
          'Právě jsme vám poslali textovou zprávu.\n Děkujeme a přejeme hezký den.',
        ],
      },
      {
        language: 'el-gr',
        texts: [
          'Μόλις σας στείλαμε ένα γραπτό μήνυμα.\n Σας ευχαριστούμε και καλή σας μέρα.',
        ],
      },
      {
        language: 'fi-fi',
        texts: [
          'Lähetimme sinulle juuri tekstiviestin.\n Kiitos ja hyvää päivänjatkoa.',
        ],
      },
      {
        language: 'hr-hr',
        texts: ['Upravo smo vam poslali SMS.  N Hvala i ugodan dan'],
      },
      {
        language: 'hu-hu',
        texts: [
          'Most küldtünk Önnek egy SMS-t.\n Köszönjük és további szép napot kívánunk!',
        ],
      },
      {
        language: 'it-ch',
        texts: [
          'Le abbiamo appena inviato un messaggio di testo.\n Grazie e buona giornata',
        ],
      },
      {
        language: 'it-it',
        texts: [
          'Le abbiamo appena inviato un messaggio di testo.\n Grazie e buona giornata',
        ],
      },
      {
        language: 'ja-jp',
        texts: [
          'テキストメッセージを送信しました。 nありがとうございます。良い一日を。',
        ],
      },
      {
        language: 'ko-kr',
        texts: [
          '이메일과 SMS로 확인서를 보내드리겠습니다.\n감사합니다. 즐거운 하루 보내세요문자 메시지를 보냈습니다.\n감사합니다. 즐거운 하루 보내세요',
        ],
      },
      {
        language: 'ms-my',
        texts: [
          'Kami telah menghantar mesej teks kepada anda.\n Terima kasih dan mempunyai hari yang baik',
        ],
      },
      {
        language: 'nl-be',
        texts: [
          'We hebben je zojuist een sms gestuurd. Dank je wel en nog een fijne dag',
        ],
      },
      {
        language: 'pl-pl',
        texts: ['Właśnie wysłaliśmy panu SMS-a. Dziękuję i życzę miłego dnia.'],
      },
      {
        language: 'ro-ro',
        texts: [
          'Tocmai v-am trimis un mesaj text.\n Mulțumesc și vă doresc o zi bună.',
        ],
      },
      {
        language: 'sk-sk',
        texts: [
          'Práve sme vám poslali textovú správu.\n Ďakujeme a prajeme pekný deň',
        ],
      },
      {
        language: 'sl-si',
        texts: [
          'Pravkar smo vam poslali besedilno sporočilo.\n Hvala in lep dan',
        ],
      },
      {
        language: 'sr-latn-rs',
        texts: [
          'Управо смо вам послали текстуалну поруку.\n Хвала и пријатан дан',
        ],
      },
      {
        language: 'sv-se',
        texts: [
          'Vi skickade just ett sms till dig.\n Tack och ha en trevlig dag.',
        ],
      },
      {
        language: 'th-th',
        texts: ['เราเพิ่งส่งข้อความตัวอักษรให้คุณ\nขอขอบคุณและขอให้มีความสุข'],
      },
      {
        language: 'tr-tr',
        texts: [
          'Size kısa bir mesaj gönderdik.\n Teşekkür ederiz ve iyi günler dileriz.',
        ],
      },
      {
        language: 'vi-vn',
        texts: [
          'Chúng tôi vừa gửi cho bạn một tin nhắn.\n Cảm ơn bạn và chúc một ngày tốt lành',
        ],
      },
    ],
  },
  {
    key: 'EXITEMAIL_MSG',
    values: [
      {
        language: 'fr-fr',
        texts: [
          'Nous venons de vous envoyer un mail.\n Merci et bonne journée',
        ],
      },
      {
        language: 'en-us',
        texts: ['We just sent you an email.\n Thank you and have a nice day'],
      },
      {
        language: 'en-gb',
        texts: ['We just sent you an email.\n Thank you and have a nice day'],
      },
      {
        language: 'nl-nl',
        texts: [
          'We hebben je zojuist een e-mail gestuurd. Dank je wel en nog een fijne dag',
        ],
      },
      {
        language: 'es-es',
        texts: ['¡Gracias y que tengas buen día!'],
      },
      {
        language: 'en-au',
        texts: ['We just sent you an email.\n Thank you and have a nice day'],
      },
      {
        language: 'en-ca',
        texts: ['We just sent you an email.\n Thank you and have a nice day'],
      },
      {
        language: 'en-de',
        texts: ['We just sent you an email.\n Thank you and have a nice day'],
      },
      {
        language: 'en-hk',
        texts: ['We just sent you an email.\n Thank you and have a nice day'],
      },
      {
        language: 'en-ie',
        texts: ['We just sent you an email.\n Thank you and have a nice day'],
      },
      {
        language: 'en-in',
        texts: ['We just sent you an email.\n Thank you and have a nice day'],
      },
      {
        language: 'en-my',
        texts: ['We just sent you an email.\n Thank you and have a nice day'],
      },
      {
        language: 'en-nl',
        texts: ['We just sent you an email.\n Thank you and have a nice day'],
      },
      {
        language: 'en-sg',
        texts: ['We just sent you an email.\n Thank you and have a nice day'],
      },
      {
        language: 'lb-lu',
        texts: ['We just sent you an email.\n Thank you and have a nice day'],
      },
      {
        language: 'de-at',
        texts: [
          'Wir haben Ihnen soeben eine E-Mail geschickt.\n Vielen Dank und einen schönen Tag',
        ],
      },
      {
        language: 'de-ch',
        texts: [
          'Wir haben Ihnen soeben eine E-Mail geschickt.\n Vielen Dank und einen schönen Tag',
        ],
      },
      {
        language: 'de-de',
        texts: [
          'Wir haben Ihnen soeben eine E-Mail geschickt.\n Vielen Dank und einen schönen Tag',
        ],
      },
      {
        language: 'fr-be',
        texts: [
          'Nous venons de vous envoyer un mail.\n Merci et bonne journée',
        ],
      },
      {
        language: 'fr-ca',
        texts: [
          'Nous venons de vous envoyer un mail.\n Merci et bonne journée',
        ],
      },
      {
        language: 'fr-ch',
        texts: [
          'Nous venons de vous envoyer un mail.\n Merci et bonne journée',
        ],
      },
      {
        language: 'fr-tn',
        texts: [
          'Nous venons de vous envoyer un mail.\n Merci et bonne journée',
        ],
      },
      {
        language: 'ar-ae',
        texts: ['لقد أرسلنا لك بريدا إلكترونيا للتو شكرا وعقدنا يوما جميلا'],
      },
      {
        language: 'bg-bg',
        texts: [
          'Току-що ви изпратихме имейл.\n Благодаря ви и ви пожелавам приятен ден',
        ],
      },
      {
        language: 'cs-cz',
        texts: [
          'Právě jsme vám poslali e-mail.\n Děkujeme a přejeme hezký den.',
        ],
      },
      {
        language: 'el-gr',
        texts: [
          'Μόλις σας στείλαμε ένα email.\n Σας ευχαριστούμε και καλή σας μέρα',
        ],
      },
      {
        language: 'fi-fi',
        texts: [
          'Lähetimme sinulle juuri sähköpostia.\n Kiitos ja hyvää päivänjatkoa.',
        ],
      },
      {
        language: 'hr-hr',
        texts: [
          'Upravo smo vam poslali SMS. \nHvala i ugodan danUpravo smo vam poslali e -poruku. \nHvala i ugodan dan',
        ],
      },
      {
        language: 'hu-hu',
        texts: [
          'Most küldtünk Önnek egy e-mailt.\n Köszönjük és további szép napot kívánunk!',
        ],
      },
      {
        language: 'it-ch',
        texts: ['Le abbiamo appena inviato un e-mail. Grazie e buona giornata'],
      },
      {
        language: 'it-it',
        texts: ['Le abbiamo appena inviato un e-mail. Grazie e buona giornata'],
      },
      {
        language: 'ja-jp',
        texts: [
          'メールをお送りしました。 nありがとうございました。良い一日を。',
        ],
      },
      {
        language: 'ko-kr',
        texts: ['방금 메일을 보냈습니다.\n감사합니다. 즐거운 하루 보내세요'],
      },
      {
        language: 'ms-my',
        texts: [
          'Kami telah menghantar e-mel kepada anda.\n Terima kasih dan semoga anda mempunyai hari yang baik',
        ],
      },
      {
        language: 'nl-be',
        texts: [
          'We hebben je zojuist een e-mail gestuurd. Dank je wel en nog een fijne dag',
        ],
      },
      {
        language: 'pl-pl',
        texts: ['Właśnie wysłaliśmy ci e-mail. Dziękuję i życzę miłego dnia.'],
      },
      {
        language: 'ro-ro',
        texts: [
          'Tocmai v-am trimis un e-mail.\n Vă mulțumim și vă dorim o zi bună.',
        ],
      },
      {
        language: 'sk-sk',
        texts: ['Práve sme vám poslali e-mail.\n Ďakujeme a prajeme pekný deň'],
      },
      {
        language: 'sl-si',
        texts: ['Pravkar smo vam poslali e-pošto.\n Hvala in lep dan'],
      },
      {
        language: 'sr-latn-rs',
        texts: ['Управо смо вам послали е-поруку.\n Хвала и пријатан дан'],
      },
      {
        language: 'sv-se',
        texts: [
          'Vi har just skickat ett e-postmeddelande till dig.\n Tack och ha en trevlig dag.',
        ],
      },
      {
        language: 'th-th',
        texts: ['เราเพิ่งส่งอีเมลถึงคุณ\nขอขอบคุณและขอให้มีวันที่ดี'],
      },
      {
        language: 'tr-tr',
        texts: [
          'Size kısa bir süre önce e-posta gönderdik.\n Teşekkür ederiz ve iyi günler dileriz',
        ],
      },
      {
        language: 'vi-vn',
        texts: [
          'Chúng tôi vừa gửi cho bạn một email.\n Cảm ơn bạn và chúc một ngày tốt lành',
        ],
      },
    ],
  },
  {
    key: 'GENERIC_REPLY_MSG',
    values: [
      {
        language: 'fr-fr',
        texts: ["Oups! Il semble que je n'ai pas bien compris votre réponse."],
      },
      {
        language: 'en-us',
        texts: ['Oops! I do not understand your answer.'],
      },
      {
        language: 'en-gb',
        texts: ['Oops! I do not understand your answer.'],
      },
      {
        language: 'nl-nl',
        texts: ['Oeps, het lijkt erop dat ik je antwoord niet heb gekregen'],
      },
      {
        language: 'es-es',
        texts: ['Oh oh. Parece que no he entendido tu respuesta.'],
      },
      {
        language: 'en-au',
        texts: ['Oops! I do not understand your answer.'],
      },
      {
        language: 'en-ca',
        texts: ['Oops! I do not understand your answer.'],
      },
      {
        language: 'en-de',
        texts: ['Oops! I do not understand your answer.'],
      },
      {
        language: 'en-hk',
        texts: ['Oops! I do not understand your answer.'],
      },
      {
        language: 'en-ie',
        texts: ['Oops! I do not understand your answer.'],
      },
      {
        language: 'en-in',
        texts: ['Oops! I do not understand your answer.'],
      },
      {
        language: 'en-my',
        texts: ['Oops! I do not understand your answer.'],
      },
      {
        language: 'en-nl',
        texts: ['Oops! I do not understand your answer.'],
      },
      {
        language: 'en-sg',
        texts: ['Oops! I do not understand your answer.'],
      },
      {
        language: 'lb-lu',
        texts: ['Oops! I do not understand your answer.'],
      },
      {
        language: 'de-at',
        texts: ['Huch! Ich verstehe Ihre Antwort nicht.'],
      },
      {
        language: 'de-ch',
        texts: ['Huch! Ich verstehe Ihre Antwort nicht.'],
      },
      {
        language: 'de-de',
        texts: ['Huch! Ich verstehe Ihre Antwort nicht.'],
      },
      {
        language: 'fr-be',
        texts: ["Oups! Il semble que je n'ai pas bien compris votre réponse."],
      },
      {
        language: 'fr-ca',
        texts: ["Oups! Il semble que je n'ai pas bien compris votre réponse."],
      },
      {
        language: 'fr-ch',
        texts: ["Oups! Il semble que je n'ai pas bien compris votre réponse."],
      },
      {
        language: 'fr-tn',
        texts: ["Oups! Il semble que je n'ai pas bien compris votre réponse."],
      },
      {
        language: 'ar-ae',
        texts: ['عفوا! لا أفهم جوابك.'],
      },
      {
        language: 'bg-bg',
        texts: ['Упс! Не разбирам отговора ви.'],
      },
      {
        language: 'cs-cz',
        texts: ['Ups! Nerozumím vaší odpovědi.'],
      },
      {
        language: 'el-gr',
        texts: ['Ουπς! Δεν καταλαβαίνω την απάντησή σας.'],
      },
      {
        language: 'fi-fi',
        texts: ['Hups! En ymmärrä vastaustasi.'],
      },
      {
        language: 'hr-hr',
        texts: ['Ups! Ne razumijem vaš odgovor.'],
      },
      {
        language: 'hu-hu',
        texts: ['Hoppá! Nem értem a válaszát.'],
      },
      {
        language: 'it-ch',
        texts: ['Ops! Non capisco la sua risposta.'],
      },
      {
        language: 'it-it',
        texts: ['Ops! Non capisco la sua risposta.'],
      },
      {
        language: 'ja-jp',
        texts: ['おっと！？私はあなたの答えを理解していません。'],
      },
      {
        language: 'ko-kr',
        texts: ['어이쿠! 저는 당신의 답변을 이해하지 못합니다.'],
      },
      {
        language: 'ms-my',
        texts: ['Oops! Saya tidak memahami jawapan anda.'],
      },
      {
        language: 'nl-be',
        texts: ['Oeps, het lijkt erop dat ik je antwoord niet heb gekregen'],
      },
      {
        language: 'pl-pl',
        texts: ['Ups! Nie rozumiem twojej odpowiedzi.'],
      },
      {
        language: 'ro-ro',
        texts: ['Oops! Nu am înțeles răspunsul dumneavoastră.'],
      },
      {
        language: 'sk-sk',
        texts: ['Ups! Nerozumiem vašej odpovedi.'],
      },
      {
        language: 'sl-si',
        texts: ['Ups! Ne razumem vašega odgovora.'],
      },
      {
        language: 'sr-latn-rs',
        texts: ['Упс! Не разумем твој одговор.'],
      },
      {
        language: 'sv-se',
        texts: ['Oops! Jag förstår inte ditt svar.'],
      },
      {
        language: 'th-th',
        texts: ['อุ๊บส์! ฉันไม่เข้าใจคําตอบของคุณ'],
      },
      {
        language: 'tr-tr',
        texts: ['Hay aksi! Cevabını anlamıyorum.'],
      },
      {
        language: 'vi-vn',
        texts: ['Rất tiếc! Tôi không hiểu câu trả lời của bạn.'],
      },
    ],
  },
  {
    key: 'CHOOSE_IN_LIST',
    values: [
      {
        language: 'fr-fr',
        texts: ['Merci de choisir parmis les choix possibles.'],
      },
      {
        language: 'en-us',
        texts: ['Thank you for your selection.'],
      },
      {
        language: 'en-gb',
        texts: ['Thank you for your selection.'],
      },
      {
        language: 'nl-nl',
        texts: ['Selecteer een tijdstip'],
      },
      {
        language: 'es-es',
        texts: ['Selecciona un horario por favor'],
      },
      {
        language: 'en-au',
        texts: ['Thank you for your selection.'],
      },
      {
        language: 'en-ca',
        texts: ['Thank you for your selection.'],
      },
      {
        language: 'en-de',
        texts: ['Thank you for your selection.'],
      },
      {
        language: 'en-hk',
        texts: ['Thank you for your selection.'],
      },
      {
        language: 'en-ie',
        texts: ['Thank you for your selection.'],
      },
      {
        language: 'en-in',
        texts: ['Thank you for your selection.'],
      },
      {
        language: 'en-my',
        texts: ['Thank you for your selection.'],
      },
      {
        language: 'en-nl',
        texts: ['Thank you for your selection.'],
      },
      {
        language: 'en-sg',
        texts: ['Thank you for your selection.'],
      },
      {
        language: 'lb-lu',
        texts: ['Thank you for your selection.'],
      },
      {
        language: 'de-at',
        texts: ['Bestätigung der Ernennung.'],
      },
      {
        language: 'de-ch',
        texts: ['Bestätigung der Ernennung.'],
      },
      {
        language: 'de-de',
        texts: ['Bestätigung der Ernennung.'],
      },
      {
        language: 'fr-be',
        texts: ['Merci de choisir parmis les choix possibles.'],
      },
      {
        language: 'fr-ca',
        texts: ['Merci de choisir parmis les choix possibles.'],
      },
      {
        language: 'fr-ch',
        texts: ['Merci de choisir parmis les choix possibles.'],
      },
      {
        language: 'fr-tn',
        texts: ['Merci de choisir parmis les choix possibles.'],
      },
      {
        language: 'ar-ae',
        texts: ['شكرا لاختيارك.'],
      },
      {
        language: 'bg-bg',
        texts: ['Благодарим ви за вашия избор.'],
      },
      {
        language: 'cs-cz',
        texts: ['Děkujeme za váš výběr.'],
      },
      {
        language: 'el-gr',
        texts: ['Σας ευχαριστούμε για την επιλογή σας.'],
      },
      {
        language: 'fi-fi',
        texts: ['Kiitos valinnastanne.'],
      },
      {
        language: 'hr-hr',
        texts: ['Hvala vam na odabiru.'],
      },
      {
        language: 'hu-hu',
        texts: ['Köszönjük a választását.'],
      },
      {
        language: 'it-ch',
        texts: ['Grazie per la vostra selezione.'],
      },
      {
        language: 'it-it',
        texts: ['Grazie per la vostra selezione.'],
      },
      {
        language: 'ja-jp',
        texts: ['お選びいただきありがとうございます。'],
      },
      {
        language: 'ko-kr',
        texts: ['선택해 주셔서 감사합니다.'],
      },
      {
        language: 'ms-my',
        texts: ['Terima kasih atas pilihan anda.'],
      },
      {
        language: 'nl-be',
        texts: ['Selecteer een tijdstip'],
      },
      {
        language: 'pl-pl',
        texts: ['Dziękujemy za wybór.'],
      },
      {
        language: 'ro-ro',
        texts: ['Vă mulțumim pentru selecție.'],
      },
      {
        language: 'sk-sk',
        texts: ['Ďakujeme za váš výber.'],
      },
      {
        language: 'sl-si',
        texts: ['Zahvaljujemo se vam za izbiro.'],
      },
      {
        language: 'sr-latn-rs',
        texts: ['Хвала на избору.'],
      },
      {
        language: 'sv-se',
        texts: ['Tack för ditt val.'],
      },
      {
        language: 'th-th',
        texts: ['ขอขอบคุณสําหรับการเลือก'],
      },
      {
        language: 'tr-tr',
        texts: ['Seçiminiz için teşekkür ederiz.'],
      },
      {
        language: 'vi-vn',
        texts: ['Cảm ơn bạn đã chọn.'],
      },
    ],
  },
  {
    key: 'ASK_EMAIL',
    values: [
      {
        language: 'fr-fr',
        texts: ['Quelle est votre adresse mail actuelle?'],
      },
      {
        language: 'en-us',
        texts: ['What is your current email address?'],
      },
      {
        language: 'en-gb',
        texts: ['What is your current email address?'],
      },
      {
        language: 'nl-nl',
        texts: ['Wat is je e-mail adres?'],
      },
      {
        language: 'es-es',
        texts: ['¿Cuál es tu correo electrónico?'],
      },
      {
        language: 'en-au',
        texts: ['What is your current email address?'],
      },
      {
        language: 'en-ca',
        texts: ['What is your current email address?'],
      },
      {
        language: 'en-de',
        texts: ['What is your current email address?'],
      },
      {
        language: 'en-hk',
        texts: ['What is your current email address?'],
      },
      {
        language: 'en-ie',
        texts: ['What is your current email address?'],
      },
      {
        language: 'en-in',
        texts: ['What is your current email address?'],
      },
      {
        language: 'en-my',
        texts: ['What is your current email address?'],
      },
      {
        language: 'en-nl',
        texts: ['What is your current email address?'],
      },
      {
        language: 'en-sg',
        texts: ['What is your current email address?'],
      },
      {
        language: 'lb-lu',
        texts: ['What is your current email address?'],
      },
      {
        language: 'de-at',
        texts: ['Wie lautet Ihre aktuelle E-Mail-Adresse?'],
      },
      {
        language: 'de-ch',
        texts: ['Wie lautet Ihre aktuelle E-Mail-Adresse?'],
      },
      {
        language: 'de-de',
        texts: ['Wie lautet Ihre aktuelle E-Mail-Adresse?'],
      },
      {
        language: 'fr-be',
        texts: ['Quelle est votre adresse mail actuelle?'],
      },
      {
        language: 'fr-ca',
        texts: ['Quelle est votre adresse mail actuelle?'],
      },
      {
        language: 'fr-ch',
        texts: ['Quelle est votre adresse mail actuelle?'],
      },
      {
        language: 'fr-tn',
        texts: ['Quelle est votre adresse mail actuelle?'],
      },
      {
        language: 'ar-ae',
        texts: ['ما هو عنوان بريدك الإلكتروني الحالي؟'],
      },
      {
        language: 'bg-bg',
        texts: ['Какъв е настоящият ви имейл адрес?'],
      },
      {
        language: 'cs-cz',
        texts: ['Jaká je vaše současná e-mailová adresa?'],
      },
      {
        language: 'el-gr',
        texts: [
          'Ποια είναι η τρέχουσα διεύθυνση ηλεκτρονικού ταχυδρομείου σας',
        ],
      },
      {
        language: 'fi-fi',
        texts: ['Mikä on nykyinen sähköpostiosoitteesi?'],
      },
      {
        language: 'hr-hr',
        texts: ['Koja je vaša trenutna adresa e -pošte?'],
      },
      {
        language: 'hu-hu',
        texts: ['Mi a jelenlegi e-mail címe?'],
      },
      {
        language: 'it-ch',
        texts: ['Qual è il suo attuale indirizzo e-mail?'],
      },
      {
        language: 'it-it',
        texts: ['Qual è il suo attuale indirizzo e-mail?'],
      },
      {
        language: 'ja-jp',
        texts: ['現在の電子メールアドレスを教えてください。'],
      },
      {
        language: 'ko-kr',
        texts: ['현재 이메일 주소가 어떻게 되시나요?'],
      },
      {
        language: 'ms-my',
        texts: ['Apakah alamat e-mel semasa anda?'],
      },
      {
        language: 'nl-be',
        texts: ['Wat is je e-mail adres?'],
      },
      {
        language: 'pl-pl',
        texts: ['Jaki jest Twój obecny adres e-mail?'],
      },
      {
        language: 'ro-ro',
        texts: ['Care este adresa dvs. actuală de e-mail?'],
      },
      {
        language: 'sk-sk',
        texts: ['Aká je vaša aktuálna e-mailová adresa?'],
      },
      {
        language: 'sl-si',
        texts: ['Kateri je vaš trenutni e-poštni naslov?'],
      },
      {
        language: 'sr-latn-rs',
        texts: ['Која је ваша тренутна е-адреса?'],
      },
      {
        language: 'sv-se',
        texts: ['Vilken är din nuvarande e-postadress?'],
      },
      {
        language: 'th-th',
        texts: ['อีเมลแอดเดรสปัจจุบันของคุณคืออะไร'],
      },
      {
        language: 'tr-tr',
        texts: ['Mevcut e-posta adresiniz nedir?'],
      },
      {
        language: 'vi-vn',
        texts: ['Địa chỉ email hiện tại của bạn là gì?'],
      },
    ],
  },
  {
    key: 'WRONG_EMAIL',
    values: [
      {
        language: 'fr-fr',
        texts: [
          'Merci de fournir un email valide.',
          'Votre email ne me semble pas correct.',
          'Etes-vous sur de ne pas avoir faire une erreur?\nCet email me parait incorrect.',
          'Merci {candidate.firstName} mais je pense que vous fait une erreur de saisie',
        ],
      },
      {
        language: 'en-us',
        texts: [
          'Your email address is incorrect.',
          'Please enter a valid email address.',
          'Your email address is wrongly formatted.',
          'Thanks {candidate.firstName} but I think your email address is incorrectly formatted.',
        ],
      },
      {
        language: 'en-gb',
        texts: [
          'Your email address is incorrect.',
          'Please enter a valid email address.',
          'Your email address is wrongly formatted.',
          'Thanks {candidate.firstName} but I think your email address is incorrectly formatted.',
        ],
      },
      {
        language: 'nl-nl',
        texts: ['Ingegeven emailadres is niet correct.'],
      },
      {
        language: 'es-es',
        texts: ['Tu email es incorrecto.'],
      },
      {
        language: 'en-au',
        texts: [
          'Your email address is incorrect.',
          'Please enter a valid email address.',
          'Your email address is wrongly formatted.',
          'Thanks {candidate.firstName} but I think your email address is incorrectly formatted.',
        ],
      },
      {
        language: 'en-ca',
        texts: [
          'Your email address is incorrect.',
          'Please enter a valid email address.',
          'Your email address is wrongly formatted.',
          'Thanks {candidate.firstName} but I think your email address is incorrectly formatted.',
        ],
      },
      {
        language: 'en-de',
        texts: [
          'Your email address is incorrect.',
          'Please enter a valid email address.',
          'Your email address is wrongly formatted.',
          'Thanks {candidate.firstName} but I think your email address is incorrectly formatted.',
        ],
      },
      {
        language: 'en-hk',
        texts: [
          'Your email address is incorrect.',
          'Please enter a valid email address.',
          'Your email address is wrongly formatted.',
          'Thanks {candidate.firstName} but I think your email address is incorrectly formatted.',
        ],
      },
      {
        language: 'en-ie',
        texts: [
          'Your email address is incorrect.',
          'Please enter a valid email address.',
          'Your email address is wrongly formatted.',
          'Thanks {candidate.firstName} but I think your email address is incorrectly formatted.',
        ],
      },
      {
        language: 'en-in',
        texts: [
          'Your email address is incorrect.',
          'Please enter a valid email address.',
          'Your email address is wrongly formatted.',
          'Thanks {candidate.firstName} but I think your email address is incorrectly formatted.',
        ],
      },
      {
        language: 'en-my',
        texts: [
          'Your email address is incorrect.',
          'Please enter a valid email address.',
          'Your email address is wrongly formatted.',
          'Thanks {candidate.firstName} but I think your email address is incorrectly formatted.',
        ],
      },
      {
        language: 'en-nl',
        texts: [
          'Your email address is incorrect.',
          'Please enter a valid email address.',
          'Your email address is wrongly formatted.',
          'Thanks {candidate.firstName} but I think your email address is incorrectly formatted.',
        ],
      },
      {
        language: 'en-sg',
        texts: [
          'Your email address is incorrect.',
          'Please enter a valid email address.',
          'Your email address is wrongly formatted.',
          'Thanks {candidate.firstName} but I think your email address is incorrectly formatted.',
        ],
      },
      {
        language: 'lb-lu',
        texts: [
          'Your email address is incorrect.',
          'Please enter a valid email address.',
          'Your email address is wrongly formatted.',
          'Thanks {candidate.firstName} but I think your email address is incorrectly formatted.',
        ],
      },
      {
        language: 'de-at',
        texts: [
          'Ihre E-Mail-Adresse ist falsch.',
          'Bitte geben Sie eine gültige E-Mail Adresse ein.',
          'Ihre E-Mail Adresse ist falsch formatiert.',
          'Danke {candidate.firstName}, aber ich glaube, Ihre E-Mail-Adresse ist falsch formatiert.',
        ],
      },
      {
        language: 'de-ch',
        texts: [
          'Ihre E-Mail-Adresse ist falsch.',
          'Bitte geben Sie eine gültige E-Mail Adresse ein.',
          'Ihre E-Mail Adresse ist falsch formatiert.',
          'Danke {candidate.firstName}, aber ich glaube, Ihre E-Mail-Adresse ist falsch formatiert.',
        ],
      },
      {
        language: 'de-de',
        texts: [
          'Ihre E-Mail-Adresse ist falsch.',
          'Bitte geben Sie eine gültige E-Mail Adresse ein.',
          'Ihre E-Mail Adresse ist falsch formatiert.',
          'Danke {candidate.firstName}, aber ich glaube, Ihre E-Mail-Adresse ist falsch formatiert.',
        ],
      },
      {
        language: 'fr-be',
        texts: [
          'Merci de fournir un email valide.',
          'Votre email ne me semble pas correct.',
          'Etes-vous sur de ne pas avoir faire une erreur?\nCet email me parait incorrect.',
          'Merci {candidate.firstName} mais je pense que vous fait une erreur de saisie',
        ],
      },
      {
        language: 'fr-ca',
        texts: [
          'Merci de fournir un email valide.',
          'Votre email ne me semble pas correct.',
          'Etes-vous sur de ne pas avoir faire une erreur?\nCet email me parait incorrect.',
          'Merci {candidate.firstName} mais je pense que vous fait une erreur de saisie',
        ],
      },
      {
        language: 'fr-ch',
        texts: [
          'Merci de fournir un email valide.',
          'Votre email ne me semble pas correct.',
          'Etes-vous sur de ne pas avoir faire une erreur?\nCet email me parait incorrect.',
          'Merci {candidate.firstName} mais je pense que vous fait une erreur de saisie',
        ],
      },
      {
        language: 'fr-tn',
        texts: [
          'Merci de fournir un email valide.',
          'Votre email ne me semble pas correct.',
          'Etes-vous sur de ne pas avoir faire une erreur?\nCet email me parait incorrect.',
          'Merci {candidate.firstName} mais je pense que vous fait une erreur de saisie',
        ],
      },
      {
        language: 'ar-ae',
        texts: ['عنوان بريدك الإلكتروني غير صحيح.'],
      },
      {
        language: 'bg-bg',
        texts: [
          'Вашият имейл адрес е грешен.',
          'Моля, въведете валиден имейл адрес.',
          'Вашият имейл адрес е неправилно форматиран.',
          'Благодаря {candidate.firstName}, но мисля, че имейл адресът ви е неправилно форматиран.',
        ],
      },
      {
        language: 'cs-cz',
        texts: [
          'Vaše e-mailová adresa je nesprávná.',
          'Zadejte prosím platnou e-mailovou adresu.',
          'Vaše e-mailová adresa je špatně naformátovaná.',
          'Děkuji {candidate.firstName}, ale myslím, že vaše e-mailová adresa je špatně naformátovaná.',
        ],
      },
      {
        language: 'el-gr',
        texts: [
          'Η διεύθυνση ηλεκτρονικού ταχυδρομείου σας είναι λανθασμένη.',
          'Παρακαλούμε εισάγετε μια έγκυρη διεύθυνση ηλεκτρονικού ταχυδρομείου.',
          'Η διεύθυνση ηλεκτρονικού ταχυδρομείου σας είναι λανθασμένα διαμορφωμένη.',
          'Ευχαριστώ {candidate.firstName} αλλά νομίζω ότι η διεύθυνση ηλεκτρονικού ταχυδρομείου σας είναι λανθασμένα μορφοποιημένη.',
        ],
      },
      {
        language: 'fi-fi',
        texts: [
          'Sähköpostiosoitteesi on virheellinen.',
          'Kirjoita voimassa oleva sähköpostiosoite.',
          'Sähköpostiosoitteesi on muotoiltu väärin.',
          'Kiitos {candidate.firstName}, mutta mielestäni sähköpostiosoitteesi on muotoiltu väärin.',
        ],
      },
      {
        language: 'hr-hr',
        texts: [
          'Vaša adresa e -pošte nije točna.',
          'Molimo unesite valjanu adresu e -pošte.',
          'Vaša je adresa e -pošte pogrešno oblikovana.',
          'Hvala {candidate.firstName}, ali mislim da je vaša adresa e -pošte pogrešno formatirana.',
        ],
      },
      {
        language: 'hu-hu',
        texts: [
          'Az e-mail címe helytelen.',
          'Kérjük, adjon meg egy érvényes e-mail címet.',
          'Az e-mail címe rosszul van formázva.',
          'Köszönöm {candidate.firstName}, de azt hiszem, az e-mail címe rosszul van formázva.',
        ],
      },
      {
        language: 'it-ch',
        texts: ['Il tuo indirizzo e-mail non è corretto.'],
      },
      {
        language: 'it-it',
        texts: ['Il tuo indirizzo e-mail non è corretto.'],
      },
      {
        language: 'ja-jp',
        texts: ['メールアドレスが正しくありません。'],
      },
      {
        language: 'ko-kr',
        texts: ['메일 주소가 잘못되었습니다.'],
      },
      {
        language: 'ms-my',
        texts: ['Alamat e-mel anda tidak betul.'],
      },
      {
        language: 'nl-be',
        texts: ['Ingegeven emailadres is niet correct.'],
      },
      {
        language: 'pl-pl',
        texts: ['Twój adres e-mail jest nieprawidłowy.'],
      },
      {
        language: 'ro-ro',
        texts: ['Adresa dvs. de e-mail este incorectă.'],
      },
      {
        language: 'sk-sk',
        texts: ['Vaša e-mailová adresa je nesprávna.'],
      },
      {
        language: 'sl-si',
        texts: ['Vaš e-poštni naslov je napačen.'],
      },
      {
        language: 'sr-latn-rs',
        texts: ['Ваша е-адреса је нетачна.'],
      },
      {
        language: 'sv-se',
        texts: ['Din e-postadress är felaktig.'],
      },
      {
        language: 'th-th',
        texts: ['อีเมลแอดเดรสของคุณไม่ถูกต้อง'],
      },
      {
        language: 'tr-tr',
        texts: ['E-posta adresiniz hatalı.'],
      },
      {
        language: 'vi-vn',
        texts: ['Địa chỉ email của bạn không đúng.'],
      },
    ],
  },
  {
    key: 'ASK_MAIL_CONFIRMATION',
    values: [
      {
        language: 'fr-fr',
        texts: ["Est ce que l'email {lastAnswer.data} est correct?"],
      },
      {
        language: 'en-us',
        texts: ['Is the email address {lastAnswer.data} correct?'],
      },
      {
        language: 'en-gb',
        texts: ['Is the email address {lastAnswer.data} correct?'],
      },
      {
        language: 'nl-nl',
        texts: ['Klopt dit e-mail adres {lastAnswer.data}?'],
      },
      {
        language: 'es-es',
        texts: ['¿Es este email {lastAnswer.data} correcto?'],
      },
      {
        language: 'en-au',
        texts: ['Is the email address {lastAnswer.data} correct?'],
      },
      {
        language: 'en-ca',
        texts: ['Is the email address {lastAnswer.data} correct?'],
      },
      {
        language: 'en-de',
        texts: ['Is the email address {lastAnswer.data} correct?'],
      },
      {
        language: 'en-hk',
        texts: ['Is the email address {lastAnswer.data} correct?'],
      },
      {
        language: 'en-ie',
        texts: ['Is the email address {lastAnswer.data} correct?'],
      },
      {
        language: 'en-in',
        texts: ['Is the email address {lastAnswer.data} correct?'],
      },
      {
        language: 'en-my',
        texts: ['Is the email address {lastAnswer.data} correct?'],
      },
      {
        language: 'en-nl',
        texts: ['Is the email address {lastAnswer.data} correct?'],
      },
      {
        language: 'en-sg',
        texts: ['Is the email address {lastAnswer.data} correct?'],
      },
      {
        language: 'lb-lu',
        texts: ['Is the email address {lastAnswer.data} correct?'],
      },
      {
        language: 'de-at',
        texts: ['Ist die E-Mail-Adresse {lastAnswer.data} korrekt?'],
      },
      {
        language: 'de-ch',
        texts: ['Ist die E-Mail-Adresse {lastAnswer.data} korrekt?'],
      },
      {
        language: 'de-de',
        texts: ['Ist die E-Mail-Adresse {lastAnswer.data} korrekt?'],
      },
      {
        language: 'fr-be',
        texts: ["Est ce que l'email {lastAnswer.data} est correct?"],
      },
      {
        language: 'fr-ca',
        texts: ["Est ce que l'email {lastAnswer.data} est correct?"],
      },
      {
        language: 'fr-ch',
        texts: ["Est ce que l'email {lastAnswer.data} est correct?"],
      },
      {
        language: 'fr-tn',
        texts: ["Est ce que l'email {lastAnswer.data} est correct?"],
      },
      {
        language: 'ar-ae',
        texts: ['هل عنوان البريد الإلكتروني {lastAnswer.data} صحيح؟'],
      },
      {
        language: 'bg-bg',
        texts: ['Правилен ли е имейл адресът {lastAnswer.data}?'],
      },
      {
        language: 'cs-cz',
        texts: ['Je e-mailová adresa {lastAnswer.data} správná?'],
      },
      {
        language: 'el-gr',
        texts: [
          'Είναι σωστή η διεύθυνση ηλεκτρονικού ταχυδρομείου {lastAnswer.data}?',
        ],
      },
      {
        language: 'fi-fi',
        texts: ['Onko sähköpostiosoite {lastAnswer.data} oikein?'],
      },
      {
        language: 'hr-hr',
        texts: ['Je li adresa e -pošte {lastAnswer.data} točna?'],
      },
      {
        language: 'hu-hu',
        texts: ['Helyes az e-mail cím {lastAnswer.data}?'],
      },
      {
        language: 'it-ch',
        texts: ["L'indirizzo e-mail {lastAnswer.data} è corretto?"],
      },
      {
        language: 'it-it',
        texts: ["L'indirizzo e-mail {lastAnswer.data} è corretto?"],
      },
      {
        language: 'ja-jp',
        texts: ['メールアドレス{lastAnswer.data}は正しいですか？'],
      },
      {
        language: 'ko-kr',
        texts: ['전자 메일 주소 {lastAnswer.data}이(가) 정확합니까?'],
      },
      {
        language: 'ms-my',
        texts: ['Adakah alamat e-mel {lastAnswer.data} betul?'],
      },
      {
        language: 'nl-be',
        texts: ['Klopt dit e-mail adres {lastAnswer.data}?'],
      },
      {
        language: 'pl-pl',
        texts: ['Czy adres e-mail {lastAnswer.data} jest poprawny?'],
      },
      {
        language: 'ro-ro',
        texts: ['Adresa de e-mail {lastAnswer.data} este corectă?'],
      },
      {
        language: 'sk-sk',
        texts: ['Je e-mailová adresa {lastAnswer.data} správna?'],
      },
      {
        language: 'sl-si',
        texts: ['Ali je e-poštni naslov {lastAnswer.data} pravilen?'],
      },
      {
        language: 'sr-latn-rs',
        texts: ['Да ли је е-адреса {lastAnswer.data} тачна?'],
      },
      {
        language: 'sv-se',
        texts: ['Är e-postadressen {lastAnswer.data} korrekt?'],
      },
      {
        language: 'th-th',
        texts: ['อีเมลแอดเดรส {lastAnswer.data} ถูกต้องหรือไม่'],
      },
      {
        language: 'tr-tr',
        texts: ['{lastAnswer.data} e-posta adresi doğru mu?'],
      },
      {
        language: 'vi-vn',
        texts: ['Địa chỉ email {lastAnswer.data} có chính xác không?'],
      },
    ],
  },
  {
    key: 'WARNINGEMAIL_MSG',
    values: [
      {
        language: 'fr-fr',
        texts: [
          "Afin de confirmer, de modifier ou d'annuler votre RDV, nous allons avoir besoin de votre email.",
        ],
      },
      {
        language: 'en-us',
        texts: [
          'In order to confirm, change or cancel your appointment, we will need to know your email address.',
        ],
      },
      {
        language: 'en-gb',
        texts: [
          'In order to confirm, change or cancel your appointment, we will need to know your email address.',
        ],
      },
      {
        language: 'nl-nl',
        texts: [
          'Om jouw afspraak te bevestigen, te wijzigen of te annuleren, hebben we jouw e-mailadres nodig.',
        ],
      },
      {
        language: 'es-es',
        texts: [
          'Para poder confirmar, cambiar o cancelar tu reserva, necesitaremos tu dirección de correo electrónico por favor.',
        ],
      },
      {
        language: 'en-au',
        texts: [
          'In order to confirm, change or cancel your appointment, we will need to know your email address.',
        ],
      },
      {
        language: 'en-ca',
        texts: [
          'In order to confirm, change or cancel your appointment, we will need to know your email address.',
        ],
      },
      {
        language: 'en-de',
        texts: [
          'In order to confirm, change or cancel your appointment, we will need to know your email address.',
        ],
      },
      {
        language: 'en-hk',
        texts: [
          'In order to confirm, change or cancel your appointment, we will need to know your email address.',
        ],
      },
      {
        language: 'en-ie',
        texts: [
          'In order to confirm, change or cancel your appointment, we will need to know your email address.',
        ],
      },
      {
        language: 'en-in',
        texts: [
          'In order to confirm, change or cancel your appointment, we will need to know your email address.',
        ],
      },
      {
        language: 'en-my',
        texts: [
          'In order to confirm, change or cancel your appointment, we will need to know your email address.',
        ],
      },
      {
        language: 'en-nl',
        texts: [
          'In order to confirm, change or cancel your appointment, we will need to know your email address.',
        ],
      },
      {
        language: 'en-sg',
        texts: [
          'In order to confirm, change or cancel your appointment, we will need to know your email address.',
        ],
      },
      {
        language: 'lb-lu',
        texts: [
          'In order to confirm, change or cancel your appointment, we will need to know your email address.',
        ],
      },
      {
        language: 'de-at',
        texts: [
          'Um Ihren Termin bestätigen, ändern oder stornieren zu können, benötigen wir Ihre E-Mail-Adresse.',
        ],
      },
      {
        language: 'de-ch',
        texts: [
          'Um Ihren Termin bestätigen, ändern oder stornieren zu können, benötigen wir Ihre E-Mail-Adresse.',
        ],
      },
      {
        language: 'de-de',
        texts: [
          'Um Ihren Termin bestätigen, ändern oder stornieren zu können, benötigen wir Ihre E-Mail-Adresse.',
        ],
      },
      {
        language: 'fr-be',
        texts: [
          "Afin de confirmer, de modifier ou d'annuler votre RDV, nous allons avoir besoin de votre email.",
        ],
      },
      {
        language: 'fr-ca',
        texts: [
          "Afin de confirmer, de modifier ou d'annuler votre RDV, nous allons avoir besoin de votre email.",
        ],
      },
      {
        language: 'fr-ch',
        texts: [
          "Afin de confirmer, de modifier ou d'annuler votre RDV, nous allons avoir besoin de votre email.",
        ],
      },
      {
        language: 'fr-tn',
        texts: [
          "Afin de confirmer, de modifier ou d'annuler votre RDV, nous allons avoir besoin de votre email.",
        ],
      },
      {
        language: 'ar-ae',
        texts: [
          'لتأكيد الموعد أو تغييره أو إلغائه، سنحتاج إلى معرفة عنوان بريدك الإلكتروني.',
        ],
      },
      {
        language: 'bg-bg',
        texts: [
          'За да потвърдим, променим или отменим вашата среща, ще трябва да знаем вашия имейл адрес.',
        ],
      },
      {
        language: 'cs-cz',
        texts: [
          'Pro potvrzení, změnu nebo zrušení schůzky budeme potřebovat znát vaši e-mailovou adresu.',
        ],
      },
      {
        language: 'el-gr',
        texts: [
          'Για να επιβεβαιώσουμε, να αλλάξουμε ή να ακυρώσουμε το ραντεβού σας, θα πρέπει να γνωρίζουμε τη διεύθυνση ηλεκτρονικού ταχυδρομείου σας.',
        ],
      },
      {
        language: 'fi-fi',
        texts: [
          'Vahvistaaksemme, muuttaaksemme tai peruuttaaksemme tapaamisesi tarvitsemme sähköpostiosoitteesi.',
        ],
      },
      {
        language: 'hr-hr',
        texts: [
          'Kako bismo potvrdili, promijenili ili otkazali svoj termin, morat ćemo znati vašu e -adresu.',
        ],
      },
      {
        language: 'hu-hu',
        texts: [
          'Ahhoz, hogy megerősíthessük, megváltoztathassuk vagy lemondhassuk az időpontját, ismernünk kell az e-mail címét.',
        ],
      },
      {
        language: 'it-ch',
        texts: [
          'Per confermare, cambiare o cancellare il suo appuntamento, abbiamo bisogno di conoscere il suo indirizzo e-mail.',
        ],
      },
      {
        language: 'it-it',
        texts: [
          'Per confermare, cambiare o cancellare il suo appuntamento, abbiamo bisogno di conoscere il suo indirizzo e-mail.',
        ],
      },
      {
        language: 'ja-jp',
        texts: [
          '予約の確認、変更、キャンセルをするためには、お客様のEメールアドレスが必要です。',
        ],
      },
      {
        language: 'ko-kr',
        texts: [
          '약속을 확인, 변경 또는 취소하려면 전자 메일 주소를 알아야 합니다.',
        ],
      },
      {
        language: 'ms-my',
        texts: [
          'Untuk mengesahkan, mengubah atau membatalkan janji temu anda, kami perlu mengetahui alamat e-mel anda.',
        ],
      },
      {
        language: 'nl-be',
        texts: [
          'Om jouw afspraak te bevestigen, te wijzigen of te annuleren, hebben we jouw e-mailadres nodig.',
        ],
      },
      {
        language: 'pl-pl',
        texts: [
          'Aby potwierdzić, zmienić lub odwołać wizytę, będziemy musieli znać Twój adres e-mail.',
        ],
      },
      {
        language: 'ro-ro',
        texts: [
          'Pentru a vă confirma, modifica sau anula programarea, vom avea nevoie de adresa dumneavoastră de e-mail.',
        ],
      },
      {
        language: 'sk-sk',
        texts: [
          'Na potvrdenie, zmenu alebo zrušenie stretnutia potrebujeme poznať vašu e-mailovú adresu.',
        ],
      },
      {
        language: 'sl-si',
        texts: [
          'Za potrditev, spremembo ali preklic sestanka moramo poznati vaš e-poštni naslov.',
        ],
      },
      {
        language: 'sr-latn-rs',
        texts: [
          'Да бисмо потврдили, променили или отказали вашу заказану обавезу, морамо да знамо вашу е-адресу.',
        ],
      },
      {
        language: 'sv-se',
        texts: [
          'För att kunna bekräfta, ändra eller avboka ditt möte behöver vi din e-postadress.',
        ],
      },
      {
        language: 'th-th',
        texts: [
          'ในการยืนยัน เปลี่ยนหรือยกเลิกการนัดหมายของคุณ เราจําเป็นต้องทราบอีเมลแอดเดรสของคุณ',
        ],
      },
      {
        language: 'tr-tr',
        texts: [
          'Randevunuzu onaylamak, değiştirmek veya iptal etmek için e-posta adresinizi bilmemiz gerekiyor.',
        ],
      },
      {
        language: 'vi-vn',
        texts: [
          'Để xác nhận, thay đổi hoặc hủy cuộc hẹn của bạn, chúng tôi sẽ cần biết địa chỉ email của bạn.',
        ],
      },
    ],
  },
  {
    key: 'CONFIRMEMAIL_MSG',
    values: [
      {
        language: 'fr-fr',
        texts: ['Votre adresse email est bien {candidate.email} ?'],
      },
      {
        language: 'en-us',
        texts: ['Is your e-mail address {candidate.email}?'],
      },
      {
        language: 'en-gb',
        texts: ['Is your e-mail address {candidate.email}?'],
      },
      {
        language: 'nl-nl',
        texts: ['Is dit jouw e-mail adres {candidate.email}?'],
      },
      {
        language: 'es-es',
        texts: ['¿Es este email {candidate.email} correcto?'],
      },
      {
        language: 'en-au',
        texts: ['Is your e-mail address {candidate.email}?'],
      },
      {
        language: 'en-ca',
        texts: ['Is your e-mail address {candidate.email}?'],
      },
      {
        language: 'en-de',
        texts: ['Is your e-mail address {candidate.email}?'],
      },
      {
        language: 'en-hk',
        texts: ['Is your e-mail address {candidate.email}?'],
      },
      {
        language: 'en-ie',
        texts: ['Is your e-mail address {candidate.email}?'],
      },
      {
        language: 'en-in',
        texts: ['Is your e-mail address {candidate.email}?'],
      },
      {
        language: 'en-my',
        texts: ['Is your e-mail address {candidate.email}?'],
      },
      {
        language: 'en-nl',
        texts: ['Is your e-mail address {candidate.email}?'],
      },
      {
        language: 'en-sg',
        texts: ['Is your e-mail address {candidate.email}?'],
      },
      {
        language: 'lb-lu',
        texts: ['Is your e-mail address {candidate.email}?'],
      },
      {
        language: 'de-at',
        texts: ['Ist Ihre E-Mail-Adresse {candidate.email}?'],
      },
      {
        language: 'de-ch',
        texts: ['Ist Ihre E-Mail-Adresse {candidate.email}?'],
      },
      {
        language: 'de-de',
        texts: ['Ist Ihre E-Mail-Adresse {candidate.email}?'],
      },
      {
        language: 'fr-be',
        texts: ['Votre adresse email est bien {candidate.email} ?'],
      },
      {
        language: 'fr-ca',
        texts: ['Votre adresse email est bien {candidate.email} ?'],
      },
      {
        language: 'fr-ch',
        texts: ['Votre adresse email est bien {candidate.email} ?'],
      },
      {
        language: 'fr-tn',
        texts: ['Votre adresse email est bien {candidate.email} ?'],
      },
      {
        language: 'ar-ae',
        texts: ['هل عنوان بريدك الإلكتروني {candidate.email}؟'],
      },
      {
        language: 'bg-bg',
        texts: ['Вашият имейл адрес {candidate.email} ли е?'],
      },
      {
        language: 'cs-cz',
        texts: ['Je vaše e-mailová adresa {candidate.email}?'],
      },
      {
        language: 'el-gr',
        texts: [
          'Είναι η διεύθυνση ηλεκτρονικού ταχυδρομείου σας {candidate.email}?',
        ],
      },
      {
        language: 'fi-fi',
        texts: ['Onko sähköpostiosoitteesi {candidate.email}?'],
      },
      {
        language: 'hr-hr',
        texts: ['Je li vaša adresa e-pošte {candidate.email}?'],
      },
      {
        language: 'hu-hu',
        texts: ['Az Ön e-mail címe {candidate.email}?'],
      },
      {
        language: 'it-ch',
        texts: ['Il tuo indirizzo e-mail è {candidate.email}?'],
      },
      {
        language: 'it-it',
        texts: ['Il tuo indirizzo e-mail è {candidate.email}?'],
      },
      {
        language: 'ja-jp',
        texts: ['あなたのメールアドレスは{candidate.email}ですか？'],
      },
      {
        language: 'ko-kr',
        texts: ['메일 주소가 {candidate.email}입니까?'],
      },
      {
        language: 'ms-my',
        texts: ['Adakah alamat e-mel anda {candidate.email}?'],
      },
      {
        language: 'nl-be',
        texts: ['Is dit jouw e-mail adres {candidate.email}?'],
      },
      {
        language: 'pl-pl',
        texts: ['Czy Twój adres e-mail to {candidate.email}?'],
      },
      {
        language: 'ro-ro',
        texts: ['Adresa dvs. de e-mail este {candidate.email}?'],
      },
      {
        language: 'sk-sk',
        texts: ['Je vaša e-mailová adresa {candidate.email}?'],
      },
      {
        language: 'sl-si',
        texts: ['Ali je vaš e-poštni naslov {candidate.email}?'],
      },
      {
        language: 'sr-latn-rs',
        texts: ['Да ли је ваша е-адреса {candidate.email}?'],
      },
      {
        language: 'sv-se',
        texts: ['Är din e-postadress {candidate.email}?'],
      },
      {
        language: 'th-th',
        texts: ['อีเมลแอดเดรสของคุณคือ {candidate.email} หรือไม่'],
      },
      {
        language: 'tr-tr',
        texts: ['E-posta adresiniz {candidate.email} mı?'],
      },
      {
        language: 'vi-vn',
        texts: ['Địa chỉ e-mail của bạn là {candidate.email}?'],
      },
    ],
  },
  {
    key: 'NO_SLOT_AVAILABLE',
    values: [
      {
        language: 'fr-fr',
        texts: [
          "Désolé, nous n'avons pas pu vous réserver de créneaux d'entretien. Nous vous contacterons ulterieurement.\nBonne journée !",
        ],
      },
      {
        language: 'en-us',
        texts: [
          "Sorry, we couln't book any meeting slot for you. We will contact you later. \nHave a nice day!",
        ],
      },
      {
        language: 'en-gb',
        texts: [
          "Sorry, we couln't book any meeting slot for you. We will contact you later. \nHave a nice day!",
        ],
      },
      {
        language: 'nl-nl',
        texts: [
          'Sorry, we konden geen afspraak voor je maken. Wij nemen contact met je op. Een fijne dag verder!',
        ],
      },
      {
        language: 'es-es',
        texts: [
          'Lo siento pero no pudimos hacer ninguna reserva para ti. Te contactaremos próximamente. \n¡Que tengas buen día!',
        ],
      },
      {
        language: 'en-au',
        texts: [
          "Sorry, we couln't book any meeting slot for you. We will contact you later. \nHave a nice day!",
        ],
      },
      {
        language: 'en-ca',
        texts: [
          "Sorry, we couln't book any meeting slot for you. We will contact you later. \nHave a nice day!",
        ],
      },
      {
        language: 'en-de',
        texts: [
          "Sorry, we couln't book any meeting slot for you. We will contact you later. \nHave a nice day!",
        ],
      },
      {
        language: 'en-hk',
        texts: [
          "Sorry, we couln't book any meeting slot for you. We will contact you later. \nHave a nice day!",
        ],
      },
      {
        language: 'en-ie',
        texts: [
          "Sorry, we couln't book any meeting slot for you. We will contact you later. \nHave a nice day!",
        ],
      },
      {
        language: 'en-in',
        texts: [
          "Sorry, we couln't book any meeting slot for you. We will contact you later. \nHave a nice day!",
        ],
      },
      {
        language: 'en-my',
        texts: [
          "Sorry, we couln't book any meeting slot for you. We will contact you later. \nHave a nice day!",
        ],
      },
      {
        language: 'en-nl',
        texts: [
          "Sorry, we couln't book any meeting slot for you. We will contact you later. \nHave a nice day!",
        ],
      },
      {
        language: 'en-sg',
        texts: [
          "Sorry, we couln't book any meeting slot for you. We will contact you later. \nHave a nice day!",
        ],
      },
      {
        language: 'lb-lu',
        texts: [
          "Sorry, we couln't book any meeting slot for you. We will contact you later. \nHave a nice day!",
        ],
      },
      {
        language: 'de-at',
        texts: [
          'Leider konnten wir keinen Termin für Sie reservieren. Wir werden Sie später kontaktieren. \nSchönen Tag noch!',
        ],
      },
      {
        language: 'de-ch',
        texts: [
          'Leider konnten wir keinen Termin für Sie reservieren. Wir werden Sie später kontaktieren. \nSchönen Tag noch!',
        ],
      },
      {
        language: 'de-de',
        texts: [
          'Leider konnten wir keinen Termin für Sie reservieren. Wir werden Sie später kontaktieren. \nSchönen Tag noch!',
        ],
      },
      {
        language: 'fr-be',
        texts: [
          "Désolé, nous n'avons pas pu vous réserver de créneaux d'entretien. Nous vous contacterons ulterieurement.\nBonne journée !",
        ],
      },
      {
        language: 'fr-ca',
        texts: [
          "Désolé, nous n'avons pas pu vous réserver de créneaux d'entretien. Nous vous contacterons ulterieurement.\nBonne journée !",
        ],
      },
      {
        language: 'fr-ch',
        texts: [
          "Désolé, nous n'avons pas pu vous réserver de créneaux d'entretien. Nous vous contacterons ulterieurement.\nBonne journée !",
        ],
      },
      {
        language: 'fr-tn',
        texts: [
          "Désolé, nous n'avons pas pu vous réserver de créneaux d'entretien. Nous vous contacterons ulterieurement.\nBonne journée !",
        ],
      },
      {
        language: 'ar-ae',
        texts: [
          'عذرا، لم نتمكن من حجز أي فتحة إجتماع لك. سنتصل بك لاحقا. \n أتمنى لك يوما ممتعا!',
        ],
      },
      {
        language: 'bg-bg',
        texts: [
          'Съжаляваме, но не можем да резервираме слот за среща за вас. Ще се свържем с вас по-късно. \nНа добър ден!',
        ],
      },
      {
        language: 'cs-cz',
        texts: [
          'Omlouváme se, ale nemůžeme pro vás rezervovat žádné místo na schůzku. Budeme vás kontaktovat později. \nPřeji hezký den!',
        ],
      },
      {
        language: 'el-gr',
        texts: [
          'Λυπούμαστε, δεν μπορέσαμε να κλείσουμε κάποια συνάντηση για εσάς. Θα επικοινωνήσουμε μαζί σας αργότερα. \nΚαλή σας μέρα!',
        ],
      },
      {
        language: 'fi-fi',
        texts: [
          'Valitettavasti emme voineet varata sinulle kokousaikaa. Otamme sinuun yhteyttä myöhemmin. \nHyvää päivänjatkoa!',
        ],
      },
      {
        language: 'hr-hr',
        texts: [
          'Nažalost, za vas ne možemo rezervirati mjesto za sastanke. Poslije ćemo vas kontaktirati. \nUgodan dan!',
        ],
      },
      {
        language: 'hu-hu',
        texts: [
          'Sajnáljuk, de nem tudtunk Önnek időpontot foglalni. Később felvesszük Önnel a kapcsolatot. \nKellemes napot!',
        ],
      },
      {
        language: 'it-ch',
        texts: [
          'Siamo spiacenti, non abbiamo potuto prenotare alcuno slot di incontro per te. La contatteremo più tardi. \nBuona giornata!',
        ],
      },
      {
        language: 'it-it',
        texts: [
          'Siamo spiacenti, non abbiamo potuto prenotare alcuno slot di incontro per te. La contatteremo più tardi. \nBuona giornata!',
        ],
      },
      {
        language: 'ja-jp',
        texts: [
          '申し訳ありませんが、あなたのためのミーティング枠を予約することができません。後日、ご連絡させていただきます',
        ],
      },
      {
        language: 'ko-kr',
        texts: [
          '죄송합니다. 모임 슬롯을 예약할 수 없습니다. 저희가 나중에 연락드리겠습니다. 좋은 하루 보내세요!',
        ],
      },
      {
        language: 'ms-my',
        texts: [
          'Maaf, kami tidak dapat menempah slot mesyuarat untuk anda. Kami akan menghubungi anda nanti.\nSemoga hari yang baik untuk anda!',
        ],
      },
      {
        language: 'nl-be',
        texts: [
          'Sorry, we konden geen afspraak voor je maken. Wij nemen contact met je op. Een fijne dag verder!',
        ],
      },
      {
        language: 'pl-pl',
        texts: [
          'Przepraszamy, nie udało nam się zarezerwować dla Ciebie żadnego terminu spotkania. Skontaktujemy się z Tobą później. \nŻyczymy miłego dnia!',
        ],
      },
      {
        language: 'ro-ro',
        texts: [
          'Ne pare rău, nu am putut să vă rezervăm nici un slot de întâlnire. Vă vom contacta mai târziu. \nO zi bună!',
        ],
      },
      {
        language: 'sk-sk',
        texts: [
          'Je nám ľúto, ale nepodarilo sa nám pre vás rezervovať žiadne miesto na stretnutie. Budeme vás kontaktovať neskôr. \nPrajem vám pekný deň!',
        ],
      },
      {
        language: 'sl-si',
        texts: [
          'Žal za vas nismo mogli rezervirati nobenega termina za sestanek. Z vami bomo stopili v stik pozneje. \nLep dan!',
        ],
      },
      {
        language: 'sr-latn-rs',
        texts: [
          'Жао нам је, нисмо могли да резервишемо место за састанке за вас. Јавићемо вам се касније. Лепо се проведите!',
        ],
      },
      {
        language: 'sv-se',
        texts: [
          'Tyvärr kunde vi inte boka någon mötesplats för dig. Vi kontaktar dig senare. \nHar en trevlig dag!',
        ],
      },
      {
        language: 'th-th',
        texts: [
          'ขออภัย เราไม่สามารถจองช่องใส่การประชุมให้คุณได้ เราจะติดต่อคุณในภายหลัง \nขอให้มีความสุข!',
        ],
      },
      {
        language: 'tr-tr',
        texts: [
          'Ne yazık ki sizin için toplantı aralığı ayıramadık. Sizinle daha sonra iletişim kuracağız. \nİyi günler!',
        ],
      },
      {
        language: 'vi-vn',
        texts: [
          'Rất tiếc, chúng tôi không thể đặt bất kỳ vị trí cuộc họp nào cho bạn. Chúng tôi sẽ liên lạc với bạn sau. \nChúc một ngày tốt lành!',
        ],
      },
    ],
  },
  {
    key: 'SEND_EMAIL_MSG',
    values: [
      {
        language: 'fr-fr',
        texts: ['Renvoyer un mail de confirmation'],
      },
      {
        language: 'en-us',
        texts: ['Send a confirmation email again'],
      },
      {
        language: 'en-gb',
        texts: ['Send a confirmation email again'],
      },
      {
        language: 'nl-nl',
        texts: ['Stuur de bevestigingsmail opnieuw'],
      },
      {
        language: 'es-es',
        texts: ['Mandar un email de confirmación de nuevo'],
      },
      {
        language: 'en-au',
        texts: ['Send a confirmation email again'],
      },
      {
        language: 'en-ca',
        texts: ['Send a confirmation email again'],
      },
      {
        language: 'en-de',
        texts: ['Send a confirmation email again'],
      },
      {
        language: 'en-hk',
        texts: ['Send a confirmation email again'],
      },
      {
        language: 'en-ie',
        texts: ['Send a confirmation email again'],
      },
      {
        language: 'en-in',
        texts: ['Send a confirmation email again'],
      },
      {
        language: 'en-my',
        texts: ['Send a confirmation email again'],
      },
      {
        language: 'en-nl',
        texts: ['Send a confirmation email again'],
      },
      {
        language: 'en-sg',
        texts: ['Send a confirmation email again'],
      },
      {
        language: 'lb-lu',
        texts: ['Send a confirmation email again'],
      },
      {
        language: 'de-at',
        texts: ['Senden Sie erneut eine Bestätigungs-E-Mail'],
      },
      {
        language: 'de-ch',
        texts: ['Senden Sie erneut eine Bestätigungs-E-Mail'],
      },
      {
        language: 'de-de',
        texts: ['Senden Sie erneut eine Bestätigungs-E-Mail'],
      },
      {
        language: 'fr-be',
        texts: ['Renvoyer un mail de confirmation'],
      },
      {
        language: 'fr-ca',
        texts: ['Renvoyer un mail de confirmation'],
      },
      {
        language: 'fr-ch',
        texts: ['Renvoyer un mail de confirmation'],
      },
      {
        language: 'fr-tn',
        texts: ['Renvoyer un mail de confirmation'],
      },
      {
        language: 'ar-ae',
        texts: ['إرسال بريد إلكتروني للتأكيد مرة أخرى'],
      },
      {
        language: 'bg-bg',
        texts: ['Повторно изпращане на имейл за потвърждение'],
      },
      {
        language: 'cs-cz',
        texts: ['Opětovné odeslání potvrzovacího e-mailu'],
      },
      {
        language: 'el-gr',
        texts: ['Στείλτε ξανά ένα email επιβεβαίωσης'],
      },
      {
        language: 'fi-fi',
        texts: ['Lähetä vahvistussähköposti uudelleen'],
      },
      {
        language: 'hr-hr',
        texts: ['Ponovno pošaljite e -poruku s potvrdom'],
      },
      {
        language: 'hu-hu',
        texts: ['Küldjön ismét megerősítő e-mailt'],
      },
      {
        language: 'it-ch',
        texts: ["Inviare di nuovo un'email di conferma"],
      },
      {
        language: 'it-it',
        texts: ["Inviare di nuovo un'email di conferma"],
      },
      {
        language: 'ja-jp',
        texts: ['確認メールの再送信'],
      },
      {
        language: 'ko-kr',
        texts: ['확인 전자 메일 다시 보내기'],
      },
      {
        language: 'ms-my',
        texts: ['Hantar e-mel pengesahan sekali lagi'],
      },
      {
        language: 'nl-be',
        texts: ['Stuur de bevestigingsmail opnieuw'],
      },
      {
        language: 'pl-pl',
        texts: ['Wyślij ponownie wiadomość e-mail z potwierdzeniem'],
      },
      {
        language: 'ro-ro',
        texts: ['Trimiteți din nou un e-mail de confirmare'],
      },
      {
        language: 'sk-sk',
        texts: ['Opätovné odoslanie potvrdzujúceho e-mailu'],
      },
      {
        language: 'sl-si',
        texts: ['Ponovno pošljite potrditveno e-poštno sporočilo'],
      },
      {
        language: 'sr-latn-rs',
        texts: ['Поново пошаљи е-поруку са потврдом'],
      },
      {
        language: 'sv-se',
        texts: ['Skicka ett bekräftelsemail igen'],
      },
      {
        language: 'th-th',
        texts: ['ส่งอีเมลยืนยันอีกครั้ง'],
      },
      {
        language: 'tr-tr',
        texts: ['Yeniden bir onay e-postası gönderin'],
      },
      {
        language: 'vi-vn',
        texts: ['Gửi lại email xác nhận'],
      },
    ],
  },
  {
    key: 'SEND_SMS_MSG',
    values: [
      {
        language: 'fr-fr',
        texts: ['Renvoyer un SMS de confirmation'],
      },
      {
        language: 'en-us',
        texts: ['Request a new confirmation SMS'],
      },
      {
        language: 'en-gb',
        texts: ['Request a new confirmation SMS'],
      },
      {
        language: 'nl-nl',
        texts: ['Stuur de bevestigings-SMS opnieuw'],
      },
      {
        language: 'es-es',
        texts: ['Mandar un SMS de confirmación de nuevo'],
      },
      {
        language: 'en-au',
        texts: ['Request a new confirmation SMS'],
      },
      {
        language: 'en-ca',
        texts: ['Request a new confirmation SMS'],
      },
      {
        language: 'en-de',
        texts: ['Request a new confirmation SMS'],
      },
      {
        language: 'en-hk',
        texts: ['Request a new confirmation SMS'],
      },
      {
        language: 'en-ie',
        texts: ['Request a new confirmation SMS'],
      },
      {
        language: 'en-in',
        texts: ['Request a new confirmation SMS'],
      },
      {
        language: 'en-my',
        texts: ['Request a new confirmation SMS'],
      },
      {
        language: 'en-nl',
        texts: ['Request a new confirmation SMS'],
      },
      {
        language: 'en-sg',
        texts: ['Request a new confirmation SMS'],
      },
      {
        language: 'lb-lu',
        texts: ['Request a new confirmation SMS'],
      },
      {
        language: 'de-at',
        texts: ['Eine neue Bestätigungs-SMS anfordern'],
      },
      {
        language: 'de-ch',
        texts: ['Eine neue Bestätigungs-SMS anfordern'],
      },
      {
        language: 'de-de',
        texts: ['Eine neue Bestätigungs-SMS anfordern'],
      },
      {
        language: 'fr-be',
        texts: ['Renvoyer un mail de confirmation'],
      },
      {
        language: 'fr-ca',
        texts: ['Renvoyer un SMS de confirmation'],
      },
      {
        language: 'fr-ch',
        texts: ['Renvoyer un SMS de confirmation'],
      },
      {
        language: 'fr-tn',
        texts: ['Renvoyer un SMS de confirmation'],
      },
      {
        language: 'ar-ae',
        texts: ['طلب رسالة تأكيد SMS جديدة'],
      },
      {
        language: 'bg-bg',
        texts: ['Заявка за нов потвърдителен SMS'],
      },
      {
        language: 'cs-cz',
        texts: ['Žádost o novou potvrzovací SMS'],
      },
      {
        language: 'el-gr',
        texts: ['Ζητήστε ένα νέο SMS επιβεβαίωσης'],
      },
      {
        language: 'fi-fi',
        texts: ['Pyydä uusi vahvistustekstiviesti'],
      },
      {
        language: 'hr-hr',
        texts: ['Zatražite novi SMS za potvrdu'],
      },
      {
        language: 'hu-hu',
        texts: ['Új megerősítő SMS kérése'],
      },
      {
        language: 'it-ch',
        texts: ['Richiedere un nuovo SMS di conferma'],
      },
      {
        language: 'it-it',
        texts: ['Richiedere un nuovo SMS di conferma'],
      },
      {
        language: 'ja-jp',
        texts: ['新しい確認用SMSのリクエスト'],
      },
      {
        language: 'ko-kr',
        texts: ['새 확인 SMS 요청'],
      },
      {
        language: 'ms-my',
        texts: ['Mohon SMS pengesahan baru'],
      },
      {
        language: 'nl-be',
        texts: ['Stuur de bevestigings-SMS opnieuw'],
      },
      {
        language: 'pl-pl',
        texts: ['Poproś o nowy SMS potwierdzający'],
      },
      {
        language: 'ro-ro',
        texts: ['Solicitați un nou SMS de confirmare'],
      },
      {
        language: 'sk-sk',
        texts: ['Vyžiadanie novej potvrdzujúcej SMS'],
      },
      {
        language: 'sl-si',
        texts: ['Zahtevajte novo potrditveno sporočilo SMS'],
      },
      {
        language: 'sr-latn-rs',
        texts: ['Захтевај нови СМС потврде'],
      },
      {
        language: 'sv-se',
        texts: ['Begär ett nytt bekräftelse-SMS'],
      },
      {
        language: 'th-th',
        texts: ['ร้องขอการยืนยัน SMS ใหม่'],
      },
      {
        language: 'tr-tr',
        texts: ["Yeni bir onay SMS'i iste"],
      },
      {
        language: 'vi-vn',
        texts: ['Yêu cầu một SMS xác nhận mới'],
      },
    ],
  },
  {
    key: 'CHANGE_SLOT_MSG',
    values: [
      {
        language: 'fr-fr',
        texts: ['Changer de créneau'],
      },
      {
        language: 'en-us',
        texts: ['Change the time slot'],
      },
      {
        language: 'en-gb',
        texts: ['Change the time slot'],
      },
      {
        language: 'nl-nl',
        texts: ['Mijn afspraak verzetten'],
      },
      {
        language: 'es-es',
        texts: ['Cambiar el horario de mi reserva'],
      },
      {
        language: 'en-au',
        texts: ['Change the time slot'],
      },
      {
        language: 'en-ca',
        texts: ['Change the time slot'],
      },
      {
        language: 'en-de',
        texts: ['Change the time slot'],
      },
      {
        language: 'en-hk',
        texts: ['Change the time slot'],
      },
      {
        language: 'en-ie',
        texts: ['Change the time slot'],
      },
      {
        language: 'en-in',
        texts: ['Change the time slot'],
      },
      {
        language: 'en-my',
        texts: ['Change the time slot'],
      },
      {
        language: 'en-nl',
        texts: ['Change the time slot'],
      },
      {
        language: 'en-sg',
        texts: ['Change the time slot'],
      },
      {
        language: 'lb-lu',
        texts: ['Change the time slot'],
      },
      {
        language: 'de-at',
        texts: ['Ändern Sie das Zeitfenster'],
      },
      {
        language: 'de-ch',
        texts: ['Ändern Sie das Zeitfenster'],
      },
      {
        language: 'de-de',
        texts: ['Ändern Sie das Zeitfenster'],
      },
      {
        language: 'fr-be',
        texts: ['Changer de créneau'],
      },
      {
        language: 'fr-ca',
        texts: ['Changer de créneau'],
      },
      {
        language: 'fr-ch',
        texts: ['Changer de créneau'],
      },
      {
        language: 'fr-tn',
        texts: ['Changer de créneau'],
      },
      {
        language: 'ar-ae',
        texts: ['تغيير فتحة الوقت'],
      },
      {
        language: 'bg-bg',
        texts: ['Промяна на времевия интервал'],
      },
      {
        language: 'cs-cz',
        texts: ['Změna časového intervalu'],
      },
      {
        language: 'el-gr',
        texts: ['Αλλαγή της χρονοθυρίδας'],
      },
      {
        language: 'fi-fi',
        texts: ['Muuta aikaväliä'],
      },
      {
        language: 'hr-hr',
        texts: ['Promijenite vremenski interval'],
      },
      {
        language: 'hu-hu',
        texts: ['Az idősáv módosítása'],
      },
      {
        language: 'it-ch',
        texts: ['Cambiare la fascia oraria'],
      },
      {
        language: 'it-it',
        texts: ['Cambiare la fascia oraria'],
      },
      {
        language: 'ja-jp',
        texts: ['タイムスロットの変更'],
      },
      {
        language: 'ko-kr',
        texts: ['시간 슬롯 변경'],
      },
      {
        language: 'ms-my',
        texts: ['Tukar slot masa'],
      },
      {
        language: 'nl-be',
        texts: ['Mijn afspraak verzetten'],
      },
      {
        language: 'pl-pl',
        texts: ['Zmiana przedziału czasowego'],
      },
      {
        language: 'ro-ro',
        texts: ['Modificarea intervalului orar'],
      },
      {
        language: 'sk-sk',
        texts: ['Zmena časového slotu'],
      },
      {
        language: 'sl-si',
        texts: ['Spreminjanje časovnega intervala'],
      },
      {
        language: 'sr-latn-rs',
        texts: ['ЗПромените временски интервал'],
      },
      {
        language: 'sv-se',
        texts: ['Ändra tidslucka'],
      },
      {
        language: 'th-th',
        texts: ['เปลี่ยนช่องเวลา'],
      },
      {
        language: 'tr-tr',
        texts: ['Zaman aralığını değiştir'],
      },
      {
        language: 'vi-vn',
        texts: ['Thay đổi khoảng thời gian'],
      },
    ],
  },
  {
    key: 'CANCEL_SLOT_MSG',
    values: [
      {
        language: 'fr-fr',
        texts: ['Annuler mon RDV'],
      },
      {
        language: 'en-us',
        texts: ['Cancel the appointment'],
      },
      {
        language: 'en-gb',
        texts: ['Cancel the appointment'],
      },
      {
        language: 'nl-nl',
        texts: ['Mijn afspraak annuleren'],
      },
      {
        language: 'es-es',
        texts: ['Cancelar mi reserva'],
      },
      {
        language: 'en-au',
        texts: ['Cancel the appointment'],
      },
      {
        language: 'en-ca',
        texts: ['Cancel the appointment'],
      },
      {
        language: 'en-de',
        texts: ['Cancel the appointment'],
      },
      {
        language: 'en-hk',
        texts: ['Cancel the appointment'],
      },
      {
        language: 'en-ie',
        texts: ['Cancel the appointment'],
      },
      {
        language: 'en-in',
        texts: ['Cancel the appointment'],
      },
      {
        language: 'en-my',
        texts: ['Cancel the appointment'],
      },
      {
        language: 'en-nl',
        texts: ['Cancel the appointment'],
      },
      {
        language: 'en-sg',
        texts: ['Cancel the appointment'],
      },
      {
        language: 'lb-lu',
        texts: ['Cancel the appointment'],
      },
      {
        language: 'de-at',
        texts: ['Den Termin absagen'],
      },
      {
        language: 'de-ch',
        texts: ['Den Termin absagen'],
      },
      {
        language: 'de-de',
        texts: ['Den Termin absagen'],
      },
      {
        language: 'fr-be',
        texts: ['Annuler mon RDV'],
      },
      {
        language: 'fr-ca',
        texts: ['Annuler mon RDV'],
      },
      {
        language: 'fr-ch',
        texts: ['Annuler mon RDV'],
      },
      {
        language: 'fr-tn',
        texts: ['Annuler mon RDV'],
      },
      {
        language: 'ar-ae',
        texts: ['إلغاء الموعد'],
      },
      {
        language: 'bg-bg',
        texts: ['Отмяна на срещата'],
      },
      {
        language: 'cs-cz',
        texts: ['Zrušení schůzky'],
      },
      {
        language: 'el-gr',
        texts: ['Ακυρώστε το ραντεβού'],
      },
      {
        language: 'fi-fi',
        texts: ['Peruuta tapaaminen'],
      },
      {
        language: 'hr-hr',
        texts: ['Otkažite termin'],
      },
      {
        language: 'hu-hu',
        texts: ['Mondja le a találkozót'],
      },
      {
        language: 'it-ch',
        texts: ["Cancellare l'appuntamento"],
      },
      {
        language: 'it-it',
        texts: ["Cancellare l'appuntamento"],
      },
      {
        language: 'ja-jp',
        texts: ['予約のキャンセル'],
      },
      {
        language: 'ko-kr',
        texts: ['약속 취소'],
      },
      {
        language: 'ms-my',
        texts: ['Batalkan janji temu'],
      },
      {
        language: 'nl-be',
        texts: ['Mijn afspraak annuleren'],
      },
      {
        language: 'pl-pl',
        texts: ['Odwołaj wizytę'],
      },
      {
        language: 'ro-ro',
        texts: ['Anulați întâlnirea'],
      },
      {
        language: 'sk-sk',
        texts: ['Zrušenie stretnutia'],
      },
      {
        language: 'sl-si',
        texts: ['Preklic sestanka'],
      },
      {
        language: 'sr-latn-rs',
        texts: ['Откажи заказану обавезу'],
      },
      {
        language: 'sv-se',
        texts: ['Avboka mötet'],
      },
      {
        language: 'th-th',
        texts: ['ยกเลิกการนัดหมาย'],
      },
      {
        language: 'tr-tr',
        texts: ['Randevuyu iptal et'],
      },
      {
        language: 'vi-vn',
        texts: ['Hủy cuộc hẹn'],
      },
    ],
  },
  {
    key: 'CANCEL_OK',
    values: [
      {
        language: 'fr-fr',
        texts: ['La réservation a bien été annulée.'],
      },
      {
        language: 'en-us',
        texts: ['The booking has been cancelled.'],
      },
      {
        language: 'en-gb',
        texts: ['The booking has been cancelled.'],
      },
      {
        language: 'nl-nl',
        texts: ['De afspraak is geannuleerd.'],
      },
      {
        language: 'es-es',
        texts: ['La reserva ha sido cancelada correctamente.'],
      },
      {
        language: 'en-au',
        texts: ['The booking has been cancelled.'],
      },
      {
        language: 'en-ca',
        texts: ['The booking has been cancelled.'],
      },
      {
        language: 'en-de',
        texts: ['The booking has been cancelled.'],
      },
      {
        language: 'en-hk',
        texts: ['The booking has been cancelled.'],
      },
      {
        language: 'en-ie',
        texts: ['The booking has been cancelled.'],
      },
      {
        language: 'en-in',
        texts: ['The booking has been cancelled.'],
      },
      {
        language: 'en-my',
        texts: ['The booking has been cancelled.'],
      },
      {
        language: 'en-nl',
        texts: ['The booking has been cancelled.'],
      },
      {
        language: 'en-sg',
        texts: ['The booking has been cancelled.'],
      },
      {
        language: 'lb-lu',
        texts: ['The booking has been cancelled.'],
      },
      {
        language: 'de-at',
        texts: ['Die Buchung wurde storniert.'],
      },
      {
        language: 'de-ch',
        texts: ['Die Buchung wurde storniert.'],
      },
      {
        language: 'de-de',
        texts: ['Die Buchung wurde storniert.'],
      },
      {
        language: 'fr-be',
        texts: ['La réservation a bien été annulée.'],
      },
      {
        language: 'fr-ca',
        texts: ['La réservation a bien été annulée.'],
      },
      {
        language: 'fr-ch',
        texts: ['La réservation a bien été annulée.'],
      },
      {
        language: 'fr-tn',
        texts: ['La réservation a bien été annulée.'],
      },
      {
        language: 'ar-ae',
        texts: ['تم إلغاء الحجز.'],
      },
      {
        language: 'bg-bg',
        texts: ['Резервацията е отменена.'],
      },
      {
        language: 'cs-cz',
        texts: ['Rezervace byla zrušena.'],
      },
      {
        language: 'el-gr',
        texts: ['Η κράτηση έχει ακυρωθεί.'],
      },
      {
        language: 'fi-fi',
        texts: ['Varaus on peruutettu.'],
      },
      {
        language: 'hr-hr',
        texts: ['Rezervacija je otkazana.'],
      },
      {
        language: 'hu-hu',
        texts: ['A foglalást törölték.'],
      },
      {
        language: 'it-ch',
        texts: ['La prenotazione è stata cancellata.'],
      },
      {
        language: 'it-it',
        texts: ['La prenotazione è stata cancellata.'],
      },
      {
        language: 'ja-jp',
        texts: ['予約がキャンセルされました。'],
      },
      {
        language: 'ko-kr',
        texts: ['예약이 취소되었습니다.'],
      },
      {
        language: 'ms-my',
        texts: ['Tempahan telah dibatalkan.'],
      },
      {
        language: 'nl-be',
        texts: ['De afspraak is geannuleerd.'],
      },
      {
        language: 'pl-pl',
        texts: ['Rezerwacja została anulowana.'],
      },
      {
        language: 'ro-ro',
        texts: ['Rezervarea a fost anulată.'],
      },
      {
        language: 'sk-sk',
        texts: ['Rezervácia bola zrušená.'],
      },
      {
        language: 'sl-si',
        texts: ['Rezervacija je bila preklicana.'],
      },
      {
        language: 'sr-latn-rs',
        texts: ['Резервација је отказана.'],
      },
      {
        language: 'sv-se',
        texts: ['Bokningen har avbokats.'],
      },
      {
        language: 'th-th',
        texts: ['การจองถูกยกเลิก'],
      },
      {
        language: 'tr-tr',
        texts: ['Rezervasyon iptal edildi.'],
      },
      {
        language: 'vi-vn',
        texts: ['Đăng ký đã bị hủy.'],
      },
    ],
  },
  {
    key: 'TOO_MANY_REQUEST_MSG',
    values: [
      {
        language: 'fr-fr',
        texts: [
          'Désolé, je crois que vous avez fait trop de demande similaire. Nous ne pouvons pas satisfaire celle-ci.',
        ],
      },
      {
        language: 'en-us',
        texts: [
          "Sorry, I think that you made too many similar requests. We can't accommodate this one.",
        ],
      },
      {
        language: 'en-gb',
        texts: [
          "Sorry, I think that you made too many similar requests. We can't accommodate this one.",
        ],
      },
      {
        language: 'nl-nl',
        texts: [
          'Sorry, we hebben meerdere verzoeken van je ontvangen. We kunnen deze taak niet uitvoeren.',
        ],
      },
      {
        language: 'es-es',
        texts: [
          'Lo siento, creo que has hecho demasiadas peticiones. No hemos podido reservar esta última.',
        ],
      },
      {
        language: 'en-au',
        texts: [
          "Sorry, I think that you made too many similar requests. We can't accommodate this one.",
        ],
      },
      {
        language: 'en-ca',
        texts: [
          "Sorry, I think that you made too many similar requests. We can't accommodate this one.",
        ],
      },
      {
        language: 'en-de',
        texts: [
          "Sorry, I think that you made too many similar requests. We can't accommodate this one.",
        ],
      },
      {
        language: 'en-hk',
        texts: [
          "Sorry, I think that you made too many similar requests. We can't accommodate this one.",
        ],
      },
      {
        language: 'en-ie',
        texts: [
          "Sorry, I think that you made too many similar requests. We can't accommodate this one.",
        ],
      },
      {
        language: 'en-in',
        texts: [
          "Sorry, I think that you made too many similar requests. We can't accommodate this one.",
        ],
      },
      {
        language: 'en-my',
        texts: [
          "Sorry, I think that you made too many similar requests. We can't accommodate this one.",
        ],
      },
      {
        language: 'en-nl',
        texts: [
          "Sorry, I think that you made too many similar requests. We can't accommodate this one.",
        ],
      },
      {
        language: 'en-sg',
        texts: [
          "Sorry, I think that you made too many similar requests. We can't accommodate this one.",
        ],
      },
      {
        language: 'lb-lu',
        texts: [
          "Sorry, I think that you made too many similar requests. We can't accommodate this one.",
        ],
      },
      {
        language: 'de-at',
        texts: [
          'Tut mir leid, ich glaube, Sie haben zu viele ähnliche Anfragen gestellt. Wir können dieser Anfrage nicht nachkommen.',
        ],
      },
      {
        language: 'de-ch',
        texts: [
          'Tut mir leid, ich glaube, Sie haben zu viele ähnliche Anfragen gestellt. Wir können dieser Anfrage nicht nachkommen.',
        ],
      },
      {
        language: 'de-de',
        texts: [
          'Tut mir leid, ich glaube, Sie haben zu viele ähnliche Anfragen gestellt. Wir können dieser Anfrage nicht nachkommen.',
        ],
      },
      {
        language: 'fr-be',
        texts: [
          'Désolé, je crois que vous avez fait trop de demande similaire. Nous ne pouvons pas satisfaire celle-ci.',
        ],
      },
      {
        language: 'fr-ca',
        texts: [
          'Désolé, je crois que vous avez fait trop de demande similaire. Nous ne pouvons pas satisfaire celle-ci.',
        ],
      },
      {
        language: 'fr-ch',
        texts: [
          'Désolé, je crois que vous avez fait trop de demande similaire. Nous ne pouvons pas satisfaire celle-ci.',
        ],
      },
      {
        language: 'fr-tn',
        texts: [
          'Désolé, je crois que vous avez fait trop de demande similaire. Nous ne pouvons pas satisfaire celle-ci.',
        ],
      },
      {
        language: 'ar-ae',
        texts: [
          'عذرا، أعتقد أنك تقدمت بالعديد من الطلبات المماثلة. لا يمكننا إستيعاب هذا.',
        ],
      },
      {
        language: 'bg-bg',
        texts: [
          'Съжалявам, но мисля, че сте направили твърде много подобни заявки. Не можем да изпълним тази.',
        ],
      },
      {
        language: 'cs-cz',
        texts: [
          'Omlouvám se, ale myslím, že podobných požadavků jste vznesl příliš mnoho. Této žádosti nemůžeme vyhovět.',
        ],
      },
      {
        language: 'el-gr',
        texts: [
          'Συγγνώμη, νομίζω ότι κάνατε πάρα πολλά παρόμοια αιτήματα. Δεν μπορούμε να ικανοποιήσουμε αυτό το αίτημα.',
        ],
      },
      {
        language: 'fi-fi',
        texts: [
          'Anteeksi, mutta taisit esittää liian monta samanlaista pyyntöä. Emme voi täyttää tätä pyyntöä.',
        ],
      },
      {
        language: 'hr-hr',
        texts: [
          'Žao mi je, mislim da ste uputili previše sličnih zahtjeva. Ovoga ne možemo smjestiti.',
        ],
      },
      {
        language: 'hu-hu',
        texts: [
          'Sajnálom, de azt hiszem, túl sok hasonló kérést tettél. Ezt nem tudjuk teljesíteni.',
        ],
      },
      {
        language: 'it-ch',
        texts: [
          'Mi dispiace, credo che tu abbia fatto troppe richieste simili. Non possiamo soddisfare questa richiesta.',
        ],
      },
      {
        language: 'it-it',
        texts: [
          'Mi dispiace, credo che tu abbia fatto troppe richieste simili. Non possiamo soddisfare questa richiesta.',
        ],
      },
      {
        language: 'ja-jp',
        texts: [
          '申し訳ありませんが、同様のリクエストが多すぎたようです。今回は対応できません。',
        ],
      },
      {
        language: 'ko-kr',
        texts: [
          '죄송합니다, 비슷한 요청을 너무 많이 하신 것 같습니다. 우리는 이것을 수용할 수 없습니다.',
        ],
      },
      {
        language: 'ms-my',
        texts: [
          'Maaf, saya fikir anda membuat terlalu banyak permohonan yang sama. Kami tidak dapat menampung yang satu ini.',
        ],
      },
      {
        language: 'nl-be',
        texts: [
          'Sorry, we hebben meerdere verzoeken van je ontvangen. We kunnen deze taak niet uitvoeren.',
        ],
      },
      {
        language: 'pl-pl',
        texts: [
          'Przykro mi, ale wydaje mi się, że złożyłeś zbyt wiele podobnych próśb. Nie możemy spełnić tej prośby.',
        ],
      },
      {
        language: 'ro-ro',
        texts: [
          'Îmi pare rău, cred că ați făcut prea multe cereri similare. Nu o putem satisface pe aceasta.',
        ],
      },
      {
        language: 'sk-sk',
        texts: [
          'Ospravedlňujem sa, ale myslím si, že ste predložili príliš veľa podobných požiadaviek. Tejto žiadosti nemôžeme vyhovieť.',
        ],
      },
      {
        language: 'sl-si',
        texts: [
          'Oprostite, mislim, da ste podali preveč podobnih zahtev. Tej ne moremo ugoditi.',
        ],
      },
      {
        language: 'sr-latn-rs',
        texts: [
          'Жао ми је, мислим да сте упутили превише сличних захтева. Не можемо да сместимо ово.',
        ],
      },
      {
        language: 'sv-se',
        texts: [
          'Tyvärr tror jag att du har gjort för många liknande förfrågningar. Vi kan inte tillgodose den här.',
        ],
      },
      {
        language: 'th-th',
        texts: [
          'ขอโทษนะ ฉันคิดว่าคุณ ได้ร้องขอมากเกินไป เราไม่สามารถรองรับสิ่งนี้ได้',
        ],
      },
      {
        language: 'tr-tr',
        texts: [
          'Üzgünüm, sanırım çok fazla benzer istek yaptınız. Bunu kabul edemeyiz.',
        ],
      },
      {
        language: 'vi-vn',
        texts: [
          'Xin lỗi, tôi nghĩ rằng bạn đã thực hiện quá nhiều yêu cầu tương tự. Chúng tôi không thể chứa cái này.',
        ],
      },
    ],
  },
  {
    key: 'ICS_CONFIRMATION_TITLE',
    values: [
      {
        language: 'fr-fr',
        texts: ['Confirmation de rendez-vous'],
      },
      {
        language: 'en-us',
        texts: ['Appointment confirmation'],
      },
      {
        language: 'en-gb',
        texts: ['Appointment confirmation'],
      },
      {
        language: 'nl-nl',
        texts: ['Afspraakbevestiging'],
      },
      {
        language: 'es-es',
        texts: ['Confirmación de entrevista'],
      },
      {
        language: 'en-au',
        texts: ['Appointment confirmation'],
      },
      {
        language: 'en-ca',
        texts: ['Appointment confirmation'],
      },
      {
        language: 'en-de',
        texts: ['Appointment confirmation'],
      },
      {
        language: 'en-hk',
        texts: ['Appointment confirmation'],
      },
      {
        language: 'en-ie',
        texts: ['Appointment confirmation'],
      },
      {
        language: 'en-in',
        texts: ['Appointment confirmation'],
      },
      {
        language: 'en-my',
        texts: ['Appointment confirmation'],
      },
      {
        language: 'en-nl',
        texts: ['Appointment confirmation'],
      },
      {
        language: 'en-sg',
        texts: ['Appointment confirmation'],
      },
      {
        language: 'lb-lu',
        texts: ['Appointment confirmation'],
      },
      {
        language: 'de-at',
        texts: ['Bestätigung der Ernennung'],
      },
      {
        language: 'de-ch',
        texts: ['Bestätigung der Ernennung'],
      },
      {
        language: 'de-de',
        texts: ['Bestätigung der Ernennung'],
      },
      {
        language: 'fr-be',
        texts: ['Confirmation de rendez-vous'],
      },
      {
        language: 'fr-ca',
        texts: ['Confirmation de rendez-vous'],
      },
      {
        language: 'fr-ch',
        texts: ['Confirmation de rendez-vous'],
      },
      {
        language: 'fr-tn',
        texts: ['Confirmation de rendez-vous'],
      },
      {
        language: 'ar-ae',
        texts: ['تأكيد الموعد'],
      },
      {
        language: 'bg-bg',
        texts: ['Потвърждение на назначението'],
      },
      {
        language: 'cs-cz',
        texts: ['Potvrzení jmenování'],
      },
      {
        language: 'el-gr',
        texts: ['Επιβεβαίωση ραντεβού'],
      },
      {
        language: 'fi-fi',
        texts: ['Nimityksen vahvistus'],
      },
      {
        language: 'hr-hr',
        texts: ['Potvrda termina'],
      },
      {
        language: 'hu-hu',
        texts: ['A kinevezés megerősítése'],
      },
      {
        language: 'it-ch',
        texts: ["Conferma dell'appuntamento"],
      },
      {
        language: 'it-it',
        texts: ["Conferma dell'appuntamento"],
      },
      {
        language: 'ja-jp',
        texts: ['アポイントメントの確認'],
      },
      {
        language: 'ko-kr',
        texts: ['약속 확인'],
      },
      {
        language: 'ms-my',
        texts: ['Pengesahan janji temu'],
      },
      {
        language: 'nl-be',
        texts: ['Afspraakbevestiging'],
      },
      {
        language: 'pl-pl',
        texts: ['Potwierdzenie nominacji'],
      },
      {
        language: 'ro-ro',
        texts: ['Confirmarea numirii'],
      },
      {
        language: 'sk-sk',
        texts: ['Potvrdenie vymenovania'],
      },
      {
        language: 'sl-si',
        texts: ['Potrditev imenovanja'],
      },
      {
        language: 'sr-latn-rs',
        texts: ['Потврда заказане обавезе'],
      },
      {
        language: 'sv-se',
        texts: ['Bekräftelse av möte'],
      },
      {
        language: 'th-th',
        texts: ['การยืนยันการนัดหมาย'],
      },
      {
        language: 'tr-tr',
        texts: ['Randevu onayı'],
      },
      {
        language: 'vi-vn',
        texts: ['Xác nhận cuộc hẹn'],
      },
    ],
  },
  {
    key: 'EXIT_SIMPLE_MSG',
    values: [
      {
        language: 'fr-fr',
        texts: ['Merci et bonne journée!'],
      },
      {
        language: 'en-us',
        texts: ['Thanks and have a nice day!'],
      },
      {
        language: 'en-gb',
        texts: ['Thanks and have a nice day!'],
      },
      {
        language: 'nl-nl',
        texts: ['Dank je wel en nog een fijne dag!'],
      },
      {
        language: 'es-es',
        texts: ['¡Gracias y que tengas buen día!'],
      },
      {
        language: 'en-au',
        texts: ['Thanks and have a nice day!'],
      },
      {
        language: 'en-ca',
        texts: ['Thanks and have a nice day!'],
      },
      {
        language: 'en-de',
        texts: ['Thanks and have a nice day!'],
      },
      {
        language: 'en-hk',
        texts: ['Thanks and have a nice day!'],
      },
      {
        language: 'en-ie',
        texts: ['Thanks and have a nice day!'],
      },
      {
        language: 'en-in',
        texts: ['Thanks and have a nice day!'],
      },
      {
        language: 'en-my',
        texts: ['Thanks and have a nice day!'],
      },
      {
        language: 'en-nl',
        texts: ['Thanks and have a nice day!'],
      },
      {
        language: 'en-sg',
        texts: ['Thanks and have a nice day!'],
      },
      {
        language: 'lb-lu',
        texts: ['Thanks and have a nice day!'],
      },
      {
        language: 'de-at',
        texts: ['Vielen Dank und einen schönen Tag!'],
      },
      {
        language: 'de-ch',
        texts: ['Vielen Dank und einen schönen Tag!'],
      },
      {
        language: 'de-de',
        texts: ['Vielen Dank und einen schönen Tag!'],
      },
      {
        language: 'fr-be',
        texts: ['Merci et bonne journée!'],
      },
      {
        language: 'fr-ca',
        texts: ['Merci et bonne journée!'],
      },
      {
        language: 'fr-ch',
        texts: ['Merci et bonne journée!'],
      },
      {
        language: 'fr-tn',
        texts: ['Merci et bonne journée!'],
      },
      {
        language: 'ar-ae',
        texts: ['شكرا وعقدي يوم حلو!'],
      },
      {
        language: 'bg-bg',
        texts: ['Благодаря и пожелавам приятен ден!'],
      },
      {
        language: 'cs-cz',
        texts: ['Děkujeme a přejeme hezký den!'],
      },
      {
        language: 'el-gr',
        texts: ['Ευχαριστώ και καλή σας μέρα!'],
      },
      {
        language: 'fi-fi',
        texts: ['Kiitos ja hyvää päivänjatkoa!'],
      },
      {
        language: 'hr-hr',
        texts: ['Hvala i ugodan dan!'],
      },
      {
        language: 'hu-hu',
        texts: ['Köszönöm és szép napot kívánok!'],
      },
      {
        language: 'it-ch',
        texts: ['Grazie e buona giornata!'],
      },
      {
        language: 'it-it',
        texts: ['Grazie e buona giornata!'],
      },
      {
        language: 'ja-jp',
        texts: ['ありがとうございました！良い一日を'],
      },
      {
        language: 'ko-kr',
        texts: ['감사합니다, 즐거운 하루 보내세요!'],
      },
      {
        language: 'ms-my',
        texts: ['Terima kasih dan semoga anda memiliki hari yang baik!'],
      },
      {
        language: 'nl-be',
        texts: ['Dank je wel en nog een fijne dag!'],
      },
      {
        language: 'pl-pl',
        texts: ['Dziękuję i życzę miłego dnia!'],
      },
      {
        language: 'ro-ro',
        texts: ['Mulțumesc și vă doresc o zi bună!'],
      },
      {
        language: 'sk-sk',
        texts: ['Ďakujeme a prajeme pekný deň!'],
      },
      {
        language: 'sl-si',
        texts: ['Hvala in lep dan!'],
      },
      {
        language: 'sr-latn-rs',
        texts: ['Хвала и пријатан дан!'],
      },
      {
        language: 'sv-se',
        texts: ['Tack och ha en trevlig dag!'],
      },
      {
        language: 'th-th',
        texts: ['ขอบคุณและขอให้มีความสุข!'],
      },
      {
        language: 'tr-tr',
        texts: ['Teşekkürler ve iyi günler!'],
      },
      {
        language: 'vi-vn',
        texts: ['Cảm ơn và có một ngày tốt đẹp!'],
      },
    ],
  },
  {
    key: 'RESTART_MSG',
    values: [
      {
        language: 'fr-fr',
        texts: ['Recommencer'],
      },
      {
        language: 'en-us',
        texts: ['Start Over'],
      },
      {
        language: 'en-gb',
        texts: ['Start Over'],
      },
      {
        language: 'nl-nl',
        texts: ['Opnieuw beginnen'],
      },
      {
        language: 'es-es',
        texts: ['Reiniciar'],
      },
      {
        language: 'en-au',
        texts: ['Start Over'],
      },
      {
        language: 'en-ca',
        texts: ['Start Over'],
      },
      {
        language: 'en-de',
        texts: ['Start Over'],
      },
      {
        language: 'en-hk',
        texts: ['Start Over'],
      },
      {
        language: 'en-ie',
        texts: ['Start Over'],
      },
      {
        language: 'en-in',
        texts: ['Start Over'],
      },
      {
        language: 'en-my',
        texts: ['Start Over'],
      },
      {
        language: 'en-nl',
        texts: ['Start Over'],
      },
      {
        language: 'en-sg',
        texts: ['Start Over'],
      },
      {
        language: 'lb-lu',
        texts: ['Start Over'],
      },
      {
        language: 'de-at',
        texts: ['Neu anfangen'],
      },
      {
        language: 'de-ch',
        texts: ['Neu anfangen'],
      },
      {
        language: 'de-de',
        texts: ['Neu anfangen'],
      },
      {
        language: 'fr-be',
        texts: ['Recommencer'],
      },
      {
        language: 'fr-ca',
        texts: ['Recommencer'],
      },
      {
        language: 'fr-ch',
        texts: ['Recommencer'],
      },
      {
        language: 'fr-tn',
        texts: ['Recommencer'],
      },
      {
        language: 'ar-ae',
        texts: ['البدء من جديد'],
      },
      {
        language: 'bg-bg',
        texts: ['Започнете отначало'],
      },
      {
        language: 'cs-cz',
        texts: ['Začít znovu'],
      },
      {
        language: 'el-gr',
        texts: ['Ξεκινήστε από την αρχή'],
      },
      {
        language: 'fi-fi',
        texts: ['Aloita alusta'],
      },
      {
        language: 'hr-hr',
        texts: ['Početi ispočetka'],
      },
      {
        language: 'hu-hu',
        texts: ['Újrakezdés'],
      },
      {
        language: 'it-ch',
        texts: ['Ricominciare da capo'],
      },
      {
        language: 'it-it',
        texts: ['Ricominciare da capo'],
      },
      {
        language: 'ja-jp',
        texts: ['スタートオーバー'],
      },
      {
        language: 'ko-kr',
        texts: ['다시 시작'],
      },
      {
        language: 'ms-my',
        texts: ['Mula semula'],
      },
      {
        language: 'nl-be',
        texts: ['Opnieuw beginnen'],
      },
      {
        language: 'pl-pl',
        texts: ['Zacznij od nowa'],
      },
      {
        language: 'ro-ro',
        texts: ['O luăm de la capăt'],
      },
      {
        language: 'sk-sk',
        texts: ['Začať odznova'],
      },
      {
        language: 'sl-si',
        texts: ['Začni znova'],
      },
      {
        language: 'sr-latn-rs',
        texts: ['Започни поново'],
      },
      {
        language: 'sv-se',
        texts: ['Börja om'],
      },
      {
        language: 'th-th',
        texts: ['เริ่มใหม่'],
      },
      {
        language: 'tr-tr',
        texts: ['Baştan Başla'],
      },
      {
        language: 'vi-vn',
        texts: ['Bắt đầu lại'],
      },
    ],
  },
];

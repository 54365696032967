import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import cn from 'classnames';
import { RootState } from '@redux/store';
import { DragOverlay, Modifier, useDroppable } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import tagStyles from '@common/assets/styles/main.scss';
import { useUILayout } from '@common/utils/UILayoutProvider';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import styles from './styles.module.scss';
import { DraggableCard } from './ConvesationItemsPane';
import { ROOT_NODE_ID } from './constants';
import { SortableTreeItem } from './TreeUtils/SortableTreeItem';
import { getChildCount } from './TreeUtils/utils';
import { useConversationDesignerContext } from './ConversationDesignerContext';

const DesignerPane = ({ miniMapListRef }) => {
  const { t } = useTranslation('conversationDesigner', {
    keyPrefix: 'designerPane',
  });

  const { isMessageBarOpen } = useUILayout();

  const treeData = useSelector(
    (state: RootState) => state.conversationDesignerStore.treeData
  );

  const designerSettings = useSelector(
    (state: RootState) => state.conversationDesignerStore.designerSettings
  );

  const { flattenedItems, activeId, activeItem, projected } =
    useConversationDesignerContext();

  const sortedIds = useMemo(
    () => flattenedItems.map(({ id }) => id),
    [flattenedItems]
  );

  const adjustTranslate: Modifier = ({ transform }) => {
    return {
      ...transform,
      y: transform.y - 25,
    };
  };

  const { setNodeRef } = useDroppable({
    id: ROOT_NODE_ID,
  });

  return (
    <div className={styles.designerPane}>
      <SortableContext items={sortedIds} strategy={verticalListSortingStrategy}>
        <div
          className={cn(styles.list, {
            [styles.isMessageBarOpen]: isMessageBarOpen,
          })}
          ref={(el) => {
            setNodeRef(el);
            miniMapListRef.current = el;
          }}
        >
          {treeData.length === 0 && (
            <div className={styles.emptyList}>
              <AccountTreeOutlinedIcon />
              <div className={tagStyles.subtitle}>{t('title')}</div>
              <div>{parse(t('subtitle'))}</div>
            </div>
          )}
          {treeData.length > 0 && (
            <>
              {['funnel', 'scheduling']?.includes(
                designerSettings?.conversationType
              ) && (
                <div
                  className={cn(styles.designerNode, styles.schedulingHintCard)}
                >
                  <div className={cn(styles.accent, styles.accent)} />
                  <div className={styles.body}>
                    <div className={cn(tagStyles.subtitle, styles.title)}>
                      <InfoOutlinedIcon /> {t('schdeulingTitle')}
                    </div>
                    <div className={styles.subtitle}>
                      {t('schdeulingSubtitle')}
                    </div>
                  </div>
                </div>
              )}
              {flattenedItems.map(({ id, type, children, depth, parentId }) => {
                const siblings = flattenedItems?.find(
                  (item) => item.id === parentId
                )?.children;
                const grandParentNode = flattenedItems.find(
                  (node) =>
                    node.id ===
                    flattenedItems.find((item) => item.id === parentId)
                      ?.parentId
                );
                return (
                  <SortableTreeItem
                    key={id}
                    id={id}
                    type={type}
                    depth={
                      id === activeId && projected ? projected.depth : depth
                    }
                    childCount={children?.length}
                    isDragOverlay={false}
                    isLastChild={siblings?.[siblings?.length - 1]?.id === id}
                    isConnectedToMiddleChild={
                      grandParentNode?.children?.findIndex(
                        (child) => child.id === parentId
                      ) <
                      grandParentNode?.children?.length - 1
                    }
                  />
                );
              })}
            </>
          )}
          {activeId && activeItem && (
            <DragOverlay modifiers={[adjustTranslate]}>
              {activeItem ? (
                <SortableTreeItem
                  id={activeId}
                  type={activeItem.type}
                  depth={activeItem.depth}
                  isDragOverlay={true}
                  childCount={getChildCount(treeData, activeId) + 1 || 0}
                  isLastChild={false}
                  isConnectedToMiddleChild={false}
                />
              ) : (
                <DraggableCard nodeType={activeItem.type} />
              )}
            </DragOverlay>
          )}
        </div>
      </SortableContext>
    </div>
  );
};
export default DesignerPane;

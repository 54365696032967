import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import {
  DragMoveEvent,
  DragOverEvent,
  DragStartEvent,
  DragEndEvent,
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { RootState } from '@redux/store';
import {
  ConversationDesignerNode,
  NodeType,
} from '@appTypes/Conversation.types';
import { setTreeData } from '@redux/reducers/conversationDesignerReducer';
import debounce from 'lodash.debounce';

import {
  buildTree,
  flattenTree,
  getProjection,
  removeChildrenOf,
} from './TreeUtils/utils';
import { INDENTATION_WIDTH, ROOT_NODE_ID } from './constants';

interface IConversationDesignerContext {
  onDragStart: (event: DragStartEvent) => void;
  onDragMove: (event: DragMoveEvent) => void;
  onDragOver: (event: DragOverEvent) => void;
  onDragEnd: (event: DragEndEvent) => void;
  onDragCancel: () => void;
  selectedLanguage: string;
  setSelectedLanguage: (language: string) => void;
  flattenedItems: ConversationDesignerNode[];
  activeId: string | null;
  activeItem: ConversationDesignerNode | null;
  overId: string | null;
  offsetLeft: number;
  projected: {
    depth: number;
    maxDepth: number;
    minDepth: number;
    parentId: string;
  };
}

const ConversationDesignerContext = React.createContext<
  IConversationDesignerContext | undefined
>(undefined);

export const ConversationDesignerContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const dispatch = useDispatch();
  const [flattenedItems, setFlattenedItems] = useState<any[]>([]);
  const [activeId, setActiveId] = useState<string | null>(null);
  const [activeItem, setActiveItem] = useState<any | null>(null);
  const [overId, setOverId] = useState<string | null>(null);
  const [offsetLeft, setOffsetLeft] = useState(0);

  const { treeData, designerSettings } = useSelector(
    (state: RootState) => state.conversationDesignerStore
  );
  const [selectedLanguage, setSelectedLanguage] = useState(
    designerSettings?.defaultLanguage
  );

  useEffect(() => {
    setSelectedLanguage(designerSettings?.defaultLanguage);
  }, [designerSettings?.defaultLanguage]);

  useEffect(() => {
    const flattenedTree = flattenTree(treeData);
    setFlattenedItems(removeChildrenOf(flattenedTree, [activeId]));
  }, [activeId, treeData]);

  const projected = useMemo(
    () =>
      activeId && overId
        ? getProjection(
            flattenedItems,
            activeId,
            overId,
            offsetLeft,
            INDENTATION_WIDTH
          )
        : null,
    [flattenedItems, activeId, overId, offsetLeft]
  );

  const onDragStart = ({ active }: DragStartEvent) => {
    const { id: activeId } = active;
    setOverId(activeId as string);
    setActiveId(activeId as string);

    const activeItem = flattenedItems.find(({ id }) => id === activeId);

    if (activeItem) {
      setActiveItem(activeItem);
    } else {
      setActiveItem({
        id: activeId,
        depth: 0,
        index: 0,
        type: active?.data.current.nodeType,
        children: [],
        parentId: ROOT_NODE_ID,
      });
    }
  };

  const onDragMove = debounce(({ delta }: DragMoveEvent) => {
    setOffsetLeft(delta.x);
  }, 500);

  const onDragOver = ({ over }: DragOverEvent) => {
    const isNewItem = !flattenedItems.some(({ id }) => id === activeId);
    if (isNewItem) {
      setFlattenedItems([...flattenedItems, activeItem]);
    }
    setOverId((over?.id as string) ?? null);
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (over) {
      let clonedItems: ConversationDesignerNode[] = structuredClone(
        flattenTree(treeData)
      );

      const overIndex = clonedItems.findIndex(({ id }) => id === over.id);
      const activeIndex = clonedItems.findIndex(({ id }) => id === active.id);
      const activeTreeItem = clonedItems[activeIndex];

      const projection = getProjection(
        clonedItems,
        active.id as string,
        over.id as string,
        offsetLeft,
        INDENTATION_WIDTH
      );

      // If true, the node is already in the tree and we are just moving it to a new position
      if (activeTreeItem) {
        clonedItems[activeIndex] = {
          ...activeTreeItem,
          depth: projection.depth,
          parentId: projection.parentId,
          index:
            activeIndex < overIndex
              ? activeTreeItem.index + 1
              : activeTreeItem.index - 1,
        };
        const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);
        const newItems = buildTree(sortedItems);
        dispatch(setTreeData(newItems));
      }
      // When dragging a new node from the right pane
      else if (activeItem) {
        const getNewNode = (
          type: NodeType,
          depth: number,
          parentId: string,
          index: number,
          id: string = uuid()
        ) => ({
          id,
          type,
          depth,
          parentId,
          index,
          children: [],
        });

        const calculatedIndex =
          overIndex === -1 ? clonedItems?.length : overIndex;

        switch (activeItem.type) {
          case NodeType.YesNoQuestion: {
            clonedItems = [
              ...clonedItems.slice(0, calculatedIndex),
              getNewNode(
                activeItem.type,
                projection.depth,
                projection.parentId,
                0,
                activeItem.id
              ),
              getNewNode(
                NodeType.YesAnswer,
                activeItem.depth + 1,
                activeItem.id,
                0
              ),
              getNewNode(
                NodeType.NoAnswer,
                activeItem.depth + 1,
                activeItem.id,
                1
              ),
              ...clonedItems.slice(calculatedIndex),
            ];
            break;
          }
          case NodeType.Condition: {
            clonedItems = [
              ...clonedItems.slice(0, calculatedIndex),
              getNewNode(
                activeItem.type,
                projection.depth,
                projection.parentId,
                0,
                activeItem.id
              ),
              getNewNode(
                NodeType.IfCondition,
                activeItem.depth + 1,
                activeItem.id,
                0
              ),
              getNewNode(
                NodeType.ElseCondition,
                activeItem.depth + 1,
                activeItem.id,
                1
              ),
              ...clonedItems.slice(calculatedIndex),
            ];
            break;
          }
          case NodeType.TermsAndConditions: {
            clonedItems = [
              ...clonedItems.slice(0, calculatedIndex),
              getNewNode(
                activeItem.type,
                projection.depth,
                projection.parentId,
                0,
                activeItem.id
              ),
              getNewNode(
                NodeType.ConfirmationText,
                activeItem.depth + 1,
                activeItem.id,
                0
              ),
              getNewNode(
                NodeType.BlockerText,
                activeItem.depth + 1,
                activeItem.id,
                1
              ),
              ...clonedItems.slice(calculatedIndex),
            ];
            break;
          }
          default: {
            clonedItems = [
              ...clonedItems.slice(0, calculatedIndex),
              getNewNode(
                activeItem.type,
                projection.depth,
                projection.parentId,
                0,
                activeItem.id
              ),
              ...clonedItems.slice(calculatedIndex),
            ];
          }
        }
        const newItems = buildTree(clonedItems);
        dispatch(setTreeData(newItems));
      }
    }

    resetState();
  };

  const onDragCancel = () => {
    resetState();
  };

  const resetState = () => {
    setOverId(null);
    setActiveId(null);
    setOffsetLeft(0);
  };

  return (
    <ConversationDesignerContext.Provider
      value={{
        onDragStart,
        onDragMove,
        onDragOver,
        onDragEnd,
        onDragCancel,
        flattenedItems,
        activeId,
        activeItem,
        overId,
        offsetLeft,
        projected,
        selectedLanguage,
        setSelectedLanguage,
      }}
    >
      {children}
    </ConversationDesignerContext.Provider>
  );
};

export const useConversationDesignerContext = () => {
  const conversationDesignerContext = useContext(ConversationDesignerContext);
  if (conversationDesignerContext === undefined) {
    throw new Error(
      'useConversationDesignerContext must be used inside ConversationDesignerContextProvider'
    );
  }

  return conversationDesignerContext;
};

import { useMemo } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { Drawer, DrawerHeader, DrawerBody } from '@adeccoux/tag-ds';
import { RootState } from '@redux/store';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import tagStyles from '@common/assets/styles/main.scss';
import Grid, { Col, Row } from '@common/Grid';
import ConversationPreview from '@components/ConversationPreview';
import { useGetAppConfigQuery } from '@redux/api/portalApiSlice';
import Select from '@common/reactHookForm/Select';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import Popup from '@common/Popup';
import Card from '@common/Card';

import styles from './styles.module.scss';
import { useBuildTemplateJSON } from '../utils';

const ConversationPreviewDrawer = ({ onClose }) => {
  const { t } = useTranslation('conversationDesigner', {
    keyPrefix: 'conversationPreviewDrawer',
  });

  const designerSettings = useSelector(
    (state: RootState) => state.conversationDesignerStore.designerSettings
  );

  const methods = useForm({
    defaultValues: {
      selectedLanguage: designerSettings?.defaultLanguage,
    },
  });

  const { data: appConfig } = useGetAppConfigQuery();
  const languageOptions = useMemo(() => {
    const languages = appConfig?.referenceConfig?.supportedCultures?.filter(
      (item) =>
        [designerSettings.defaultLanguage, ...designerSettings.languages]?.find(
          (language) => item.cultureCode === language
        )
    );

    return languages.map((item) => ({
      label: (
        <div className={styles.languageSelectorLabel}>
          <div
            className={styles.imageContainer}
            style={{
              background: `no-repeat center url(/assets/flags/${item.countryCode}.svg)`,
            }}
          />
          {item.languageName}
        </div>
      ),
      value: item.cultureCode,
    }));
  }, [designerSettings, appConfig]);

  const selectedLanguage = methods.watch('selectedLanguage');

  const buildTemplateJSON = useBuildTemplateJSON();

  return (
    <Grid>
      <FormProvider {...methods}>
        <div className={styles.drawerWrapper}>
          <Drawer className={cn(styles.drawer, 'tag-ds')} open>
            <DrawerHeader className={styles.header}>
              <h4>{t('conversationPreview')}</h4>
              <button
                id="close"
                type="button"
                className={tagStyles.upperMenuIcon}
                onClick={onClose}
              >
                <CloseOutlinedIcon />
              </button>
            </DrawerHeader>
            <DrawerBody>
              <Row marginBottom={24}>
                <Col colSpan={3}>
                  <Popup
                    trigger={
                      <button type="button" className={styles.languageSelector}>
                        {
                          languageOptions?.find(
                            (option) => option.value === selectedLanguage
                          )?.label
                        }
                        <ExpandMoreOutlinedIcon />
                      </button>
                    }
                  >
                    {(dismissPopup) => (
                      <Card className={styles.dropdown}>
                        {languageOptions?.map((item) => (
                          <button
                            type="button"
                            className={styles.dropdownItem}
                            onClick={() => {
                              methods.setValue('selectedLanguage', item.value);
                              dismissPopup();
                            }}
                          >
                            {item.label}
                          </button>
                        ))}
                      </Card>
                    )}
                  </Popup>
                </Col>
              </Row>
              <Row>
                <ConversationPreview
                  template={{ content: buildTemplateJSON(), version: 1 } as any}
                  language={selectedLanguage}
                  disabled={true}
                />
              </Row>
            </DrawerBody>
          </Drawer>
        </div>
      </FormProvider>
    </Grid>
  );
};

export default ConversationPreviewDrawer;

import { useMemo } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import { useField } from 'formik';
import tagStyles from '@common/assets/styles/main.scss';
import CloseIcon from '@mui/icons-material/Close';
import Loader from '@components/common/Loader';

import styles from './styles.module.scss';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#ccf2ff' : '#fff',
    color: '#1c304b',
    fontFamily: 'Roboto, sans-serif',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#ccf2ff',
    },
    '&:active': {
      backgroundColor: '#ccf2ff',
    },
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: '8px',
    border: '1px solid',
    fontFamily: 'Roboto',
    fontWeight: '400',
    boxShadow: 'none',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
};

interface Option {
  value: string;
  label: string;
}

const EnhancedSelect = (props) => {
  const { t } = useTranslation('common');
  const [field, meta, helpers] = useField(props);
  const { options, onChange, isDismissable, onDismiss } = props;
  const hasError = meta.touched && meta.error;

  const value = useMemo(
    () => options?.find((option) => option.value === field.value),
    [options, field.value]
  );

  return (
    <div
      className={cn(tagStyles.tagDs, tagStyles.inputWrapper, styles.select, {
        [tagStyles.error]: hasError,
      })}
    >
      {!isDismissable && (
        <label className={tagStyles.caption}>{props?.label}</label>
      )}
      {isDismissable && (
        <span className={styles.dismissContainer}>
          <label className={tagStyles.caption}>{props?.label}</label>
          {value && (
            <button
              className={cn(tagStyles.upperMenuIcon)}
              id={`clear-group-${field.name}`}
              onClick={onDismiss}
              type="button"
            >
              <CloseIcon />
            </button>
          )}
        </span>
      )}

      <ReactSelect
        {...field}
        {...props}
        options={options?.sort((a, b) => a.label.localeCompare(b.label)) || []}
        className={cn({ [tagStyles.error]: hasError })}
        styles={customStyles}
        value={value || null}
        placeholder={t('select')}
        loadingMessage={() => <Loader />}
        onChange={(option: Option) => {
          helpers.setValue(option?.value);
          onChange?.(option);
        }}
      />
      {hasError && <div className="error">{meta.error}</div>}
    </div>
  );
};

export default EnhancedSelect;

import { useEffect, useMemo, useState } from 'react';
import { Form, Formik, useFormikContext } from 'formik';
import cn from 'classnames';
import {
  useGetGroupsForCurrentUserQuery,
  useLazyListGroupsWithFilterQuery,
  useListGroupsWithFilterQuery,
} from '@redux/api/securityApiSlice';

import EnhancedSelect from './EnhancedSelect';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

const labels = (t) => ({
  root: t('organization'),
  country: t('country'),
  brand: t('brand'),
  branchOffice: t('branchOffice'),
  noneFirst: 'Select a group',
  none: 'Select a group (optional)',
});

type Level = { [key: string]: { id: string; path: string } };

const GroupSelector = ({
  level,
  setSelectedGroupPath,
  removeAllDescendentLevels,
  removeAllDescendentLevelsAndAddLevel,
  listGroupsWithFilter,
  isFetching,
}) => {
  const { t } = useTranslation('common');
  const labelKey = Object.keys(level)[0];
  const { data } = useListGroupsWithFilterQuery({
    parentId: level[labelKey]?.id,
  });
  const { setFieldValue, submitForm } = useFormikContext();

  const options = useMemo(
    () =>
      data?.map((data) => ({
        value: data.id,
        label: data.name,
        path: data.path,
      })),
    [data]
  );

  const onSelectedGroupChange = async ({ value, path }) => {
    const { data } = await listGroupsWithFilter({ parentId: value });
    removeAllDescendentLevelsAndAddLevel(
      labelKey,
      { [data?.[0]?.type]: { id: value, path } },
      setFieldValue,
      submitForm
    );
    setSelectedGroupPath(path);
  };

  const onDismiss = () => {
    removeAllDescendentLevels(labelKey, setFieldValue, submitForm);
    setFieldValue(labelKey, null);
  };

  if (!isFetching && (!data || data.length === 0)) {
    return null;
  }

  return (
    <EnhancedSelect
      name={labelKey}
      label={labels(t)[labelKey] || 'Sub group'}
      options={options}
      onChange={onSelectedGroupChange}
      isLoading={isFetching}
      isSearchable={false}
      isDismissable={true}
      onDismiss={onDismiss}
    />
  );
};

const SecurityGroupSelector = ({
  onChange,
  values,
  path,
  initialLevels,
  overflowLayout,
  fullWidth,
}: {
  onChange: (values: any) => void;
  values?: any;
  path?: any;
  initialLevels?: any;
  overflowLayout?: boolean;
  fullWidth?: boolean;
}) => {
  const { t } = useTranslation('common');
  const { data: topLevelData } = useGetGroupsForCurrentUserQuery();
  const [listGroupsWithFilter, { isFetching }] =
    useLazyListGroupsWithFilterQuery();
  const [levels, setLevels] = useState<Level[]>(initialLevels || []);
  const [selectedGroupPath, setSelectedGroupPath] = useState(
    path || topLevelData?.[0]?.path
  );

  useEffect(() => {
    if (initialLevels) {
      setLevels(initialLevels);
    }
  }, [initialLevels]);

  const removeAllDescendentLevels = (key, setFieldValue?, submitForm?) => {
    const startIndex = levels.findIndex((item) => Object.keys(item)[0] === key);
    key !== 'root' &&
      levels
        .slice(startIndex + 1)
        .forEach((item) => setFieldValue?.(Object.keys(item)[0], undefined));
    const newLevels = levels.slice(0, startIndex + 1);
    setLevels(newLevels);
    setSelectedGroupPath(newLevels[newLevels.length - 1]?.[key]?.path);
    submitForm?.();
  };

  const removeAllDescendentLevelsAndAddLevel = (
    key,
    level,
    setFieldValue?,
    submitForm?
  ) => {
    const startIndex = levels.findIndex((item) => Object.keys(item)[0] === key);
    key !== 'root' &&
      levels
        .slice(startIndex + 1)
        .forEach((item) => setFieldValue?.(Object.keys(item)[0], undefined));
    const newLevels = levels.slice(0, startIndex + 1);
    setLevels([...newLevels, level]);
    setSelectedGroupPath(newLevels[newLevels.length - 1]?.[key]?.path);
    submitForm?.();
  };

  useEffect(() => {
    if (!initialLevels) {
      setLevels([
        {
          [labels(t).root]: {
            id: topLevelData?.[0]?.id,
            path: topLevelData?.[0]?.path,
          },
        },
      ]);
      onChangeRootGroupSelector({
        value: topLevelData?.[0]?.id,
        path: topLevelData?.[0]?.path,
      });
    }
  }, [topLevelData]);

  const options = useMemo(
    () =>
      topLevelData
        ?.map((data) => ({
          value: data.id,
          label: data.name,
          path: data.path,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [topLevelData]
  );

  const onChangeRootGroupSelector = async (
    { value, path },
    setFieldValue?,
    submitForm?
  ) => {
    const { data } = await listGroupsWithFilter({ parentId: value });
    removeAllDescendentLevelsAndAddLevel(
      Object.keys(levels)?.[0],
      { [data?.[0]?.type]: { id: value, path } },
      setFieldValue,
      submitForm
    );
    setSelectedGroupPath(path);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={values || { root: topLevelData?.[0]?.id }}
      onSubmit={(values) => onChange({ ...values, levels, selectedGroupPath })}
    >
      {({ setFieldValue, submitForm }) => (
        <Form>
          <div
            className={cn(styles.securityGroupsSelector, {
              [styles.overflowLayout]: overflowLayout,
              [styles.fullWidth]: fullWidth,
            })}
          >
            <EnhancedSelect
              name="root"
              label={labels(t)[topLevelData?.[0]?.type]}
              options={options}
              onChange={(option) =>
                onChangeRootGroupSelector(option, setFieldValue, submitForm)
              }
              isSearchable={false}
            />
            {levels.map((level) => (
              <GroupSelector
                key={Object.keys(level)[0]}
                level={level}
                setSelectedGroupPath={setSelectedGroupPath}
                removeAllDescendentLevels={removeAllDescendentLevels}
                removeAllDescendentLevelsAndAddLevel={
                  removeAllDescendentLevelsAndAddLevel
                }
                listGroupsWithFilter={listGroupsWithFilter}
                isFetching={isFetching}
              />
            ))}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SecurityGroupSelector;

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Notification,
  NotificationBody,
  NotificationFooter,
  NotificationHeader,
} from '@adeccoux/tag-ds';
import { Handle, Position } from '@xyflow/react';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import RichTextEditor from '@common/reactHookForm/RichTextEditor';

const Question = ({ data, language, disabled }) => {
  const { t } = useTranslation('common');
  const { fieldName, label } = data;

  const [isOpen, setIsOpen] = useState(false);

  if (isOpen) {
    return (
      <div className="tag-ds">
        <Handle type="target" position={Position.Top} style={{ opacity: 0 }} />
        <Notification icon={<QuizOutlinedIcon />} warning>
          <NotificationHeader>{label}</NotificationHeader>
          <NotificationBody>
            <RichTextEditor
              name={`${fieldName}.${language}`}
              disabled={disabled}
            />
          </NotificationBody>
          <NotificationFooter>
            <button onClick={() => setIsOpen(false)}>
              {disabled ? t('close') : t('save')}
            </button>
          </NotificationFooter>
        </Notification>
        <Handle
          type="source"
          position={Position.Bottom}
          style={{ opacity: 0 }}
        />
      </div>
    );
  }

  return (
    <div className="tag-ds" onClick={() => setIsOpen(true)}>
      <Handle type="target" position={Position.Top} style={{ opacity: 0 }} />
      <Notification icon={<QuizOutlinedIcon />} warning>
        <NotificationHeader>{label}</NotificationHeader>
      </Notification>
      <Handle type="source" position={Position.Bottom} style={{ opacity: 0 }} />
    </div>
  );
};

export default Question;

import cn from 'classnames';
import { useController } from 'react-hook-form';
import tagStyles from '@common/assets/styles/main.scss';

import styles from './styles.module.scss';

export const Radio = (props) => {
  const { selectedvalue, value, label, onChange, error } = props;
  return (
    <div>
      <label
        className={cn({
          [styles.error]: error,
          [styles.disabled]: props.disabled,
        })}
      >
        <input
          type="radio"
          {...props}
          checked={selectedvalue === value}
          onChange={(e) => e.target.checked && onChange(e.target.value)}
        />
        {label}
      </label>
    </div>
  );
};

const RadioGroup = (props) => {
  const { name, control } = props;
  const {
    field,
    fieldState: { error },
  } = useController({ name, control });

  return (
    <fieldset
      className={cn(
        tagStyles.tagDs,
        tagStyles.radioGroupContainer,
        styles.radio
      )}
    >
      <label className={tagStyles.caption}>{props.label}</label>
      <div
        className={cn(styles.radioGroup, {
          [styles.horizontal]: props.horizontal,
        })}
      >
        {props.choices?.map((choice) => (
          <div
            className={cn(tagStyles.radioContainer, styles.radioContainer)}
            key={choice?.id || choice?.value}
          >
            <Radio
              selectedvalue={field.value}
              disabled={props.disabled}
              onChange={field.onChange}
              error={error}
              {...choice}
            />
          </div>
        ))}
      </div>
      {error && <div className={styles.error}>{error?.message}</div>}
    </fieldset>
  );
};

export default RadioGroup;

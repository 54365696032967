const theme = {
  text: {
    bold: 'text-bold',
    italic: 'text-italic',
    underline: 'text-underline',
    strikethrough: 'text-strikethrough',
    underlineStrikethrough: 'text-underline-strikethrough',
  },
  list: {
    olDepth: ['ol1', 'ol2', 'ol3', 'ol4', 'ol5'],
    ul: 'ul',
  },
  link: 'link',
};

export default theme;

import { useFormContext } from 'react-hook-form';
import cn from 'classnames';
import tagStyles from '@common/assets/styles/main.scss';
import styles from './styles.module.scss';

const Checkbox = (props) => {
  const { name, label } = props;
  const {
    register,
    getFieldState,
  } = useFormContext();
  const error = getFieldState(name)?.error;

  return (
    <div
      className={cn(tagStyles.tagDs, tagStyles.inputWrapper, styles.input, {
        [tagStyles.error]: error,
      })}
    >
      <label>
        <input
          type="checkbox"
          className={cn({ [tagStyles.error]: error })}
          {...props}
          {...register(name)}
        />
        {label}
      </label>
      {error && <div className={styles.error}>{error.message.toString()}</div>}
    </div>
  );
};

export default Checkbox;

import { useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { useFormState } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from '@redux/store';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';

import { Nodes } from '../constants';
import { flattenTree } from '../TreeUtils/utils';
import styles from './styles.module.scss';

const MiniMap = ({ miniMapListRef }) => {
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const { errors }: any = useFormState();

  const { treeData } = useSelector(
    (state: RootState) => state.conversationDesignerStore
  );

  const flattenedItems = useMemo(() => flattenTree(treeData), [treeData]);

  useEffect(() => {
    setScrollHeight(miniMapListRef?.current?.clientHeight * 0.5);
  }, []);

  useEffect(() => {
    miniMapListRef.current?.addEventListener(
      'scroll',
      (e: React.MouseEvent<HTMLDivElement>) => {
        const target = e.target as HTMLDivElement;
        setScrollTop(target.scrollTop * 0.25);
        setScrollHeight(target.clientHeight * 0.5);
      }
    );
  }, [miniMapListRef.current]);

  return (
    <div className={styles.miniMap}>
      <div
        className={styles.scrollView}
        style={{
          top: scrollTop,
          height: scrollHeight,
        }}
      />
      {flattenedItems?.map(({ id, type, depth, parentId }) => {
        const siblings = flattenedItems?.find(
          (item) => item.id === parentId
        )?.children;
        const grandParentNode = flattenedItems.find(
          (node) =>
            node.id ===
            flattenedItems.find((item) => item.id === parentId)?.parentId
        );
        return (
          <div
            className={styles.borderContainer}
            style={{ bottom: scrollTop }}
            key={id}
          >
            {Array.from({ length: depth }).map((_, index) => (
              <div
                key={index}
                className={styles.border}
                style={{
                  borderColor:
                    (grandParentNode?.children?.findIndex(
                      (child) => child.id === parentId
                    ) <
                    grandParentNode?.children?.length - 1
                      ? index + 2
                      : index + 1) < depth && 'transparent',
                  height:
                    siblings?.[siblings?.length - 1]?.id === id &&
                    index + 1 === depth
                      ? '24px'
                      : 'auto',
                }}
              />
            ))}
            <div className={styles.nodeWrapper}>
              <div
                className={styles.connectorLine}
                style={{
                  display: depth === 0 ? 'none' : 'block',
                }}
              ></div>
              <div
                className={cn(styles.node, {
                  [styles.error]:
                    errors?.[id]?.name?.message || errors?.[id]?.message,
                })}
              >
                <div
                  className={styles.accent}
                  style={{ backgroundColor: Nodes[type].color }}
                ></div>
                {(errors?.[id]?.name?.message || errors?.[id]?.message) && (
                  <WarningOutlinedIcon />
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MiniMap;

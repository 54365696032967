import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import tagStyles from '@common/assets/styles/main.scss';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import styles from './styles.module.scss';

export interface ISearchboxProps {
  id?: string;
  placeholder?: string;
  name?: string;
  onChange: (value: string) => void;
  onRemove?: () => void;
  value: string;
  small?: boolean;
  /**
   * Additional styling if needed
   */
  className?: string;
  [others: string]: any;
}

export const Searchbox: React.FC<ISearchboxProps> = (props) => {
  const {
    id,
    placeholder,
    name,
    onChange,
    onRemove,
    value,
    small,
    className,
    ...rest
  } = props;

  const [fieldValue, setFieldValue] = useState(value);

  useEffect(() => {
    setFieldValue(value);
  }, [value]);

  return (
    <div
      className={cn(tagStyles.inputContainer, styles.searchBox, className)}
      data-testid={
        rest && rest['data-testid']
          ? `${rest['data-testid']}-wrapper`
          : undefined
      }
    >
      <span className={styles.searchIcon}>
        <SearchIcon />
      </span>

      <input
        id={id}
        name={name}
        className={`searchbox${small ? '_small' : ''}`}
        placeholder={placeholder}
        value={fieldValue}
        type="text"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue(e.target.value);
          onChange(e.target.value as string);
        }}
        {...rest}
      />

      {fieldValue && typeof onRemove === 'function' && (
        <button
          className={tagStyles.buttonSecondaryIcon}
          onClick={() => {
            onRemove();
            setFieldValue('');
          }}
          data-testid={
            rest && rest['data-testid']
              ? `${rest['data-testid']}-remove-btn`
              : undefined
          }
        >
          <span className={styles.clearIcon}>
            <CloseIcon />
          </span>
        </button>
      )}
    </div>
  );
};

export default Searchbox;

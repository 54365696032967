import React, { useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import cn from 'classnames';
import { FormProvider, useForm } from 'react-hook-form';
import Dagre from '@dagrejs/dagre';
import {
  Background,
  BackgroundVariant,
  Controls,
  MiniMap,
  ReactFlow,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { Template } from '@appTypes/Conversation.types';
import { useUILayout } from '@common/utils/UILayoutProvider';

import styles from './styles.module.scss';
import DataBinding from './Nodes/DataBinding';
import AdaptiveCard from './Nodes/AdaptiveCard';
import Condition from './Nodes/Condition';
import Exit from './Nodes/Exit';
import Foreach from './Nodes/ForEach';
import InvokeTemplate from './Nodes/InvokeTemplate';
import Message from './Nodes/Message';
import Navigate from './Nodes/Navigate';
import Question from './Nodes/Question';
import QuestionChoice from './Nodes/QuestionChoice';
import RemoteStep from './Nodes/RemoteStep';
import Switch from './Nodes/Switch';
import Edge from './Edge';
import { buildConversationPreview, getDefaultValues } from './utils';

interface IConversationPreviewProps {
  template: Template;
  language: string;
  height?: string;
  disabled?: boolean;
}

const ConversationPreview = React.forwardRef<any, IConversationPreviewProps>(
  ({ template, language, height, disabled }, ref) => {
    const nodeTypes = useMemo(
      () => ({
        AdaptiveCard,
        Condition,
        DataBinding,
        Exit: (props) => (
          <Exit disabled={disabled} language={language} {...props} />
        ),
        Foreach,
        InvokeTemplate,
        Message: (props) => (
          <Message disabled={disabled} language={language} {...props} />
        ),
        Navigate,
        Question: (props) => (
          <Question disabled={disabled} language={language} {...props} />
        ),
        QuestionChoice: (props) => (
          <QuestionChoice disabled={disabled} language={language} {...props} />
        ),
        RemoteStep,
        Switch,
      }),
      [language]
    );

    const { nodes, edges } = buildConversationPreview(template.content);

    const methods = useForm({
      defaultValues: getDefaultValues(template.content),
    });

    useImperativeHandle(ref, () => ({
      getModifiedTemplate: () => ({
        ...template,
        content: {
          ...template.content,
          languageResources: {
            ...template.content.languageResources,
            ...methods.getValues(),
          },
        },
      }),
    }));

    return (
      <FormProvider {...methods}>
        <form>
          <ReactFlowProvider>
            <FlowComponent
              nodeTypes={nodeTypes}
              initialNodes={nodes}
              initialEdges={edges}
              height={height}
            />
          </ReactFlowProvider>
        </form>
      </FormProvider>
    );
  }
);

const FlowComponent = ({ nodeTypes, initialNodes, initialEdges, height }) => {
  const { isSidebarOpen } = useUILayout();
  const [finalNodes, setFinalNodes, onNodesChange] =
    useNodesState(initialNodes);
  const [finalEdges, setFinalEdges, onEdgesChange] =
    useEdgesState(initialEdges);
  const shouldRelayout = useRef(true);

  const { fitView } = useReactFlow();
  const edgeTypes = useMemo(() => ({ edge: Edge }), []);

  const autoLayout = () => {
    const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
    g.setGraph({
      rankdir: 'TB',
      align: 'DR',
    });

    finalEdges.forEach((edge) => g.setEdge(edge.source, edge.target));
    finalNodes.forEach((node) => {
      g.setNode(node.id, {
        ...node,
        width: node.measured?.width ?? 400,
        height: node.measured?.height ?? 80,
      });
    });

    Dagre.layout(g);

    setFinalNodes(
      finalNodes.map((node) => {
        const position = g.node(node.id);
        const x = position.x - (node.measured?.width ?? 400) / 2;
        const y = position.y - (node.measured?.height ?? 80) / 2;

        return {
          ...node,
          position: { x, y },
        };
      })
    );

    setFinalEdges(finalEdges);
    fitView(fitViewOptions);
    shouldRelayout.current = false;
  };

  useEffect(() => {
    // We need to call autoLayout twice to make sure the sizes are calculated correctly before render
    setTimeout(() => {
      autoLayout();
    }, 100);
    setTimeout(() => {
      autoLayout();
    }, 100);
  }, [shouldRelayout.current]);

  const fitViewOptions = {
    nodes: finalNodes?.slice(0, 8)?.map((node) => ({ id: node.id })),
    maxZoom: 1,
  };

  return (
    <div className={styles.conversationPreview}>
      <div
        className={cn(styles.container, {
          [styles.sideBarOpen]: isSidebarOpen,
        })}
        style={{
          height,
        }}
      >
        <ReactFlow
          proOptions={{ hideAttribution: true }}
          nodes={finalNodes}
          edges={finalEdges}
          nodesDraggable={false}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          onEdgesChange={onEdgesChange}
          onNodesChange={(changes) => {
            changes.forEach((change) => {
              if (change.type === 'dimensions') {
                shouldRelayout.current = true;
              }
            });
            onNodesChange(changes);
          }}
        >
          <Background variant={BackgroundVariant.Dots} />
          <MiniMap
            pannable={true}
            zoomable={true}
            nodeBorderRadius={8}
            nodeColor={(node) => {
              switch (node.type) {
                case 'AdaptiveCard':
                case 'ForEach':
                case 'InvokeTemplate':
                case 'Navigate':
                case 'RemoteStep':
                  return 'var(--secondary400)';

                case 'Condition':
                case 'DataBinding':
                case 'Switch':
                  return 'var(--neutral400)';

                case 'Message':
                case 'Question':
                case 'QuestionChoice':
                  return 'var(--alert400)';

                case 'Exit':
                  return 'var(--success400)';

                default:
                  return 'var(--primary700)';
              }
            }}
          />
          <Controls
            className={styles.controls}
            fitViewOptions={fitViewOptions}
            showInteractive={false}
          />
        </ReactFlow>
      </div>
    </div>
  );
};

export default ConversationPreview;

import React, { CSSProperties } from 'react';
import cn from 'classnames';
import { AnimateLayoutChanges, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import styles from './styles.module.scss';
import { INDENTATION_WIDTH } from '../constants';
import DesignerNode from '../DesignerNode';

const animateLayoutChanges: AnimateLayoutChanges = ({
  isSorting,
  wasDragging,
}) => (isSorting || wasDragging ? false : true);

export const SortableTreeItem = ({
  id,
  type,
  depth,
  childCount,
  isDragOverlay,
  isLastChild,
  isConnectedToMiddleChild,
}) => {
  const {
    attributes,
    isDragging,
    listeners,
    setDraggableNodeRef,
    setDroppableNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
    animateLayoutChanges,
  });

  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <TreeItem
      childCount={childCount}
      isDragOverlay={isDragOverlay}
      depth={depth}
      isDragging={isDragging}
      ref={setDraggableNodeRef}
      wrapperRef={setDroppableNodeRef}
      style={style}
      type={type}
      id={id}
      isLastChild={isLastChild}
      isConnectedToMiddleChild={isConnectedToMiddleChild}
      handleProps={{
        ...attributes,
        ...listeners,
      }}
    />
  );
};

const TreeItem = React.forwardRef<any, any>(
  (
    {
      childCount,
      isDragOverlay,
      depth,
      isDragging,
      handleProps,
      style,
      wrapperRef,
      type,
      id,
      isLastChild,
      isConnectedToMiddleChild,
    },
    ref
  ) => (
    <div className={cn(styles.wrapper)} ref={wrapperRef}>
      <div className={styles.borderContainer}>
        {!isDragging &&
          Array.from({ length: depth }).map((_, index) => (
            <div
              key={index}
              className={styles.border}
              style={{
                paddingLeft: `${INDENTATION_WIDTH * 2}px`,
                display: isDragOverlay ? 'none' : 'block',
                borderColor:
                  (isConnectedToMiddleChild ? index + 2 : index + 1) < depth &&
                  'transparent',
                height: isLastChild && index + 1 === depth ? '60px' : 'auto',
              }}
            />
          ))}
        {isDragging && (
          <div ref={ref} style={style} className={styles.placeholder}>
            <div
              className={styles.placeholderContent}
              style={{
                marginLeft: `${INDENTATION_WIDTH * depth * 3}px`,
              }}
            >
              <></>
            </div>
          </div>
        )}
        {!isDragging && (
          <div ref={ref} className={cn(styles.nodeWrapper)} style={style}>
            <div
              className={styles.connectorLine}
              style={{
                width: `${INDENTATION_WIDTH * 2}px`,
                left: `-${INDENTATION_WIDTH * 2}px`,
                display: depth === 0 || isDragOverlay ? 'none' : 'block',
              }}
            />
            <DesignerNode
              id={id}
              type={type}
              handleProps={handleProps}
              isDragging={isDragging}
            />
            {isDragOverlay && childCount && childCount > 1 ? (
              <span className={styles.count}>{childCount}</span>
            ) : null}
          </div>
        )}
      </div>
    </div>
  )
);

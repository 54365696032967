import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Template } from '@appTypes/Conversation.types';
import { TemplateQuestion } from '@appTypes/Conversation.types';

export interface ITextFieldItem {
  field: string;
  id: string;
  name: string;
  label: string;
  value?: string | boolean;
  draggable: boolean;
}

interface IConversationStructureValues {
  conversationStructureItems: (TemplateQuestion | ITextFieldItem)[];
  currentTemplate: Template;
}

const initialState: IConversationStructureValues = {
  conversationStructureItems: [
    {
      field: 'textField',
      id: 'initialMessage',
      name: 'initialMessage',
      label: 'initialMessage',
      value: '',
      draggable: false,
    },
    {
      field: 'askConversationRating',
      id: 'askConversationRating',
      name: 'askConversationRating',
      label: '',
      value: true,
      draggable: false,
    },
    {
      field: 'textField',
      id: 'endingMessage',
      name: 'successMessage',
      label: 'successMessage',
      value: '',
      draggable: false,
    },
    {
      field: 'textField',
      id: 'failureMessage',
      name: 'failureMessage',
      label: 'failureMessage',
      value: '',
      draggable: false,
    },
  ],
  currentTemplate: null,
};

const conversationStructureSlice = createSlice({
  name: 'conversationStructure',
  initialState,
  reducers: {
    addSelectedQuestions: (
      state,
      action: PayloadAction<TemplateQuestion[]>
    ) => {
      state.conversationStructureItems = [
        state.conversationStructureItems[0],
        ...action.payload,
        ...state.conversationStructureItems?.slice(
          state.conversationStructureItems.length - 3,
          state.conversationStructureItems.length
        ),
      ];
    },
    insertQuestions: (
      state,
      action: PayloadAction<{
        questions: (TemplateQuestion | ITextFieldItem)[];
        language: string;
      }>
    ) => {
      const lastIndex = state.conversationStructureItems.findIndex(
        (item: TemplateQuestion | ITextFieldItem) =>
          (item as TemplateQuestion)?.mandatoryInCountries?.find(
            (item) =>
              item.countryCode === action.payload?.language &&
              item.questionPosition === 'last'
          ) || (item as ITextFieldItem)?.id === 'askConversationRating'
      );

      state.conversationStructureItems = [
        ...state.conversationStructureItems.slice(0, lastIndex),
        ...action.payload?.questions?.filter(
          (question) =>
            !state.conversationStructureItems.some(
              (item) => item.id === question.id
            )
        ),
        ...state.conversationStructureItems?.slice(
          lastIndex,
          state.conversationStructureItems.length
        ),
      ];
    },
    insertDescriptiveSentence: (
      state,
      action: PayloadAction<{ item: TemplateQuestion; language: string }>
    ) => {
      const firstIndex = state.conversationStructureItems.findIndex(
        (item: TemplateQuestion | ITextFieldItem) =>
          (item as TemplateQuestion)?.mandatoryInCountries?.find(
            (item) =>
              item.countryCode === action.payload?.language &&
              item.questionPosition !== 'last'
          )
      );

      state.conversationStructureItems = [
        state.conversationStructureItems[firstIndex === -1 ? 0 : firstIndex],
        action.payload.item,
        ...state.conversationStructureItems.slice(
          firstIndex === -1 ? 1 : firstIndex + 1
        ),
      ];
    },
    removeQuestion: (state, action: PayloadAction<TemplateQuestion>) => {
      state.conversationStructureItems =
        state.conversationStructureItems.filter(
          (item) => item.id !== action.payload.id
        );
    },
    replaceSelectedQuestions: (
      state,
      action: PayloadAction<TemplateQuestion[] | ITextFieldItem[]>
    ) => {
      state.conversationStructureItems = action.payload;
    },
    setCurrentTemplate: (state, action: PayloadAction<Template>) => {
      state.currentTemplate = action.payload;
    },
    clearConversationStructureState: (state) => {
      state.conversationStructureItems =
        initialState.conversationStructureItems;
      state.currentTemplate = initialState.currentTemplate;
    },
  },
});

export const {
  addSelectedQuestions,
  insertQuestions,
  insertDescriptiveSentence,
  removeQuestion,
  replaceSelectedQuestions,
  setCurrentTemplate,
  clearConversationStructureState,
} = conversationStructureSlice.actions;
export default conversationStructureSlice.reducer;

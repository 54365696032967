import { arrayMove } from '@dnd-kit/sortable';
import { ConversationDesignerNode } from '@appTypes/Conversation.types';
import { Nodes, ROOT_NODE_ID } from '../constants';

export const iOS = /iPad|iPhone|iPod/.test(navigator.platform);

const getDragDepth = (offset: number, indentationWidth: number) => {
  return Math.round(offset / indentationWidth);
};

export const getProjection = (
  items: ConversationDesignerNode[],
  activeId: string,
  overId: string,
  dragOffset: number,
  indentationWidth: number
) => {
  const overItemIndex = items.findIndex(({ id }) => id === overId);
  const activeItemIndex = items.findIndex(({ id }) => id === activeId);
  const activeItem = items[activeItemIndex];
  const newItems = arrayMove(items, activeItemIndex, overItemIndex);
  const previousItem = newItems[overItemIndex - 1];
  const nextItem = newItems[overItemIndex + 1];
  const dragDepth = getDragDepth(dragOffset, indentationWidth);
  const projectedDepth = activeItem ? activeItem?.depth + dragDepth : 0;
  const maxDepth = getMaxDepth({
    previousItem,
  });
  const minDepth = getMinDepth({ nextItem });
  let depth = projectedDepth;

  if (projectedDepth >= maxDepth) {
    depth = maxDepth;
  } else if (projectedDepth < minDepth) {
    depth = minDepth;
  }

  if (depth >= previousItem?.depth && Nodes[previousItem?.type].allowChild) {
    depth = previousItem?.depth + 1;
  }

  const getParentId = () => {
    if (depth === 0 || !previousItem) {
      return ROOT_NODE_ID;
    }

    if (
      depth === previousItem.depth &&
      Nodes[previousItem?.type].allowSibings
    ) {
      return previousItem.parentId;
    }

    if (depth >= previousItem.depth && Nodes[previousItem?.type].allowChild) {
      return previousItem.id;
    }

    const newParent = newItems
      .slice(0, overItemIndex)
      .reverse()
      .find((item) => item.depth === depth)?.parentId;

    return Nodes[previousItem?.type].allowChild ? newParent : null;
  };

  return { depth, maxDepth, minDepth, parentId: getParentId() };
};

const getMaxDepth = ({
  previousItem,
}: {
  previousItem: ConversationDesignerNode;
}) => {
  if (previousItem) {
    return previousItem.depth + 1;
  }

  return 0;
};

const getMinDepth = ({ nextItem }: { nextItem: ConversationDesignerNode }) => {
  if (nextItem) {
    return nextItem.depth;
  }

  return 0;
};

const flatten = (
  items: ConversationDesignerNode[],
  parentId: string = ROOT_NODE_ID,
  depth = 0
) => {
  return items?.reduce((acc, item, index) => {
    return [
      ...acc,
      { ...item, parentId, depth, index },
      ...flatten(item.children, item.id, depth + 1),
    ];
  }, []);
};

export const flattenTree = (items: ConversationDesignerNode[]) => {
  return flatten(items);
};

export const buildTree = (flattenedItems: ConversationDesignerNode[]) => {
  const root = {
    id: ROOT_NODE_ID,
    children: [],
    index: 0,
    depth: 0,
    parentId: null,
    type: 'Root',
  };
  const nodes = { [root.id]: root };
  const items = flattenedItems.map((item) => ({ ...item, children: [] }));

  for (const item of items) {
    const { id, children } = item;
    const parentId = item.parentId ?? root.id;
    const parent = nodes[parentId] ?? findItem(items, parentId);

    nodes[id] = { id, children, ...item };
    parent.children?.push(item);

    if (parent.id !== ROOT_NODE_ID) {
      parent.children?.sort((a, b) => a.index - b.index);
    }
  }

  return root.children;
};

export const findItem = (items: ConversationDesignerNode[], itemId: string) => {
  return items.find(({ id }) => id === itemId);
};

export const findItemDeep = (
  items: ConversationDesignerNode[],
  itemId: string
): ConversationDesignerNode | undefined => {
  for (const item of items) {
    const { id, children } = item;

    if (id === itemId) {
      return item;
    }

    if (children.length) {
      const child = findItemDeep(children, itemId);

      if (child) {
        return child;
      }
    }
  }

  return undefined;
};

export const removeItem = (items: ConversationDesignerNode[], id: string) => {
  const newItems = [];

  for (const item of items) {
    if (item.id === id) {
      continue;
    }

    if (item.children.length) {
      item.children = removeItem(item.children, id);
    }

    newItems.push(item);
  }

  return newItems;
};

const countChildren = (
  items: ConversationDesignerNode[],
  count = 0
): number => {
  return items.reduce((acc, { children }) => {
    if (children.length) {
      return countChildren(children, acc + 1);
    }

    return acc + 1;
  }, count);
};

export const getChildCount = (
  items: ConversationDesignerNode[],
  id: string
) => {
  if (!id) {
    return 0;
  }

  const item = findItemDeep(items, id);

  return item ? countChildren(item.children) : 0;
};

export const removeChildrenOf = (
  items: ConversationDesignerNode[],
  ids: string[]
) => {
  const excludeParentIds = [...ids];

  return items.filter((item) => {
    if (item.parentId && excludeParentIds.includes(item.parentId)) {
      if (item.children.length) {
        excludeParentIds.push(item.id);
      }
      return false;
    }

    return true;
  });
};

import { useEffect, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Drawer,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
} from '@adeccoux/tag-ds';
import { RootState } from '@redux/store';
import tagStyles from '@common/assets/styles/main.scss';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Input from '@common/reactHookForm/Input';
import Grid, { Col, Row } from '@common/Grid';
import {
  useGetGroupsForCurrentUserQuery,
  useGetResourcesForGroupQuery,
} from '@redux/api/securityApiSlice';
import { setDesignerSettings } from '@redux/reducers/conversationDesignerReducer';
import Searchbox from '@common/Searchbox';
import Card from '@common/Card';
import Loader from '@common/Loader';

import styles from './styles.module.scss';

const SettingsDrawer = ({ onClose }) => {
  const { t } = useTranslation('conversationDesigner', {
    keyPrefix: 'settingsDrawer',
  });

  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');

  const designerSettings = useSelector(
    (state: RootState) => state.conversationDesignerStore.designerSettings
  );

  const [selectedLanguages, setSelectedLanguages] = useState([
    designerSettings.defaultLanguage,
    ...designerSettings.languages,
  ]);

  useEffect(() => {
    setSelectedLanguages([
      designerSettings.defaultLanguage,
      ...designerSettings.languages,
    ]);
  }, [designerSettings.defaultLanguage, designerSettings.languages]);

  const methods = useForm({
    defaultValues: {
      conversationName: designerSettings?.conversationName,
    },
  });

  const { data: groups } = useGetGroupsForCurrentUserQuery();
  const { data, isFetching: isGetLanguagesLoading } =
    useGetResourcesForGroupQuery(
      {
        groupId: groups?.[0]?.id,
        resourceType: 'country',
      },
      { skip: !groups?.[0]?.id }
    );

  const onSubmit = (values) => {
    dispatch(
      setDesignerSettings({
        ...designerSettings,
        conversationName: values.conversationName,
        languages: selectedLanguages,
      })
    );
    onClose();
  };

  if (isGetLanguagesLoading) {
    return <Loader fullHeight />;
  }

  return (
    <Grid>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className={cn(styles.settingsDialog)}>
            <div className={styles.drawerWrapper}>
              <Drawer className={cn(styles.drawer, 'tag-ds')} open>
                <DrawerHeader className={styles.header}>
                  <h4>{t('conversationSettings')}</h4>
                  <button
                    id="close"
                    type="button"
                    className={tagStyles.upperMenuIcon}
                    onClick={onClose}
                  >
                    <CloseOutlinedIcon />
                  </button>
                </DrawerHeader>
                <DrawerBody>
                  <Row marginBottom={24}>
                    <Col colSpan={12} className={tagStyles.subtitle}>
                      {t('conversationType')}
                    </Col>
                    <Col colSpan={4}>
                      <div className={tagStyles.statusTag}>{t('default')}</div>
                    </Col>
                  </Row>
                  <Row marginBottom={24}>
                    <Input
                      name="conversationName"
                      label={t('conversationName')}
                    />
                  </Row>
                  <Row marginBottom={12}>
                    <div className={tagStyles.subtitle}>
                      {t('campaignLanguages')}
                    </div>
                  </Row>
                  <Row marginBottom={12}>
                    {selectedLanguages?.map((cultureCode) => {
                      const language = data?.languages?.find(
                        (language) => language.cultureCode === cultureCode
                      );
                      return (
                        <>
                          <div className={styles.selectedLanguageItem}>
                            <div className={styles.languageSelectorLabel}>
                              <div
                                className={styles.imageContainer}
                                style={{
                                  background: `no-repeat center url(/assets/flags/${language?.countryCode}.svg)`,
                                }}
                              />
                              {language?.languageName}
                            </div>
                            {cultureCode ===
                            designerSettings?.defaultLanguage ? (
                              <div className={tagStyles.statusTag}>
                                {t('default')}
                              </div>
                            ) : (
                              <div className={styles.controls}>
                                <button
                                  className={tagStyles.chip}
                                  type="button"
                                  onClick={() =>
                                    dispatch(
                                      setDesignerSettings({
                                        ...designerSettings,
                                        defaultLanguage: language.cultureCode,
                                      })
                                    )
                                  }
                                >
                                  {t('makeDefault')}
                                </button>
                                <button
                                  className={tagStyles.upperMenuIcon}
                                  type="button"
                                  onClick={() => {
                                    setSelectedLanguages(
                                      selectedLanguages.filter(
                                        (language) => language !== cultureCode
                                      )
                                    );
                                  }}
                                >
                                  <DeleteOutlineOutlinedIcon />
                                </button>
                              </div>
                            )}
                          </div>
                          <div className={tagStyles.separator} />
                        </>
                      );
                    })}
                  </Row>
                  <Row marginBottom={12}>
                    <div className={tagStyles.subtitle}>
                      {t('availableLanguages')}
                    </div>
                  </Row>
                  <Row marginBottom={12}>
                    <Searchbox
                      value=""
                      onChange={setSearchValue}
                      onRemove={() => setSearchValue('')}
                      placeholder={t('searchForLanguages')}
                    />
                  </Row>
                  <Row marginBottom={12}>
                    <Card className={styles.languageSelector}>
                      {data?.languages?.map(
                        (language) =>
                          (!searchValue ||
                            searchValue === '' ||
                            language?.languageName
                              ?.toLowerCase()
                              ?.includes(searchValue?.toLowerCase())) &&
                          !selectedLanguages?.includes(
                            language.cultureCode
                          ) && (
                            <div className={styles.selectedLanguageItem}>
                              <div className={styles.languageSelectorLabel}>
                                <div
                                  className={styles.imageContainer}
                                  style={{
                                    background: `no-repeat center url(/assets/flags/${language.countryCode}.svg)`,
                                  }}
                                />
                                {language.languageName}
                              </div>
                              <button
                                className={tagStyles.upperMenuIcon}
                                type="button"
                                onClick={() =>
                                  setSelectedLanguages([
                                    ...selectedLanguages,
                                    language.cultureCode,
                                  ])
                                }
                              >
                                <AddOutlinedIcon />
                              </button>
                            </div>
                          )
                      )}
                    </Card>
                  </Row>
                  <Row marginBottom={12}>
                    <div className={tagStyles.separator} />
                  </Row>
                </DrawerBody>
                <DrawerFooter>
                  <Row marginBottom={12}>
                    <div className={styles.buttonBar}>
                      <button
                        type="button"
                        className={tagStyles.buttonSecondary}
                        onClick={onClose}
                      >
                        {t('cancel')}
                      </button>
                      <button type="submit">{t('save')}</button>
                    </div>
                  </Row>
                </DrawerFooter>
              </Drawer>
            </div>
          </div>
        </form>
      </FormProvider>
    </Grid>
  );
};

export default SettingsDrawer;

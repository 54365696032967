import { Template } from '@appTypes/Conversation.types';
import format from 'date-fns/format';

export const getInitialActivity = (
  template: Template,
  language: string,
  channel: string,
  contextId?: string,
  activityName?: string
) =>
  ({
    from: {
      id: '00000000-0000-0000-0000-000000000000',
      name: 'Testing',
    },
    name: activityName || 'requestWelcomeDialog',
    type: 'event',
    localTimestamp: `${format(new Date(), 'yyyy-LL-dd')}T${format(
      new Date(),
      'HH:mm:SS.SSSxxx'
    )}`,
    localTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    value: {
      contextId: contextId || '00000000-0000-0000-0000-000000000000',
      contextType: 'candidate',
      userLanguage: language,
      language,
      debugMode: {
        templateId: null,
        templateVersion: 1,
        templateContent: JSON.stringify(template?.content),
        campaignId: undefined,
        candidateId: undefined,
        debugChannelId: channel,
      },
      clientInfo: {
        userAgent: navigator.userAgent,
        screenWidth: (window.screen.width * window.devicePixelRatio).toString(),
        screenHeight: (
          window.screen.height * window.devicePixelRatio
        ).toString(),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    },
  } as any);

import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import isEqual from 'lodash.isequal';
import { useTranslation } from 'react-i18next';
import { persistor, RootState } from '@redux/store';
import {
  rectIntersection,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  initializeTreeData,
  persistFormValues,
  resetState,
  setDesignerSettings,
} from '@redux/reducers/conversationDesignerReducer';
import Card from '@common/Card';
import Loader from '@common/Loader';
import {
  IConversationDesignerFormValues,
  NodeType,
} from '@appTypes/Conversation.types';
import { useUILayout } from '@common/utils/UILayoutProvider';
import { useLazyGetStoredConversationDesignerItemsByIdQuery } from '@redux/api/conversationApiSlice';
import {
  useRegisterSignalRGroupMutation,
  useUnRegisterSignalRGroupMutation,
} from '@redux/api/portalApiSlice';
import { hubConnection } from '@redux/api/notificationApiSlice';

import ConvesationItemsPane from './ConvesationItemsPane';
import DesignerPane from './DesignerPane';
import styles from './styles.module.scss';
import { sortableTreeKeyboardCoordinates } from './TreeUtils/keyboardCoordinates';
import { INDENTATION_WIDTH } from './constants';
import MiniMap from './MiniMap';
import Toolbar from './Toolbar';
import DesignerSettingsDrawer from './DesignerSettingsDrawer';
import {
  ConversationDesignerContextProvider,
  useConversationDesignerContext,
} from './ConversationDesignerContext';
import NewDesigner from './NewDesigner';
import OpenExistingDialog from './OpenExistingDialog';
import { createValidationSchema } from './validations';
import CreateConversationDrawer from './PublishTemplateDrawer';
import { getNodesAndValuesFromTemplate } from './utils';

const ConversationDesigner = () => {
  const { t } = useTranslation('conversationDesigner', {
    keyPrefix: 'designer',
  });
  const { isMessageBarOpen } = useUILayout();
  const dispatch = useDispatch();
  const { id: designerId } = useParams();
  const [isDesignerSettingsDrawerOpen, setDesignerSettingsDrawerOpen] =
    useState<boolean>(false);
  const [isOpenExistingDialogOpen, setOpenExistingDialogOpen] =
    useState<boolean>(false);
  const [isCreateConversationDrawerOpen, setCreateConversationDrawerOpen] =
    useState<boolean>(false);
  const miniMapListRef = useRef<HTMLDivElement>();

  const { designerSettings, treeData, formValues } = useSelector(
    (state: RootState) => state.conversationDesignerStore
  );

  const onSubmit = () => setCreateConversationDrawerOpen(true);

  const {
    flattenedItems,
    offsetLeft,
    onDragStart,
    onDragMove,
    onDragOver,
    onDragEnd,
    onDragCancel,
  } = useConversationDesignerContext();

  const sensorContext = useRef({
    items: flattenedItems,
    offset: offsetLeft,
  });

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableTreeKeyboardCoordinates(
        sensorContext,
        INDENTATION_WIDTH
      ),
    })
  );

  const defaultValues = useMemo(() => {
    const defaultValues = {
      selectedLanguage: designerSettings?.defaultLanguage,
      ...formValues,
    };

    flattenedItems?.forEach((item) => {
      if (
        item.type === NodeType.MultipleChoiceQuestion ||
        item.type === NodeType.YesNoQuestion ||
        item.type === NodeType.TextQuestion
      ) {
        defaultValues[item.id] = {
          dataBinding: 'none',
          ...formValues[item.id],
        };
      }
    });

    return defaultValues;
  }, [flattenedItems]);

  const methods = useForm<IConversationDesignerFormValues>({
    defaultValues,
    mode: 'all',
    resolver: yupResolver(
      createValidationSchema(
        flattenedItems,
        designerSettings?.defaultLanguage,
        designerSettings?.languages,
        t
      )
    ) as any,
  });

  useEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues]);

  const watchValues = methods.watch();
  useEffect(() => {
    if (!isEqual(watchValues, formValues)) {
      dispatch(persistFormValues(structuredClone(watchValues)));
    }
  }, [watchValues]);

  const [
    getStoredConversationDesignerItemsById,
    { isLoading: isStoredConversationDesignerItemsByIdLoading },
  ] = useLazyGetStoredConversationDesignerItemsByIdQuery();

  const getExistingDesignerItems = async () => {
    const template = await getStoredConversationDesignerItemsById(
      designerId
    ).unwrap();

    dispatch(
      setDesignerSettings({
        conversationName: template.name,
        defaultLanguage: template.content?.defaultLanguage,
        languages: template.content?.supportedLanguages?.filter(
          (language: string) => language !== template.content?.defaultLanguage
        ),
      })
    );
    const { treeData, formValues } = getNodesAndValuesFromTemplate(
      template.content
    );
    dispatch(initializeTreeData(treeData));
    dispatch(persistFormValues(formValues));
  };

  useEffect(() => {
    if (designerId) {
      methods.reset({});
      persistor.flush().then(() => persistor.purge());
      dispatch(resetState());
      getExistingDesignerItems();
    } else if (treeData?.length > 0) {
      setOpenExistingDialogOpen(true);
    }
  }, []);

  const [registerSignalRGroup] = useRegisterSignalRGroupMutation();
  const [unRegisterSignalRGroup] = useUnRegisterSignalRGroupMutation();

  useEffect(() => {
    if (designerId) {
      registerSignalRGroup(designerId);
    }

    return () => {
      designerId && unRegisterSignalRGroup(designerId);
      hubConnection.on('ITFUpdated', () => {
        getExistingDesignerItems();
      });
    };
  }, [designerId]);

  if (designerId && isStoredConversationDesignerItemsByIdLoading) {
    return <Loader fullHeight />;
  }

  if (!designerSettings?.conversationName) {
    return (
      <div
        className={cn(styles.conversationDesigner, {
          [styles.isMessageBarOpen]: isMessageBarOpen,
        })}
      >
        <NewDesigner />
      </div>
    );
  }

  return (
    <PersistGate persistor={persistor} loading={<Loader />}>
      <div
        className={cn(styles.conversationDesigner, {
          [styles.isMessageBarOpen]: isMessageBarOpen,
        })}
      >
        {isOpenExistingDialogOpen && (
          <OpenExistingDialog
            onClose={() => setOpenExistingDialogOpen(false)}
          />
        )}
        {isDesignerSettingsDrawerOpen && (
          <DesignerSettingsDrawer
            onClose={() => setDesignerSettingsDrawerOpen(false)}
          />
        )}
        <FormProvider {...methods}>
          {isCreateConversationDrawerOpen && (
            <CreateConversationDrawer
              onClose={() => setCreateConversationDrawerOpen(false)}
            />
          )}
          {treeData?.length > 0 && (
            <div className={styles.miniMapPanel}>
              <div className={styles.designerNameBackground}>
                <Card className={styles.designerNameCard}>
                  <div className={styles.heading}>{t('title')}</div>
                  <div className={styles.designerName}>
                    {designerSettings.conversationName}
                  </div>
                </Card>
              </div>
              <div className={styles.miniMap}>
                <MiniMap miniMapListRef={miniMapListRef} />
              </div>
            </div>
          )}
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className={styles.wrapper}>
              <Toolbar
                onSettingsClick={() => setDesignerSettingsDrawerOpen(true)}
              />
              <div className={styles.designer}>
                <DndContext
                  sensors={sensors}
                  collisionDetection={rectIntersection}
                  autoScroll={true}
                  onDragStart={onDragStart}
                  onDragMove={onDragMove}
                  onDragOver={onDragOver}
                  onDragEnd={onDragEnd}
                  onDragCancel={onDragCancel}
                >
                  <div className={styles.leftPane}>
                    <DesignerPane miniMapListRef={miniMapListRef} />
                  </div>
                  <div className={styles.rightPane}>
                    <ConvesationItemsPane />
                  </div>
                </DndContext>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </PersistGate>
  );
};

export default () => (
  <ConversationDesignerContextProvider>
    <ConversationDesigner />
  </ConversationDesignerContextProvider>
);

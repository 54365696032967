import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Grid, { Row } from '@common/Grid';
import tagStyles from '@common/assets/styles/main.scss';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import intlFormatDistance from 'date-fns/intlFormatDistance';
import parseISO from 'date-fns/parseISO';
import i18n from '@i18n/i18n';

import styles from './styles.module.scss';

const HistoryDialog = ({ onClose, templateDetails }) => {
  const { t } = useTranslation('conversationDesigner', {
    keyPrefix: 'historyDialog',
  });

  return (
    <Grid>
      <div className={styles.historyDialog}>
        <div className={styles.modalWrapper}>
          <div
            className={cn(
              tagStyles.tagDs,
              tagStyles.modal,
              tagStyles.docs,
              styles.modal
            )}
          >
            <Row marginBottom={16}>
              <h3>{t('editHistory')}</h3>
              <button
                id="close"
                type="button"
                className={tagStyles.modalCloseButton}
                onClick={onClose}
              >
                <CloseOutlinedIcon />
              </button>
            </Row>
            <Row marginBottom={24}>
              <ul>
                <li>
                  <b className={styles.key}>{t('created')}:</b>
                  {parseISO(templateDetails?.created) >
                    parseISO('0001-01-01T00:00:00+00:00') &&
                    intlFormatDistance(
                      parseISO(templateDetails?.created),
                      new Date(),
                      {
                        locale: i18n.language,
                      }
                    )}
                </li>
                <li>
                  <b className={styles.key}>{t('lastUpdated')}:</b>
                  {parseISO(templateDetails?.updated) >
                    parseISO('0001-01-01T00:00:00+00:00') &&
                    intlFormatDistance(
                      parseISO(templateDetails?.updated),
                      new Date(),
                      {
                        locale: i18n.language,
                      }
                    )}
                </li>
              </ul>
            </Row>
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default HistoryDialog;

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import cn from 'classnames';
import { v4 as uuid } from 'uuid';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { RootState } from '@redux/store';

import styles from './styles.module.scss';
import {
  AdditionalContext,
  ConditionalAndDynamicLogic,
  ConversationStructure,
  LegalAndCompliance,
  Nodes,
  QuestionTypes,
} from './constants';

const findNodeOfType = (nodes, type) => {
  for (const node of nodes) {
    if (node.type === type) {
      return node;
    }
    if (node.children) {
      const found = findNodeOfType(node.children, type);
      if (found) {
        return found;
      }
    }
  }
  return null;
};

export const DraggableCard = ({ nodeType }: any) => {
  const { t } = useTranslation('conversationDesigner', {
    keyPrefix: 'designerNode',
  });
  const treeData = useSelector(
    (state: RootState) => state.conversationDesignerStore.treeData
  );

  const isDisabled = useMemo(
    () => Nodes[nodeType]?.singleUseOnly && findNodeOfType(treeData, nodeType),
    [nodeType, treeData]
  );

  const { attributes, listeners, setNodeRef, transform, isDragging, over } =
    useDraggable({
      id: uuid(),
      data: { nodeType },
      disabled: isDisabled,
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    opacity: isDragging && over?.id ? 0 : 1,
  };

  return (
    <div
      ref={setNodeRef}
      className={cn(styles.convesationItem, { [styles.disabled]: isDisabled })}
      style={style}
      {...listeners}
      {...attributes}
    >
      <div
        className={styles.accent}
        style={{ backgroundColor: Nodes[nodeType]?.color }}
      />
      {!isDisabled && <DragIndicatorIcon />}
      <div className={styles.label}>{parse(t(Nodes[nodeType]?.label))}</div>
    </div>
  );
};

const ConvesationItemsPane = () => {
  const { t } = useTranslation('conversationDesigner', {
    keyPrefix: 'conversationItemsPane',
  });
  return (
    <div className={styles.convesationItemsPane}>
      <h4>{t('conversationalStructure')}</h4>
      {ConversationStructure?.map((nodeType) => (
        <DraggableCard key={nodeType} nodeType={nodeType} />
      ))}
      <h4>{t('questionTypes')}</h4>
      {QuestionTypes?.map((nodeType) => (
        <DraggableCard key={nodeType} nodeType={nodeType} />
      ))}
      <h4>{t('legalAndCompliance')}</h4>
      {LegalAndCompliance?.map((nodeType) => (
        <DraggableCard key={nodeType} nodeType={nodeType} />
      ))}
      <h4>{t('additionalContext')}</h4>
      {AdditionalContext?.map((nodeType) => (
        <DraggableCard key={nodeType} nodeType={nodeType} />
      ))}
      <h4>{t('conditionalAndDynamicLogic')}</h4>
      {ConditionalAndDynamicLogic?.map((nodeType) => (
        <DraggableCard key={nodeType} nodeType={nodeType} />
      ))}
    </div>
  );
};

export default ConvesationItemsPane;

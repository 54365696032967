import { useMemo, useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik, useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import TagTable, { TBody, THead, TRow, TElement } from '@adeccoux/tag-ds/table';
import Loader from '@common/Loader';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import FilterListIcon from '@mui/icons-material/FilterList';
import { FilterType } from '@appTypes/Campaign.types';
import Input from '@common/formik/Input';
import tagStyles from '@common/assets/styles/main.scss';
import Popup from '@common/Popup';
import Select from '@common/formik/Select';
import Card from '@common/Card';
import DatePicker from '@common/formik/DatePicker';
import CloseIcon from '@mui/icons-material/Close';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import parseISO from 'date-fns/parseISO';
import { capitalizeFirstLetter } from '@common/utils';

import styles from './styles.module.scss';

interface ITable {
  columns: any[];
  data: any[];
  onSort?: (column: any, isDesecnding: boolean, filters: any) => void;
  onFilter?: (filters: any) => void;
  onClearFilter?: (filters: any) => void;
  initialFilters?: any;
  initialSort?: any;
  isLoading?: boolean;
  isSelectable?: ((row) => boolean) | boolean;
  onSelectAll?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectRow?: (row: any, e: React.ChangeEvent<HTMLInputElement>) => void;
  noResultsComponent?: React.ReactNode;
}

const Table: React.FC<ITable> = ({
  columns,
  data,
  onSort,
  onFilter,
  onClearFilter,
  initialFilters,
  initialSort,
  isLoading,
  isSelectable,
  onSelectAll,
  onSelectRow,
  noResultsComponent,
}) => {
  const initialValues = useMemo(() => {
    const values = {};
    columns
      .filter((column) => column.filterType)
      .forEach((column) => {
        column.filterType === FilterType.RANGE
          ? Object.assign(values, {
              [`min${capitalizeFirstLetter(column.id)}`]: '',
              [`max${capitalizeFirstLetter(column.id)}`]: '',
            })
          : Object.assign(values, { [column.id]: '' });
      });
    return values;
  }, [columns]);

  const [filters, setFilters] = useState(initialFilters || initialValues);
  const [sortColumn, setSortColumn] = useState(null);
  const [isDescendingSorted, setDescendingSorted] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const checkboxRef = useRef(null);

  useEffect(() => {
    if (initialFilters) {
      setFilters(initialFilters);
    }
    if (initialSort?.column) {
      setSortColumn(initialSort?.column);
      setDescendingSorted(initialSort?.isDescending);
    }
  }, [initialFilters, initialSort]);

  useEffect(() => {
    if (!data?.[0]?.id) {
      data = data?.map((item) => ({ ...item, id: uuidv4() }));
    }
  }, [data]);

  const selectableRows = useMemo(
    () =>
      typeof isSelectable === 'boolean' && isSelectable
        ? data?.length
        : typeof isSelectable === 'function' &&
          data?.filter((row) => isSelectable(row))?.length,
    [data, isSelectable]
  );

  useEffect(() => {
    setSelectedRows([]);
  }, [data, filters]);

  useEffect(() => {
    if (
      checkboxRef.current &&
      selectedRows.length > 0 &&
      selectedRows.length < selectableRows
    ) {
      checkboxRef.current.indeterminate = true;
    } else if (checkboxRef.current) {
      checkboxRef.current.indeterminate = false;
    }
  }, [selectedRows, selectableRows]);

  const handleSelectRow = (
    row: any,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    onSelectRow?.(row, e);
    if (e.target.checked) {
      setSelectedRows([...selectedRows, row.id]);
    } else {
      setSelectedRows(
        selectedRows.filter((selectedRow) => selectedRow !== row.id)
      );
    }
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSelectAll?.(e);
    if (e.target.checked) {
      setSelectedRows(
        data
          .map((row) => {
            if (
              (typeof isSelectable === 'function' && isSelectable(row)) ||
              (typeof isSelectable === 'boolean' && isSelectable)
            ) {
              return row.id;
            }
          })
          .filter((row) => row)
      );
    } else {
      setSelectedRows([]);
    }
  };

  return (
    <div className="tag-ds">
      <div className={styles.table}>
        <Formik
          initialValues={filters}
          onSubmit={(filters) => {
            setFilters(filters);
            onFilter(filters);
          }}
        >
          <Form>
            <FilterChipRow
              columns={columns}
              filters={filters}
              onClearFilter={(filters) => {
                setFilters(filters);
                onClearFilter(filters);
              }}
            />
            <TagTable>
              <THead>
                <TRow>
                  {isSelectable && (
                    <TElement>
                      <input
                        type="checkbox"
                        ref={checkboxRef}
                        checked={
                          selectedRows?.length > 0 &&
                          selectableRows === selectedRows?.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TElement>
                  )}
                  {columns.map((column) => (
                    <ColumnHeaderCell
                      key={column.id}
                      column={column}
                      sortColumn={sortColumn}
                      setSortColumn={setSortColumn}
                      isDescendingSorted={isDescendingSorted}
                      setDescendingSorted={setDescendingSorted}
                      onSort={onSort}
                      onClearFilter={(filters) => {
                        setFilters(filters);
                        onClearFilter(filters);
                      }}
                    />
                  ))}
                </TRow>
              </THead>
              <TBody>
                {isLoading ? (
                  <TRow>
                    <TElement colSpan={columns?.length}>
                      <Loader />
                    </TElement>
                  </TRow>
                ) : noResultsComponent && data?.length === 0 ? (
                  <TRow>
                    <TElement colSpan={columns?.length}>
                      {noResultsComponent}
                    </TElement>
                  </TRow>
                ) : (
                  data?.map((row) => (
                    <TRow
                      key={row.id}
                      className={cn({
                        [styles.selected]: selectedRows?.includes(row.id),
                      })}
                    >
                      <TableRow
                        row={row}
                        columns={columns}
                        isSelectable={isSelectable}
                        selectedRows={selectedRows}
                        handleSelectRow={handleSelectRow}
                      />
                    </TRow>
                  ))
                )}
              </TBody>
            </TagTable>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

const TableRow = ({
  row,
  columns,
  isSelectable,
  selectedRows,
  handleSelectRow,
}) => (
  <>
    {isSelectable ? (
      (typeof isSelectable === 'function' && isSelectable(row)) ||
      (typeof isSelectable === 'boolean' && isSelectable) ? (
        <TElement key={row.id}>
          <input
            type="checkbox"
            checked={selectedRows.includes(row.id)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleSelectRow(row, e)
            }
          />
        </TElement>
      ) : (
        <TElement key={uuidv4()}>
          <input type="checkbox" disabled />
        </TElement>
      )
    ) : null}
    {columns.map((column: any) =>
      column.formatter ? (
        <TElement key={column.id}>{column.formatter(row)}</TElement>
      ) : (
        <TElement key={column.id}>{row[column.id]}</TElement>
      )
    )}
  </>
);

const ColumnHeaderCell = ({
  column,
  sortColumn,
  setSortColumn,
  isDescendingSorted,
  setDescendingSorted,
  onSort,
  onClearFilter,
}) => {
  const { t } = useTranslation('common');
  const { setFieldValue, handleSubmit, values } = useFormikContext();

  const handleSort = (column) => {
    setSortColumn(column.id);
    if (sortColumn === column.id) {
      onSort && onSort(column, !isDescendingSorted, values);
      setDescendingSorted(!isDescendingSorted);
    } else {
      onSort && onSort(column, true, values);
      setDescendingSorted(true);
    }
  };

  const filterComponent = (filterType: FilterType, dismissPopup: any) => {
    switch (filterType) {
      case FilterType.TEXT: {
        return (
          <Input
            type="text"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleSubmit();
                dismissPopup();
              }
            }}
            name={column.id}
            placeholder={`${t('filterBy')} ${column.name}...`}
          />
        );
      }
      case FilterType.DATE: {
        return (
          <DatePicker
            name={column.id}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleSubmit();
                dismissPopup();
              }
            }}
            minDate="01/01/0001"
            placeholder={`${t('filterBy')} ${column.name}...`}
          />
        );
      }
      case FilterType.DROPDOWN: {
        return (
          <Select
            name={column.id}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleSubmit();
                dismissPopup();
              }
            }}
            options={column.filterOptions}
            placeholder={`${t('filterBy')} ${column.name}...`}
            isSearchable={false}
          />
        );
      }
      case FilterType.RANGE: {
        return (
          <div className={styles.range}>
            <Field name={column.id}>
              {({ form }) => (
                <>
                  <label
                    className={tagStyles.caption}
                    htmlFor={`min${column.id}`}
                  >
                    {t('min')}
                    <input
                      name={`min${column.id}`}
                      value={
                        form.values[`min${capitalizeFirstLetter(column.id)}`]
                      }
                      onChange={(e) => {
                        form.setFieldValue(
                          `min${capitalizeFirstLetter(column.id)}`,
                          e.target.value
                        );
                      }}
                    />
                  </label>
                  <label
                    className={tagStyles.caption}
                    htmlFor={`min${column.id}`}
                  >
                    {t('max')}
                    <input
                      name={`max${column.id}`}
                      value={
                        form.values[`max${capitalizeFirstLetter(column.id)}`]
                      }
                      onChange={(e) => {
                        form.setFieldValue(
                          `max${capitalizeFirstLetter(column.id)}`,
                          e.target.value
                        );
                      }}
                    />
                  </label>
                </>
              )}
            </Field>
          </div>
        );
      }
      case FilterType.MULTICHOICE: {
        return column.filterOptions?.map((option) => (
          <div className={styles.multiChoice} key={option.value}>
            <label>
              <input
                type="checkbox"
                checked={values[`${column.id}`]?.includes(option.value)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setFieldValue(`${column.id}`, [
                      ...(values[`${column.id}`] || []),
                      option.value,
                    ]);
                  } else {
                    setFieldValue(
                      `${column.id}`,
                      values[`${column.id}`]?.filter(
                        (value) => value !== option.value
                      )
                    );
                  }
                }}
              />
              {option.label}
            </label>
          </div>
        ));
      }
      default: {
        return null;
      }
    }
  };

  return (
    <TElement
      className={cn(styles.header, { [styles.sortableColumn]: column.sort })}
      id={column.id}
      key={column.id}
      onClick={() => column.sort && handleSort(column)}
    >
      <div className={styles.sortHeader}>
        <span className={styles.sortHeaderText}>
          {column.name}
          {column.sort &&
            sortColumn === column.id &&
            (isDescendingSorted ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />)}
        </span>
        {column.filterType && (
          <Popup
            trigger={
              <button
                className={cn(tagStyles.upperMenuIcon, styles.filterButton)}
                type="button"
                id={`filter-${column.id}`}
                onClick={(e) => e.stopPropagation()}
              >
                <FilterListIcon />
              </button>
            }
          >
            {(dismissPopup) => (
              <Card
                className={styles.dropdownMenu}
                onClick={(e) => e.stopPropagation()}
              >
                <div>{filterComponent(column.filterType, dismissPopup)}</div>
                <span className={styles.filterActions}>
                  <button
                    className={cn(tagStyles.buttonSecondary)}
                    type="button"
                    id={`clear-filter-${column.id}`}
                    onClick={() => {
                      if (column.filterType === FilterType.RANGE) {
                        setFieldValue(
                          `min${capitalizeFirstLetter(column.id)}`,
                          null
                        );
                        setFieldValue(
                          `max${capitalizeFirstLetter(column.id)}`,
                          null
                        );
                        onClearFilter &&
                          onClearFilter({
                            ...(values as any),
                            [`min${capitalizeFirstLetter(column.id)}`]: '',
                            [`max${capitalizeFirstLetter(column.id)}`]: '',
                          });
                      } else {
                        setFieldValue(column.id, '');
                        onClearFilter &&
                          onClearFilter({
                            ...(values as any),
                            [column.id]: '',
                          });
                      }

                      dismissPopup();
                    }}
                  >
                    {t('common:reset')}
                  </button>
                  <button
                    id={`confirm-filter-${column.id}`}
                    type="submit"
                    onClick={() => {
                      dismissPopup();
                      handleSubmit();
                    }}
                  >
                    {t('common:apply')}
                  </button>
                </span>
              </Card>
            )}
          </Popup>
        )}
      </div>
    </TElement>
  );
};

const FilterChipRow = ({ filters, columns, onClearFilter }) => {
  const { setFieldValue } = useFormikContext();
  const { t } = useTranslation('common');

  const filtersCount = Object.keys(filters).filter(
    (item) => filters[item] && columns.some((column: any) => column.id === item)
  )?.length;

  return filtersCount > 0 ? (
    <>
      <label className={cn(tagStyles.caption, styles.chipRowLabel)}>
        {filtersCount > 0 && `Active filters (${filtersCount})`}
      </label>
      <div className={styles.chipRow}>
        {columns.map((column) => {
          return (
            (filters[column.id] ||
              (column.filterType === FilterType.RANGE &&
                (filters[`min${capitalizeFirstLetter(column.id)}`] ||
                  filters[`max${capitalizeFirstLetter(column.id)}`]))) && (
              <div className="tag-ds" key={column.id}>
                <button
                  className="chip"
                  id={`clear-active-filter-${column.id}`}
                  onClick={() => {
                    if (column.filterType === FilterType.RANGE) {
                      setFieldValue(
                        `min${capitalizeFirstLetter(column.id)}`,
                        null
                      );
                      setFieldValue(
                        `max${capitalizeFirstLetter(column.id)}`,
                        null
                      );
                      onClearFilter &&
                        onClearFilter({
                          ...filters,
                          [`min${capitalizeFirstLetter(column.id)}`]: '',
                          [`max${capitalizeFirstLetter(column.id)}`]: '',
                        });
                    } else {
                      setFieldValue(column.id, '');
                      onClearFilter &&
                        onClearFilter({
                          ...filters,
                          [column.id]: '',
                        });
                    }
                  }}
                >
                  {column?.name}:
                  <b>
                    {column.filterType === FilterType.DROPDOWN ? (
                      column.filterOptions.find(
                        (option) => option.value === filters[column.id]
                      )?.label
                    ) : column.filterType === FilterType.RANGE ? (
                      <div className={styles.rangeValue}>
                        <div>
                          {filters[`min${capitalizeFirstLetter(column.id)}`] &&
                            `${t('min')}: ${
                              filters[`min${capitalizeFirstLetter(column.id)}`]
                            }`}
                        </div>
                        <div>
                          {filters[`max${capitalizeFirstLetter(column.id)}`] &&
                            `${t('max')}:
                        ${filters[`max${capitalizeFirstLetter(column.id)}`]}`}
                        </div>
                      </div>
                    ) : column.filterType === FilterType.MULTICHOICE ? (
                      filters[column.id]?.join(', ')
                    ) : column.filterType === FilterType.DATE &&
                      isValid(parseISO(filters[column.id])) ? (
                      format(parseISO(filters[column.id]), 'dd/LL/yyyy')
                    ) : (
                      filters[column.id]
                    )}
                  </b>
                  <CloseIcon />
                </button>
              </div>
            )
          );
        })}
      </div>
    </>
  ) : null;
};

export default Table;

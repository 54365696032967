import { Notification, NotificationHeader } from '@adeccoux/tag-ds';
import { Handle, Position } from '@xyflow/react';
import AltRouteIcon from '@mui/icons-material/AltRoute';

const Switch = ({ data }) => {
  const { label } = data;

  return (
    <div className="tag-ds">
      <Handle type="target" position={Position.Top} style={{ opacity: 0 }} />
      <Notification
        icon={<AltRouteIcon style={{ transform: 'rotate(180deg)' }} />}
        info
      >
        <NotificationHeader>{label}</NotificationHeader>
      </Notification>
      <Handle type="source" position={Position.Bottom} style={{ opacity: 0 }} />
    </div>
  );
};

export default Switch;

import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState, persistor } from '@redux/store';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ChatBotTesterDialog from '@common/ChatBotTester';
import Popup from '@common/Popup';
import Card from '@common/Card';
import {
  redo,
  resetState,
  undo,
} from '@redux/reducers/conversationDesignerReducer';
import { useGetAppConfigQuery } from '@redux/api/portalApiSlice';
import { useGetStoredConversationDesignerItemsByIdQuery } from '@redux/api/conversationApiSlice';
import { CampaignDetails } from '@appTypes/Campaign.types';
import { useGetGroupsForCurrentUserQuery } from '@redux/api/securityApiSlice';
import tagStyles from '@common/assets/styles/main.scss';

import styles from './styles.module.scss';
import { useConversationDesignerContext } from './ConversationDesignerContext';
import { useBuildTemplateJSON } from './utils';
import ShareDialog from './ShareDialog';
import HistoryDialog from './HistoryDialog';
import ConversationPreviewDrawer from './ConversationPreviewDrawer';
import SaveDialog from './SaveDialog';

const Toolbar = ({ onSettingsClick }) => {
  const { t } = useTranslation('conversationDesigner', {
    keyPrefix: 'toolBar',
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: designerId } = useParams();
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
  const [isHistoryDialogOpen, setIsHistoryDialogOpen] = useState(false);
  const [isChatbotOpen, setChatbotOpen] = useState(false);
  const [isPreviewOpen, setPreviewOpen] = useState(false);
  const [isSaveDialogOpen, setSaveDialogOpen] = useState(false);
  const isCollapsed = window?.innerWidth <= 1600;

  const { setValue, trigger, reset } = useFormContext();

  const { selectedLanguage, setSelectedLanguage } =
    useConversationDesignerContext();

  const { data: appConfig } = useGetAppConfigQuery();

  const { data: templateDetails } =
    useGetStoredConversationDesignerItemsByIdQuery(designerId, {
      skip: !designerId,
    });

  const { data: groups } = useGetGroupsForCurrentUserQuery();

  const { treeData, undoStack, redoStack, designerSettings } = useSelector(
    (store: RootState) => store.conversationDesignerStore
  );

  const languageOptions = useMemo(() => {
    const languages = appConfig?.referenceConfig?.supportedCultures?.filter(
      (item) =>
        [designerSettings.defaultLanguage, ...designerSettings.languages]?.find(
          (language) => item.cultureCode === language
        )
    );

    return languages.map((item) => ({
      label: (
        <div className={styles.languageSelectorLabel}>
          <div
            className={styles.imageContainer}
            style={{
              background: `no-repeat center url(/assets/flags/${item.countryCode}.svg)`,
            }}
          />
          {item.languageName}
        </div>
      ),
      value: item.cultureCode,
    }));
  }, [designerSettings, appConfig]);

  const onNew = async () => {
    if (treeData?.length > 0) {
      setSaveDialogOpen(true);
    } else {
      
      await persistor.flush();
      await persistor.purge();
      reset({});
      dispatch(resetState());
      navigate(`/designer`, { replace: true });
    }
  };

  const onTestConversation = async () => {
    const isValid = await trigger();
    if (isValid) {
      setChatbotOpen(true);
    }
  };

  const onPreview = async () => {
    const isValid = await trigger();
    if (isValid) {
      setPreviewOpen(true);
    }
  };

  const onShare = () => {
    setIsShareDialogOpen(true);
  };

  const buildTemplateJSON = useBuildTemplateJSON();
  const onDownloadJson = async () => {
    const isValid = await trigger();
    if (isValid) {
      const json = buildTemplateJSON();
      const blob = new Blob([JSON.stringify(json, null, 2)], {
        type: 'application/json',
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'export_template.json';
      a.click();
      URL.revokeObjectURL(url);
    }
  };

  useEffect(() => {
    setValue('selectedLanguage', designerSettings?.defaultLanguage);
  }, [designerSettings?.defaultLanguage]);

  return (
    <div className={styles.toolbar}>
      {isShareDialogOpen && (
        <ShareDialog onClose={() => setIsShareDialogOpen(false)} />
      )}
      {isHistoryDialogOpen && (
        <HistoryDialog
          onClose={() => setIsHistoryDialogOpen(false)}
          templateDetails={templateDetails}
        />
      )}
      {isChatbotOpen && (
        <ChatBotTesterDialog
          currentCampaign={
            { brand: 'Adecco', groupId: groups[0]?.id } as CampaignDetails
          }
          currentTemplate={{ content: buildTemplateJSON(), version: 1 } as any}
          showLanguageAndChannelSelectors={true}
          onClose={() => setChatbotOpen(false)}
        />
      )}
      {isPreviewOpen && (
        <ConversationPreviewDrawer onClose={() => setPreviewOpen(false)} />
      )}
      {isSaveDialogOpen && (
        <SaveDialog onClose={() => setSaveDialogOpen(false)} />
      )}
      <div>
        <button className={styles.toolbarItem} type="button" onClick={onNew}>
          <NoteAddOutlinedIcon />
          {t('new')}
        </button>
        <button
          className={styles.toolbarItem}
          type="button"
          onClick={() => dispatch(undo())}
          disabled={undoStack.length === 0}
        >
          <UndoIcon />
          {t('undo')}
        </button>
        <button
          className={styles.toolbarItem}
          type="button"
          onClick={() => dispatch(redo())}
          disabled={redoStack.length === 0}
        >
          <RedoIcon />
          {t('redo')}
        </button>
        <div className={styles.separator} />
        <button
          className={styles.toolbarItem}
          type="button"
          disabled={treeData.length === 0}
          onClick={onPreview}
        >
          <AccountTreeOutlinedIcon />
          {t('preview')}
        </button>
        <button
          className={styles.toolbarItem}
          type="button"
          onClick={onTestConversation}
          disabled={treeData.length === 0}
        >
          <ScienceOutlinedIcon />
          {t('testConversation')}
        </button>
        <div className={styles.separator} />
        <button
          className={styles.toolbarItem}
          type="button"
          onClick={onSettingsClick}
        >
          <SettingsOutlinedIcon />
          {t('settings')}
        </button>
        {isCollapsed ? (
          <Popup
            trigger={
              <button className={tagStyles.upperMenuIcon} type="button">
                <MoreHorizOutlinedIcon />
              </button>
            }
          >
            <Card className={styles.dropdown}>
              {designerId && (
                <button
                  className={styles.dropdownItem}
                  onClick={() => setIsHistoryDialogOpen(true)}
                  disabled={treeData.length === 0}
                >
                  <HistoryOutlinedIcon />
                  {t('editHistory')}
                </button>
              )}
              <button
                className={styles.dropdownItem}
                onClick={onShare}
                disabled={treeData.length === 0}
              >
                <ShareOutlinedIcon />
                {t('share')}
              </button>
              <button
                className={styles.dropdownItem}
                onClick={onDownloadJson}
                disabled={treeData.length === 0}
              >
                <DownloadOutlinedIcon />
                {t('downloadJson')}
              </button>
            </Card>
          </Popup>
        ) : (
          <>
            {designerId && (
              <button
                className={styles.toolbarItem}
                type="button"
                onClick={() => setIsHistoryDialogOpen(true)}
                disabled={treeData.length === 0}
              >
                <HistoryOutlinedIcon />
                {t('editHistory')}
              </button>
            )}
            <button
              className={styles.toolbarItem}
              type="button"
              onClick={onShare}
              disabled={treeData.length === 0}
            >
              <ShareOutlinedIcon />
              {t('share')}
            </button>
            <button
              className={styles.toolbarItem}
              type="button"
              onClick={onDownloadJson}
              disabled={treeData.length === 0}
            >
              <DownloadOutlinedIcon />
              {t('downloadJson')}
            </button>
          </>
        )}
      </div>
      <div>
        <Popup
          trigger={
            <button type="button" className={styles.languageSelector}>
              {
                languageOptions?.find(
                  (option) => option.value === selectedLanguage
                )?.label
              }
              <ExpandMoreOutlinedIcon />
            </button>
          }
        >
          {(dismissPopup) => (
            <Card className={styles.dropdown}>
              {languageOptions?.map((item) => (
                <button
                  type="button"
                  className={styles.dropdownItem}
                  onClick={() => {
                    setSelectedLanguage(item.value);
                    dismissPopup();
                  }}
                >
                  {item.label}
                </button>
              ))}
            </Card>
          )}
        </Popup>
        <button
          className={cn(tagStyles.chip, styles.chipButton)}
          type="submit"
          disabled={treeData.length === 0}
        >
          {t('publishTemplate')}
        </button>
      </div>
    </div>
  );
};

export default Toolbar;

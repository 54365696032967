import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import cn from 'classnames';
import Loader from '@common/Loader';
import { RootState } from '@redux/store';
import Grid, { Row } from '@common/Grid';
import tagStyles from '@common/assets/styles/main.scss';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import {
  useSaveConversationDesignerItemsMutation,
  useUpdateStoredConversationDesignerItemsMutation,
} from '@redux/api/conversationApiSlice';

import styles from './styles.module.scss';
import { getGeneratorData } from '../utils';

const ShareDialog = ({ onClose }) => {
  const { t } = useTranslation('conversationDesigner');
  const navigate = useNavigate();
  const { id: designerId } = useParams();
  const [url, setUrl] = useState('');

  const { getValues } = useFormContext();

  const { treeData, designerSettings } = useSelector(
    (store: RootState) => store.conversationDesignerStore
  );

  const [
    saveConversationDesignerItems,
    { isLoading: iseSaveConversationDesignerItemsLoading },
  ] = useSaveConversationDesignerItemsMutation();

  const [
    updateStoredConversationDesignerItems,
    { isLoading: isUpdateStoredConversationDesignerItemsLoading },
  ] = useUpdateStoredConversationDesignerItemsMutation();

  const saveOrUpdateDesignerItems = async () => {
    const generatorData = getGeneratorData(
      treeData,
      getValues(),
      designerSettings
    );

    if (designerId) {
      await updateStoredConversationDesignerItems({
        id: designerId,
        name: generatorData.name,
        content: generatorData,
      });
      setUrl(window.location.href);
    } else {
      const data = await saveConversationDesignerItems({
        name: generatorData.name,
        content: generatorData,
      }).unwrap();

      navigate(`/designer/${data.templateId}`, { replace: true });
      setUrl(window.location.href);
    }
  };

  useEffect(() => {
    saveOrUpdateDesignerItems();
  }, []);

  const methods = useForm({
    defaultValues: { url },
  });

  useEffect(() => {
    methods.reset({ url });
  }, [url]);

  return (
    <Grid>
      <FormProvider {...methods}>
        <form>
          <div className={styles.shareDialog}>
            <div className={styles.modalWrapper}>
              <div
                className={cn(
                  tagStyles.tagDs,
                  tagStyles.modal,
                  tagStyles.docs,
                  styles.modal
                )}
              >
                <Row marginBottom={16}>
                  <h4>{t('shareDialog.title')}</h4>
                  <button
                    id="close"
                    type="button"
                    className={tagStyles.modalCloseButton}
                    onClick={onClose}
                  >
                    <CloseOutlinedIcon />
                  </button>
                </Row>
                {iseSaveConversationDesignerItemsLoading ||
                isUpdateStoredConversationDesignerItemsLoading ? (
                  <Loader />
                ) : (
                  <>
                    <Row marginBottom={24}>{t('shareDialog.description')}</Row>
                    <Row>
                      <div className={styles.buttonBar}>
                        <button
                          id="copy-campaign-link-button"
                          className={tagStyles.buttonSecondary}
                          type="button"
                          onClick={() => navigator.clipboard.writeText(url)}
                        >
                          {t('shareDialog.copyLink')} <InsertLinkOutlinedIcon />
                        </button>
                        <button
                          id="close-button"
                          type="button"
                          onClick={onClose}
                        >
                          {t('common:close')}
                        </button>
                      </div>
                    </Row>
                  </>
                )}
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </Grid>
  );
};

export default ShareDialog;

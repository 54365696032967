import { Content } from '@appTypes/Conversation.types';
import { MarkerType } from '@xyflow/react';

export enum NodeType {
  AdaptiveCard = 'AdaptiveCard',
  Condition = 'Condition',
  DataBinding = 'DataBinding',
  Exit = 'Exit',
  ForEach = 'Foreach',
  InvokeTemplate = 'InvokeTemplate',
  QuestionChoice = 'QuestionChoice',
  Question = 'Question',
  Message = 'Message',
  Navigate = 'Navigate',
  RemoteStep = 'RemoteStep',
  Switch = 'Switch',
}

export enum StepEnum {
  GotoNextStep = 'GotoNextStep',
  GotoParentStep = 'GotoParentStep',
  GotoParentNextStep = 'GotoParentNextStep',
  GotoPreviousStep = 'GotoPreviousStep',
  RepeatStep = 'RepeatStep',
  GotoStepByName = 'GotoStepByName',
  GotoSteps = 'GotoSteps',
  ChoiceSource = 'choiceSource',
}

const getNodeFieldName = (node) => {
  switch (node.type) {
    case NodeType.Message:
    case NodeType.Exit:
      return node.message;

    case NodeType.Question:
      return node.question?.key || node.question?.question || node.question;

    case NodeType.QuestionChoice:
      return node.questions?.[0]?.text;

    default:
      return node.name;
  }
};

const newNode = (node) => ({
  id: node.name,
  type: node.type,

  data: { ...node, label: node.name, fieldName: getNodeFieldName(node) },
  position: { x: 0, y: 0 },
});

export const getDefaultValues = (content: Content) => {
  const defaultValues = {};
  const getResourceTexts = (id: string) => {
    const values = content.languageResources.resources?.find(
      (resource) => resource.key === id
    )?.values;

    const result = {};
    if (values?.length > 0) {
      values?.forEach((value) => (result[value?.language] = value?.texts?.[0]));

      return result;
    }

    return null;
  };

  const nodes = flattenTree(content.steps);
  Object.values(nodes)?.forEach((item: any) => {
    if (
      ['message', 'question', 'questionchoice', 'exit'].includes(
        item.type?.toLowerCase()
      )
    ) {
      defaultValues[item.data?.fieldName] = getResourceTexts(
        item.data?.fieldName
      );

      if (item.type?.toLowerCase() === 'questionchoice') {
        item.data?.choices?.source?.forEach((choice) => {
          defaultValues[choice?.label] = getResourceTexts(choice?.label);
        });
      }
    }
  });
  return defaultValues;
};

const newEdge = (sourceNodeName, targetNodeName) => ({
  id: `${sourceNodeName}-${targetNodeName}`,
  type: 'edge',
  source: sourceNodeName,
  target: targetNodeName,
  markerEnd: {
    type: MarkerType.ArrowClosed,
    width: 20,
    height: 20,
    color: 'var(--neutral700)',
  },
});

const getChildNodes = (node) => {
  const nodes = [];
  switch (node.type) {
    case NodeType.Condition: {
      if (node.if?.type === StepEnum.GotoSteps) {
        nodes.push(...node?.if?.steps);
      }

      if (node.if?.type === StepEnum.GotoStepByName) {
        nodes.push(node.if);
      }

      if (node.else?.type === StepEnum.GotoSteps) {
        nodes.push(...node?.else?.steps);
      }

      if (node.else?.type === StepEnum.GotoStepByName) {
        nodes.push(node.else);
      }

      return nodes;
    }

    case NodeType.ForEach: {
      if (node.steps) {
        node.steps.forEach((step) => {
          nodes.push(step);
        });
      }
      return nodes;
    }

    case NodeType.QuestionChoice: {
      if (node.action?.type === StepEnum.GotoSteps) {
        node.action?.steps.forEach((step) => {
          nodes.push(step);
        });
      }

      if (node.allChoices) {
        node.allChoices?.action?.steps?.forEach((step) => {
          nodes.push(step);
        });
      }

      if (node.stopChoices) {
        node.stopChoices?.action?.steps?.forEach((step) => {
          nodes.push(step);
        });
      }

      if (node.noChoices) {
        node.noChoices?.action?.steps?.forEach((step) => {
          nodes.push(step);
        });
      }

      if (node.choices && node.choices?.type === StepEnum.ChoiceSource) {
        for (const choiceItem of node.choices?.source) {
          choiceItem?.navAction?.steps?.forEach((step) => {
            nodes.push(step);
          });
        }
      }

      return nodes;
    }

    case NodeType.Question: {
      if (node.defaultAction) {
        node.defaultAction?.action?.steps?.forEach((step) => {
          nodes.push(step);
        });
      }

      if (node.answers) {
        for (const answer of node.answers) {
          answer?.action?.steps?.forEach((step) => {
            nodes.push(step);
          });
        }
      }

      return nodes;
    }

    case NodeType.Navigate: {
      if (node.action) {
        node.action?.steps?.forEach((step) => {
          nodes.push(step);
        });
      }

      return nodes;
    }

    case NodeType.Switch: {
      if (node.cases) {
        for (const currentCase of node.cases) {
          currentCase.then?.steps?.forEach((step) => {
            nodes.push(step);
          });
        }
      }

      return nodes;
    }
    default:
      return [];
  }
};

export const buildConversationPreview = (content) => {
  const nodes = flattenTree(content.steps);
  const edges = [];

  const addEdge = (sourceNode, targetNode) => {
    if (
      !edges.some(
        (edge) => edge.source === sourceNode.id && edge.target === targetNode.id
      )
    ) {
      edges.push(newEdge(sourceNode.id, targetNode.id));
    }
  };

  nodes.forEach((firstNode) => {
    nodes.forEach((secondNode) => {
      if (firstNode.id !== secondNode.id) {
        if (
          !edges.some(
            (edge) =>
              edge.source === firstNode.id && edge.target === secondNode.id
          )
        ) {
          if (hasEgdeBetweenNodes(nodes, firstNode.data, secondNode.data)) {
            addEdge(firstNode, secondNode);
          }
          if (hasEgdeBetweenNodes(nodes, secondNode.data, firstNode.data)) {
            addEdge(secondNode, firstNode);
          }
        }
      }
    });
  });

  nodes.forEach((node, i) => {
    if (i < nodes.length - 1) {
      const nextNode = nodes[i + 1];
      if (
        !edges.find((edge) => edge.source === node.id) &&
        !edges.find((edge) => edge.target === nextNode.id) &&
        !node.data?.nextStepName
      ) {
        addEdge(node, nextNode);
      }
    }
  });

  nodes.forEach((node) => {
    const sourceEdge = edges.find((edge) => edge.source === node.id);
    const targetEdge = edges.find((edge) => edge.target === node.id);
    if (targetEdge && !sourceEdge && node.type !== NodeType.Exit) {
      addEdge(
        node,
        newNode(content.steps?.find((node) => node.type === NodeType.Exit))
      );
    }
  });

  return { nodes, edges };
};

const flattenTree = (steps, nodes = []) => {
  steps.forEach((step) => {
    if (!nodes.some((node) => node.name === step.name)) {
      nodes.push(newNode(step));
    }
    flattenTree(getChildNodes(step), nodes);
  });

  return nodes;
};

const hasEgdeBetweenNodes = (flattenedNodes, sourceNode, targetNode) => {
  if (sourceNode?.nextStepName === targetNode?.name) {
    return true;
  }

  switch (sourceNode.type) {
    case NodeType.Condition: {
      if (
        sourceNode.if?.type === StepEnum.GotoStepByName &&
        sourceNode.if?.stepName === targetNode.name
      ) {
        return true;
      }

      if (
        sourceNode.if?.type === StepEnum.GotoSteps &&
        targetNode.name === sourceNode.if?.steps?.[0]?.name
      ) {
        return true;
      }

      if (
        sourceNode.else?.type === StepEnum.GotoStepByName &&
        sourceNode.else?.stepName === targetNode.name
      ) {
        return true;
      }

      if (
        sourceNode.else?.type === StepEnum.GotoSteps &&
        targetNode.name === sourceNode.else?.steps?.[0]?.name
      ) {
        return true;
      }

      if (!sourceNode.else) {
        const nextNodeIndex =
          flattenedNodes.findIndex((node) => node.id === sourceNode.name) + 1;

        if (nextNodeIndex > 0) {
          const nextNode = flattenedNodes[nextNodeIndex];
          if (nextNode.id === targetNode.name) {
            return true;
          }
        }
      }
      return false;
    }

    case NodeType.Navigate: {
      if (
        sourceNode.action?.type === StepEnum.GotoStepByName &&
        targetNode.name === sourceNode.action?.stepName
      ) {
        return true;
      }
      return false;
    }

    case NodeType.QuestionChoice: {
      if (
        sourceNode.allChoices?.action?.type === StepEnum.GotoStepByName &&
        targetNode.name === sourceNode.allChoices?.action?.stepName
      ) {
        return true;
      }

      if (
        sourceNode.stopChoices?.action?.type === StepEnum.GotoStepByName &&
        targetNode.name === sourceNode.allChoices?.action?.stepName
      ) {
        return true;
      }

      if (
        sourceNode.noChoices?.action?.type === StepEnum.GotoStepByName &&
        targetNode.name === sourceNode.allChoices?.action?.stepName
      ) {
        return true;
      }

      if (sourceNode.choices?.type === StepEnum.ChoiceSource) {
        for (const choiceItem of sourceNode.choices?.source) {
          if (
            (choiceItem.navAction?.type === StepEnum.GotoStepByName &&
              targetNode.name === choiceItem.navAction?.stepName) ||
            (choiceItem.navAction?.type === StepEnum.GotoSteps &&
              targetNode.name === choiceItem.navAction?.steps?.[0].name)
          ) {
            return true;
          }
        }
      }
      return false;
    }

    case NodeType.Question: {
      if (
        sourceNode.defaultAction?.action?.type === StepEnum.GotoStepByName &&
        targetNode.name === sourceNode.defaultAction?.action?.stepName
      ) {
        return true;
      }

      if (
        sourceNode.answers?.[0]?.parser?.type === 'YesNo' &&
        sourceNode.answers?.[0]?.action?.type === StepEnum.GotoSteps &&
        targetNode.name === sourceNode.answers?.[0]?.action?.steps?.[0]?.name
      ) {
        return true;
      }

      if (
        sourceNode.answers?.[1]?.parser?.type === 'YesNo' &&
        sourceNode.answers?.[1]?.action?.type === StepEnum.GotoSteps &&
        targetNode.name === sourceNode.answers?.[1]?.action?.steps?.[0]?.name
      ) {
        return true;
      }

      return false;
    }

    case NodeType.Switch: {
      if (sourceNode.cases) {
        for (const currentCase of sourceNode.cases) {
          if (
            currentCase.then?.type === StepEnum.GotoStepByName &&
            targetNode.name === currentCase.then?.stepName
          ) {
            return true;
          }

          if (
            currentCase.then?.type === StepEnum.GotoSteps &&
            targetNode.name === currentCase.then?.steps?.[0]?.name
          ) {
            return true;
          }
        }
      }
      return false;
    }

    default:
      return false;
  }
};

import { MessagingTemplate, NewCampaignType } from '@appTypes/Campaign.types';
import { UserAccess } from '@appTypes/Security.types';

export interface ConversationCreationResponse {
  message: string;
  id: string;
  type?: string;
}

export interface TemplateQuestionResponse {
  categories: TemplateQuestionCategory[];
  parts: TemplateQuestion[];
}

export interface TemplateQuestionCategory {
  id: string;
  titles: TemplateQuestionText[];
}

export interface TemplateQuestion {
  id: string;
  createdBy?: string;
  categoryId: string;
  subCategoryId?: string;
  title: TemplateQuestionTitle;
  name: TemplateQuestionTitle;
  nameToShow?: TemplateQuestionTitle;
  Tag?: string;
  questionDependency?: string;
  subQuestions?: TemplateQuestion[];
  subQuestionsCondition?: string;
  subQuestionTag?: string;
  existsField?: string;
  notExistsField?: string;
  type: TemplateQuestionType;
  subType: TemplateQuestionType;
  originalType: TemplateQuestionType;
  placeholder?: string;
  dynamicPlaceholder?: string;
  isQualifying?: boolean;
  qualifyingChoice?: string;
  executionCondition?: string;
  order?: number;
  parser?: string;
  variable?: string;
  skip?: boolean;
  isFailure?: boolean;
  isMapped?: boolean;
  isAutoCompletion?: boolean;
  askContent?: boolean;
  questionCategory?: string;
  languageResources?: TemplateQuestionText;
  mandatoryInCountries?: [
    {
      countryCode?: string;
      questionPosition?: string;
    }
  ];
  forbiddenQuestions?: {
    language: [string];
  };
  tips?: TemplateQuestionTip[];
  choices: TemplateQuestionChoice[];
  parameters: TemplateQuestionParameter[];
  additionalVariables?: TemplateQuestionAdditionalVariables;
  subQuestionsTags?: string[];
  isHidden?: boolean;
  conditionProperties?: any;
  events?: any;
  dataBindings?: any;
  _partitionKey?: string;
  videosettings: {
    url: string;
    thumbnailUrl: string;
    message: string;
  };
  fileFormats: string[];
  datePickerSettings?: {
    allowPast: boolean;
    allowFuture: boolean;
    weekdaysOnly: boolean;
  };
  [extraParams: string]: any;
}

export interface TemplateQuestionText {
  language: string;
  texts: string[];
  isQualifying?: boolean;
  id?: string;
  title?: string;
}

export interface TemplateQuestionTitle {
  title: string;
  languageResources: TemplateQuestionText[];
}

export interface TemplateQuestionTip {
  title?: string;
  languageResources?: TemplateQuestionText[];
  replaces?: string;
}

export interface TemplateQuestionChoice {
  resources?: TemplateQuestionTitle;
  title?: string;
  isQualifying?: boolean;
}

export interface TemplateQuestionParameter {
  id: string;
  content: string;
  description: string;
}

export interface TemplateQuestionAdditionalVariables {
  [Code: string]: string;
}

export interface ReportingSummary {
  id: number;
  campaignId: string;
  campaignName: string;
  startDate: string;
  endDate: string;
  newType: string;
  purpose: string;
  isIntegration: boolean;
  isFormless: boolean;
  waveCount: number;
  candidatePreparationTotal: number;
  candidatePreparationSuccess: number;
  candidatePreparationFail: number;
  validCandidates: number;
  clickNum: number;
  outreachNum: number;
  engagedNum: number;
  completedNum: number;
  rejectedNum: number;
  abandonnedNum: number;
}

export interface FunnelReportingSummary {
  id: number;
  campaignId: string;
  campaignName: string;
  startDate: string;
  endDate: string;
  newType: string;
  purpose: string;
  isIntegration: boolean;
  isFormless: boolean;
  importedNum: number;
  candidateNum: number;
  contactedNum: number;
  clickNum: number;
  engagedNum: number;
  interestedNum: number;
  stoppedNum: number;
  completedNum: number;
  droppedNum: number;
  scheduledMeetings: number;
  integrationScheduledMeetings: number;
  totalScheduledMeetings: number;
  reportByChannel: ChannelReport[];
}

export interface Template {
  id: string;
  name: string;
  status: TemplateState;
  description: string;
  category: string;
  version: number;
  type: TemplateType;
  templateId: string;
  masterTemplateId: string;
  created: Date;
  updated: Date;
  createdBy: string;
  isVisible: boolean;
  conversationType: string;
  content: Content;
  statusIcon?: any;
  messagingTemplates: MessagingTemplate[];
  defaultLanguages?: string;
  languages?: string[];
}

export class Content {
  dataSourceTypes: string[];
  parameters: Record<string, TemplateParameter>;
  variables: any[];
  metadata: Metadata;
  steps: Step[];
  languageResources: LanguageResources;
  dataExportSetting?: TemplateDataExportSetting[];
}

export interface Step {
  type: string;
  name: string;
  shortDescription: string;
  stepId: string;
  originStepId: string;
  executionCondition: string;
  PropertyBindingSource: PropertyBinding[];
  telemetryEvents: TelemetryEventRule[];
  progressHint?: number;
  feedbackOptions: FeedbackOptions;
  ignore: boolean;
  nextStepName: string;
  events?: Event[];
}

export interface LanguageResources {
  supportedLanguages: string[];
  defaultLanguage: string;
  resources: Resource[];
}

export interface TemplateDataExportSetting {
  exportName: string;
  propertyPath: string;
}

export interface ChannelReport {
  channel: string;
  contactedNum: number;
  clickNum: number;
  engagedNum: number;
  interestedNum: number;
  stoppedNum: number;
  completedNum: number;
  droppedNum: number;
}

export interface PropertyBinding {
  name: string;
  value: string;
}

export interface TemplateParameter {
  description: string;
  type: ParameterType;
  defaultValue: any | string | any[];
}

export interface Metadata {
  formless?: boolean;
  pdfDownloadEnabled?: boolean;
  disablePreview?: boolean;
  generator?: string;
  generatorVersion?: number;
  generatorData?: any;
}

export class FeedbackOptions {
  feedbackMessage: string;
  isEnabled: boolean;
  feedbackType: FeedbackType;
}

export interface TelemetryEventRule {
  name: string;
  properties: Record<string, string>;
  trigger: TriggerOn;
  condition: string;
}

export interface Resource {
  key: string;
  values: Value[];
}

export interface Value {
  language: string;
  texts: string[];
}

export interface ResourcesResult {
  yesResources: Record<string, string>;
  noResources: Record<string, string>;
  feedbackTextResources: Record<string, string>;
  restartButtonResources: Record<string, string>;
  yesNoResources: Record<string, string>;
  multipleAnswerResources: Record<string, string>;
  multipleChoiceResources: Record<string, string>;
  freeTextResources: Record<string, string>;
  datePickerResources: Record<string, string>;
  videoResources: Record<string, string>;
  fileUploadResources: Record<string, string>;
}

export interface ClientSummary {
  browserTypes: ClientInfo[];
  deviceTypes: ClientInfo[];
  browserLanguages: ClientInfo[];
}

export interface ClientInfo {
  name: string;
  number: number;
  pourcentage: number;
}

export interface Engagements {
  triggerChannel: string;
  context: string;
}

export interface AverageMessageCount {
  fromUser: number;
  fromBot: number;
}

export interface ReportingConversationStartDate {
  id: number;
  startDate: string;
  conversationCount: number;
}

export interface ReportingConversationQuestions {
  id: number;
  stepName: string;
  stepShortDescription: string;
  inProgress: number;
  success: number;
  fail: number;
}

export interface ReportingQuestionStep {
  stepName: string;
  stepShortDescription: string;
  answers: Answer[];
}

export interface Answer {
  value: string;
  number: number;
}

export interface ReportingConversationAbandonned {
  id: number;
  lastStep: string;
  stepShortDescription: string;
  abandonnedCount: number;
}

export interface CampaignDetailsResponse {
  campaignId: string;
  name: string;
  channels?: string;
  launchedOn?: Date;
  engagedCandidates?: number;
  outreachCandidates?: number;
  completedCandidates?: number;
  validCandidates?: number;
  totalImported?: number;
}

export interface GlobalReportingRequest {
  campaignType?: NewCampaignType;
  fromDate?: Date;
  toDate?: Date;
  campaignPurpose?: string;
  groupPath?: string;
  campaignIds?: string[];
  country?: string;
  brand?: string;
}

export interface NpsCommentsRequest extends GlobalReportingRequest {
  pageSize?: number;
  continuationToken?: string;
  previousPageToken?: string;
}

export interface GlobalReportingResponse {
  totalCampaigns: number;
  validCandidates: number;
  totalImported: number;
  outReached: number;
  clicked: number;
  engaged: number;
  completed: number;
  scheduledInterviews: number;
  campaignSent: GlobalReportingCampaignSent[];
}

export interface GlobalReportingCampaignSent {
  name: string;
  campaignSent: number;
  messagesSent: number;
}

export interface Last24HrsReport {
  activeCampaignNum: number;
  importedNum: number;
  validCandidates: number;
  contactedNum: number;
  clickNum: number;
  engagedNum: number;
  completedNum: number;
}

export interface ActiveCampaigns {
  campaignName: string;
  campaignId: string;
}

export interface ActiveCampaignsByBrand {
  brand: string;
  campaignNum: number;
}

export interface TemplateUserAccess extends UserAccess {
  template: TemplateWithLanguages;
}

export interface TemplateWithLanguages extends Template {
  languages: string[];
}

export interface ShowSurveyReponse {
  id: number;
  showSurvey: boolean;
  canSkip: boolean;
}

export interface NpsReportResponse {
  totalAnswers: number;
  averageScore: number;
  totalComments: number;
  npsScoreRangeNeg100_0: number;
  npsScoreRangePos0_40: number;
  npsScoreRangePos40_75: number;
  npsScoreRangePos75_100: number;
  monthlyNpsScores: number;
  quarterlyNpsScores: number;
}

export interface NpsTrendsResponse {
  npsMonthlyByCountryNpsScores: NpsMonthlyByCountryNpsScores[];
  monthlyNpsScores: { [key: string]: number };
  quarterlyNpsScores: { [key: string]: number };
}

export interface NpsMonthlyByCountryNpsScores {
  country: string;
  countryNpsScore: number;
  numberOfResponses: number;
  monthlyNpsScores: { [key: string]: number };
}

export interface MonthlyNpsByCountryResponse {
  country: string;
  countryNpsScore: number;
  numberOfResponses: number;
  monthlyNpsScores: MonthlyNpsScore[];
}

export interface MonthlyNpsScore {
  [month: string]: number;
}

export interface CommentsNpsReport {
  country: string;
  feedbackValue: number;
  comment: string;
  brand: string;
}

export interface NpsCommentsResponse {
  comments: CommentsNpsReport[];
  continuationToken: string;
  previousPageToken: string;
  totalPages: number;
}

export interface ConversationDesignerNode {
  id: string;
  depth: number;
  index: number;
  type: NodeType;
  children: ConversationDesignerNode[];
  parentId: string;
}

export interface IConversationDesignerFormValues {
  [key: string]:
    | string
    | {
        name?: string;
        message?:
          | { from: string; to: string }
          | string
          | { [language: string]: string };
        instanceData?: {
          firstNameEnabled?: boolean;
          lastNameEnabled?: boolean;
          mobileNumberEnabled?: boolean;
          emailAddressEnabled?: boolean;
        };
      };
}

export enum ParameterType {
  Integer = 'Integer',
  String = 'String',
  Boolean = 'Boolean',
  Float = 'Float',
  Double = 'Double',
  Date = 'Date',
  Datetime = 'Datetime',
  Timespan = 'Timespan',
  Guid = 'Guid',
  Array = 'Array',
  Object = 'Object',
}

export enum TemplateQuestionType {
  toBeDefined = 'toBeDefined',
  yesNo = 'yesNo',
  multipleAnswer = 'multipleAnswer',
  multipleChoice = 'multipleChoice',
  freeText = 'freeText',
  exit = 'exit',
  message = 'message',
  datePicker = 'datePicker',
  video = 'video',
  fileUpload = 'fileUpload',
}

export enum FeedbackType {
  ThumbsUpDown = 'ThumbsUpDown',
  Emojis = 'Emojis',
  FiveStars = 'FiveStars',
}

export enum TriggerOn {
  OnLoadStep = 'OnLoadStep',
  OnUserReply = 'OnUserReply',
}

export enum BotTestingChannel {
  Sms = 'sms',
  Directline = 'directline',
  WhatsApp = 'WhatsApp',
  FullSMS = 'FullSMS',
}

export type TemplateState = 'None' | 'Published' | 'Draft';

export type TemplateType =
  | 'None'
  | 'Template'
  | 'TemplatePart'
  | 'TemplateInstance'
  | 'TemplateFromQuestionSelection';

export enum NodeType {
  Comment = 'Comment',
  Condition = 'Condition',
  IfCondition = 'IfCondition',
  ElseCondition = 'ElseCondition',
  Failure = 'Failure',
  Success = 'Success',
  DataBinding = 'DataBinding',
  IntroMessage = 'IntroMessage',
  MultipleChoiceQuestion = 'MultipleChoiceQuestion',
  MultipleChoiceAnswerChoice = 'MultipleChoiceAnswerChoice',
  PersonalDataQuestion = 'PersonalDataQuestion',
  TermsAndConditions = 'TermsAndConditions',
  ConfirmationText = 'ConfirmationText',
  BlockerText = 'BlockerText',
  TextQuestion = 'TextQuestion',
  YesNoQuestion = 'YesNoQuestion',
  YesAnswer = 'YesAnswer',
  NoAnswer = 'NoAnswer',
}

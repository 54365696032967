import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { RootState } from '@redux/store';
import {
  ConversationDesignerNode,
  NodeType,
} from '@appTypes/Conversation.types';
import { useGetAppConfigQuery } from '@redux/api/portalApiSlice';

import { ROOT_NODE_ID, schedulingTextResources } from './constants';
import { buildTree } from './TreeUtils/utils';

const toCamelCase = (str: string) => {
  return str
    ?.replace(/-/g, ' ')
    ?.replace(/\s+/g, ' ')
    ?.split(' ')
    ?.map((word, i) =>
      i > 0 ? word.charAt(0).toUpperCase() + word.substr(1) : word
    )
    ?.join('');
};

const nodeToSteps = (
  node: ConversationDesignerNode,
  values: any,
  defaultLanguage: string
) => {
  const steps: any[] = [];
  const resources: any[] = [];
  const variables: any = {};

  switch (node.type) {
    case NodeType.Condition: {
      const ifBranch = node.children?.find(
        (child) => child.type === NodeType.IfCondition
      );
      const elseBranch = node.children?.find(
        (child) => child.type === NodeType.ElseCondition
      );

      steps.push({
        type: 'Condition',
        expression: values[node.id]?.name,
        name: values[node.id]?.message,
        if: {
          type: 'GotoSteps',
          steps: ifBranch?.children
            ? ifBranch?.children
                .map((child) => {
                  const { steps, resources: _resources } = nodeToSteps(
                    child,
                    values,
                    defaultLanguage
                  );
                  resources.push(..._resources?.flat());
                  return steps;
                })
                .flat()
            : [],
        },
        else: {
          type: 'GotoSteps',
          steps: elseBranch?.children
            ? elseBranch?.children
                .map((child) => {
                  const { steps, resources: _resources } = nodeToSteps(
                    child,
                    values,
                    defaultLanguage
                  );
                  resources.push(..._resources?.flat());
                  return steps;
                })
                .flat()
            : [],
        },
      });
      break;
    }

    case NodeType.DataBinding: {
      steps.push({
        type: 'DataBinding',
        name: values[node.id]?.name,
        dataBindings: [
          {
            from: values[node.id]?.message?.from || undefined,
            to: values[node.id]?.message?.to || undefined,
          },
        ],
      });

      break;
    }

    case NodeType.Failure: {
      steps.push({
        type: 'Exit',
        name: values[node.id]?.name,
        message: node.id,
        isSuccess: false,
      });

      resources.push({
        key: node.id,
        values: Object.entries(values[node.id]?.message).map(
          ([key, value]) => ({
            language: key,
            texts: [value],
          })
        ),
      });

      break;
    }

    case NodeType.IntroMessage: {
      steps.push({
        type: 'Message',
        name: values[node.id]?.name,
        message: node.id,
        showImmediately: true,
      });

      resources.push({
        key: node.id,
        values: Object.entries(values[node.id]?.message).map(
          ([key, value]) => ({
            language: key,
            texts: [value],
          })
        ),
      });

      break;
    }

    case NodeType.MultipleChoiceQuestion: {
      steps.push({
        type: 'QuestionChoice',
        name: values[node.id]?.name,
        questions: [{ text: node.id }],
        choices: {
          type: 'choiceSource',
          source: node.children.map((child) => {
            return {
              label: child.id,
              value: values[child.id]?.message[defaultLanguage],
            };
          }),
        },
        dataBindings: [
          {
            from: 'LastAnswer.text',
            to:
              values[node.id]?.dataBinding === 'none'
                ? `candidate.props.${toCamelCase(values[node.id]?.name)}`
                : values[node.id]?.dataBinding,
          },
        ],
        outOfRangeMessage: {
          key: 'CHOOSE_IN_LIST',
        },
      });

      node?.children?.forEach((child) =>
        resources.push({
          key: child.id,
          values: Object.entries(values[child.id]?.message).map(
            ([key, value]) => ({
              language: key,
              texts: [value],
            })
          ),
        })
      );

      node.children.forEach((child) => {
        variables[child.id] = values[child.id]?.message[defaultLanguage];
      });

      steps.push({
        name: `__switch__${values[node.id]?.name}`,
        type: 'Switch',
        when: 'LastAnswer.text',
        cases: node.children.map((child) => ({
          equal: `resource('${child.id}').value`,
          then: {
            type: 'GotoSteps',
            steps: child.children
              ?.map((subChild) => {
                const { steps, resources: _resources } = nodeToSteps(
                  subChild,
                  values,
                  defaultLanguage
                );
                resources.push(..._resources?.flat());
                return steps;
              })
              .flat(),
          },
        })),
      });

      resources.push({
        key: node.id,
        values: Object.entries(values[node.id]?.message).map(
          ([key, value]) => ({
            language: key,
            texts: [value],
          })
        ),
      });

      if (!resources?.find((resource) => resource.key === 'CHOOSE_IN_LIST')) {
        resources.push({
          key: 'CHOOSE_IN_LIST',
          values: [
            {
              language: 'fr-fr',
              texts: ['Merci de choisir parmis les choix possibles.'],
            },
            {
              language: 'en-us',
              texts: ['Thank you for your selection.'],
            },
            {
              language: 'en-gb',
              texts: ['Thank you for your selection.'],
            },
            {
              language: 'nl-nl',
              texts: ['Selecteer een tijdstip'],
            },
            {
              language: 'es-es',
              texts: ['Selecciona un horario por favor'],
            },
            {
              language: 'en-au',
              texts: ['Thank you for your selection.'],
            },
            {
              language: 'en-ca',
              texts: ['Thank you for your selection.'],
            },
            {
              language: 'en-de',
              texts: ['Thank you for your selection.'],
            },
            {
              language: 'en-hk',
              texts: ['Thank you for your selection.'],
            },
            {
              language: 'en-ie',
              texts: ['Thank you for your selection.'],
            },
            {
              language: 'en-in',
              texts: ['Thank you for your selection.'],
            },
            {
              language: 'en-my',
              texts: ['Thank you for your selection.'],
            },
            {
              language: 'en-nl',
              texts: ['Thank you for your selection.'],
            },
            {
              language: 'en-sg',
              texts: ['Thank you for your selection.'],
            },
            {
              language: 'lb-lu',
              texts: ['Thank you for your selection.'],
            },
            {
              language: 'de-at',
              texts: ['Bestätigung der Ernennung.'],
            },
            {
              language: 'de-ch',
              texts: ['Bestätigung der Ernennung.'],
            },
            {
              language: 'de-de',
              texts: ['Bestätigung der Ernennung.'],
            },
            {
              language: 'fr-be',
              texts: ['Merci de choisir parmis les choix possibles.'],
            },
            {
              language: 'fr-ca',
              texts: ['Merci de choisir parmis les choix possibles.'],
            },
            {
              language: 'fr-ch',
              texts: ['Merci de choisir parmis les choix possibles.'],
            },
            {
              language: 'fr-tn',
              texts: ['Merci de choisir parmis les choix possibles.'],
            },
            {
              language: 'ar-ae',
              texts: ['شكرا لاختيارك.'],
            },
            {
              language: 'bg-bg',
              texts: ['Благодарим ви за вашия избор.'],
            },
            {
              language: 'cs-cz',
              texts: ['Děkujeme za váš výběr.'],
            },
            {
              language: 'el-gr',
              texts: ['Σας ευχαριστούμε για την επιλογή σας.'],
            },
            {
              language: 'fi-fi',
              texts: ['Kiitos valinnastanne.'],
            },
            {
              language: 'hr-hr',
              texts: ['Hvala vam na odabiru.'],
            },
            {
              language: 'hu-hu',
              texts: ['Köszönjük a választását.'],
            },
            {
              language: 'it-ch',
              texts: ['Grazie per la vostra selezione.'],
            },
            {
              language: 'it-it',
              texts: ['Grazie per la vostra selezione.'],
            },
            {
              language: 'ja-jp',
              texts: ['お選びいただきありがとうございます。'],
            },
            {
              language: 'ko-kr',
              texts: ['선택해 주셔서 감사합니다.'],
            },
            {
              language: 'ms-my',
              texts: ['Terima kasih atas pilihan anda.'],
            },
            {
              language: 'nl-be',
              texts: ['Selecteer een tijdstip'],
            },
            {
              language: 'pl-pl',
              texts: ['Dziękujemy za wybór.'],
            },
            {
              language: 'ro-ro',
              texts: ['Vă mulțumim pentru selecție.'],
            },
            {
              language: 'sk-sk',
              texts: ['Ďakujeme za váš výber.'],
            },
            {
              language: 'sl-si',
              texts: ['Zahvaljujemo se vam za izbiro.'],
            },
            {
              language: 'sr-latn-rs',
              texts: ['Хвала на избору.'],
            },
            {
              language: 'sv-se',
              texts: ['Tack för ditt val.'],
            },
            {
              language: 'th-th',
              texts: ['ขอขอบคุณสําหรับการเลือก'],
            },
            {
              language: 'tr-tr',
              texts: ['Seçiminiz için teşekkür ederiz.'],
            },
            {
              language: 'vi-vn',
              texts: ['Cảm ơn bạn đã chọn.'],
            },
          ],
        });
      }

      break;
    }

    case NodeType.PersonalDataQuestion: {
      if (values[node.id]?.instanceData.firstNameEnabled) {
        steps.push({
          type: 'Question',
          name: `__dataQuestions_${values[node.id]?.name}_firstName`,
          question: `${node.id}_firstName`,
          dataBindings: [
            {
              from: 'true',
              to: 'candidate.firstName',
            },
          ],
        });

        resources.push({
          key: `${node.id}_firstName`,
          values: Object.entries(values[node.id]?.message?.firstName)?.map(
            ([key, value]) => ({
              language: key,
              texts: [value],
            })
          ),
        });
      }

      if (values[node.id]?.instanceData.lastNameEnabled) {
        steps.push({
          type: 'Question',
          name: `__dataQuestions_${values[node.id]?.name}_lastName`,
          question: `${node.id}_lastName`,
          dataBindings: [
            {
              from: 'true',
              to: 'candidate.lastName',
            },
          ],
        });

        resources.push({
          key: `${node.id}_lastName`,
          values: Object.entries(values[node.id]?.message?.lastName)?.map(
            ([key, value]) => ({
              language: key,
              texts: [value],
            })
          ),
        });
      }

      if (values[node.id]?.instanceData.mobileNumberEnabled) {
        steps.push({
          type: 'Question',
          name: `__dataQuestions_${values[node.id]?.name}_mobileNumber`,
          question: `${node.id}_mobileNumber`,
          answers: [
            {
              parser: {
                type: 'Phone',
                '[PropertyBinding]': [
                  {
                    name: 'countryCodes',
                    value: "parameters('countryCodes')",
                  },
                ],
                countryCodes: [],
              },
              dataBindings: [
                {
                  from: 'lastAnswer.data',
                  to: 'candidate.phoneNumbers.cellPhone',
                },
              ],
            },
          ],
        });

        resources.push({
          key: `${node.id}_mobileNumber`,
          values: Object.entries(values[node.id]?.message?.mobileNumber)?.map(
            ([key, value]) => ({
              language: key,
              texts: [value],
            })
          ),
        });
      }

      if (values[node.id]?.instanceData.emailAddressEnabled) {
        steps.push({
          type: 'Question',
          name: `__dataQuestions_${values[node.id]?.name}_emailAddress`,
          question: `${node.id}_emailAddress`,
          answers: [
            {
              parser: {
                type: 'Email',
              },
              dataBindings: [
                {
                  from: 'lastAnswer.data',
                  to: 'candidate.email',
                },
              ],
            },
          ],
        });

        resources.push({
          key: `${node.id}_emailAddress`,
          values: Object.entries(values[node.id]?.message?.emailAddress)?.map(
            ([key, value]) => ({
              language: key,
              texts: [value],
            })
          ),
        });
      }

      steps.push({
        type: 'DataBinding',
        name: `__dataQuestions_${values[node.id]?.name}_setIsActive`,
        dataBindings: [
          {
            from: 'true',
            to: 'candidate.isActive',
          },
        ],
      });

      break;
    }

    case NodeType.Success: {
      steps.push({
        type: 'Exit',
        name: values[node.id]?.name,
        message: node.id,
        isSuccess: true,
      });

      resources.push({
        key: node.id,
        values: Object.entries(values[node.id]?.message).map(
          ([key, value]) => ({
            language: key,
            texts: [value],
          })
        ),
      });

      break;
    }

    case NodeType.TermsAndConditions: {
      steps.push({
        name: '__termsAndConditions',
        type: 'QuestionChoice',
        questions: [
          {
            text: node.id,
          },
        ],
        choices: {
          type: 'choiceSource',
          source: [
            {
              label: `${node.id}_confirm`,
              value: 'Yes',
            },
          ],
        },
        dataBindings: [
          {
            from: '"Yes"',
            to: 'candidate.props.optIn',
          },
          {
            from: 'DateNow()',
            to: 'candidate.TermsAndCondAcceptedDate',
          },
        ],
        outOfRangeMessage: `${node.id}_blocker`,
      });

      const confirmStep = node.children.find(
        (child) => child.type === NodeType.ConfirmationText
      );
      const blockerStep = node.children.find(
        (child) => child.type === NodeType.BlockerText
      );

      resources.push({
        key: node.id,
        values:
          values[node.id]?.message &&
          Object.entries(values[node.id]?.message).map(([key, value]) => ({
            language: key,
            texts: [value],
          })),
      });

      resources.push({
        key: `${node.id}_confirm`,
        values:
          values[confirmStep.id]?.message &&
          Object.entries(values[confirmStep.id]?.message).map(
            ([key, value]) => ({
              language: key,
              texts: [value],
            })
          ),
      });

      resources.push({
        key: `${node.id}_blocker`,
        values:
          values[blockerStep.id]?.message &&
          Object.entries(values[blockerStep.id]?.message).map(
            ([key, value]) => ({
              language: key,
              texts: [value],
            })
          ),
      });

      break;
    }

    case NodeType.TextQuestion: {
      steps.push({
        type: 'Question',
        name: values[node.id]?.name,
        question: node.id,
        suggestions: node.children.map((child) => child.id),
        answers: node.children.map((child) => ({
          parser: {
            type: 'TextMatch',
            matchKeywords: [child.id],
          },
          action: {
            type: 'GotoSteps',
            steps: child.children
              .map((subChild) => {
                const { steps, resources: _resources } = nodeToSteps(
                  subChild,
                  values,
                  defaultLanguage
                );
                resources.push(..._resources?.flat());
                return steps;
              })
              .flat(),
          },
        })),
        dataBindings: [
          {
            from: 'LastAnswer.text',
            to:
              values[node.id]?.dataBinding === 'none'
                ? `candidate.props.${toCamelCase(values[node.id]?.name)}`
                : values[node.id]?.dataBinding,
          },
        ],
      });

      values[node.id]?.message &&
        resources.push({
          key: node.id,
          values: Object.entries(values[node.id]?.message).map(
            ([key, value]) => ({
              language: key,
              texts: [value],
            })
          ),
        });

      break;
    }

    case NodeType.YesNoQuestion: {
      steps.push({
        type: 'Question',
        name: values[node.id]?.name,
        question: node.id,
        suggestions: node.children.map((child) => child.id),
        answers: node.children.map((child) => ({
          parser: {
            type: 'YesNo',
            expect: child.type === NodeType.YesAnswer ? 'Yes' : 'No',
            returnValue: child.type === NodeType.YesAnswer ? true : false,
          },
          action: {
            type: 'GotoSteps',
            steps: child.children
              .map((subChild) => {
                const { steps, resources: _resources } = nodeToSteps(
                  subChild,
                  values,
                  defaultLanguage
                );
                resources.push(..._resources?.flat());
                return steps;
              })
              .flat(),
          },
          dataBindings: [
            {
              from: 'LastAnswer.data',
              to:
                values[node.id]?.dataBinding === 'none'
                  ? `candidate.props.${toCamelCase(values[node.id]?.name)}`
                  : values[node.id]?.dataBinding,
            },
          ],
        })),
      });

      values[node.id]?.message &&
        resources.push({
          key: node.id,
          values: Object.entries(values[node.id]?.message).map(
            ([key, value]) => ({
              language: key,
              texts: [value],
            })
          ),
        });

      node.children?.map((child) => {
        resources.push({
          key: child.id,
          values:
            values[child.id] &&
            Object.entries(values[child.id]?.message).map(([key, value]) => ({
              language: key,
              texts: [value],
            })),
        });
      });

      break;
    }

    case NodeType.Comment: {
      values[node.id]?.message &&
        resources.push({
          key: node.id,
          values: Object.entries(values[node.id]?.message).map(
            ([key, value]) => ({
              language: key,
              texts: [value],
            })
          ),
        });
      break;
    }

    default:
      break;
  }

  return {
    steps,
    resources,
    variables,
  };
};

export const getGeneratorData = (
  treeData: ConversationDesignerNode[],
  values: any,
  designerSettings: any
) => {
  enum NodeTypeMap {
    Comment = 'comment',
    Condition = 'condition',
    IfCondition = 'branch',
    ElseCondition = 'branch',
    DataBinding = 'data_binding',
    IntroMessage = 'message',
    Success = 'exit_success',
    Failure = 'exit_failure',
    MultipleChoiceQuestion = 'question_choice',
    MultipleChoiceAnswerChoice = 'answer',
    PersonalDataQuestion = 'personal_data_questions',
    TermsAndConditions = 'terms_and_conditions',
    ConfirmationText = 'text_resource',
    BlockerText = 'text_resource',
    TextQuestion = 'question_text',
    YesNoQuestion = 'question_yesno',
    YesAnswer = 'answer',
    NoAnswer = 'answer',
  }

  const generatorData = {
    name: designerSettings?.conversationName,
    type: 'default',
    defaultLanguage: designerSettings?.defaultLanguage,
    supportedLanguages: designerSettings?.languages,
    version: 1,
    rootId: ROOT_NODE_ID,
    items: {
      items: {
        id: ROOT_NODE_ID,
        hasChildren: true,
        children: [],
      },
    },
  };

  const getItems = (node, items) => {
    items[node.id] = {
      id: node.id,
      type: NodeTypeMap[node.type],
      hasChildren: true,
      children: node.children.map((child) => child.id),
      name:
        node.type === NodeType.YesAnswer
          ? 'Yes'
          : node.type === NodeType.NoAnswer
          ? 'No'
          : node.type === NodeType.IfCondition
          ? 'If'
          : node.type === NodeType.ElseCondition
          ? 'Else'
          : node.type === NodeType.Condition
          ? values[node.id]?.message
          : values[node.id]?.name ||
            values[node.id]?.message?.[designerSettings.defaultLanguage] ||
            values[node.id]?.message ||
            '',
      message:
        node.type === NodeType.Condition
          ? values[node.id]?.name
          : values[node.id]?.message,
      dataBinding: values[node.id]?.dataBinding || 'none',
    };

    if (node.type === NodeType.PersonalDataQuestion) {
      items[node.id].instanceData = values[node.id]?.instanceData;
    }

    if (node?.children?.length === 0) {
      return items;
    }

    return node.children.forEach((child) => getItems(child, items));
  };

  const items = {};
  treeData.forEach((node) => {
    generatorData.items.items.children.push(node.id);
    generatorData.items = {
      ...generatorData.items,
      ...getItems(node, items),
    };
  });

  return generatorData;
};

export const useBuildTemplateJSON = () => {
  const { data: appConfig } = useGetAppConfigQuery();

  const { treeData, designerSettings } = useSelector(
    (state: RootState) => state.conversationDesignerStore
  );

  const { getValues } = useFormContext();
  const values = getValues();

  const template = {
    dataSourceTypes: [
      {
        name: 'candidate',
      },
      {
        name: 'campaign',
        readOnly: true,
      },
    ] as any,
    metadata: {
      generator: 'portalDesigner',
      generatorVersion: 1,
      generatorData: {},
    },
    parameters: {
      countryCodes: {
        type: 'Array',
        defaultValue: [],
        description: 'country codes',
      },
    },
    variables: null,
    steps: [] as any[],
    languageResources: {
      defaultLanguage: designerSettings.defaultLanguage,
      supportedLanguages: [
        designerSettings.defaultLanguage,
        ...designerSettings.languages,
      ],
      resources: [] as any[],
    },
  };

  const buildTemplateJson = () => {
    template.parameters.countryCodes.defaultValue =
      designerSettings?.languages?.map((language) =>
        parseInt(
          appConfig.referenceConfig.supportedCultures
            ?.find((item) => item.cultureCode === language)
            ?.phonePrefix.substring(1)
        )
      );
    template.metadata.generatorData = getGeneratorData(
      treeData,
      values,
      designerSettings
    );
    treeData.forEach((node) => {
      const { steps, resources, variables } = nodeToSteps(
        node,
        values,
        designerSettings.defaultLanguage
      );
      template.steps.push(...steps);
      template.languageResources.resources.push(...resources);
      template.variables = { ...template.variables, ...variables };
    });

    if (designerSettings.conversationType === 'scheduling') {
      return addSchedulingParameters(template);
    }

    return template;
  };

  return buildTemplateJson;
};

export const getNodesAndValuesFromTemplate = (content: any) => {
  enum NodeTypeMap {
    comment = 'Comment',
    condition = 'Condition',
    branch = 'IfCondition',
    data_binding = 'DataBinding',
    message = 'IntroMessage',
    exit_success = 'Success',
    exit_failure = 'Failure',
    question_choice = 'MultipleChoiceQuestion',
    answer = 'MultipleChoiceAnswerChoice',
    personal_data_questions = 'PersonalDataQuestion',
    terms_and_conditions = 'TermsAndConditions',
    text_resource = 'ConfirmationText',
    question_text = 'TextQuestion',
    question_yesno = 'YesNoQuestion',
  }

  const formValues = {};
  const treeItems = [];
  Object.entries(content?.items).forEach(([key, value]: any) => {
    const parent = Object.values(content?.items)?.find((item: any) =>
      item.children?.includes(key)
    ) as any;

    if (key !== ROOT_NODE_ID) {
      treeItems.push({
        ...value,
        type:
          value.name === 'Yes'
            ? NodeType.YesAnswer
            : value.name === 'No'
            ? NodeType.NoAnswer
            : value.name === 'If'
            ? NodeType.IfCondition
            : value.name === 'Else'
            ? NodeType.ElseCondition
            : NodeTypeMap[value.type],
        parentId: parent.id,
      });
      formValues[key] = {
        name: value.name,
        message: value.message,
        dataBinding: value.dataBinding || 'none',
      };
    }
  });

  return { treeData: buildTree(treeItems), formValues };
};

export const addSchedulingParameters = (template: any) => {
  if (!template.parameters) {
    template.parameters = {};
  }

  template.parameters = {
    ...template.parameters,
    countryCodes: {
      type: 'Array',
      defaultValue: [33, 44],
      description: 'country codes',
    },
  };

  if (!template.variables) {
    template.variables = {};
  }

  template.variables = {
    ...template.variables,
    booking: {},
    myBookings: {
      bookings: null,
    },
    isCancellationSuccess: null,
    dynProps: {},
    firstDataModel: {
      meetingDate: 'To be defined',
      interviewDuration: '00h00',
      recruter: '',
      location: {
        address: {
          addressLine: '',
        },
      },
      candidate: {
        firstName: '',
      },
      onlineMeetingUrl: '',
    },
    secondDataModel: {
      meetingDate: '',
      interviewDuration: '',
      location: {
        address: {
          addressLine: '',
        },
      },
      candidate: {
        firstName: '',
      },
      onlineMeetingUrl: '',
    },
    icsAttachment: {
      fileName: 'AdeccoMeeting',
      title: '',
      location: '',
      description: '',
      start: null,
      end: null,
    },
    onlineInterviewId: '3176c5dc-a144-4957-816d-fb6655f4427f',
  };

  template.steps = [
    {
      name: '__Binding candidate sending counter',
      executionCondition: 'candidate.props.sendingCounter = null',
      type: 'DataBinding',
      dataBindings: [
        {
          from: '0',
          to: 'candidate.props.sendingCounter',
        },
      ],
    },
    {
      progressHint: 0,
      type: 'RemoteStep',
      name: '__Check booking',
      serviceName: 'AgendaGetBooking',
      serviceParameter: {},
      dataBindings: [
        {
          from: 'returnValue',
          to: 'myBookings.bookings',
        },
      ],
      '[PropertyBinding]': [
        {
          name: 'serviceParameter.candidateId',
          value: 'candidate.id',
        },
      ],
    },
    {
      progressHint: 0,
      name: '__Check if previous completion',
      type: 'Condition',
      expression:
        'AND(myBookings.Bookings != null,count(myBookings.Bookings) > 0)',
      if: {
        type: 'GotoSteps',
        steps: [
          {
            progressHint: 20,
            type: 'QuestionChoice',
            name: '__Previous completion reminder',
            questions: [{ text: 'WELCOMEAGAIN_MSG' }],
            choices: {
              type: 'choiceSource',
              source: [
                {
                  label: 'SEND_EMAIL_MSG',
                  value: 'Send',
                  enabled:
                    "HasMessagingTemplate('Interview confirmation','email')",
                  navAction: {
                    type: 'GotoSteps',
                    steps: [
                      {
                        name: '__Check sending counter email',
                        type: 'Condition',
                        expression: 'candidate.props.sendingCounter > 3',
                        if: {
                          type: 'GotoStepByName',
                          stepName: '__Exit too many requests',
                        },
                        else: {
                          type: 'GotoSteps',
                          steps: [
                            {
                              name: '__Increase counter after email check',
                              type: 'DataBinding',
                              dataBindings: [
                                {
                                  from: 'add(candidate.props.sendingCounter,1)',
                                  to: 'candidate.props.sendingCounter',
                                },
                              ],
                            },
                          ],
                        },
                      },
                      {
                        progressHint: 50,
                        type: 'Question',
                        name: '__Ask email',
                        question: 'ASK_EMAIL',
                        askConfirmation: {
                          text: 'ASK_MAIL_CONFIRMATION',
                          yes: 'YES_MSG',
                          no: 'NO_MSG',
                        },
                        answers: [
                          {
                            parser: {
                              type: 'Email',
                            },
                            dataBindings: [
                              {
                                from: 'lastAnswer.data',
                                to: 'candidate.email',
                              },
                            ],
                          },
                        ],
                        defaultAction: {
                          message: 'WRONG_EMAIL',
                          action: {
                            type: 'RepeatStep',
                          },
                        },
                      },
                      {
                        name: '__Binding existing meeting to email data model',
                        type: 'DataBinding',
                        dataBindings: [
                          {
                            from: 'myBookings.bookings[0].location',
                            to: 'firstDataModel.location.address.addressLine',
                          },
                          {
                            from: 'myBookings.bookings[0].recruter',
                            to: 'firstDataModel.recruter',
                          },
                          {
                            from: 'TimeSpanAutoFormatHoursMinutes(GetDuration(myBookings.bookings[0].datetimeStart,myBookings.bookings[0].datetimeEnd))',
                            to: 'firstDataModel.interviewDuration',
                          },
                          {
                            from: "dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dddd dd MMMM yyyy HH:mm')",
                            to: 'firstDataModel.meetingDate',
                          },
                          {
                            from: 'candidate.firstName',
                            to: 'firstDataModel.candidate.FirstName',
                          },
                          {
                            from: "if(or(IsNull(candidate.ShortenerCodes),Count(candidate.ShortenerCodes) = 0),'null',candidate.ShortenerCodes[0].code)",
                            to: 'firstDataModel.shortenerCode',
                          },
                          {
                            from: "resource('ICS_CONFIRMATION_TITLE').value",
                            to: 'icsAttachment.title',
                          },
                          {
                            from: 'myBookings.bookings[0].location',
                            to: 'icsAttachment.location',
                          },
                          {
                            from: 'myBookings.bookings[0].datetimeStart',
                            to: 'icsAttachment.start',
                          },
                          {
                            from: 'myBookings.bookings[0].datetimeEnd',
                            to: 'icsAttachment.end',
                          },
                          {
                            from: 'myBookings.bookings[0].onlineMeetingUrl',
                            to: 'firstDataModel.onlineMeetingUrl',
                          },
                        ],
                      },
                      {
                        progressHint: 90,
                        type: 'RemoteStep',
                        name: '__Send confirmation email again',
                        serviceName: 'SendEmail',
                        serviceParameter: {
                          to: null,
                          templateDataModel: null,
                        },
                        telemetryEvents: [
                          {
                            name: 'Confirmation email re-sent',
                            trigger: 'OnLoadStep',
                          },
                        ],
                        '[PropertyBinding]': [
                          {
                            name: 'serviceParameter.to',
                            value: 'candidate.email',
                          },
                          {
                            name: 'serviceParameter.emailTemplateId',
                            value:
                              "if(myBookings.Bookings[0].interviewType = 'online', onlineInterviewId , GetMessagingTemplateId('Interview confirmation','email','b6ca14fa-3a9e-4bf0-87e5-bc054ee07c32','Confirmation'))",
                          },
                          {
                            name: 'serviceParameter.emailLanguage',
                            value: 'GetLanguage()',
                          },
                          {
                            name: 'serviceParameter.templateDataModel',
                            value: 'firstDataModel',
                          },
                          {
                            name: 'serviceParameter.icsAttachment',
                            value: 'icsAttachment',
                          },
                          {
                            name: 'serviceParameter.tracking',
                            value:
                              "GetTrackingContext('Interview confirmation')",
                          },
                        ],
                      },
                      {
                        progressHint: 100,
                        type: 'Exit',
                        name: '__Exit with email',
                        message: 'EXITEMAIL_MSG',
                        isSuccess: true,
                        restartText: 'RESTART_MSG',
                      },
                    ],
                  },
                },
                {
                  label: 'SEND_SMS_MSG',
                  value: 'Send',
                  enabled:
                    "HasMessagingTemplate('Interview confirmation','sms')",
                  navAction: {
                    type: 'GotoSteps',
                    steps: [
                      {
                        name: '__Check sending counter sms',
                        type: 'Condition',
                        expression: 'candidate.props.sendingCounter > 3',
                        if: {
                          type: 'GotoStepByName',
                          stepName: '__Exit too many requests',
                        },
                        else: {
                          type: 'GotoSteps',
                          steps: [
                            {
                              name: '__Increase counter after sms heck',
                              type: 'DataBinding',
                              dataBindings: [
                                {
                                  from: 'add(candidate.props.sendingCounter,1)',
                                  to: 'candidate.props.sendingCounter',
                                },
                              ],
                            },
                          ],
                        },
                      },
                      {
                        name: '__Binding existing meeting to sms data model',
                        type: 'DataBinding',
                        dataBindings: [
                          {
                            from: 'myBookings.bookings[0].location',
                            to: 'secondDataModel.location.address.addressLine',
                          },
                          {
                            from: 'myBookings.bookings[0].recruter',
                            to: 'secondDataModel.recruter',
                          },
                          {
                            from: 'TimeSpanAutoFormatHoursMinutes(GetDuration(myBookings.bookings[0].datetimeStart,myBookings.bookings[0].datetimeEnd))',
                            to: 'secondDataModel.interviewDuration',
                          },
                          {
                            from: "dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dddd dd MMMM yyyy HH:mm')",
                            to: 'secondDataModel.meetingDate',
                          },
                          {
                            from: "if(or(IsNull(candidate.ShortenerCodes),Count(candidate.ShortenerCodes) = 0),'null',candidate.ShortenerCodes[0].code)",
                            to: 'secondDataModel.shortenerCode',
                          },
                          {
                            from: 'candidate.firstName',
                            to: 'secondDataModel.candidate.FirstName',
                          },
                        ],
                      },
                      {
                        type: 'RemoteStep',
                        name: '__Send confirmation sms again',
                        serviceName: 'SendSms',
                        serviceParameter: {},
                        telemetryEvents: [
                          {
                            name: '__Confirmation sms re-sent',
                            trigger: 'OnLoadStep',
                          },
                        ],
                        '[PropertyBinding]': [
                          {
                            name: 'serviceParameter.to',
                            value: 'candidate.phoneNumbers.cellPhone',
                          },
                          {
                            name: 'serviceParameter.campaignId',
                            value: 'campaign.id',
                          },
                          {
                            name: 'serviceParameter.smsTemplateId',
                            value:
                              "GetMessagingTemplateId('Interview confirmation','sms','8b85d340-10a7-4d67-a370-eb6291ff1aab', 'Confirmation')",
                          },
                          {
                            name: 'serviceParameter.smsLanguage',
                            value: 'GetLanguage()',
                          },
                          {
                            name: 'serviceParameter.templateDataModel',
                            value: 'secondDataModel',
                          },
                          {
                            name: 'serviceParameter.tracking',
                            value:
                              "GetTrackingContext('Interview confirmation')",
                          },
                        ],
                      },
                      {
                        progressHint: 100,
                        type: 'Exit',
                        name: '__Exit with sms',
                        message: 'EXITSMS_MSG',
                        isSuccess: true,
                        restartText: 'RESTART_MSG',
                      },
                    ],
                  },
                },
                {
                  label: 'CHANGE_SLOT_MSG',
                  value: 'Change',
                  navAction: {
                    type: 'GotoSteps',
                    steps: [
                      {
                        name: '__Reset counter for changing timeslot',
                        type: 'DataBinding',
                        dataBindings: [
                          {
                            from: '0',
                            to: 'candidate.props.sendingCounter',
                          },
                        ],
                      },
                      {
                        name: '__Binding former meeting to email data model',
                        type: 'DataBinding',
                        dataBindings: [
                          {
                            from: 'myBookings.bookings[0].location',
                            to: 'firstDataModel.location.address.addressLine',
                          },
                          {
                            from: 'myBookings.bookings[0].recruter',
                            to: 'firstDataModel.recruter',
                          },
                          {
                            from: 'TimeSpanAutoFormatHoursMinutes(GetDuration(myBookings.bookings[0].datetimeStart,myBookings.bookings[0].datetimeEnd))',
                            to: 'firstDataModel.interviewDuration',
                          },
                          {
                            from: "dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dddd dd MMMM yyyy HH:mm')",
                            to: 'firstDataModel.meetingDate',
                          },
                          {
                            from: "if(or(IsNull(candidate.ShortenerCodes),Count(candidate.ShortenerCodes) = 0),'null',candidate.ShortenerCodes[0].code)",
                            to: 'firstDataModel.shortenerCode',
                          },
                          {
                            from: 'candidate.firstName',
                            to: 'firstDataModel.candidate.FirstName',
                          },
                        ],
                      },
                      {
                        type: 'InvokeTemplate',
                        templateId: '838b92dd-80c4-44df-bebc-21e58370f985',
                        name: '__Call change meeting module',
                        parameters: [
                          {
                            name: 'seatId',
                            value: 'myBookings.bookings[0].seatId',
                          },
                          {
                            name: 'campaignId',
                            value: 'campaign.Id',
                          },
                          {
                            name: 'waveId',
                            value: '0',
                          },
                          {
                            name: 'candidateId',
                            value: 'candidate.Id',
                          },
                          {
                            name: 'candidateFirstName',
                            value: 'candidate.FirstName',
                          },
                          {
                            name: 'candidateLastName',
                            value: 'candidate.LastName',
                          },
                          {
                            name: 'candidateEmail',
                            value: 'candidate.email',
                          },
                          {
                            name: 'candidatePhone',
                            value: 'candidate.phoneNumbers.cellPhone',
                          },
                        ],
                        dataBindings: [
                          {
                            from: 'returnValue',
                            to: 'myBookings.bookings',
                          },
                        ],
                      },
                      {
                        name: '__Binding candidate slot update',
                        type: 'DataBinding',
                        dataBindings: [
                          {
                            to: 'candidate.Props.SlotStartDate',
                            from: 'myBookings.bookings[0].datetimeStart',
                          },
                          {
                            to: 'candidate.Props.SlotEndDate',
                            from: 'myBookings.bookings[0].datetimeEnd',
                          },
                        ],
                      },
                      {
                        type: 'Condition',
                        name: '__Check if new booking done',
                        expression:
                          'or(isnull(myBookings.bookings),Count(myBookings.bookings) = 0)',
                        if: {
                          type: 'GotoStepByName',
                          stepName: '__Booking not done',
                        },
                      },
                      {
                        type: 'RemoteStep',
                        name: '__Send cancellation email before the confirmation one',
                        executionCondition:
                          "HasMessagingTemplate('Interview cancellation','email')",
                        serviceName: 'SendEmail',
                        serviceParameter: {
                          to: null,
                          templateDataModel: null,
                        },
                        '[PropertyBinding]': [
                          {
                            name: 'serviceParameter.to',
                            value: 'candidate.email',
                          },
                          {
                            name: 'serviceParameter.emailTemplateId',
                            value:
                              "GetMessagingTemplateId('Interview cancellation','email','10a8388a-49d1-45dc-9c9f-647a71d07d17', 'Cancellation')",
                          },
                          {
                            name: 'serviceParameter.emailLanguage',
                            value: 'GetLanguage()',
                          },
                          {
                            name: 'serviceParameter.templateDataModel',
                            value: 'firstDataModel',
                          },
                          {
                            name: 'serviceParameter.tracking',
                            value:
                              "GetTrackingContext('Interview cancellation')",
                          },
                        ],
                      },
                      {
                        type: 'RemoteStep',
                        name: '__Send cancellation sms before the confirmation one',
                        executionCondition:
                          "HasMessagingTemplate('Interview cancellation','sms')",
                        serviceName: 'SendSms',
                        serviceParameter: {},
                        '[PropertyBinding]': [
                          {
                            name: 'serviceParameter.to',
                            value: 'candidate.phoneNumbers.cellPhone',
                          },
                          {
                            name: 'serviceParameter.campaignId',
                            value: 'campaign.id',
                          },
                          {
                            name: 'serviceParameter.smsTemplateId',
                            value:
                              "GetMessagingTemplateId('Interview cancellation','sms','68354681-403f-4943-ae69-f65db5cadb8d', 'Cancellation')",
                          },
                          {
                            name: 'serviceParameter.smsLanguage',
                            value: 'GetLanguage()',
                          },
                          {
                            name: 'serviceParameter.templateDataModel',
                            value: 'firstDataModel',
                          },
                          {
                            name: 'serviceParameter.tracking',
                            value:
                              "GetTrackingContext('Interview cancellation')",
                          },
                        ],
                      },
                      {
                        name: '__Binding new meeting to email data model',
                        type: 'DataBinding',
                        dataBindings: [
                          {
                            from: 'myBookings.bookings[0].location',
                            to: 'secondDataModel.location.address.addressLine',
                          },
                          {
                            from: 'myBookings.bookings[0].recruter',
                            to: 'secondDataModel.recruter',
                          },
                          {
                            from: 'TimeSpanAutoFormatHoursMinutes(GetDuration(myBookings.bookings[0].datetimeStart,myBookings.bookings[0].datetimeEnd))',
                            to: 'secondDataModel.interviewDuration',
                          },
                          {
                            from: "if(or(IsNull(candidate.ShortenerCodes),Count(candidate.ShortenerCodes) = 0),'null',candidate.ShortenerCodes[0].code)",
                            to: 'secondDataModel.shortenerCode',
                          },
                          {
                            from: "dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dddd dd MMMM yyyy HH:mm')",
                            to: 'secondDataModel.meetingDate',
                          },
                          {
                            from: 'candidate.firstName',
                            to: 'secondDataModel.candidate.FirstName',
                          },
                          {
                            from: "resource('ICS_CONFIRMATION_TITLE').value",
                            to: 'icsAttachment.title',
                          },
                          {
                            from: 'myBookings.bookings[0].location',
                            to: 'icsAttachment.location',
                          },
                          {
                            from: 'myBookings.bookings[0].datetimeStart',
                            to: 'icsAttachment.start',
                          },
                          {
                            from: 'myBookings.bookings[0].datetimeEnd',
                            to: 'icsAttachment.end',
                          },
                          {
                            from: 'myBookings.bookings[0].onlineMeetingUrl',
                            to: 'firstDataModel.onlineMeetingUrl',
                          },
                        ],
                      },
                      {
                        type: 'RemoteStep',
                        name: '__Send confirmation email after the cancellation one',
                        executionCondition:
                          "HasMessagingTemplate('Interview confirmation','email')",
                        serviceName: 'SendEmail',
                        serviceParameter: {
                          to: null,
                          templateDataModel: null,
                        },
                        '[PropertyBinding]': [
                          {
                            name: 'serviceParameter.to',
                            value: 'candidate.email',
                          },
                          {
                            name: 'serviceParameter.emailTemplateId',
                            value:
                              "GetMessagingTemplateId('Interview confirmation','email')",
                          },
                          {
                            name: 'serviceParameter.emailLanguage',
                            value: 'GetLanguage()',
                          },
                          {
                            name: 'serviceParameter.templateDataModel',
                            value: 'secondDataModel',
                          },
                          {
                            name: 'serviceParameter.icsAttachment',
                            value: 'icsAttachment',
                          },
                          {
                            name: 'serviceParameter.tracking',
                            value:
                              "GetTrackingContext('Interview confirmation')",
                          },
                        ],
                      },
                      {
                        type: 'RemoteStep',
                        name: '__Send confirmation sms after the cancellation one',
                        executionCondition:
                          "HasMessagingTemplate('Interview confirmation','sms')",
                        serviceName: 'SendSms',
                        serviceParameter: {},
                        '[PropertyBinding]': [
                          {
                            name: 'serviceParameter.to',
                            value: 'candidate.phoneNumbers.cellPhone',
                          },
                          {
                            name: 'serviceParameter.campaignId',
                            value: 'campaign.id',
                          },
                          {
                            name: 'serviceParameter.smsTemplateId',
                            value:
                              "GetMessagingTemplateId('Interview confirmation','sms')",
                          },
                          {
                            name: 'serviceParameter.smsLanguage',
                            value: 'GetLanguage()',
                          },
                          {
                            name: 'serviceParameter.templateDataModel',
                            value: 'secondDataModel',
                          },
                          {
                            name: 'serviceParameter.tracking',
                            value:
                              "GetTrackingContext('Interview confirmation')",
                          },
                        ],
                      },
                      {
                        progressHint: 100,
                        executionCondition:
                          "and(or(HasMessagingTemplate('Interview confirmation','email'),HasMessagingTemplate('Interview cancellation','email')),or(HasMessagingTemplate('Interview confirmation','sms'),HasMessagingTemplate('Interview cancellation','sms')))",
                        type: 'Exit',
                        name: '__Exit change with email and sms',
                        message: 'EXITSMSANDEMAIL_MSG',
                        isSuccess: true,
                        restartText: 'RESTART_MSG',
                      },
                      {
                        progressHint: 100,
                        executionCondition:
                          "and(not(HasMessagingTemplate('Interview confirmation','email')),not(HasMessagingTemplate('Interview cancellation','email')),or(HasMessagingTemplate('Interview confirmation','sms'),HasMessagingTemplate('Interview cancellation','sms')))",
                        type: 'Exit',
                        name: '__Exit change with sms',
                        message: 'EXITSMS_MSG',
                        isSuccess: true,
                        restartText: 'RESTART_MSG',
                      },
                      {
                        progressHint: 100,
                        executionCondition:
                          "and(or(HasMessagingTemplate('Interview confirmation','email'),HasMessagingTemplate('Interview cancellation','email')),not(HasMessagingTemplate('Interview confirmation','sms')),not(HasMessagingTemplate('Interview cancellation','sms')))",
                        type: 'Exit',
                        name: '__Exit change with email',
                        message: 'EXITEMAIL_MSG',
                        isSuccess: true,
                        restartText: 'RESTART_MSG',
                      },
                      {
                        progressHint: 100,
                        type: 'Exit',
                        name: '__Exit change without email or sms',
                        message: 'EXIT_SIMPLE_MSG',
                        isSuccess: true,
                        restartText: 'RESTART_MSG',
                      },
                    ],
                  },
                },
                {
                  label: 'CANCEL_SLOT_MSG',
                  value: 'Cancel',
                  navAction: {
                    type: 'GotoSteps',
                    steps: [
                      {
                        name: '__Reset counter for cancelling timeslot',
                        type: 'DataBinding',
                        dataBindings: [
                          {
                            from: '0',
                            to: 'candidate.props.sendingCounter',
                          },
                        ],
                      },
                      {
                        type: 'InvokeTemplate',
                        templateId: '96c44960-c517-455c-9f74-7bd344daf01c',
                        name: '__Call cancel meeting module',
                        parameters: [
                          {
                            name: 'seatId',
                            value: 'myBookings.Bookings[0].seatId',
                          },
                        ],
                        dataBindings: [
                          {
                            from: 'returnValue',
                            to: 'isCancellationSuccess',
                          },
                        ],
                      },
                      {
                        name: '__Binding candidate slot cancel',
                        type: 'DataBinding',
                        dataBindings: [
                          {
                            to: 'candidate.Props.SlotStartDate',
                            from: 'null',
                          },
                          {
                            to: 'candidate.Props.SlotEndDate',
                            from: 'null',
                          },
                        ],
                      },
                      {
                        name: '__Binding canceled current meeting to email data model',
                        type: 'DataBinding',
                        dataBindings: [
                          {
                            from: 'myBookings.bookings[0].location',
                            to: 'firstDataModel.location.address.addressLine',
                          },
                          {
                            from: 'myBookings.bookings[0].recruter',
                            to: 'firstDataModel.recruter',
                          },
                          {
                            from: 'TimeSpanAutoFormatHoursMinutes(GetDuration(myBookings.bookings[0].datetimeStart,myBookings.bookings[0].datetimeEnd))',
                            to: 'firstDataModel.interviewDuration',
                          },
                          {
                            from: "dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dddd dd MMMM yyyy HH:mm')",
                            to: 'firstDataModel.meetingDate',
                          },
                          {
                            from: "if(or(IsNull(candidate.ShortenerCodes),Count(candidate.ShortenerCodes) = 0),'null',candidate.ShortenerCodes[0].code)",
                            to: 'firstDataModel.shortenerCode',
                          },
                          {
                            from: 'candidate.firstName',
                            to: 'firstDataModel.candidate.FirstName',
                          },
                        ],
                      },
                      {
                        progressHint: 90,
                        type: 'RemoteStep',
                        name: '__Send cancellation email',
                        executionCondition:
                          "HasMessagingTemplate('Interview cancellation','email')",
                        serviceName: 'SendEmail',
                        serviceParameter: {
                          to: null,
                          templateDataModel: null,
                        },
                        '[PropertyBinding]': [
                          {
                            name: 'serviceParameter.to',
                            value: 'candidate.email',
                          },
                          {
                            name: 'serviceParameter.emailTemplateId',
                            value:
                              "GetMessagingTemplateId('Interview cancellation','email','10a8388a-49d1-45dc-9c9f-647a71d07d17', 'Cancellation')",
                          },
                          {
                            name: 'serviceParameter.emailLanguage',
                            value: 'GetLanguage()',
                          },
                          {
                            name: 'serviceParameter.templateDataModel',
                            value: 'firstDataModel',
                          },
                          {
                            name: 'serviceParameter.tracking',
                            value:
                              "GetTrackingContext('Interview cancellation')",
                          },
                        ],
                      },
                      {
                        type: 'RemoteStep',
                        name: '__Send cancellation sms',
                        executionCondition:
                          "HasMessagingTemplate('Interview cancellation','sms')",
                        serviceName: 'SendSms',
                        serviceParameter: {},
                        '[PropertyBinding]': [
                          {
                            name: 'serviceParameter.to',
                            value: 'candidate.phoneNumbers.cellPhone',
                          },
                          {
                            name: 'serviceParameter.campaignId',
                            value: 'campaign.id',
                          },
                          {
                            name: 'serviceParameter.smsTemplateId',
                            value:
                              "GetMessagingTemplateId('Interview cancellation','sms','68354681-403f-4943-ae69-f65db5cadb8d', 'Cancellation')",
                          },
                          {
                            name: 'serviceParameter.smsLanguage',
                            value: 'GetLanguage()',
                          },
                          {
                            name: 'serviceParameter.templateDataModel',
                            value: 'firstDataModel',
                          },
                          {
                            name: 'serviceParameter.tracking',
                            value:
                              "GetTrackingContext('Interview cancellation')",
                          },
                        ],
                      },
                      {
                        progressHint: 90,
                        type: 'Message',
                        name: '__Cancel ok warning',
                        message: 'CANCEL_OK',
                      },
                      {
                        progressHint: 100,
                        executionCondition:
                          "and(HasMessagingTemplate('Interview cancellation','email'),HasMessagingTemplate('Interview cancellation','sms'))",
                        type: 'Exit',
                        name: '__Exit cancel with email and sms',
                        message: 'EXITSMSANDEMAIL_MSG',
                        isSuccess: true,
                        restartText: 'RESTART_MSG',
                      },
                      {
                        progressHint: 100,
                        executionCondition:
                          "and(not(HasMessagingTemplate('Interview cancellation','email')),HasMessagingTemplate('Interview cancellation','sms'))",
                        type: 'Exit',
                        name: '__Exit cancel with sms',
                        message: 'EXITSMS_MSG',
                        isSuccess: true,
                        restartText: 'RESTART_MSG',
                      },
                      {
                        progressHint: 100,
                        executionCondition:
                          "and(HasMessagingTemplate('Interview cancellation','email'),not(HasMessagingTemplate('Interview cancellation','sms')))",
                        type: 'Exit',
                        name: '__Exit cancel with email',
                        message: 'EXITEMAIL_MSG',
                        isSuccess: true,
                        restartText: 'RESTART_MSG',
                      },
                      {
                        progressHint: 100,
                        type: 'Exit',
                        name: '__Exit cancel without email or sms',
                        message: 'EXIT_SIMPLE_MSG',
                        isSuccess: true,
                        restartText: 'RESTART_MSG',
                      },
                    ],
                  },
                },
              ],
            },
            outOfRangeMessage: 'CHOOSE_IN_LIST',
          },
        ],
      },
      else: {
        type: 'GotoSteps',
        steps: [
          ...template.steps,
          {
            name: '__Warning email',
            type: 'Message',
            message: 'WARNINGEMAIL_MSG',
          },
          {
            progressHint: 20,
            executionCondition: 'candidate.email != null',
            name: '__Confirm e-mail address',
            type: 'Question',
            question: 'CONFIRMEMAIL_MSG',
            suggestions: ['YES_MSG', 'NO_MSG'],
            answers: [
              {
                parser: {
                  type: 'YesNo',
                  expect: 'No',
                },
                action: {
                  type: 'GotoNextStep',
                },
              },
              {
                parser: {
                  type: 'YesNo',
                  expect: 'Yes',
                },
                action: {
                  type: 'GotoStepByName',
                  stepName: '__Call booking meeting module',
                },
              },
            ],
            defaultAction: {
              message: 'GENERIC_REPLY_MSG',
              action: {
                type: 'RepeatStep',
              },
            },
          },
          {
            progressHint: 30,
            name: '__Ask e-mail address',
            type: 'Question',
            question: 'ASK_EMAIL',
            answers: [
              {
                parser: {
                  type: 'Email',
                },
                dataBindings: [
                  {
                    from: 'lastAnswer.data',
                    to: 'candidate.email',
                  },
                ],
              },
            ],
            defaultAction: {
              message: 'WRONG_EMAIL',
              action: {
                type: 'RepeatStep',
              },
            },
          },
          {
            progressHint: 40,
            type: 'InvokeTemplate',
            templateId: 'a157dc3f-0799-4ee3-9603-249d8a43ba4a',
            name: '__Call booking meeting module',
            parameters: [
              {
                name: 'campaignId',
                value: 'campaign.Id',
              },
              {
                name: 'waveId',
                value: '0',
              },
              {
                name: 'candidateId',
                value: 'candidate.Id',
              },
              {
                name: 'candidateFirstName',
                value: 'candidate.FirstName',
              },
              {
                name: 'candidateLastName',
                value: 'candidate.LastName',
              },
              {
                name: 'candidateEmail',
                value: 'candidate.email',
              },
              {
                name: 'candidatePhone',
                value: 'candidate.phoneNumbers.cellPhone',
              },
            ],
            dataBindings: [
              {
                from: 'returnValue',
                to: 'myBookings.bookings',
              },
            ],
          },
          {
            progressHint: 80,
            type: 'Condition',
            name: '__Check if booking done',
            expression:
              'or(isnull(myBookings.bookings),Count(myBookings.bookings) = 0)',
            if: {
              type: 'GotoStepByName',
              stepName: '__Booking not done',
            },
          },
          {
            progressHint: 80,
            name: '__Binding candidate slot',
            type: 'DataBinding',
            dataBindings: [
              {
                to: 'candidate.Props.SlotStartDate',
                from: 'myBookings.bookings[0].datetimeStart',
              },
              {
                to: 'candidate.Props.SlotEndDate',
                from: 'myBookings.bookings[0].datetimeEnd',
              },
            ],
          },
          {
            progressHint: 80,
            name: '__Binding Meeting to email data model',
            type: 'DataBinding',
            dataBindings: [
              {
                from: 'myBookings.bookings[0].location',
                to: 'firstDataModel.location.address.addressLine',
              },
              {
                from: 'myBookings.bookings[0].recruter',
                to: 'firstDataModel.recruter',
              },
              {
                from: 'TimeSpanAutoFormatHoursMinutes(GetDuration(myBookings.bookings[0].datetimeStart,myBookings.bookings[0].datetimeEnd))',
                to: 'firstDataModel.interviewDuration',
              },
              {
                from: "dateFormat(ToLocalTime(myBookings.bookings[0].datetimeStart),'dddd dd MMMM yyyy HH:mm')",
                to: 'firstDataModel.meetingDate',
              },
              {
                from: "if(or(IsNull(candidate.ShortenerCodes),Count(candidate.ShortenerCodes) = 0),'null',candidate.ShortenerCodes[0].code)",
                to: 'firstDataModel.shortenerCode',
              },
              {
                from: 'candidate.firstName',
                to: 'firstDataModel.candidate.FirstName',
              },
              {
                from: "resource('ICS_CONFIRMATION_TITLE').value",
                to: 'icsAttachment.title',
              },
              {
                from: 'myBookings.bookings[0].location',
                to: 'icsAttachment.location',
              },
              {
                from: 'myBookings.bookings[0].datetimeStart',
                to: 'icsAttachment.start',
              },
              {
                from: 'myBookings.bookings[0].datetimeEnd',
                to: 'icsAttachment.end',
              },
              {
                from: 'myBookings.bookings[0].onlineMeetingUrl',
                to: 'firstDataModel.onlineMeetingUrl',
              },
            ],
          },
          {
            progressHint: 90,
            type: 'RemoteStep',
            executionCondition:
              "HasMessagingTemplate('Interview confirmation','email')",
            name: '__Send confirmation email',
            serviceName: 'SendEmail',
            serviceParameter: {
              to: null,
              templateDataModel: null,
            },
            '[PropertyBinding]': [
              {
                name: 'serviceParameter.to',
                value: 'candidate.email',
              },
              {
                name: 'serviceParameter.emailTemplateId',
                value:
                  "if(myBookings.Bookings[0].interviewType = 'online', onlineInterviewId , GetMessagingTemplateId('Interview confirmation','email','b6ca14fa-3a9e-4bf0-87e5-bc054ee07c32','Confirmation'))",
              },
              {
                name: 'serviceParameter.emailLanguage',
                value: 'GetLanguage()',
              },
              {
                name: 'serviceParameter.templateDataModel',
                value: 'firstDataModel',
              },
              {
                name: 'serviceParameter.icsAttachment',
                value: 'icsAttachment',
              },
              {
                name: 'serviceParameter.tracking',
                value: "GetTrackingContext('Interview confirmation')",
              },
            ],
          },
          {
            type: 'RemoteStep',
            name: '__Send confirmation sms',
            executionCondition:
              "HasMessagingTemplate('Interview confirmation','sms')",
            serviceName: 'SendSms',
            serviceParameter: {},
            '[PropertyBinding]': [
              {
                name: 'serviceParameter.to',
                value: 'candidate.phoneNumbers.cellPhone',
              },
              {
                name: 'serviceParameter.campaignId',
                value: 'campaign.id',
              },
              {
                name: 'serviceParameter.smsTemplateId',
                value:
                  "GetMessagingTemplateId('Interview confirmation','sms','8b85d340-10a7-4d67-a370-eb6291ff1aab', 'Confirmation')",
              },
              {
                name: 'serviceParameter.smsLanguage',
                value: 'GetLanguage()',
              },
              {
                name: 'serviceParameter.templateDataModel',
                value: 'firstDataModel',
              },
              {
                name: 'serviceParameter.tracking',
                value: "GetTrackingContext('Interview confirmation')",
              },
            ],
          },
          {
            progressHint: 100,
            executionCondition:
              "and(HasMessagingTemplate('Interview confirmation','email'),HasMessagingTemplate('Interview confirmation','sms'))",
            type: 'Exit',
            name: '__Exit booking with email and sms',
            message: 'EXITSMSANDEMAIL_MSG',
            isSuccess: true,
            restartText: 'RESTART_MSG',
          },
          {
            progressHint: 100,
            executionCondition:
              "and(not(HasMessagingTemplate('Interview confirmation','email')),HasMessagingTemplate('Interview confirmation','sms'))",
            type: 'Exit',
            name: '__Exit booking with sms',
            message: 'EXITSMS_MSG',
            isSuccess: true,
            restartText: 'RESTART_MSG',
          },
          {
            progressHint: 100,
            executionCondition:
              "and(HasMessagingTemplate('Interview confirmation','email'),not(HasMessagingTemplate('Interview confirmation','sms')))",
            type: 'Exit',
            name: '__Exit booking with email',
            message: 'EXITEMAIL_MSG',
            isSuccess: true,
            restartText: 'RESTART_MSG',
          },
          {
            progressHint: 100,
            type: 'Exit',
            name: '__Exit booking without email or sms',
            message: 'EXIT_SIMPLE_MSG',
            isSuccess: true,
            restartText: 'RESTART_MSG',
          },
        ],
      },
    },
    {
      progressHint: 100,
      type: 'DataBinding',
      name: '__Booking not done',
      dataBindings: [
        {
          to: 'candidate.Props.NoMoreTimeSlot',
          from: 'true',
        },
      ],
      message: 'NO_SLOT_AVAILABLE',
    },
    {
      progressHint: 100,
      type: 'Exit',
      name: '__Booking not done exit',
      message: 'NO_SLOT_AVAILABLE',
      isSuccess: false,
      restartText: 'RESTART_MSG',
    },
    {
      name: '__Exit too many requests',
      type: 'Exit',
      message: 'TOO_MANY_REQUEST_MSG',
      isSuccess: false,
      restartText: 'RESTART_MSG',
    },
  ];

  template.languageResources.resources = [
    ...template.languageResources.resources,
    ...schedulingTextResources,
  ];

  return template;
};

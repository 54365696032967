import { v4 as uuid } from 'uuid';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ConversationDesignerNode,
  NodeType,
} from '@appTypes/Conversation.types';

const findNode = (nodes, id) => {
  for (const node of nodes) {
    if (node.id === id) {
      return node;
    }
    if (node.children) {
      const found = findNode(node.children, id);
      if (found) {
        return found;
      }
    }
  }
  return null;
};

const deleteNode = (nodes, id) => {
  nodes?.forEach((node, index) => {
    if (node.id === id) {
      nodes.splice(index, 1);
      return;
    }
    if (node.children) {
      deleteNode(node.children, id);
    }
  });
};

interface IConversationDesignerSliceValues {
  designerSettings: {
    conversationName?: string;
    defaultLanguage?: string;
    languages?: string[];
    conversationType?: string;
  };
  treeData: ConversationDesignerNode[];
  formValues: any;
  undoStack: {
    treeData: ConversationDesignerNode[];
    formValues?: any;
  }[];
  redoStack: {
    treeData: ConversationDesignerNode[];
    formValues?: any;
  }[];
}

const initialState: IConversationDesignerSliceValues = {
  designerSettings: {
    conversationName: '',
    defaultLanguage: '',
    languages: [],
  },
  formValues: {},
  treeData: [],
  undoStack: [],
  redoStack: [],
};

const conversationDesignerSlice = createSlice({
  name: 'conversationDesigner',
  initialState,
  reducers: {
    setDesignerSettings(state, action: PayloadAction<any>) {
      state.designerSettings = action.payload;
    },
    initializeTreeData(
      state,
      action: PayloadAction<ConversationDesignerNode[]>
    ) {
      state.treeData = action.payload;
    },
    setTreeData(state, action: PayloadAction<ConversationDesignerNode[]>) {
      state.treeData = action.payload;
      state.undoStack.push({ treeData: action.payload });
    },
    addNode(
      state,
      action: PayloadAction<{ parentId: string; type: NodeType }>
    ) {
      state.undoStack.push({ treeData: state.treeData });
      const { parentId, type } = action.payload;
      const parent = findNode(state.treeData, parentId);
      parent.children.push({ id: uuid(), type, children: [] });
    },
    removeNode(state, action: PayloadAction<ConversationDesignerNode>) {
      state.undoStack.push({
        treeData: state.treeData,
      });
      const nodeId = action.payload;
      deleteNode(state.treeData, nodeId);
    },
    undo: (state) => {
      const lastAction = state.undoStack.pop();
      const currentAction = state.undoStack[state.undoStack.length - 1];
      state.redoStack.push({
        treeData: lastAction.treeData,
        formValues: lastAction.formValues,
      });
      state.treeData = currentAction?.treeData || [];
      state.formValues = currentAction?.formValues || {};
    },
    redo: (state) => {
      const lastAction = state.redoStack.pop();
      if (lastAction) {
        state.undoStack.push({
          treeData: lastAction.treeData,
          formValues: lastAction.formValues,
        });
        state.treeData = lastAction.treeData || [];
        state.formValues = lastAction.formValues || {};
      }
    },
    persistFormValues: (state, action: PayloadAction<any>) => {
      state.formValues = action.payload;
      state.undoStack[state.undoStack.length - 1] = {
        ...state.undoStack[state.undoStack.length - 1],
        formValues: action.payload,
      };
    },
    resetState: (state) => {
      state.designerSettings = initialState.designerSettings;
      state.treeData = initialState.treeData;
      state.formValues = initialState.formValues;
      state.undoStack = initialState.undoStack;
      state.redoStack = initialState.redoStack;
    },
  },
});

export const {
  setDesignerSettings,
  initializeTreeData,
  setTreeData,
  addNode,
  removeNode,
  undo,
  redo,
  persistFormValues,
  resetState,
} = conversationDesignerSlice.actions;

export default conversationDesignerSlice.reducer;

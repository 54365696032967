import { Notification, NotificationHeader } from '@adeccoux/tag-ds';
import { Handle, Position } from '@xyflow/react';
import TurnSlightRightOutlinedIcon from '@mui/icons-material/TurnSlightRightOutlined';

const Navigate = ({ data }) => {
  const { label } = data;

  return (
    <div className="tag-ds">
      <Handle type="target" position={Position.Top} style={{ opacity: 0 }} />
      <Notification icon={<TurnSlightRightOutlinedIcon />} info>
        <NotificationHeader>{label}</NotificationHeader>
      </Notification>
      <Handle type="source" position={Position.Bottom} style={{ opacity: 0 }} />
    </div>
  );
};

export default Navigate;

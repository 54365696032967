import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { persistor, RootState } from '@redux/store';
import Grid, { Row } from '@common/Grid';
import tagStyles from '@common/assets/styles/main.scss';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { resetState } from '@redux/reducers/conversationDesignerReducer';
import {
  useSaveConversationDesignerItemsMutation,
  useUpdateStoredConversationDesignerItemsMutation,
} from '@redux/api/conversationApiSlice';

import styles from './styles.module.scss';
import { getGeneratorData } from '../utils';

const SaveDialog = ({ onClose }) => {
  const { t } = useTranslation('conversationDesigner');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: designerId } = useParams();
  const { getValues, reset } = useFormContext();

  const { treeData, designerSettings } = useSelector(
    (store: RootState) => store.conversationDesignerStore
  );

  const [
    saveConversationDesignerItems,
    { isLoading: iseSaveConversationDesignerItemsLoading },
  ] = useSaveConversationDesignerItemsMutation();

  const [
    updateStoredConversationDesignerItems,
    { isLoading: isUpdateStoredConversationDesignerItemsLoading },
  ] = useUpdateStoredConversationDesignerItemsMutation();

  const onSave = async () => {
    const generatorData = getGeneratorData(
      treeData,
      getValues(),
      designerSettings
    );

    if (designerId) {
      await updateStoredConversationDesignerItems({
        id: designerId,
        name: generatorData.name,
        content: generatorData,
      });
    } else {
      await saveConversationDesignerItems({
        name: generatorData.name,
        content: generatorData,
      }).unwrap();
    }
    onDiscard();
  };

  const onDiscard = async () => {
    
    await persistor.flush();
    await persistor.purge();
    reset();
    dispatch(resetState());
    onClose();
    navigate(`/designer`, { replace: true });
  };

  if (
    iseSaveConversationDesignerItemsLoading ||
    isUpdateStoredConversationDesignerItemsLoading
  ) {
  }

  return (
    <Grid>
      <div className={styles.saveDialog}>
        <div className={styles.modalWrapper}>
          <div
            className={cn(
              tagStyles.tagDs,
              tagStyles.modal,
              tagStyles.docs,
              styles.modal
            )}
          >
            <Row marginBottom={16}>
              <h3>{t('saveDialog.title')}</h3>
              <button
                id="close"
                type="button"
                className={tagStyles.modalCloseButton}
                onClick={onClose}
              >
                <CloseOutlinedIcon />
              </button>
            </Row>
            <>
              <Row marginBottom={32}>{t('saveDialog.description')}</Row>
              <Row>
                <div className={styles.buttonBar}>
                  <button
                    onClick={onDiscard}
                    className={tagStyles.buttonSecondary}
                  >
                    {t('common:discard')}
                  </button>
                  <button onClick={onSave}>{t('common:save')}</button>
                </div>
              </Row>
            </>
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default SaveDialog;

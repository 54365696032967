import { useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useFormState, useWatch } from 'react-hook-form';
import parse from 'html-react-parser';
import { parseMarkdownText } from '@common/utils';
import Input from '@common/reactHookForm/Input';
import RichTextEditor from '@common/reactHookForm/RichTextEditor';
import Checkbox from '@common/reactHookForm/Checkbox';
import tagStyles from '@common/assets/styles/main.scss';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import {
  addNode,
  removeNode,
} from '@redux/reducers/conversationDesignerReducer';
import { NodeType } from '@appTypes/Conversation.types';

import styles from './styles.module.scss';
import { Nodes } from './constants';
import { useConversationDesignerContext } from './ConversationDesignerContext';
import ItemSettingsDialog from './ItemSettingsDialog';

const DesignerNode = ({ id, type, handleProps, isDragging }) => {
  const { t } = useTranslation('conversationDesigner', {
    keyPrefix: 'designerNode',
  });
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isSettingsDialogOpen, setSettingsDialogOpen] = useState(false);
  const { selectedLanguage } = useConversationDesignerContext();
  const message = useWatch({ name: `${id}.message` });
  const { errors }: any = useFormState();

  const getErrorMessage = () => {
    if (errors?.[id]?.name?.message) {
      return errors?.[id]?.name?.message;
    }

    if (errors?.[id]?.message && errors?.[id]?.message?.message) {
      return errors?.[id]?.message?.message;
    } else if (
      errors?.[id]?.message &&
      Object.keys(errors?.[id]?.message)?.length > 0
    ) {
      return errors?.[id]?.message?.[Object.keys(errors?.[id]?.message)?.[0]]
        ?.message;
    } else {
      return '';
    }
  };

  const nodeSettings = Nodes[type];

  const onAdd = () => {
    dispatch(
      addNode({ parentId: id, type: NodeType.MultipleChoiceAnswerChoice })
    );
  };

  const onDelete = () => dispatch(removeNode(id));

  if (
    type === NodeType.Condition ||
    type === NodeType.IfCondition ||
    type === NodeType.ElseCondition
  ) {
    return (
      <ConditionNode
        id={id}
        type={type}
        handleProps={handleProps}
        getErrorMessage={getErrorMessage}
      />
    );
  }
  if (type === NodeType.DataBinding) {
    return (
      <DataBindingNode
        id={id}
        type={type}
        handleProps={handleProps}
        getErrorMessage={getErrorMessage}
      />
    );
  }

  if (type === NodeType.PersonalDataQuestion) {
    return (
      <PersonalDataQuestionNode
        id={id}
        type={type}
        handleProps={handleProps}
        isDragging={isDragging}
        getErrorMessage={getErrorMessage}
      />
    );
  }

  if (!isOpen) {
    return (
      <>
        {isSettingsDialogOpen && (
          <ItemSettingsDialog
            nodeId={id}
            onClose={() => setSettingsDialogOpen(false)}
          />
        )}
        <div className={styles.designerNode}>
          <div
            className={styles.accent}
            style={{ background: nodeSettings.color }}
          />

          <div className={styles.body}>
            {errors?.[id] && (
              <div className={styles.errorMsg}>
                <WarningOutlinedIcon />
                {getErrorMessage()}
              </div>
            )}
            <div className={styles.title}>
              {nodeSettings.isModifiable && (
                <button
                  className={styles.dragIndicator}
                  type="button"
                  {...handleProps}
                >
                  <DragIndicatorIcon />
                </button>
              )}
              <div className={tagStyles.caption}>
                {parse(t(nodeSettings.label))}
              </div>
            </div>
            <div className={styles.previewText}>
              {!isDragging &&
                parse(parseMarkdownText(message?.[selectedLanguage] || ''))}
            </div>
            <div className={styles.buttonBar}>
              {nodeSettings.hasSettings && (
                <a onClick={() => setSettingsDialogOpen(true)}>
                  <SettingsOutlinedIcon /> {t('settings')}
                </a>
              )}
              {type === NodeType.MultipleChoiceQuestion && (
                <a onClick={onAdd}>
                  <AddOutlinedIcon /> {t('addOption')}
                </a>
              )}
              <a onClick={() => setIsOpen(true)}>
                <EditOutlinedIcon /> {t('edit')}
              </a>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {isSettingsDialogOpen && (
        <ItemSettingsDialog
          nodeId={id}
          onClose={() => setSettingsDialogOpen(false)}
        />
      )}
      <div className={cn(styles.designerNode, styles.open)}>
        <div
          className={styles.accent}
          style={{ background: nodeSettings.color }}
        />
        <div className={styles.body}>
          {nodeSettings.isModifiable && (
            <div className={styles.titleBar}>
              <div className={styles.title}>
                <button
                  className={styles.dragIndicator}
                  type="button"
                  {...handleProps}
                >
                  <DragIndicatorIcon />
                </button>
                <div className={tagStyles.caption}>
                  {parse(t(nodeSettings.label))}
                </div>
              </div>
            </div>
          )}
          {nodeSettings?.hasBlockName && (
            <Input name={`${id}.name`} label={t('blockName')} />
          )}
          <RichTextEditor
            key={selectedLanguage}
            name={`${id}.message.${selectedLanguage}`}
            label={parse(t(nodeSettings.label))}
          />
          <div className={styles.controls}>
            <a onClick={() => setIsOpen(false)}>
              <CheckOutlinedIcon /> {t('save')}
            </a>
            {type === NodeType.MultipleChoiceQuestion && (
              <a onClick={onAdd}>
                <AddOutlinedIcon /> {t('addOption')}
              </a>
            )}
            {nodeSettings.hasSettings && (
              <a onClick={() => setSettingsDialogOpen(true)}>
                <SettingsOutlinedIcon /> {t('settings')}
              </a>
            )}
            {nodeSettings.isModifiable && (
              <a onClick={onDelete} className={styles.error}>
                <DeleteOutlineOutlinedIcon /> {t('delete')}
              </a>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const ConditionNode = ({ id, type, handleProps, getErrorMessage }) => {
  const { t } = useTranslation('conversationDesigner', {
    keyPrefix: 'designerNode',
  });
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const { selectedLanguage } = useConversationDesignerContext();
  const message = useWatch({ name: `${id}.message` });
  const { errors }: any = useFormState();

  const nodeSettings = Nodes[type];

  const onDelete = () => dispatch(removeNode(id));

  if (!isOpen) {
    return (
      <div className={styles.designerNode}>
        <div
          className={styles.accent}
          style={{ background: nodeSettings.color }}
        />

        <div className={styles.body}>
          {errors?.[id] && (
            <div className={styles.errorMsg}>
              <WarningOutlinedIcon />
              {getErrorMessage()}
            </div>
          )}
          <div className={styles.title}>
            {nodeSettings.isModifiable && (
              <button
                className={styles.dragIndicator}
                type="button"
                {...handleProps}
              >
                <DragIndicatorIcon />
              </button>
            )}
            <div className={tagStyles.caption}>
              {parse(t(nodeSettings.label))}
            </div>
          </div>
          <div className={styles.previewText}>{message || ''}</div>
          <div className={styles.buttonBar}>
            <a onClick={() => setIsOpen(true)}>
              <EditOutlinedIcon /> {t('edit')}
            </a>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={cn(styles.designerNode, styles.open)}>
      <div
        className={styles.accent}
        style={{ background: nodeSettings.color }}
      />
      <div className={styles.body}>
        {nodeSettings.isModifiable && (
          <div className={styles.titleBar}>
            <div className={styles.title}>
              <button
                className={styles.dragIndicator}
                type="button"
                {...handleProps}
              >
                <DragIndicatorIcon />
              </button>
              <div className={tagStyles.caption}>
                {parse(t(nodeSettings.label))}
              </div>
            </div>
          </div>
        )}
        {nodeSettings?.hasBlockName && (
          <Input name={`${id}.name`} label={t('blockName')} />
        )}
        <Input
          key={selectedLanguage}
          name={`${id}.message`}
          label={
            nodeSettings.label === 'Condition'
              ? t('designerNode.message')
              : parse(t(nodeSettings.label))
          }
        />
        <div className={styles.controls}>
          <a onClick={() => setIsOpen(false)}>
            <CheckOutlinedIcon /> {t('save')}
          </a>
          {nodeSettings.isModifiable && (
            <a onClick={onDelete} className={styles.error}>
              <DeleteOutlineOutlinedIcon /> {t('delete')}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

const DataBindingNode = ({ id, type, handleProps, getErrorMessage }) => {
  const { t } = useTranslation('conversationDesigner', {
    keyPrefix: 'designerNode',
  });
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const messageFrom = useWatch({ name: `${id}.message.from` });
  const messageTo = useWatch({ name: `${id}.message.to` });
  const { errors }: any = useFormState();

  const nodeSettings = Nodes[type];

  const onDelete = () => dispatch(removeNode(id));

  if (!isOpen) {
    return (
      <div className={styles.designerNode}>
        <div
          className={styles.accent}
          style={{ background: nodeSettings.color }}
        />
        <div className={styles.body}>
          {errors?.[id] && (
            <div className={styles.errorMsg}>
              <WarningOutlinedIcon />
              {getErrorMessage()}
            </div>
          )}
          <div className={styles.title}>
            <button
              className={styles.dragIndicator}
              type="button"
              {...handleProps}
            >
              <DragIndicatorIcon />
            </button>
            <div className={tagStyles.caption}>
              {parse(t(nodeSettings.label))}
            </div>
          </div>
          <div className={styles.previewText}>
            {messageFrom && parse(`<b>${t('from')}: </b>${messageFrom}` || '')}
          </div>
          <div className={styles.previewText}>
            {messageTo && parse(`<b>${t('to')}: </b>${messageTo}` || '')}
          </div>
          <div className={styles.buttonBar}>
            <a onClick={() => setIsOpen(true)}>
              <EditOutlinedIcon /> {t('edit')}
            </a>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={cn(styles.designerNode, styles.open)}>
      <div
        className={styles.accent}
        style={{ background: nodeSettings.color }}
      />
      <div className={styles.body}>
        <div className={styles.titleBar}>
          <div className={styles.title}>
            <button
              className={styles.dragIndicator}
              type="button"
              {...handleProps}
            >
              <DragIndicatorIcon />
            </button>
            <div className={tagStyles.caption}>
              {parse(t(nodeSettings.label))}
            </div>
          </div>
        </div>
        <Input name={`${id}.name`} label="" />
        <Input
          name={`${id}.message.from`}
          label={t('from')}
          placeholder={t('enterExpression')}
        />
        <Input
          name={`${id}.message.to`}
          label={t('to')}
          placeholder={t('enterExpression')}
        />
        <div className={styles.controls}>
          <a onClick={() => setIsOpen(false)}>
            <CheckOutlinedIcon /> {t('save')}
          </a>
          <a onClick={onDelete} className={styles.error}>
            <DeleteOutlineOutlinedIcon /> {t('delete')}
          </a>
        </div>
      </div>
    </div>
  );
};

const PersonalDataQuestionNode = ({
  id,
  type,
  handleProps,
  isDragging,
  getErrorMessage,
}) => {
  const { t } = useTranslation('conversationDesigner', {
    keyPrefix: 'designerNode',
  });
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const { selectedLanguage } = useConversationDesignerContext();
  const { errors }: any = useFormState();

  const nodeSettings = Nodes[type];

  const firstNameEnabled = useWatch({
    name: `${id}.instanceData.firstNameEnabled`,
  });
  const lastNameEnabled = useWatch({
    name: `${id}.instanceData.lastNameEnabled`,
  });
  const emailAddressEnabled = useWatch({
    name: `${id}.instanceData.emailAddressEnabled`,
  });
  const mobileNumberEnabled = useWatch({
    name: `${id}.instanceData.mobileNumberEnabled`,
  });
  const firstNameMessage = useWatch({
    name: `${id}.message.firstName`,
  });
  const lastNameMessage = useWatch({
    name: `${id}.message.lastName`,
  });
  const emailAddressMessage = useWatch({
    name: `${id}.message.emailAddress`,
  });
  const mobileNumberMessage = useWatch({
    name: `${id}.message.mobileNumber`,
  });

  const onDelete = () => dispatch(removeNode(id));

  if (!isOpen) {
    return (
      <div className={styles.designerNode}>
        <div
          className={styles.accent}
          style={{ background: nodeSettings.color }}
        />
        <div className={styles.body}>
          {errors?.[id] && (
            <div className={styles.errorMsg}>
              <WarningOutlinedIcon />
              {getErrorMessage() ||
                (errors?.[id] &&
                  errors?.[id]?.message &&
                  Object.values(Object.values(errors?.[id]?.message)?.[0])?.[0]
                    ?.message)}
            </div>
          )}
          <div className={styles.title}>
            <button
              className={styles.dragIndicator}
              type="button"
              {...handleProps}
            >
              <DragIndicatorIcon />
            </button>
            <div className={tagStyles.caption}>
              {parse(t(nodeSettings.label))}
            </div>
          </div>
          <div className={styles.previewText}>
            {!isDragging &&
              firstNameEnabled &&
              firstNameMessage?.[selectedLanguage] && (
                <li>
                  {parse(
                    parseMarkdownText(firstNameMessage?.[selectedLanguage])
                  )}
                </li>
              )}
            {!isDragging &&
              lastNameEnabled &&
              lastNameMessage?.[selectedLanguage] && (
                <li>
                  {parse(
                    parseMarkdownText(lastNameMessage?.[selectedLanguage])
                  )}
                </li>
              )}
            {!isDragging &&
              emailAddressEnabled &&
              emailAddressMessage?.[selectedLanguage] && (
                <li>
                  {parse(
                    parseMarkdownText(emailAddressMessage?.[selectedLanguage])
                  )}
                </li>
              )}
            {!isDragging &&
              mobileNumberEnabled &&
              mobileNumberMessage?.[selectedLanguage] && (
                <li>
                  {parse(
                    parseMarkdownText(mobileNumberMessage?.[selectedLanguage])
                  )}
                </li>
              )}
          </div>
          <div className={styles.buttonBar}>
            <a onClick={() => setIsOpen(true)}>
              <EditOutlinedIcon /> {t('edit')}
            </a>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={cn(styles.designerNode, styles.open)}>
      <div
        className={styles.accent}
        style={{ background: nodeSettings.color }}
      />
      <div className={styles.body}>
        <div className={styles.titleBar}>
          <div className={styles.title}>
            <button
              className={styles.dragIndicator}
              type="button"
              {...handleProps}
            >
              <DragIndicatorIcon />
            </button>
            <div className={tagStyles.caption}>
              {parse(t(nodeSettings.label))}
            </div>
          </div>
        </div>
        <Input name={`${id}.name`} label={t('blockName')} />
        <label className={cn(tagStyles.caption, styles.label)}>
          {t('personalDataQuestion')}
        </label>
        <Checkbox
          name={`${id}.instanceData.firstNameEnabled`}
          label={t('firstName')}
        />
        {firstNameEnabled && (
          <RichTextEditor
            key={`${selectedLanguage}.firstName`}
            name={`${id}.message.firstName.${selectedLanguage}`}
            label={t('firstName')}
          />
        )}
        <Checkbox
          name={`${id}.instanceData.lastNameEnabled`}
          label={t('lastName')}
        />
        {lastNameEnabled && (
          <RichTextEditor
            key={`${selectedLanguage}.lastName`}
            name={`${id}.message.lastName.${selectedLanguage}`}
            label={t('lastName')}
          />
        )}
        <Checkbox
          name={`${id}.instanceData.mobileNumberEnabled`}
          label={t('mobileNumber')}
        />
        {mobileNumberEnabled && (
          <RichTextEditor
            key={`${selectedLanguage}.mobileNumber`}
            name={`${id}.message.mobileNumber.${selectedLanguage}`}
            label={t('mobileNumber')}
          />
        )}
        <Checkbox
          name={`${id}.instanceData.emailAddressEnabled`}
          label={t('emailAddress')}
        />
        {emailAddressEnabled && (
          <RichTextEditor
            key={`${selectedLanguage}.emailAddress`}
            name={`${id}.message.emailAddress.${selectedLanguage}`}
            label={t('emailAddress')}
          />
        )}
        <div className={styles.controls}>
          <a onClick={() => setIsOpen(false)}>
            <CheckOutlinedIcon /> {t('save')}
          </a>
          <a onClick={onDelete} className={styles.error}>
            <DeleteOutlineOutlinedIcon /> {t('delete')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default DesignerNode;

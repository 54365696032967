import { useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Sidebar, SidebarButton } from '@adeccoux/tag-ds';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import GridOnIcon from '@mui/icons-material/GridOn';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import TravelExploreOutlinedIcon from '@mui/icons-material/TravelExploreOutlined';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import BrushIcon from '@mui/icons-material/Brush';
import InsightsIcon from '@mui/icons-material/Insights';
import { useFeatureFlag, useHasPermission } from '@common/utils/hooks';
import Permissions from '@common/utils/Permissions';
import { useUILayout } from '@common/utils/UILayoutProvider';
import { useGetTokenQuery } from '@redux/api/portalApiSlice';
import { useGetPermissionsByUserIdQuery } from '@redux/api/securityApiSlice';

import styles from './styles.module.scss';

const SideBar = () => {
  const { t } = useTranslation('layout');
  const location = useLocation();
  const navigate = useNavigate();
  const { hasPermission } = useHasPermission();
  const { isSidebarOpen, setSidebarOpen } = useUILayout();
  const { data: tokenData } = useGetTokenQuery();

  const isOutreachTemplatesEnabled = useFeatureFlag(
    'isOutreachTemplatesEnabled'
  );

  const isNewConversationDesignerEnabled = useFeatureFlag(
    'isNewConversationDesignerEnabled'
  );

  const { data: permissions } = useGetPermissionsByUserIdQuery(tokenData?.oid, {
    skip: !tokenData?.oid,
  });

  const defaultItemSelected = location.pathname?.split('/')[1];

  return (
    <div className="tag-ds">
      <Sidebar
        collapsed={!isSidebarOpen}
        className={cn(styles.sideBar, { [styles.open]: isSidebarOpen })}
        defaultItemSelected={defaultItemSelected}
        onClickSidebarItem={(item) => navigate(`/${item}`)}
        onCollapse={(isCollapsed: boolean) => setSidebarOpen(!isCollapsed)}
      >
        <SidebarButton
          icon={<HomeOutlinedIcon />}
          id="home"
          label={t('dashboard')}
        />
        {hasPermission(Permissions.campaign.read) && (
          <SidebarButton
            icon={<GridOnIcon />}
            id="campaigns"
            label={t('campaigns')}
          />
        )}
        <SidebarButton
          icon={<InsertChartOutlinedIcon />}
          id="reporting"
          label={t('reporting')}
        />
        {hasPermission(Permissions.template.read) && (
          <SidebarButton
            icon={<ChatBubbleOutlineOutlinedIcon />}
            id="conversationTemplates"
            label={t('conversationTemplates')}
          />
        )}
        {isOutreachTemplatesEnabled && (
          <SidebarButton
            icon={<TravelExploreOutlinedIcon />}
            id="outreachTemplates"
            label={t('outreachTemplates')}
          />
        )}
        {hasPermission(Permissions.user.read) && (
          <SidebarButton
            icon={<SupervisedUserCircleIcon />}
            id="userManagement"
            label={t('userManagement')}
          />
        )}
        {permissions.find(
          (permission) => permission.roleName === 'Global Admin'
        ) && (
          <SidebarButton
            icon={<InsightsIcon />}
            id="sanitizer"
            label={t('dataSanitization')}
          />
        )}
        {hasPermission(Permissions.conversationDesigner.access) && (
          <SidebarButton
            icon={<BrushIcon />}
            id={
              isNewConversationDesignerEnabled
                ? 'designer'
                : 'conversationDesigner'
            }
            label={t('conversationDesigner')}
          />
        )}
      </Sidebar>
    </div>
  );
};

export default SideBar;

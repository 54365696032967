import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import Grid, { Row } from '@common/Grid';
import tagStyles from '@common/assets/styles/main.scss';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Select from '@common/reactHookForm/Select';
import Input from '@common/reactHookForm/Input';

import styles from './styles.module.scss';

const ItemSettingsDialog = ({ nodeId, onClose }) => {
  const { t } = useTranslation('conversationDesigner', {
    keyPrefix: 'itemSettingsDialog',
  });

  const dataBinding = useWatch({ name: `${nodeId}.dataBinding` });
  const { setValue } = useFormContext();

  return (
    <Grid>
      <div className={styles.itemSettingsDialog}>
        <div className={styles.modalWrapper}>
          <div
            className={cn(
              tagStyles.tagDs,
              tagStyles.modal,
              tagStyles.docs,
              styles.modal
            )}
          >
            <Row marginBottom={32}>
              <h3>{t('blockSettings')}</h3>
              <button
                id="close"
                type="button"
                className={tagStyles.modalCloseButton}
                onClick={onClose}
              >
                <CloseOutlinedIcon />
              </button>
            </Row>
            <>
              <Row marginBottom={dataBinding === 'custom' ? 16 : 32}>
                <Select
                  name={`${nodeId}.dataBinding`}
                  label={t('saveDataTo')}
                  isSearchable={false}
                  menuPosition="fixed"
                  options={[
                    {
                      value: 'none',
                      label: 'Automatically saved to step name',
                    },
                    { value: 'firstName', label: t('firstName') },
                    { value: 'lastName', label: t('lastName') },
                    { value: 'middleName', label: t('middleName') },
                    { value: 'email', label: t('email') },
                    {
                      value: 'phoneNumbers.cellPhone',
                      label: t('cellPhone'),
                    },
                    { value: 'custom', label: t('customField') },
                  ]}
                  onChange={({ value }) => {
                    setValue(
                      `${nodeId}.dataBinding`,
                      value === 'custom' ? '' : value
                    );
                  }}
                />
              </Row>
              {dataBinding === 'custom' && (
                <Row marginBottom={32}>
                  <Input
                    name={`${nodeId}.dataBinding`}
                    label={t('customField')}
                  />
                </Row>
              )}
              <Row>
                <div className={styles.buttonBar}>
                  <button onClick={onClose}>{t('close')}</button>
                </div>
              </Row>
            </>
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default ItemSettingsDialog;
